import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'FGF11-U6-P83-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page83/key/E1answerKey.png',
    hideBtnFooter: false,
    inputSize: 160,
    textareaStyle: { width: 900 },
    maxLength: 1,
    titleQuestion: [
      {
        num: 'READING',
        title: `
				<br><span style="margin-left:-150px;font-weight:bold">Read the story of Helen Keller's life as a young girl.Are the sentences true or false?Write T or F.</span>
			  `,
        color: '#3d65a1',
      },
    ],
    titleImage: '',
    hintBox: [
      //   {
      //     src: [
      //       'aquarium',
      //       'botanical gardens',
      //       'castle',
      //       'cathedral',
      //       'fountain',
      //       'bay',
      //       'market',
      //       'mosque',
      //       'museum',
      //       'national park',
      //       'palace',
      //       'ruins',
      //       'statue',
      //       'temple',
      //       'theme park',
      //       'tower',
      //       'water park',
      //     ],
      // arrow: true,
      //     width: 500,
      //     borderColor: '#92278f',
      //   },
    ],
    questions: [
      {
        title: `
        <div style=''>
				  <div>
           <p>Helen Keller was born in 1880 in Alabama, with the 
ability to both see and hear, like any normal child. She 
began to speak when she was six months old and 
to walk at the age of one. But six months later, she 
contracted a serious illness, possibly meningitis. After 
she had recovered, her mother noticed that Helen didn’t 
react to sounds, or when she waved her hand in front of 
Helen’s face. The illness had left her both deaf and blind. 
As she grew up, she learned to communicate with the 
daughter of the family cook using sign language that 
they invented together. But Helen was an unhappy child 
and often flew into a rage. Her mother was very patient 
with her and tried to help her. She had read about the 
successful education of another deaf-blind child, and 
when Helen was seven she contacted a special school 
for blind children in Boston. The director of the school 
suggested that Helen work with Anne Sullivan, who was 
herself visually impaired and a recent graduate of the 
school. It was the beginning of a 49-year relationship 
between pupil and teacher. 
Anne travelled to Helen’s home and immediately began 
teaching her ‘finger-spelling’: spelling out words on 
the palm of Helen’s hand. The first word she tried to 
teach her was ‘doll’ – Anne had brought Helen a doll 
as a present. But Helen could not make the connection 
between the letters and the objects and became very 
frustrated. After about a month, however, there was a 
breakthrough. Helen realised that the movements of 
Anne’s fingers on her palm, while she poured water over 
her other hand, signified ‘water’. By the end of the day 
Helen had learned thirty new words.</p>
          </div>
          <div>
          <p><span style="font-weight:bold;margin-right:8px;">1</span> As a baby, Helen had normal sight and hearing. </p>
          
          </div>
          <textarea id='0' rows='2'></textarea>
          <div>
          <p><span style="font-weight:bold;margin-right:8px;">2</span> A relation of one of the family servants gradually taught Helen sign language. </p>
          
          </div>
          <textarea id='1' rows='2'></textarea>
          <div>
          <p><span style="font-weight:bold;margin-right:8px;">3</span> Helen’s mother was very unhappy and frequently got cross with her. </p>
          
          </div>
          <textarea id='2' rows='2'></textarea>
          <div>
          <p><span style="font-weight:bold;margin-right:8px;">4</span> Anne Sullivan was partially blind. </p>
          
          </div>
          <textarea id='3' rows='2'></textarea>
          <div>
          <p><span style="font-weight:bold;margin-right:8px;">5</span> The first word that Helen understood through finger-spelling was ‘doll’. </p>
          
          </div>
          <textarea id='4' rows='2'></textarea>
        </div>
			  `,
        answer: [
          'T',

          'F. A relation of one of the family servants invented a sign language with Helen. / Anne Sullivan taught Helen sign language.',
          'F. Helen was very unhappy and frequently got cross.',

          'T',

          `F. The first word that Helen understood through finger-spelling was 'water'.`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FGF11-U6-P83-E2",
    audio: "Audios/2.38-audio.mp3",
    video: "",
    exerciseKey: '/img/FriendsPlus/Page83/key/E2answerKey.png',
    component: Circle_Write,

    titleQuestion: [
      {
        num: 'LISTENING',
        title: `
          <br><span style="margin-left:-165px"><headphone1 name="2.38" src="Audios/2.38-tieude.mp3"></headphone1>  You will hear an interview with a business expert. Choose the correct answers (A–C).</span>
        `,
        color: '#3d65a1',
      },
    ],

    question: {
      Write: {
        inputStyle: { width: 150 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 6,
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",

        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          `
          A <br> B <br> C 
          
          
          `, `
          A <br> B <br> C 
          
          
          `, `
          A <br> B <br> C 
          
          
          `, `
          A <br> B <br> C 
          
          
          `
        ],
        answers: ['0-30', '1-22', '2-30', '3-30'],
        initialValue: [],
      },
      Layout: `
      <div style="margin-top:35px;margin-left:30px">
     
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='0' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <p style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">1 </span>  The interviewer thinks young people</p>
        <div style="margin-top:46px ; margin-top:-0px">
        <p style="margin-top:-21px">usually set up part-time businesses. </p><p style="margin-top:-21px"> are starting new businesses at a lower age than previosly.</p><p style="margin-top:-21px">should only set up small businesses.</p>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='1' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <p style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">2 </span>  The interviewer doesn’t understand</p>
        <div style="margin-top:46px ; margin-top:-0px">
        <p style="margin-top:-21px">how young people can create successful businesses. </p><p style="margin-top:-21px">how most new businesses actually work.</p><p style="margin-top:-21px">why young people would want to start businesses.</p>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='2' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <p style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">3 </span>  Lisa says that very young people</p>
        <div style="margin-top:46px ; margin-top:-0px">
        <p style="margin-top:-21px">do not achieve business success very often. </p><p style="margin-top:-21px">do not understand what they need to do to start a business</p><p style="margin-top:-21px">have the right qualities for creating businesses.</p>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='3' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <p style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">4 </span>  What does Lisa say about knowledge and experience?</p>
        <div style="margin-top:46px ; margin-top:-0px">
        <p style="margin-top:-21px">They are very necessary. </p><p style="margin-top:-21px">They take a lot of time to develop</p><p style="margin-top:-21px">They can sometimes be a disadvantage.</p>
        </div>
        </div>
        
      </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 6',
    id: 'FGF11-U6-P83-E3',
    audio: '',
    video: '',
    component: T6,
    // exerciseKey: '',
    hideBtnFooter: true,
    inputSize: 160,
    maxLength: 1,
    titleQuestion: [
      {
        num: 'SPEAKING',
        title: `
				<br><span style="margin-left:-170px">Work in groups. Look at the photo and discuss the 
following point.</span>
			  `,
        color: '#3d65a1',
      },
    ],
    titleImage: '',
    recorder: true,
    hintBox: [
      //   {
      //     src: [
      //       'aquarium',
      //       'botanical gardens',
      //       'castle',
      //       'cathedral',
      //       'fountain',
      //       'bay',
      //       'market',
      //       'mosque',
      //       'museum',
      //       'national park',
      //       'palace',
      //       'ruins',
      //       'statue',
      //       'temple',
      //       'theme park',
      //       'tower',
      //       'water park',
      //     ],
      // arrow: true,
      //     width: 500,
      //     borderColor: '#92278f',
      //   },
    ],
    questions: [
      {
        title: `
        <div style='margin-top:10px'>
				  <div>
           <img style="margin-left: 250px;" src="/img/FriendsPlus/Page83/E3/1.jpg" alt="">
          </div>
          <div>
            <p style="font-weight: bold; margin-left:-30px; margin-top:30px">‘It’s better to work alone because people in groups 
spend too much time talking.’ Do you agree? Why? 
Why not?</p>
          </div>
          
        </div>
			  `,
        answer: [''],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 6',
    id: 'FGF11-U6-P83-E4',
    audio: '',
    video: '',
    component: T6,
    // exerciseKey: '',
    hideBtnFooter: true,
    inputSize: 160,
    maxLength: 1,
    titleQuestion: [
      {
        num: 'WRITING',
        title: `
				<br><span style="margin-left:-145px">Write an opinion essay (120–150 words) on the following 
topic.</span>
			  `,
        color: '#3d65a1',
      },
    ],
    titleImage: '',
    textareaStyle: { width: 800 },
    hintBox: [
      //   {
      //     src: [
      //       'aquarium',
      //       'botanical gardens',
      //       'castle',
      //       'cathedral',
      //       'fountain',
      //       'bay',
      //       'market',
      //       'mosque',
      //       'museum',
      //       'national park',
      //       'palace',
      //       'ruins',
      //       'statue',
      //       'temple',
      //       'theme park',
      //       'tower',
      //       'water park',
      //     ],
      // arrow: true,
      //     width: 500,
      //     borderColor: '#92278f',
      //   },
    ],
    questions: [
      {
        title: `
        <div style='margin-top:10px;'>
				  
          <div>
            <p style="font-weight: bold; font-style:italic; margin-left:-30px; margin-top:30px">Many students nowadays choose careers based on only 
their passion. Is it a good decision?
</p>
          </div>
          <div style="margin-left:100px">
          <textarea id='6' rows="9"></textarea>
          </div>
          
        </div>
			  `,
        answer: [''],
      },
    ],
  },
};

export default json;
