import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P60-E1',
    exerciseKey: 'img/FriendsPlus/Page60/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 350,
    maxLength: 20,
    textAlign: 'left',
    hideBtnFooter: true,
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 90,
    },
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style="margin-bottom: 10px;font-size: 22px; color: rgb(146,39,143)">SPEAKING</span>   
       Work in pairs. Ask and answer about the following activities. Use How often ...? Give extra information in your answer.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:25px;'>
              <div style=" gap: 20px; color:rgb(146,39,143); margin-bottom:50px  ">
                <b>Digital activities</b>
                &ensp;do your homework
                &ensp;download music
                &ensp;follow people on Twitter
                &ensp;play games
                &ensp;read / write a blog
                &ensp;shop
                &ensp;use social networking sites
              </div>
              
							<img style="width:20cm" src='img/FriendsPlus/Page60/E1/1.jpg' />
            </div>
              
          </div>
          
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P60-E2',
    exerciseKey: 'img/FriendsPlus/Page60/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 150,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: { fontSize: 25, height: 26, borderBottom: 'none', fontWeight: 'bold' },
    hintBox: [
      {
        src: [
          '<b>Computing verbs</b>',
          'comment',
          'forward',
          'install',
          'log on',
          'print',
          '<s>programme</s>',
          'search',
          'set up',
          'subscribe',
          'update',
          'upload',
        ],
        width: '1000px',
        fontColor: 'rgb(146,39,143)',
        borderColor: 'none',
      },
    ],
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
        <span style="margin-bottom: 10px;font-size: 25px; color: rgb(146,39,143)">VOCABULARY</span>   
       Complete the quiz questions with the verbs below.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style=' position: relative'>
             <img style="position: absolute; top:55px ;left:755px;height:8cm;border: 4px solid red;border-radius:100%" src='img/FriendsPlus/Page60/E2/1.jpg' />
						 <img style="position: absolute; top:300px ;left:700px;height:75mm;border: 4px solid red;border-radius:100%" src='img/FriendsPlus/Page60/E2/2.jpg' />
						 <img style="position: absolute; top:500px ;left:700px;height:60mm;border: 4px solid red;border-radius:100%" src='img/FriendsPlus/Page60/E2/3.jpg' />
						 <img style="height:24cm;" src='img/FriendsPlus/Page60/E2/4.jpg' />
             <span style='position: absolute; top:110px ;left:84px'>#</span>
             <span style='position: absolute; top:180px ;left:276px'>#</span>
             <span style='position: absolute; top:249px ;left:84px'>#</span>
             <span style='position: absolute; top:318px ;left:157px'>#</span>
             <span style='position: absolute; top:423px ;left:84px'>#</span>
             <span style='position: absolute; top:492px ;left:84px'>#</span>
             <span style='position: absolute; top:596px ;left:84px'>#</span>
             <span style='position: absolute; top:665px ;left:84px'>#</span>
             <span style='position: absolute; top:768px ;left:84px'>#</span>
             <span style='position: absolute; top:838px ;left:84px'>#</span>
             
             

          </div>
          
        `,
        answer: [
          'upload',
          'search',
          'update',
          'comment',
          'set up',
          'log on',
          'subscribe',
          'forward',
          'print',
          'install',
        ],
      },
    ],
  },
};

export default json;
