import moment from 'moment'
import 'moment/locale/vi'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Button, Container, Row } from 'reactstrap'
import AudioPlayer from '../../../AudioPlayer'
import FooterModal from '../../../FooterModal'
import TitleQuestion from '../../../TitleQuestion'
import PointDot, { RenderLineTo, calculateResultLine } from './PointDot'
import StarModal from './StarModal'
moment.locale('vi')

function MatchDots(props) {
	const [state, setState] = React.useState({
		booleanArray: [],
		isDisabledRetry: true,
		isDisabledSubmit: true,
		imagesQuestion: null,
	})
	const [Question, setQuestion] = React.useState(null)
	const [modalStar, setModalStar] = React.useState(false)

	React.useEffect(() => {
		const clonedProps = JSON.parse(JSON.stringify(props))
		const question = clonedProps.question
		//initialDrawLines
		question.DrawLines.isDoing = true
		question.DrawLines.userAnswers = Array.from(question.DrawLines.initialValue)
		console.log(props.imagesQuestion)
		question.imagesQuestion = props.imagesQuestion
		//
		const audioUrl = clonedProps.audio
		const videoUrl = clonedProps.video

		setState((prevState) => ({ ...prevState, audioUrl, videoUrl, question }))
		setQuestion(question)
	}, [props])

	const onClickImage = React.useCallback(
		(item, index, rowIndex) => () => {
			const url = item.audioImgUrl || item.videoUrl
			if (url) {
				const timeStamp = Date.now()
				// if (url?.includes(".mp4")) {
				//   setState((prevState) => ({ ...prevState, videoUrl: url }));
				//   return videoRef.current?.toggleModal("isVisibled");
				// }
				if (state.audioUrl) {
					document.getElementById('myAudio')?.pause()
				}
				setState((prevState) => ({
					...prevState,
					audioImgUrl: url,
					timeStamp,
				}))
			}
		},
		[state.audioUrl]
	)

	const renderImage = React.useCallback(
		(images, rowIndex) => {
			return images.map((item, index) => {
				let imageSrc = item.url
				console.log(item)
				let cursor = ''
				if (item.audioImgUrl) {
					cursor = `url(img/volume.png) 12 12, auto`
				}
				if (item.videoUrl) {
					cursor = `url(img/video.png) 12 12, auto`
				}
				return (
					<div key={index} style={{ display: 'grid', position: 'relative' }}>
						<img
							alt='...'
							src={imageSrc}
							style={{ cursor, width: '100%' }}
							onClick={onClickImage(item, index, rowIndex)}
						/>
					</div>
				)
			})
		},
		[onClickImage]
	)
	console.log(state)
	const renderImageQuestion = React.useMemo(() => {
		return state.question?.imagesQuestion?.map((rowItem, rowIndex) => {
			return (
				<div
					key={rowIndex}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{renderImage(rowItem, rowIndex)}
				</div>
			)
		})
	}, [renderImage, state.data])

	// hiển thị ngôi sao
	const onModalStar = () => {
		setModalStar(true)
		setTimeout(() => {
			setModalStar(false)
		}, 3000)
	}
	const onSubmit = React.useCallback(() => {
		const { DrawLines } = Question
		DrawLines.isDoing = false //
		DrawLines.resultLines = calculateResultLine(DrawLines.userAnswers, DrawLines.answers)
		//State
		const { star, booleanArray, percent, resultString } = DrawLines.resultLines

		const studentAnswer = DrawLines.userAnswers
		const params = {
			score: percent,
			unit: props.unit,
			results: resultString,
			exerciseId: props.id,
			sreatedDate: moment().format(),
			studentAnswer: JSON.stringify(studentAnswer),
		}
		props.postAnswerToApi(params)

		//update
		setState((pre) => ({
			...pre,

			isDisabledRetry: false,

			isDisabledSubmit: true,

			booleanArray,

			star,
		}))
		setQuestion((pre) => ({ ...pre, DrawLines }))
		onModalStar()
	}, [Question, props])

	const onRetry = React.useCallback(() => {
		const { DrawLines } = Question
		//initialDrawLines
		DrawLines.isDoing = true //
		DrawLines.userAnswers = Array.from(DrawLines.initialValue)
		//initialState
		const booleanArray = []
		//update
		setState((pre) => ({ ...pre, booleanArray, isDisabledRetry: true }))
		setQuestion((pre) => ({ ...pre, DrawLines }))
	}, [Question])

	const transform = React.useCallback(
		(node, index) => {
			if (node.type === 'tag' && node.name === 'input' && node.attribs.type === 'boxMatch') {
				//render tag input boxMatch
				const id_dot = node.attribs.id
				return (
					<PointDot
						key={index}
						id={id_dot}
						data={Question.DrawLines}
						setData={(data) => setQuestion((pre) => ({ ...pre, DrawLines: data }))}
					/>
				)
			}
		},
		[Question]
	)

	const checkEnableSubmit = React.useCallback(() => {
		setQuestion((question) => {
			const {
				DrawLines: { userAnswers, answers },
			} = question
			if (!userAnswers) return null
			const isSubmit = userAnswers.length >= answers.length
			setState((pre) => ({ ...pre, isDisabledSubmit: !isSubmit }))

			return question
		})
	}, [])

	const onStopRecording = React.useCallback((recordURL) => {
		setState((prevState) => ({ ...prevState, recordURL: recordURL.blobURL }))
	})

	const renderContent = React.useCallback(
		(value) => {
			const { Layout, DrawLines } = Question
			return (
				<div className='match-container' style={{ position: 'relative' }} onClick={checkEnableSubmit}>
					{ReactHtmlParser(Layout, { transform })}
					<RenderLineTo
						userAnswers={DrawLines.userAnswers}
						isDoing={DrawLines.isDoing}
						listBoolean={DrawLines.resultLines?.listBoolean}
						checkEnableSubmit={checkEnableSubmit}
					/>
				</div>
			)
		},
		[Question, checkEnableSubmit, transform]
	)

	if (!Question) return null

	return (
		<Container>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<div>
					<Row>
						<TitleQuestion titleQuestion={props.titleQuestion} />
					</Row>
					<Row
						style={{
							display: 'flex',
							justifyContent: 'start',
							alignItems: 'start',
						}}
					>
						{renderContent()}
					</Row>
					<StarModal isModal={modalStar} star={state.star} />
				</div>
			</div>
			<Row
				style={{
					minWidth: 985,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{renderImageQuestion}
			</Row>
			{state.audioImgUrl && (
				<AudioPlayer
					id='audio'
					timeStamp={state.timeStamp}
					isPause={state.isPause}
					audioUrl={state.audioImgUrl}
					// isVisibled={props.isVisibled}
				/>
			)}
			{/* {testButton({ Question, setQuestion })} */}

			<FooterModal
				onRetry={onRetry}
				onSubmit={onSubmit}
				audioUrl={state.audioUrl}
				result={state?.booleanArray}
				studentAnswer={state.studentAnswer}
				exerciseKey={props.exerciseKey}
				isDisabledRetry={state.isDisabledRetry}
				isDisabledSubmit={state.isDisabledSubmit}
				hideBtnFooter={props.hideBtnFooter} //not answer
			/>
		</Container>
	)
}

export default MatchDots

// test
function testButton({ Question, setQuestion }) {
	return (
		<div>
			<Button
				onClick={() => {
					setQuestion((pre) => {
						const { DrawLines } = pre
						DrawLines.userAnswers = Array.from(DrawLines.answers)
						return { ...pre, DrawLines }
					})
				}}
			>
				điền đáp án test
			</Button>
			<Button
				onClick={() => {
					console.log('🚀 ~ DrawLines.userAnswers: ', Question.DrawLines.userAnswers)
				}}
			>
				log userAnswers
			</Button>
		</div>
	)
}
