import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P28-E1',
		audio: '',
		component: T6,
		// recorder: true,
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '1',
				title: '<span style="color: rgb(2, 146, 97)">SPEAKING</span> Ask and answer the questions in pairs.',
				color: '#8e258c',
			},
		],
		questions: [
			{
				title: `
        <div>
						<div><b>1.</b> How often do you watch films? How do you watch them: at the cinema, on DVD, on live TV, or online?  </div>
						<div><b>2.</b> Are some types of film better to watch at the cinema than on TV? Why? Give examples.  </div>
        </div>
          `,
				answer: ['M', 'M', 'S', 'S', 'S'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P28-E2',
		audio: 'Audios/1.23-audio.mp3',
		component: T6,
		inputSize: 1000,
		// exerciseKey: 'img/FriendsPlus/Page28/E1/Key/answerKey.png',
		exerciseKey: 'img/FriendsPlus/Page28/Key/E2answerKey.png',
		textareaStyle: {
			// background: 'none',
			width: 400,
			fontSize: 22,
			height: 250,
		},
		titleQuestion: [
			{
				num: '2',
				title:
					'<span style="font-size: 23px"><headphone1 name="1.23" src="Audios/1.23-tieude.mp3"></headphone1> Read and listen to the dialogue. Who do you think is more enthusiastic about seeing the film: Jack or Ellie? Find evidence for your opinion.</span> ',
				color: '#8e258c',
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page28/E1/1.jpg' }]],
		questions: [
			{
				title: `
        <div style='display: flex'>
        <img style="width: 16cm" src = 'img/FriendsPlus/Page28/E1/1.jpg' >
        
        <div style="margin-left:50px">
        <b>Answer:</b>
        <div><textarea id='0' row='3'></div></div>
        </div>
       
          `,
				answer: [
					'Ellie is more enthusiastic. She wants to see the film even though it has already started',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P28-E3',
		inputSize: 50,
		textareaStyle: {
			// background: 'none',
			width: 500,
			fontSize: 21,
			height: 300,
		},
		titleImage: '',
		textAlign: 'center',
		component: T6,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page28/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title:
					'<span  >Read the <span style="color: orange">Learn this!</span> box and complete the rules. Use the dialogue to help you.How many examples of this tense are there in the dialogue?</span>',
				color: '#8e258c',
			},
		],
		questions: [
			{
				title: `
				<div style='display: flex; position: relative;'>
					<div div style=" position: relative; ">
          <div style=""><img style='width:16cm' src ='img/FriendsPlus/Page28/E1/2.jpg' ></div>
        		<div style="  position: absolute; top: 75px; left: 37px; width: 179px;"><input id='0' font-size='21px' width='179px' border-bottom='0px'></input></div>
    				<div style="  position: absolute; top: 75px; left: 198px; width: 179px "><input id='1' font-size='21px' width='179px' border-bottom='0px'></input></div>
    				<div style="  position: absolute; top: 204px; left: 102px; width: 179px; "><input id='2' font-size='21px' width='179px' border-bottom='0px'></input></div>
        		<div style="  position: absolute; top: 231px; left: 252px; width: 179px;"><input id='3' font-size='21px' width='179px' border-bottom='0px'></input></div>
        		<div style="  position: absolute; top: 289px; left: 111px; width: 179px; "><input id='4' font-size='21px' width='179px' border-bottom='0px'></input></div>
        		<div style="  position: absolute; top: 289px; left: 360px; width: 179px "><input id='5' font-size='21px' width='179px' border-bottom='0px'></input></div>
        		<div style="  position: absolute; top: 410px; left: 211px; width: 179px; "><input id='6' font-size='21px' width='179px' border-bottom='0px'></input></div>
        	</div>
					<div>
						<b>How many examples of this tense are there in the dialogue?</b>
						<div>
						<textarea id='7' row='3'>
						</div>
					</div>
				</div>
				 <img style="width: 15cm" src = 'img/FriendsPlus/Page28/E1/1.jpg' >
          `,
				answer: [
					'has',
					'been',
					"haven't been",
					'since',
					'have been',
					'for',
					'have been',
					`There are six examples of present perfect continuous: I've been waiting for ages. What have you been doing? I've been trying to phone you since 7.30 … I've been looking forward to it for weeks. It hasn't been raining. I've been running for 25 minutes!`,
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P28-E2',
		// audio: 'Audios/1.23.mp3',

		inputSize: 700,
		textareaStyle: { width: 500 },
		maxLength: 300,
		titleImage: '',
		textAlign: 'left',
		component: T6,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page28/Key/E4answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: ' Write questions about Ellie and Jack from exercise 2. Use the present perfect continuous.',
				color: '#8e258c',
			},
		],
		questions: [
			{
				title: `
        <div>
          <div><b>1.</b> How long / Ellie / wait?</div>
          #
          <div style="margin-top:15px";><b>2.</b> How long / Ellie / look forward to the film?</div>
          #
          <div style="margin-top:15px"><b>3.</b> Why / Ellie / look forward to the film?</div>
          #
          <div style="margin-top:15px"><b>4.</b> How long / Jack / run?</div>
          #
          <div style="margin-top:15px"><b>5.</b> Why / Jack / run?</div>
          #

        </div>
          `,
				answer: [
					'How long has Ellie been waiting ?',
					'How long has Ellie been looking forward to the film ?',
					'Why has Ellie been looking forward to the film ?',
					'How long has Jack been running ?',
					'Why has Jack been running ?',
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P28-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page28/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `Read the <span style="color:#ee9933">Learn this!</span> box. Underline an example of the 
        present perfect simple for a completed action in exercise 2.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		component: Circle_Write,

		question: {
			Write: {
				isHiddenCheck: true,
				maxLength: 11,
				inputStyle: { width: 149, fontSize: 23, borderBottom: 'none', textAlign: 'center' },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					// borderRadius: "50%",
					borderColor: 'transparent',
				},
				selectWordStyle: {
					// padding: 5,
					borderBottom: 'solid 2px',
					borderColor: '#00aeef',
				},
				limitSelect: 12,
				listWords: [
					`At_last! I’ve_been_waiting_for_ages. Where_have_you_been? What_have_you_been_doing?<br>
          My_bus_didn’t_come. I’ve_been_trying_to_phone_you_since_7.30_…<br>
          You’re_25_minutes_late! The_film_has_started.<br>
          Sorry. Do_you_still_want_to_see_it?<br>
          Yes,_I_do. I’ve_been_looking_forward_to_it_for_weeks. It_stars_my_favourite_actor. And_I’ve_already_bought_the_tickets!<br>
          Let’s_go_inside_then.<br>
          OK. But_why_is_your_hair_wet? It_hasn’t_been_raining.<br>
          That’s_sweat. I’ve_been_running_for_25_minutes! And_I_haven’t_eaten. Can_we_see_the_film_later?
         `, //0
				],
				answers: ['0-54', '0-106', '0-180'],
				initialValue: [],
			},
			Layout: `
        <div style='position: relative;'>
          <img style='height:' src='img/FriendsPlus/Page28/E5/1.jpg' />
        </div> 
        <div style='display:flex;line-height:45px'>
          
          <div style='margin-right:50px; font-weight:bold'>
            Ellie<br>
            Jack<br> 
            Ellie<br>
            Jack<br> 
            Ellie<br><br>
            Jack<br> 
            Ellie<br>
            Jack<br> 
            
          </div>
          <div>
            <input id='0'  type='Circle' />
          </div>
        </div>
      `,
		},
	},
	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P28-E2',
		// audio: 'Audios/1.23.mp3',

		inputSize: 400,
		textareaStyle: { width: 500 },
		titleImage: '',
		textAlign: 'center',
		component: T6,
		maxLength: 32,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page28/Key/E6answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title:
					' <span style="color: #92278f">USE OF ENGLISH</span> Complete the sentences using the correct form of the words in brackets.Do not change the order of the words.You can add up to three other words.',
				color: '#8e258c',
			},
		],
		questions: [
			{
				title: `
        <div>
          <div>
            <b>1.</b> I’m not sure what’s happening in this film because I # (only / watch / it) for a few minutes.
          </div>
          <div>
            <b>2.</b> How long # (Alex / be / member) of the film club?
          </div>
          <div>
            <b>3.</b> I can’t go to the cinema because # (I / finish / my homework) yet.
          </div>
          <div style="letter-spacing:1px">
            <b>4.</b> Those two girls in the front row # (talk / each other) since the film started!
          </div>
        </div>
          `,
				answer: [
					'have only been watching it',
					'has Alex been a member',
					"I haven't finished my homework",
					'have been talking to each other',
				],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P28-E2',
		inputSize: 700,
		textareaStyle: { width: 500 },
		maxLength: 300,
		titleImage: '',
		textAlign: 'left',
		component: UI,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '7',
				title: '<span style="color: rgb(2, 146, 97)">SPEAKING</span> Work in pairs. Take turns to be A and B.',
				color: '#8e258c',
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page28/E1/3.jpg' }]],
		questions: [
			{
				title: `
          
          `,
				answer: [
					'How long has Ellie been waiting ?',
					'How long has Ellie been looking forward to the film ?',
					'Why has Ellie been looking forward to the film ?',
					'How long has Jack been running ?',
					'Why has Jack been running ?',
				],
			},
		],
	},
}

export default json
