import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P112-U8-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `<strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>8A</span>City problems</strong> <br />
         <strong style='color:#860052'>1</strong> Add the words below to the table.`,
        // color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 23,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    exerciseKey: 'img/FriendsPlus/Page112/Key/U8E1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style='width: 1150px; >
          <div style='font-size:21px;dislay:flex'>
              <div style="color:rgb(146,39,143);  ">
              <b>
              &ensp;hustle
              &ensp;overpopulation
              &ensp;polluted
              &ensp;crowds
              &ensp;congestion
            </div>

          </div>
    <style>
  table {
    border-collapse: collapse;
  }

  td, th {
    border: 1px solid black;
    padding: 8px;
  }
</style>

<table>
  <tr>
    <td>The city air is</td>
    <td>not fresh enough. <br> dirty. <br> <sup>1</sup> <input/>.</td>
  </tr>
  <tr>
    <td>We took a 3-day <br> vacation to escape the city's</td>
    <td>noise pollution.
    <br> <sup>2</sup><input/>.
    <br> bustle. <br>
     <sup>3</sup><input/>.</td>
  </tr>
  <tr>
    <td>The car journey was <br> slow because of</td>
    <td>slow-moving traffic. <br>
     traffic jams. <br>
      <sup>4</sup><input/>. <br>
     an accident.</td>
  </tr>
  <tr>
    <td>Because there <br> are a lot of <br> job opportunities, <br> big cities often face</td>
    <td><sup>5</sup><input/>. <br> crime. <br> shortage of accommodation.</td>
  </tr>
</table>





				`,
        answer: ['polluted', 'hustle', 'crowds', 'congestion', 'overpopulation'],
      },
    ],
  },
  2: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P112-U8-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: "<strong style='color:#860052'>2</strong> Complete the table with the correct form of the words.",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
      border: 'none',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page112/Key/U8E2answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
<style>
  table {
    border-collapse: collapse;
    width:1000px;
  }

  td, th {
    border: 1px solid black;
    padding: 8px;
    text-align:center;
  }
</style>

<table>
  <tr>
    <td><b> Verb </b></td>
    <td><b> Noun </b></td>
  </tr>
  <tr>
    <td>populate</td>
    <td> <input/> </td>
  </tr>
  <tr>
    <td><input/> </td>
    <td>immigrant
 </td>
  </tr>
  <tr>
    <td>commute
 </td>
    <td><input/> </td>
  </tr>
  <tr>
    <td> <input/></td>
    <td> benefit
</td>
  </tr>
  <tr>
    <td>dwell
 </td>
    <td><input/> </td>
  </tr>
</table>
				`,
        answer: ['population', 'immigrate', 'commuter', 'benefit', 'dweller'],
      },
    ],
  },
  3: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P112-U8-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Use the words in the table in exercise 2 to complete the sentences.',
        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page112/Key/U8E3answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
       <div style='line-height:2;'>
          <div style="display: flex; column-gap: 20px; margin-top:20px">
            <strong>a</strong>
            <div>
              <div>There has been an increase in the city’s
              <input />  for the last five years.
              </div>

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>b</strong>
            <div>
             <div>  One
              <input /> of tourism is the introduction of culture to the other countries.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>c</strong>
            <div>
              <div> The gorillas
              <input />   in the high rainforests.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>d</strong>
            <div>
            <div> Sarah is no longer a
              <input /> She is now living in a <br>
              small apartment near her work.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>e</strong>
            <div>
              <div> The <input />  cannot speak English, so finding a job is impossible for her.
              </div>
            </div>
          </div>

         </div>
				`,
        answer: ['population', 'benefit', 'dwell', 'commuter', 'immigrant'],
      },
    ],
  },
  4: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P112-U8F',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `<strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>8F</span>Future cities</strong> <br />
         Use the correct form of the following words to complete the following sentences. `,
        // color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 170,
    exerciseKey: 'img/FriendsPlus/Page112/Key/U8FanswerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style='width: 1150px; >
          <div style='font-size:21px;dislay:flex'>
              <div style="color:rgb(146,39,143);line-height:2;  ">
              <b>
              &ensp;effect
              &ensp;innovate
              &ensp;autonomy
              &ensp;safe
              &ensp;convenient
              &ensp;credit
             </b>


            </div>

          </div>
      </div>
         <div style='line-height:1.5;'>
          <div style="display: flex; column-gap: 20px; margin-top:20px">
            <strong>1</strong>
            <div>
              <div>Recent
              <input /> in means of transport have made our life much easier.
              </div>

            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
             <div>  Thanks to internet, weather and traffic conditions are <br>
             communicated to users of transport, enhancing the
              <input /> communicated to users of transport, enhancing the <input />
              on every city street.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div> City-dwellers now use an
              <input />  amount of electricity for lighting, heating and daily gadgets.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
            <div> Artificial intelligence has made it possible to introduce <br>
            lots of household devices which act
              <input />
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div> In the hustle and bustle of city life, people appreciate
              cost <input /> and time-efficient activities.
              </div>
            </div>
          </div>
    
         </div>
          
          
				`,
        answer: ['innovations', 'convenience', 'safety', 'incredible', 'autonomously', 'effective'],
      },
    ],
  },
};

export default json;
