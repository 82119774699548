import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P128-U8.3-E1',
    audio: '',
    video: '',
    titleQuestion: [
      {
        num: '1',
        title: `<span style="font-weight:bold;font-size:30px">Match the reply questions (a–e) with the statements (1–5).</span>
`,
        color: 'rgb(149,54,159)',
        // prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: ['<b>Compound nouns</b>', ''],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page128/key/E1answerKey.png',
    stylesTextInput: { borderBottom: 'dotted' },
    inputSize: 700,
    fontSize: 55,
    maxLength: 200,
    // checkUppercase: true,

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '560px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 0
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '60px',
              left: '560px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '105px',
              left: '560px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '150px',
              left: '560px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '195px',
              left: '560px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '195px',
              left: '710px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 5
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '20px',
              left: '710px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 6
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '60px',
              left: '710px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 7
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '105px',
              left: '710px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 8
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '150px',
              left: '710px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #2eb6e1',
              background: 'white',
            },
          }, // 9
        ],
        answers: ['0-8', '1-6', '2-5', '3-7', '4-9'],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
        <input id='0' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='1' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='2' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='3' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='4' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='5' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='6' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='7' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='8' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='9' style="margin-bottom:20px" type= 'boxMatch' />
        <div style="display:flex; font-size:29px">
					<div style='margin-right: 200px'>
						<b>1</b>&emsp;Yesterday was Monday.<br>
						<b>2</b>&emsp;Nothing exciting happened on holiday.<br>
						<b>3</b>&emsp;Our dog had never run away before.<br>
						<b>4</b>&emsp;The train had to stop suddenly.<br>
            	<b>5</b>&emsp;My laptop has broken again.
				 
					</div>

						<div style="">

								<b>a</b>&emsp;Didn’t it?<br>
								<b>b</b>&emsp;Did it?<br>
								<b>c</b>&emsp;Was it?<br>
								<b>d</b>&emsp;Has it?<br>
                <b>e</b>&emsp;Hadn’t it?</div>
						</div>
				</div>
       

      </div>
        
      `,
    },
  },
  2: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P128-U8.3-E2',
    audio: '',
    video: '',
    component: T6,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: '<span style="font-size:30px">Write reply questions for these statements. </span>',
        color: 'rgb(149,54,159)',
      },
    ],
    stylesTextInput: {
      textAlign: 'left',
      // borderBottom: 'none',
      backgroundColor: 'transparent',
    },
    inputSize: 310,
    exerciseKey: 'img/FriendsPlus/Page128/key/E2answerKey.png',
    questionImage: [],

    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style="display:flex;flex-direction:column;font-size:25px">
           <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">1</strong> I want to go somewhere different for my holiday this year.</div>
<input/>            <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">2</strong>Nobody told me about your accident.</div>
<input/>              <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">3</strong> I’m not ready to go out yet.</div>
<input/>                <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">4</strong>Our friends hardly ever go out during the week.</div>
<input/>                  <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">5</strong> It costs a lot to send texts abroad.</div>
<input/>                    <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">6</strong> Gorillas can’t swim.</div>
<input/>                        <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">7</strong> I’d rather spend this weekend at home.</div>
<input/>                        <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">8</strong> My parents wouldn’t enjoy skiing.</div>
<input/>
     
          </div>
				`,
        answer: [
          'Do you?',
          "Didn't they?",
          "Aren't you?",
          "Don't they?",
          'Does it?',
          "Can't they?",
          'Would you?',
          "Wouldn't they?",
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P128-U8.4-E1',
    audio: '',
    video: '',
    component: T6,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: '<span >Match the question tags below with statements 1–8. </span>',
        color: 'rgb(149,54,159)',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      marginTop: 10,
    },
    inputSize: 250,
    maxLength: 15,
    exerciseKey: 'img/FriendsPlus/Page128/key/E1.1answerKey.png',
    questionImage: [],

    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style="display:flex;flex-direction:column;">
<p style="color:rgb(247,144,61);word-spacing:15px">aren’t I?  didn’t you?   had you?   shall we? <br>   were they?    weren’t they?   will we?   wouldn’t you? </p>
           <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">1</strong> You hadn’t seen your friends for years, #</div>
      
            <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">2</strong>Let’s go away for a few days,#</div>
         
              <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">3</strong> You’d rather be alone this evening,#</div>
           
                <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">4</strong>Nobody was surprised at his decision, #</div>
             
                  <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">5</strong> We won’t arrive on time,#</div>
               
                    <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">6</strong> Your parents were teachers,#</div>
                 
                        <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">7</strong> I’m the best chess player in the school,#</div>
                 
                        <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">8</strong> You had double maths this morning,#</div>
                 
           
     
          </div>
				`,
        answer: [
          'had you?',
          'shall we?',
          "wouldn't you?",
          'were they?',
          'will we?',
          "weren't they?",
          "aren't I?",
          "didn't you?",
        ],
      },
    ],
  },
  4: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P128-U8.4-E2',
    audio: '',
    video: '',
    component: T6,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: '<span>Add a question tag to these statements.  </span>',
        color: 'rgb(149,54,159)',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      marginTop: 10,
    },
    inputSize: 150,
    maxLength: 7,
    exerciseKey: 'img/FriendsPlus/Page128/key/E1.2answerKey.png',
    questionImage: [],

    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style="display:flex;flex-direction:column;">
      
           <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">1</strong> Is isn’t too late to change my mind, #?</div>
      
            <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">2</strong>Nobody wants to go home,#?</div>
         
              <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">3</strong>We hadn’t finished that pizza,#?</div>
           
                <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">4</strong>You can’t see my phone, #?</div>
             
                  <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">5</strong> That shop is never open,#?</div>
               
                    <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">6</strong> Your dad wouldn’t give us a lift,#?</div>
                 
                        <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">7</strong> You’re hardly ever at home,#?</div>
                 
                        <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">8</strong> Let’s buy some popcorn,#?</div>
                          <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">9</strong> I’m your best friend,#?</div>
                            <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">10</strong> They aren’t listening,#?</div>
                              <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">11</strong> Calls from abroad cost a lot,#?</div>
                                <div style="display:flex;flex-direction:row"> <strong style="margin-right:20px">12</strong>I’ll see you soon,#?</div>
                 
           
     
          </div>
				`,
        answer: [
          'is it',
          'do they',
          'had we',
          'can you',
          'is it',
          'would he',
          'are you',
          'shall we',
          "aren't I",
          'are they',
          "don't they",
          "won't I",
        ],
      },
    ],
  },
};

export default json;
