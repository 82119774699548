import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	5: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P13-E5',
		audio: 'Audios/1.08-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page13/E5/Key/answerKey.png',
		component: Circle_Write,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '5',
				title: `
				<span style='margin-right: 10px;'><headphone1 name="1.08" src="Audios/1.08-tieude.mp3"></headphone1></span>
				Listen to four people talking about their backgrounds 
				and their families. Circle the correct answers (a–c).
				`,
				color: '#92278f',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 5, border: 'solid 3px', borderColor: 'gray' },
				limitSelect: 1,
				listWords: [
					`
          a|&emsp;was&ensp;born&ensp;in&ensp;the&ensp;UK.
          <br> b|&emsp;is&ensp;going&ensp;to&ensp;have&ensp;a&ensp;change&ensp;of&ensp;career.
          <br> c|&emsp;wants&ensp;to&ensp;study&ensp;law.
         `, // 0
					`
          a|&emsp;have&ensp;owned&ensp;a&ensp;number&ensp;of&ensp;farms.
          <br> b|&emsp;sold&ensp;the&ensp;first&ensp;farm&ensp;and&ensp;bought&ensp;a&ensp;bigger&ensp;one.
          <br> c|&emsp;have&ensp;owned&ensp;the&ensp;same&ensp;farm&ensp;for&ensp;many&ensp;years.
         `, // 1
					`
          a|&emsp;brought&ensp;up&ensp;her&ensp;daughter&ensp;in&ensp;France.
          <br> b|&emsp;isn’t&ensp;married.
          <br> c|&emsp;has&ensp;been&ensp;married&ensp;twice.
         `, // 2
					`
          a|&emsp;inherited&ensp;and&ensp;ran&ensp;a&ensp;successful&ensp;family&ensp;business.
          <br> b|&emsp;didn’t&ensp;have&ensp;a&ensp;good&ensp;education.
          <br> c|&emsp;left&ensp;Britain&ensp;many&ensp;years&ensp;ago.
         `, // 3
				],
				answers: ['0-22', '1-74', '2-74', '3-74'],
				initialValue: [],
			},
			Layout: `
			<div style="display: flex; flex-direction: column; gap: 15px;">
				<div style="display: flex; gap: 20px;">
					<strong>1</strong>
					<div>
						<div>Bilal’s dad</div>
						<input id='0' type='Circle' /> 
					</div>
				</div>
				<div style="display: flex; gap: 20px;">
					<strong>2</strong>
					<div>
						<div>Sandra’s family</div>
						<input id='1' type='Circle' /> 
					</div>
				</div>
				<div style="display: flex; gap: 20px;">
					<strong>3</strong>
					<div>
						<div>Charlotte’s mum</div>
						<input id='2' type='Circle' /> 
					</div>
				</div>
				<div style="display: flex; gap: 20px;">
					<strong>4</strong>
					<div>
						<div>Callum’s parents</div>
						<input id='3' type='Circle' /> 
					</div>
				</div>
			</div>
      `,
		},
	},
	6: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P13-E6',
		audio: 'Audios/1.08-audio.mp3',
		video: '',
		component: T6,
		textAlign: 'center',
		inputSize: 140,
		maxLength: 18,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page13/E6/Key/answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title: `
				<span style='margin-right: 10px;'><headphone1 name="1.08" src="Audios/1.08-tieude.mp3"></headphone1></span>
				Complete the sentences with the past simple form of the verbs below. 
				Then listen again and check.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
        <div style="max-width: 892px; color: rgb(146, 39, 144); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span>be</span><span>buy</span><span>emigrate</span><span>fall</span>
          <span>get</span><span>get</span><span>grow up</span><span>leave</span>
          <span>move</span><span>not go</span><span>not leave</span><span>not retire</span>
          <span>start</span>
        </div>
				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>My grandparents # from Pakistan in the 1960s.</div>
					</div>
					
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>She # home until last summer, when she # married.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>I # in the village where my family has lived for generations.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div>They managed to save quite a bit of money and eventually # a small farmhouse with some land.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">5</span>
						<div>They # in love, # engaged after a week and were married a month later.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">6</span>
						<div>I # brought up by my mum after she # back to the UK.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">7</span>
						<div>They # school at sixteen and # to university.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">8</span>
						<div>They # a successful business and # until they were in their seventies.</div>
					</div>

				</div>
        `,
				answer: [
					'emigrated',
					"didn't leave",
					'got',
					'grew up',
					'bought',
					'fell',
					'got',
					'was',
					'moved',
					'left',
					"didn't go",
					'started',
					"didn't retire",
				],
			},
		],
	},
	7: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P13-E7',
		// audio: 'Audios/1.08.mp3',
		video: '',
		component: T6,
		// recorder: true,
		hideBtnFooter: true,
		hintBox: [
			{
				src: [`How long has your family lived in this city / town?`],
				borderColor: 'rgb(211, 181, 214)',
				// fontColor: 'rgb(67 63 151)',
				width: '700px',
				backgroundColor: '#D3B5D6',
				arrow: true,
				position: 1,
			},
			{
				src: [`We’ve been here for about ten years. Before that we lived in Long An.`],
				borderColor: 'rgb(211, 181, 214)',
				// fontColor: 'rgb(67 63 151)',
				width: '700px',
				backgroundColor: '#D3B5D6',
				arrow: true,
				position: 2,
			},
		],
		textAlign: 'center',
		inputSize: 140,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page13/E7/Key/answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>
				Work in pairs. Ask and answer about your family and your ancestors. 
				Give extra information where you can.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>How long has your family lived in this city / town?</div>
					</div>
					
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>
							Did any of them move from another part of Viet Nam? 
							From where? When?
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>
							Did any of your ancestors or members of your family emigrate to another country? 
							Where to? When?
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div>Are you related to anyone famous? If so, who?</div>
					</div>
				</div>

				<div style='margin-top: 20px'>
					<hintbox id='0'></hintbox>
				</div> 
				<div style='margin-top: 60px; margin-left: 100px;'>
					<hintbox id='1'></hintbox>
				</div> 
        `,
				answer: [
					'emigrated',
					"didn't leave",
					'got',
					'grew up',
					'bought',
					'fell',
					'got',
					'was',
					'moved',
					'left',
					"didn't go",
					'started',
					"didn't retire",
				],
			},
		],
	},
}

export default json
