import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
  1: {
    unit: 'Unit 1',
    id: 'FGF11-U1-P23-E1',
    audio: '',
    video: '',
    component: T6,
    maxLength: 1,
    textAlign: 'center',
    isHiddenCheck: true,
    inputSize: 50,
    exerciseKey: 'img/FriendsPlus/Page23/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="margin-right: 10px; color: rgb(0, 111, 147); font-size: 30px;">Reading</span>
          <div style="margin-left: 30px;">
            Read the text about staying young. 
            Match the headings (A–G) with the paragraphs (1–5). 
            There are two extra headings.
          </div>
          
          `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style="margin-top: 20px; display: flex; gap: 20px; flex-wrap: wrap; align-items: start; font-size:20px">
          <div style="flex: 1; padding: 10px 50px; background-color: rgb(227, 243, 244); max-width: 630px;">
            <div>
              <div><span style="margin-right: 10px;">1</span><span>#</span></div>
              <div style="margin-top: 10px;">
                As people grow older, they often think back to the 
                days of their youth. They remember the great friends 
                of those days, great times together, and how bright 
                the future looked. Of course, not everything was 
                perfect − but it can look that way as we get older. And 
                this can make people feel pessimistic and bitter.
              </div>
            </div>
            <div style="margin-top: 20px;">
              <div><span style="margin-right: 10px;">2</span><span>#</span></div>
              <div style="margin-top: 10px;">
                It doesn’t need to be that way. There are many secrets 
                to staying young in spirit well into old age. Why put up 
                with feeling miserable when you can be enthusiastic 
                instead? Here are some tips to keep you young at heart 
                as you grow older.
              </div>
            </div>
            <div style="margin-top: 20px;">
              <div><span style="margin-right: 10px;">3</span><span>#</span></div>
              <div style="margin-top: 10px;">
                Relationships are extremely important. Family gives 
                you comfort and support, and catching up with old 
                friends can help older people to feel young. Making 
                new friends is important, too – but make sure they are 
                optimistic, enthusiastic people.
              </div>
            </div>
            <div style="margin-top: 20px;">
              <div><span style="margin-right: 10px;">4</span><span>#</span></div>
              <div style="margin-top: 10px;">
                Bad things happen to all of us. We split up with a 
                boyfriend or girlfriend, and later we may have to do a 
                boring job for years. But don’t think too much about 
                negative things. Deal with them, and get on with your 
                life.
              </div>
            </div>
            <div style="margin-top: 20px;">
              <div><span style="margin-right: 10px;">5</span><span>#</span></div>
              <div style="margin-top: 10px;">
                Always keep special things around you, like family 
                photos or favourite possessions – and be grateful 
                that you can enjoy them. Thank your loved ones daily 
                for sharing your life, too. When you see the pleasure 
                on their faces, you will never feel old – even in your 
                eighties!
              </div>
            </div>
          </div>

          <div style="flex: 1; padding: 10px 50px; background-color: rgb(238 244 200); display: flex; flex-direction: column; gap: 7px;">
            <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">A</span>
              <div>The importance of people in your life</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">B</span>
              <div>Ageing can be a positive experience</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">C</span>
              <div>You can choose to stay young</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">D</span>
              <div>Appreciate the things and people in your life</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">E</span>
              <div>Looking back can make old people feel bad</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">F</span>
              <div>Getting over the negatives and moving on</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">G</span>
              <div>Even pessimists can have friends</div>
            </div>
          </div>

        </div>
        `,
        answer: [`E`, `B`, `A`, `F`, `D`],
      },
    ],
  },
  2: {
    unit: 'Unit 1',
    id: 'FGF11-U1-P23-E2',
    audio: 'Audios/1.19-audio.mp3',
    video: '',
    component: T6,
    maxLength: 1,
    textAlign: 'center',
    isHiddenCheck: true,
    inputSize: 50,
    exerciseKey: 'img/FriendsPlus/Page23/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="margin-right: 10px; color: rgb(0, 111, 147); font-size: 30px;">Listening</span>
          <div style="margin-left: 30px;">
            <span style='margin-right: 10px;'><headphone1 name="1.19" src="Audios/1.19-tieude.mp3"></headphone1></span>
            Listen to two young people talking about their 
            family history. You will hear the recording twice. Are the 
            statements true (T) or false (F)? 
          </div>
          `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
          <div style="display: flex; gap: 10px;">
            <span style="font-weight: bold; margin-right: 10px;">1</span>
            <div style="display: flex; flex-wrap: wrap;">
              <div style="width: 560px;">Lila has no memory of moving to the UK.</div>
              <span>#</span>
            </div>
          </div>
          <div style="display: flex; gap: 10px;">
            <span style="font-weight: bold; margin-right: 10px;">2</span>
            <div style="display: flex; flex-wrap: wrap;">
              <div style="width: 560px;">Lila’s mother made the decision to move to the UK.</div>
              <span>#</span>
            </div>
          </div>
          <div style="display: flex; gap: 10px;">
            <span style="font-weight: bold; margin-right: 10px;">3</span>
            <div style="display: flex; flex-wrap: wrap;">
              <div style="width: 560px;">Most of Tim’s family came to the UK from Norway.</div>
              <span>#</span>
            </div>
          </div>
          <div style="display: flex; gap: 10px;">
            <span style="font-weight: bold; margin-right: 10px;">4</span>
            <div style="display: flex; flex-wrap: wrap;">
              <div style="width: 560px;">Tim’s father grew up in the countryside.</div>
              <span>#</span>
            </div>
          </div>
          <div style="display: flex; gap: 10px;">
            <span style="font-weight: bold; margin-right: 10px;">5</span>
            <div style="display: flex; flex-wrap: wrap;">
              <div style="width: 560px;">Tim admires his father’s achievements.</div>
              <span>#</span>
            </div>
          </div>
        </div>	
        `,
        answer: [`T`, `F`, `F`, `T`, `T`],
      },
    ],
  },
  3: {
    unit: 'Unit 1',
    id: 'FGF11-U1-P23-E3',
    audio: '',
    video: '',
    component: T6,
    // recorder: true,
    hideBtnFooter: true,
    maxLength: 1,
    textAlign: 'center',
    inputSize: 50,
    exerciseKey: 'img/FriendsPlus/Page23/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="margin-right: 10px; color: rgb(0, 111, 147); font-size: 30px;">Speaking</span>
          <div style="margin-left: 30px;">
            Work in pairs. Imagine the following situation. Your 
            friend from another town is coming to stay with your 
            family for two weeks over the summer holidays. Give 
            him / her some advice. Discuss the following points: 
          </div>
          `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <ul style="margin-top: 20px;">
          <li>What he / she should pack for the visit</li>
          <li>Activities you could do together</li>
          <li>Advice on staying with your family</li>
          <li>What sports he / she can do in your town</li>
        </ul>

        <div style="margin-left: 20px; font-weight: bold;">Swap roles and repeat the Speaking activity.</div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Unit 1',
    id: 'FGF11-U1-P23-E4',
    audio: '',
    video: '',
    component: T6,
    textareaStyle: { width: 550 },
    hideBtnFooter: true,
    // maxLength: 1,
    textAlign: 'center',
    inputSize: 50,
    exerciseKey: 'img/FriendsPlus/Page23/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="margin-right: 10px; color: rgb(0, 111, 147); font-size: 30px;">Writing</span>
          `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style="display: flex; flex-wrap: wrap; gap: 30px;">
          <div style="flex: 1;">
            <div style="padding: 10px 25px 10px 15px; background-color: rgb(209, 223, 242); max-width: 592px;
              border-radius: 10px; box-shadow: 0px 0px 10px rgb(234, 240, 249);">
            You have seen this advertisement on a website. 
            Write a message (70–80 words) in reply and provide 
            the information Peter asks for. Include a request for 
            information in your message.
            </div>
            <div style="margin-top: 10px;"><img src='img/FriendsPlus/Page23/E4/1.jpg' width='100%';/></div>
          </div>
          <div style="flex: 1;"><textarea id="0" rows="17"></textarea></div>
          
        </div>
        `,
        answer: [],
      },
    ],
  },
}

export default json
