import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P33-E1',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		maxLength: 16,
		exerciseKey: 'img/FriendsPlus/Page33/Key/E1answerKey.png',
		question: [],
		textAlign: 'center',
		titleQuestion: [
			{
				num: '1',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Match the photos with two clubs from the list
        below. Which clubs from the list would you like to belong to? Give reasons.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Adventure activities</b>',
					'art club',
					'astronomy club',
					'baking club',
					'computer club',
					'film club',
					'fitness club',
					'photography club',
					'school choir',
					'science club',
				],
				width: 1100,
				fontColor: '#2887c6',
				borderColor: 'none',
			},
		],
		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 220,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: ` 
          <hintbox id=0></hintbox>
           <div style='position: relative;margin-left:20px'>
            <img  src="img/FriendsPlus/Page33/E1/1.jpg" />
              <span style='position: absolute; top: 75px; left: 211px;line-height:70px'>
                <u style='font-style:italic;font-weight:bold'>Picture A:</u>#&emsp;<u style='font-style:italic;font-weight:bold'>Picture B:</u>#<br>
              </span>
           </div>
          `,
				answer: ['astronomy club', 'school choir'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P33-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page33/Key/E2answerKey.png',
		// checkUppercase:true,
		component: T6,

		titleQuestion: [
			{
				num: '2',
				title: ` Read the task and the blog post below. Which clubs from
        exercise 1 are mentioned? Which other clubs that are not
        in exercise 1 are mentioned?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 620 },
		inputSize: 760,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex'>
          <div>
            <img style='height:8cm' src='img/FriendsPlus/Page33/E2/1.jpg'>
            <div>
                <u style='font-style:italic;font-weight:bold'>Answers:</u>
                <textarea id='0' rows='4'></textarea>
            </div>

          </div>
            <img style='height:17cm' src='img/FriendsPlus/Page33/E2/2.jpg'>
          
        </div>
          `,
				answer: [
					'The school choir, the ballroom dancing club, the martial arts club and the ice skating club are also mentioned.',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P33-E3',
		// audio: 'Audios/1.27.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page33/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `Answer the questions about the blog post.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		component: T6,
		maxLength: 100,
		// isHiddenCheck: true,
		textareaStyle: {
			// background: 'none',
			width: 500,
			fontSize: 22,
			height: 100,
		},
		inputSize: 740,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        
        <div style='display:flex'>
          <div style='margin-left:20px'>
          What phrase does the writer use to?<br>
            <b>a</b>&ensp;say when a past event took place?<br><textarea id='0' rows='3'></textarea>
            <b>b</b>&ensp;introduce a personal opinion?<br><textarea id='1' rows='3'></textarea>
            <b>c</b>&ensp;introduce an additional point?<br><textarea id='2' rows='3'></textarea>
          </div>
            <img style='height:17cm' src='img/FriendsPlus/Page33/E3/1.jpg'>
        </div>
          `,
				answer: ['One Saturday last month', 'In my view, …', 'As well as that, …'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P33-E4',
		// audio: 'Audios/1.27.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page33/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Read the <span style='color:#2c89c3'>Writing Strategy</span>. In pairs, look through 
        the blog post in exercise 2 and suggest twelve words you 
        could delete. Then compare your ideas with another pair’s
        `,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		component: T6,
		// maxLength: 27,
		// isHiddenCheck: true,
		textareaStyle: { width: 530 },
		inputSize: 740,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        
			<div style='display:flex'>
      <img style='height:18cm' src="img/FriendsPlus/Page33/E2/2.jpg" />
        <div>
        <img style='width:15cm' src="img/FriendsPlus/Page33/E4/1.jpg" />
          Which of the phrases did the students use?<br>
          <div><textarea id='0' rows='6'></textarea></div>
        </div>
      </div>    
          `,
				answer: [
					'One Saturday, all, also in, to the school, of them, at the beginning of the next school year, who are, different school, at the stalls, to the visitors, short, great, lots of, including ballroom dancing and ice skating',
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P33-E5',
		audio: '',
		video: '',
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '5',
				title: `Read the task below. Then prepare a plan for your blog post.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		component: T6,
		// maxLength: 27,
		// isHiddenCheck: true,
		textareaStyle: { width: 700, height: 500 },
		inputSize: 740,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
			<div>
        <img style='height:60mm' src="img/FriendsPlus/Page33/E5/1.jpg" />
        <div>
          <b>Paragraph 1:</b> the time, the place and the clubs<br>
          <b>Paragraph 2:</b> the attendees and their reactions<br>
          <b>Parapgrah 3:</b> your opinions<br>
          <b>Paragraph 4:</b> suggested improvements
        </div>
        <div><textarea id='0' row='3'></div>
      </div>
          `,
				answer: [''],
			},
		],
	},

	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P33-E6',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '6',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. Compare your notes from
        exercise 5 and suggest at least one improvement for your
        partner’s plan.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
	},
	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P33-E7',
		audio: '',
		video: '',
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page33/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `Write a blog post (150–180 words) using the task and
plan from exercise 5. Include phrases from exercise 3 if
appropriate.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		component: T6,
		maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 40,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
      <div style='display:flex'>
        <img style='height:60mm' src="img/FriendsPlus/Page33/E7/1.jpg" />
        <div>
          <textarea id='0' rows='6'></textarea>
        </div>
      </div>
          `,
				answer: [''],
			},
		],
	},
}

export default json
