import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Introduction',
    id: 'FGF11-I-P9-E1',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    hideBtnFooter: true,
    recorder: true,
    inputSize: 500,
    // exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span> Work in pairs. Find out a) when and where your partner usually does his / her homework and b) what type of homework he / she likes most and least.`,
        color: '#92278f',
      },
    ],

    textareaStyle: { width: 650 },
    questions: [
      {
        title: `
        <b>1</b>&ensp; When and where your partner usually does his / her homework? <br><textarea id='0' ></textarea>
        </div>
				<div>
          <b>2</b>&ensp; What type of homework he / she likes most and least?<br><textarea  id='1'></textarea>
        </div>
				<div>

        </div>

        `,
        answer: ['', '', ''],
      },
    ],
  },
  2: {
    unit: 'Introduction',
    id: 'FGF11-I-P9-E2',
    audio: 'Audios/1.03-audio.mp3',
    video: '',
    component: T6,
    isHiddenCheck: true,
    inputSize: 430,
    stylesTextInput: {
      fontSize: '22px',
    },
    textareaStyle: { width: 500 },

    exerciseKey: 'img/FriendsPlus/Page9/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `<headphone1 name="1.03" src='Audios/1.03-tieude.mp3'></headphone1> Read and listen to the dialogue. Why is Sue annoyed with Dan at the end?
`,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<div style="display: flex; flex-wrap: wrap; gap: 30px;">
					<div style="flex: 3;"><img src='img/FriendsPlus/Page9/E2/1.jpg' width='95%';/></div>
					<div style="flex: 2; display: flex; gap: 20px; flex-direction: column; margin-top: 50px; font-size: 22px;">
						<div '>
							<u style='font-weight:bold;font-style:italic'>Answer:</u><br /><textarea id='0' rows='2'></textarea>
						</div>

					<div>
				</div>
        `,
        answer: [`He forgot they were supposed to revise together on saturday.`],
      },
    ],
  },
  3: {
    unit: 'Introduction',
    id: 'FGF11-I-P9-E3',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    inputSize: 430,
    stylesTextInput: {
      fontSize: '22px',
    },
    textareaStyle: { width: 500 },

    exerciseKey: 'img/FriendsPlus/Page9/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `Why is present simple or present continuous used in each of the highlighted examples from the dialogue?
`,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<div style="display: flex; flex-wrap: wrap; gap: 30px;">
					<div style="flex: 3;"><img src='img/FriendsPlus/Page9/E2/1.jpg' width='95%';/></div>
					<div style="flex: 2; display: flex; flex-direction: column; gap: 10px;  font-size: 22px;">
          <div>You aren’t wearing #</div>
          <div>P.E. starts  #</div>
          <div>You’re always forgetting things!  #</div>
          <div>It's makes #</div>
          <div>You are doing #</div>
          <div>Mr Harley is giving  #</div>
          <div>Mr Harley always gives #</div>
          <div>We have #</div>
          <div> we’re revising  #</div>
          <div> I’m going away  #</div>
          <div>you’re always forgetting  #</div>
          <div>when I get back # </div>
					<div>
				</div>
        `,
        answer: [
          'for something happening now',
          'for timetables and schedules',
          'for describing annoying behaviour',
          'for a permanent situation or fact',
          'for future arrangements',
          'for something happening about now',
          'for habits and routines',
          'for timetables and schedules',
          'for future arrangements',
          'for future arrangements',
          'for describing annoying behaviour',
          'in future time clauses',
        ],
      },
    ],
  },
  4: {
    unit: 'Introduction',
    id: 'FGF11-I-P9-E4',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page9/Key/E4answerkey.png',
    inputSize: 234,
    stylesTextInput: { fontSize: 22, borderBottom: 'none' },
    textAlign: 'center',
    maxLength: 19,
    titleQuestion: [
      {
        num: '4',
        title: `Read the <span style="margin-right: 10px; color: orange">Learn this!</span>box. Complete the rules (a–g) with the correct tenses: <i>present simple or present continuous.</i> `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
<div style=" position: relative; padding-top:10px; ">
<div>                         <img style='height:12cm;width:19cm' src='img/FriendsPlus/Page9/E4/1.jpg' />
</div>
    <div style="; position: absolute; top:92px; left: 103px; ">#</div>
    <div style="  position: absolute; top:126px; left: 103px; ">#</div>
    <div style=" position: absolute; top:189px; left: 103px; ">#</div>
    <div style=" position: absolute; top:254px; left: 103px; ">#</div>
    <div style=" ;position: absolute; top:289px; left: 103px; ">#</div>
     <div style="position: absolute; top:323px; left: 103px; ">#</div>
      <div style="position: absolute; top:359px; left: 103px; ">#</div>

       </div>

        `,
        answer: [
          `present simple`,
          `present continuous`,
          `present continuous`,
          `present simple`,
          `present simple`,
          `present continuous`,
          `present simple`,
        ],
      },
    ],
  },
  5: {
    unit: 'Introduction',
    id: 'FGF11-I-P9-E5',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page9/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `Read the <span style="margin-right: 10px; color: orange">Learn this!</span>box. Find five stative verbs in the dialogue in exercise 2. How do you know they are stative verbs? `,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 550 },
    questions: [
      {
        title: `
				<div style="display: flex"; flex-wrap: wrap; gap: 30px;">
					<div style="flex: 3"><img src='img/FriendsPlus/Page9/E5/3.jpg' width='95%';/></div>
				  <div style="flex: 2">
          <div><u style='font-weight:bold;font-style:italic'>Answer:</u>
          <div><i style='font-size:17px; color: rgb(152,198,204)'>*Answer:VerbA, VerbB, VerbC,...</i></div>
          <textarea id="0" rows="5"></textarea></div>
          </div>
				</div>

        `,
        answer: [
          'like, mind, need, know, remember/like, mind, need, know, remember - they describe a state, not an action, and they are used in the present simple. We do not normally use them in the present continuous.',
          // 'they describe a state, not an action, and they are used in the present simple. We do not normally use them in the present continuous.',
        ],
      },
    ],
  },
  6: {
    unit: 'Introduction',
    id: 'FGF11-I-P9-E6',
    audio: '',
    video: '',
    component: T6,
    inputSize: 220,
    isHiddenCheck: true,
    maxLength: 19,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page9/Key/E6answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				Complete the sentences with the present simple or present continuous form of the verbs in brackets. 
				`,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 1000 },

    questions: [
      {
        title: `
       <div style='line-height:50px'>
       <div >
       
        <b>1</b>&ensp; I # (meet) Jack in town later.    #  (you / want) to come?  <br>
        </div>
				<div>
          <b>2</b>&ensp; We usually # (walk) to school, but tomorrow we # (go) by bus. <br>
        </div>
				<div>
          <b>3</b>&ensp;  My friends # (not want) to play tennis because it # (rain).   <br>
        </div>
        <div>
          <b>4</b>&ensp;  # (you / enjoy) this film?  Or # (you / prefer) comedies?   <br>
        </div><div>
          <b>5</b>&ensp;   I # (not believe) Ben’s story.  He # (always / invent) things.  <br>
        </div>

       </div>
        `,
        answer: [
          "am meeting",
          "Do you want",
          "walk",
          "are going",
          "don't want",
          "is raining",
          "Are you enjoying",
          "do you prefer",
          "don't believe",
          "is always inventing",
        ],
      },
    ],
  },
  7: {
    unit: 'Introduction',
    id: 'FGF11-I-P9-E7',
    audio: '',
    video: '',
    component: T6v2,
    inputSize: 700,
    hideBtnFooter: true,
    // isHiddenCheck: true,
    // exerciseKey: 'img/FriendsPlus/Page8/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `<span style="color:rgb(10 127 64)">SPEAKING</span>
				 Work in pairs. Find out what your partner <br>  a) usually does at weekends and b) is doing this weekend.  Use the words below or your own activities.
				`,
        color: '#92278f',
      },
    ],
    //hideBtnFooter: true,
    recorder: true,

    questions: [
      {
        title: `
        <div style="color:rgb(246,169,89); margin-bottom:30px  ">
              <b>Social activities</b>
                &ensp;go for a bike ride
                &ensp;go for a walk               
                &ensp;go out for lunch
                &ensp;go shopping listen to music
                &ensp;meet friends in town
                &ensp;play basketball
                &ensp;play table tennis

              </div>

				`,
        answer: [''],
      },
    ],
  },
};

export default json;
