import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  4: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P121-U4-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page121/Key/E1answerKey.png',
    inputSize: 250,
    maxLength: 4000,
    ////hideBtnFooter: true,
    titleQuestion: [
      {
        num: '4',
        title: ' Rewrite the sentences with less or least',
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div>
            <div><b>1</b> Janet is more confident than Ryan.</div>
            <div style="padding-left:20px;">Ryan # Janet.</div>
            
            <div><b>2</b> Hannah and Ellie are more intelligent than Vicky.</div>
            <div style="padding-left:20px;">Vicky # of the three girls.</div>
          </div>
            <div><b>3</b> Marcus's house and Daniel's house are more spacious than Donna's.</div>
            <div style="padding-left:20px;">Donna's house # of the three.</div>
         

          <div>
            <div><b>4</b> Harriet's flat is more cramped than Clare's flat.</div>
            <div style="padding-left:20px;">Clare's flat # Harriet's flat.</div>

            <div><b>5</b> Jess and Maisy live in a more lively area than Annie.</div>
            <div style="padding-left:20px;">Annie lives # area.</div>
          </div>
        </div>

        `,
        answer: [
          'is less confident than',
          'is the least intelligent',
          'is the least spacious',
          'is less cramped than',
          'in the least lively',
        ],
      },
    ],
  },
  5: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P121-U4-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page121/Key/E5answerKey.png',
    inputSize: 400,
    // maxLength: 4000,
    ////hideBtnFooter: true,
    titleQuestion: [
      {
        num: '5',
        title: ' Complete the sentences with than or as and the phrases below. ',
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style ='font-weight:bold'>
          <div style='color:rgb(245,140,31,1);display: flex;justify-content: space-around; padding:20px'>
            <div>I thought she would be</div>
            <div>I was before</div>
            <div>it looks from the outside</div>
          </div>

          <div style='color:rgb(245,140,31,1);display: flex;justify-content: space-around;'>
            <div><del>it used to be</del></div>
            <div>it was when I first moved into</div>
            <div>it there used to be</div>
          </div>
         
        </div>
        <div style ='line-height:2.5;'>
         <div><b>1</b> This part of town is much less popular <b style="color:rgb(0,121,133,1);">than it used to be.</b></div>

          <div><b>2</b> My flat isn't as charming #.</div>

          <div><b>3</b> The house is actually more spacious #.</div>

          <div><b>4</b> Kelly wasn't as late #.</div>

          <div><b>5</b> There are fewer contemporary buildings in the town centre #.</div>

          <div><b>6</b> I'm happier in my new flat #.</div>
        </div>
        `,
        answer: [
          'as it was when I first moved into it',
          'than it looks from the outside',
          'as I thought she would be',
          'than there used to be',
          'than I was before',
        ],
      },
    ],
  },
  6: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P121-U4-E6',
    audio: '',
    video: '',
    component: T6v2,
    exerciseKey: 'img/FriendsPlus/Page121/Key/E6answerKey.png',
    inputSize: 870,
    isHiddenCheck: true,
    // maxLength: 4000,
    ////hideBtnFooter: true,
    titleQuestion: [
      {
        num: '6',
        title:
          "<b style='color:rgb(145,39,140,1);'>USE OF ENGLISH </b>Complete the second sentence so that it means the same as the first. Use the words in brackets",
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div><b>1</b> My car is smaller than yours.</div>
          <div style="padding-left:20px;">My car # .(as)</div>
          
          <div><b>2</b> There aren't as many people on the beach now.</div>
          <div style="padding-left:20px;">There # .(fewer)</div>
          
          <div><b>3</b>  Flats are usually cheaper than houses.</div>
          <div style="padding-left:20px;">Flats # .(expensive)</div>

          <div><b>4</b> The weather is becoming increasingly hot.</div>
          <div style="padding-left:20px;">The weather #.(hotter)</div>

          <div><b>5</b> We've never stayed in a less spacious hotel room.</div>
          <div style="padding-left:20px;">This is #.(least)</div>

          <div><b>6</b> As you spend more on the flat, it becomes more valuable.</div>
          <div style="padding-left:20px;">The #.(the)</div>

          <div><b>7</b> I didn't think the rent would be so high.</div>
          <div style="padding-left:20px;">The rent #.(than)</div>
        `,
        answer: [
          "isn't as big | large as yours",
          'are fewer people on the beach now',
          "are usually less expensive than houses | usually aren't as expensive as houses",
          'is becoming hotter and hotter',
          "the least spacious hotel room we've ever stayed in",
          'more you spend on the flat, the more valuable it becomes',
          'was higher than I thought (it would be)',
        ],
      },
    ],
  },

  7: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P121-U4.2-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page121/Key/E7answerKey.png',
    inputSize: 350,
    textAlign: 'center',
    maxLength: 50,
    isHiddenCheck: true,
    //hideBtnFooter: true,
    titleQuestion: [
      {
        num: '1',
        title: 'Complete the second sentence in each pair to mean the same as the first. Use the second conditional.',
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style=''>
          <div><b>1</b> I can't drive a car; I'm too young.</div>
          <div style="padding-left:20px;">If #,# a car.</div>
          
          <div><b>2</b> He won't offer you a job; you aren't reliable enough.</div>
          <div style="padding-left:20px;">If #,# a job.</div>
          
          <div><b>3</b>  She hasn't got a dog; her flat is really small.</div>
          <div style="padding-left:20px;">If her flat # ,# a dog.</div>

          <div><b>4</b> I can't use my brother's phone; I don't know the password.</div>
          <div style="padding-left:20px;">If I #,# my brother's phone.</div>

          <div><b>5</b> She isn't a good guitarist; she doesn't practise.</div>
          <div style="padding-left:20px;">If #,# a good guitarist.</div>

          <div><b>6</b> We can't go to the beach; it isn't sunny today.</div>
          <div style="padding-left:20px;">If #,# to the beach.</div>
        </div>
        
        `,
        answer: [
          "I weren't too young",
          'I could drive',
          'you were more reliable',
          'he would offer you',
          "weren't/wasn't so small/if her flat were/was bigger/larger",
          'she would have ',
          'knew the password',
          'I could use',
          'she practised',
          'she would be',
          'it were/was sunny today',
          'we could go',
        ],
      },
    ],
  },

  8: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P121-U4.2-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page121/Key/E2answerKey.png',
    inputSize: 350,
    maxLength: 4000,
    ////hideBtnFooter: true,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete these second conditional sentences with the correct verb form and your own ideas.',
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
      <div style='line-height:2.5'>
        <div><b>1</b> If I # (live) in the USA,#.</div>

          <div><b>2</b> If I # (have) ten brothers and sisters,#.</div>

          <div><b>3</b> If humans # (can) fly,#.</div>

          <div><b>4</b> If I # (can) travel through time,#.</div>

          <div><b>5</b> If computers # (not exist),#.</div>

          <div><b>6</b> If I # (find) a diamond ring,#.</div>
      </div>

        `,
        answer: ['lived', '', 'had', '', 'could', '', 'could', '', "didn't exist", '', 'found', ''],
      },
    ],
  },
};

export default json;
