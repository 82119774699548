import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Unit 5',
		id: 'FGF11-A-P61-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E1/Key/answerKey.png',
		component: T6,
		inputSize: 260,
		maxLength: 20,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		hideBtnFooter: true,
		// isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(146, 39, 144)">VOCABULARY</span> Match five of the jobs below with photos A–E.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; justify-content: center; align-items: center;">
            <img src="img/FriendsPlus/Page61/E1/1.jpg" style="max-width: 100%;" />
          </div>

          <div style="margin-top: 10px; color: rgb(196, 12, 70);">
            <div>
              <strong>Jobs(2)</strong>
              <span style="margin-left: 91px;">builder</span>
              <span style="margin-left: 45px;">estate agent</span>
              <span style="margin-left: 51px;">gardener</span>
            </div>
            <div>
              <span>groundskeeper</span>
              <span style="margin-left: 20px;">journalist</span>
              <span style="margin-left: 20px;">locksmith</span>
            </div>
            <div>
              <span>photographer</span>
              <span style="margin-left: 36px;">solicitor</span>
              <span style="margin-left: 38px;">pizza delivery man / woman</span>
            </div>
            <div>
              <span>police officer</span>
              <span style="margin-left: 45px;">stunt performer</span>
            </div>
            <div>
              <span>surfing instructor</span>
              <span style="margin-left: 73px;">video game developer</span>
            </div>
          </div>	

          <div>
            <i>Answer:</i>
            <div style="display: flex; align-items: space-between; justify-content: space-between;">
              <div style="display:flex; flex-direction: column;">
                <span><strong>A</strong> #</span>
                <span><strong>B</strong> #</span>
              </div>
              <div style="margin-left: 10px; display:flex; flex-direction: column;">
                <span><strong>C</strong> #</span>
                <span><strong>D</strong> #</span>
              </div>
              <div style="margin-left: 10px; display:flex; flex-direction: column;">
                <span><strong>E</strong> #</span>
              </div>
            </div>
          </div>
        `,
				answer: ['locksmith', 'estate agent', 'roundskeeper', 'stunt performer', 'builder'],
			},
		],
	},
	2: {
		unit: 'Unit 5',
		id: 'FGF11-A-P61-E2',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 180,
		maxLength: 18,
		checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		//hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
					Answer the questions.
           Use the jobs and work activites from lesson 5A to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="width: 600px;">
            <div style="display: flex;">
              <strong style="padding-right: 10px;">1</strong>
              <span>
                Choose two jobs from exercise 1 and describe them. <br/>
                <span style="color: rgb(75, 142, 168);">
                  Builders work outdoors. They’re on their feet all day. 
                  I think it’s repetitive work but it is skilled.
                </span>
              </span>			
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">2</strong>
              <span>
                Which is the easiest / most difficult of all the jobs in your opinion? Why? <br/>
                <span style="color: rgb(75, 142, 168);">
                  I think the easiest job is … . That’s because … .
                </span>
              </span>			
            </div>
          </div>
	      `,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Unit 5',
		id: 'FGF11-A-P61-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E3/Key/answerKey.png',
		component: T6,
		recorder: true,
		inputSize: 180,
		maxLength: 17,
		// checkDuplicated: true,
		// stylesTextInput: {
		// 	textAlign: 'center',
		// 	fontSize: 23,
		// },
		hideBtnFooter: true,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          Answer the quiz and calculate your own score to see how tech-savvy you are.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-left:150px">
					<img src=img/FriendsPlus/Page61/E1/1.jpg>
				<img src=img/FriendsPlus/Page61/E1/2.jpg>
				<img src=img/FriendsPlus/Page61/E1/3.jpg>
				<img src=img/FriendsPlus/Page61/E1/4.jpg>
				</div>


		    `,
				answer: [
				],
			},
		],
	},
	4: {
		unit: 'Unit 5',
		id: 'FGF11-A-P61-E4',
		audio: 'Audios/2.16-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/Key/E4answerKey.png',
		component: T6,
		inputSize: 35,
		maxLength: 1,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
				<span style="margin-right: 10px;"><headphone1 name="2.16" src="Audios/2.16-tieude.mp3"></headphone1></span> 
				<span style="color: #92278f">VOCABULARY</span> Match a–e with 1–5 and f–j with 6–10. Then listen and check.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<img style="margin-left:128px" src= 'img/FriendsPlus/Page61/E1/5.jpg' >
					
					<div>
						<i>Answer:</i> <br/>
						<span style="padding-left: 30px;"><strong>1</strong>#</span>
						<span style="padding-left: 30px;"><strong>2</strong>#</span>
						<span style="padding-left: 30px;"><strong>3</strong>#</span>
						<span style="padding-left: 30px;"><strong>4</strong>#</span>
						<span style="padding-left: 30px;"><strong>5</strong>#</span>
						<span style="padding-left: 30px;"><strong>6</strong>#</span>
						<span style="padding-left: 30px;"><strong>7</strong>#</span>
						<span style="padding-left: 30px;"><strong>8</strong>#</span>
						<span style="padding-left: 30px;"><strong>9</strong>#</span>
						<span style="padding-left: 30px;"><strong>10</strong>#</span>
					</div>
        `,
				answer: ['e', 'd', 'c', 'b', 'a', 'g', 'f', 'i', 'j', 'h'],
			},
		],
	},
	5: {
		unit: 'Unit 5',
		id: 'FGF11-U5-P61-E5',
		audio: 'Audios/2.17-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/Key/E5answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '5',
				title: `
       <headphone1 name="2.17" src="Audios/2.17-tieude.mp3"></headphone1> Listen to three helpline dialogues. Circle the correct answers (a–c).
			  `,
				color: '#860052',
			},
		],
		question: {
			Write: {
				isHiddenCheck: true,
				maxLength: 11,
				inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
				answers: [''],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					borderRadius: '50%',
					fontWeight: 'bold',
					borderColor: 'transparent',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					// borderColor: '#00aeef',
				},
				limitSelect: 1,
				listWords: [
					`a <br> b <br> c`, //1
					`a <br> b <br> c`, //1
					`a <br> b <br> c`, //1


				],
				answers: ['0-8', '1-0', '2-8',],
				initialValue: [],
			},
			Layout: `
      <div >
        <div style ='flex:1;width:100%'>


 						<b>1</b>&ensp;
		      The customer doesn’t know how to
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      enter his payment details. <br>
              add items to the basket. <br>
              get money off something he wants to buy.
		          </div>
            </div>


          
            <b>2</b>&ensp;
		      The technical support assistant advises the woman to
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	     send the email to the bank, then remove it from her computer.<br>
             click on the link in the email. <br>
             choose a new password.
		          </div>
            </div>

              <b>3</b>&ensp;
		         The man can
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      send emails, but can’t receive them. <br>
              receive emails, but can’t send them. <br>
              send and receive emails from one email account, but not from another
		          </div>
            </div>

		          </div>
            </div>
           </div>
      </div>

                        </div>


        
      `,
		},
	},
	6: {
		unit: 'Unit 5',
		id: 'FGF11-A-P61-E6',
		audio: 'Audios/2.17-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/Key/E6answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 200,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 25,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
					<headphone1 name="2.17" src="Audios/2.17-tieude.mp3"></headphone1>
					Read the <span style="color:#92278f"> Recycle! </span> box. Then listen again and complete the sentences
					with the affirmative or negative 
					imperative of the verbs below.
				`,
				color: '#860052',
			},
		],
		hintBox: [{
			src: [
				"click on,",
				"delete,",
				"draw,",
				"Enter,",
				"follow,",
				"log on,"
			],
			width: 700,
			borderColor: "black",
			fontColor: "#92278f",
			justifyContent: "left"
		}

		],
		questions: [
			{
				title: `
					<div >
						<hintBox id=0 ></hintBox>
					</div>

					<div style="margin-top: 20px;">
				<div >
						<div style="font-weight: bold; padding-right: 10px;">Dialogue 1</div>
						 <div><b><sup>1</sup></b> #that button just yet. <b><sup>2</sup></b> #the discount code first.</div>
				</div>
				<div style="margin-top:15px">
					<div style="font-weight: bold; padding-right: 10px;">Dialogue 2</div> 
						<div>And then you should <b><sup>3</sup></b># the email. And whatever you do,
						 <b><sup>4</sup></b># any links contained in the email.</div>
				</div>
						<div style="margin-top:15px" >
						<div style="font-weight: bold; padding-right: 10px;">Dialogue 3</div> 
						<div> <b><sup>5</sup></b># to your email account. Once you’ve got it, you can reset your password.</div>
						</div>
					</div>
		    `,
				answer: [
					"Don't click on",
					'Enter',
					'delete',
					"don't follow",
					'Log on',
				],
			},
		],
	},
	7: {
		unit: 'Unit 5',
		id: 'FGF11-A-P61-E7',
		// audio: 'Audios/Friends Global Grade 10 SB_CD2_18.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E7/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		// maxLength: 1,
		recorder: true,
		hideBtnFooter: true,
		// checkDuplicated: true,
		// stylesTextInput: {
		// 	textAlign: 'center',
		// 	fontSize: 20,
		// },
		titleQuestion: [
			{
				num: '7',
				title: `
					<span style="color: rgb(45,206,137)">SPEAKING</span> Work in pairs. Give each other instructions on how to do three of these 
						things. Use words from exercises 2, 4 and 6 to help you, and use the imperatives.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>Buy and download a song</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>Create a new document</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>Buy something online</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>Comment on a Facebook post</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">5</strong>
							<span>Comment on a Facebook post</span>				
						</div>
					</div>
        `,
				answer: ['F', 'T', 'F', 'F', 'T'],
			},
		],
	},
	8: {
		unit: 'Unit 5',
		id: 'FGF11-A-P61-E8',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E8/Key/answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 18,
		recorder: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		hintBox: [
			{
				src: [
					'I’d like to be a dentist for a week because it’s a well-paid and challenging job. I’d also like to deal with the public.',
				],
				borderColor: 'rgb(236, 181, 179)',
				arrow: true,
				position: 1,
				width: 700,
			},
		],
		textareaStyle: { width: 900 },
		//hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Think of a job that you would like to try for a week. 
					<br/>Tell your partner what you would like about it.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px;"><hintbox id='0'></hintbox></div>
					<div style="margin-top: 40px;"><textarea id="0" rows="5"></textarea></div>
        `,
				answer: [],
			},
		],
	},
};

export default json;
