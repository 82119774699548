import Proptypes from 'prop-types';
import styles from './finished.module.css';
export default function Finished({ text = '' }) {
  return (
    <div>
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend} style={{color: 'green'}}>Finished ?</legend>
        {text}
      </fieldset>
    </div>
  );
}

Finished.propTypes = {
  text: Proptypes.string,
};
