import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P52-E1',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		// recorder: true,
		maxLength: 16,
		exerciseKey: 'img/FriendsPlus/Page52/Key/E1answerKey.png',
		question: [],
		textAlign: 'center',
		titleQuestion: [
			{
				num: '1',
				title: `<span style="color:rgb(10 127 64)">SPEAKING</span> Read the fact file about Castle Howard, a
stately home in the north of England. Would you like to
live in a house like this? Why? / Why not?
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'become dehydrated',
					'climber',
					'desert',
					'food and water',
					'frostbite',
					'high altitude',
					'oxygen',
					'tent',
				],
				width: 750,
				fontColor: '#be1e54',
				borderColor: 'none',
			},
		],
		// maxLength: 14,
		recorder: true,
		isHiddenCheck: true,
		textareaStyle: { width: 800 },
		inputSize: 450,
		// textAlign: 'center',
		hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
          <img  src="img/FriendsPlus/Page52/E1/1.jpg" />

          `,
				answer: [''],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		audio: 'Audios/2.09-audio.mp3',
		id: 'FGF11-U4-P52-E2',
		exerciseKey: 'img/FriendsPlus/Page52/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `<headphone1 name=2.09 src='Audios/2.09-tieude.mp3'></headphone1> Read and listen to the dialogue. What two
outdoor and indoor changes do the speakers mention?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		maxLength: 9,
		hintBox: [
			{
				src: ['contact', 'enjoy', 'ignore', 'talk', 'try'],
				width: 600,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 100,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex'>
        <img src="img/FriendsPlus/Page52/E2/1.jpg" />
          <div style='margin-left:20px'>
              <u style='font-weight:bold;font-style:italic'>Answer:</u>
              <textarea id='0' rows='3'></textarea>
            
          </div>
        </div>

           
          `,
				answer: ['removing trees to make room for a swimming pool, taking away statues … to build a bowling alley'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P52-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page52/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `Read the <span style="color:#ee9933">Learn this!</span>  box and complete it. Underline all
the examples of the second conditional in the dialogue.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		// maxLength: 6,
		component: Circle_Write,

		question: {
			Write: {
				isHiddenCheck: true,
				maxLength: 11,
				inputStyle: { width: 149, fontSize: 23, borderBottom: 'none', textAlign: 'center' },
				answers: ['past simple', 'would / could'],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					// borderRadius: "50%",
					borderColor: 'transparent',
				},
				selectWordStyle: {
					// padding: 5,
					borderBottom: 'solid 2px',
					borderColor: '#00aeef',
				},
				limitSelect: 7,
				listWords: [
					`This_is_an_amazing_place. I_wish_I_lived_here. <br>
           Me_too. If_only_I_were_a_member_of_the_Howard_family! <br> 
          But_if_it_were_my_house,_I_wouldn’t_allow_people_to_visit. <br> That’s_mean! You’d_get_bored_on_your_own. <br>
          Oh,_I’d_let_my_friends_visit,_of_course. But_not_the_public. <br>
          If_you_had_a_party,_you_could_invite_hundreds_of_people! <br>
          I_know. The_parties_would_be_better_if_I_made_a_few_changes,_though.<br>
          What_kinds_of_changes?<br>
          Well,_if_those_trees_weren’t_there,_there’d_be_room_for_a_swimming_pool!<br>
          You_could_make_some_changes_inside_as_well. I_mean,_look_at_this_corridor.<br>
          The_Antique_Passage,_you_mean?<br>
          Yes._If_you_took_away_all_the_statues,_you_could_build_a_great_bowling_alley_there!
         `, //1
				],
				answers: [ '0-56', '0-112', '0-138', '0-182', '0-250'],
				initialValue: [],
			},
			Layout: `
        <div style='position: relative;'>
          <img style='height:' src='img/FriendsPlus/Page52/E3/1.jpg' />
          <div style=" position: absolute; top: 126px; left: 197px; "><input id='0'></input></div>
          <div style=" position: absolute; top: 158px; left: 120px; "><input id='1'></input></div>
          
        </div> 
        <div style='display:flex;line-height:45px'>
          
          <div style='margin-right:50px; font-weight:bold'>
            Alfie<br>
            Macy<br> 
            Alfie<br>
            Macy<br> 
            Alfie<br>
            Macy<br> 
            Alfie<br>
            Macy<br> 
            Alfie<br>
            Macy<br> 
            Alfie<br>
            Macy<br> 
          </div>
          <div>
            <input id='0'  type='Circle' />
          </div>
        </div>
      `,
		},
	},

	4: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P52-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page52/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `Complete these second conditional sentences using the
verbs in brackets.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		// maxLength: 11,
		component: T6,

		// isHiddenCheck: true,
		textareaStyle: { width: 340 },
		inputSize: 150,
		textAlign: 'center',
		// recorder: true,
		questionImage: [],
		questions: [
			{
				title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;If I#(live) in a mansion, I#(have) parties every week.<br>
          <b>2</b>&ensp;If you#(visit) me, you#(sleep) in a huge bedroom.<br>
          <b>3</b>&ensp;We#(can) go on long bike rides if the weather#(be) good.<br>
          <b>4</b>&ensp;If the weather#(not be) good, we#(watch) movies in the Great Hall.<br>
        </div>
          `,
				answer: ['lived', 'would have', 'visited', 'would sleep', 'could', 'was', `wasn't`, 'would watch '],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P52-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page52/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `Read the <span style="color:#ee9933">Learn this!</span> box and complete it. Underline one
example of I wish …  and one example of If only … in the
dialogue.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		stylesTextInput: { fontSize: 25, height: 25, borderBottom: 'none', background: 'none' },
		maxLength: 11,
		component: T6,
		isHiddenCheck: true,
		// isHiddenCheck: true,
		textareaStyle: { width: 340 },
		inputSize: 150,
		textAlign: 'center',
		// recorder: true,
		questionImage: [],
		questions: [
			{
				title: `
       <div style='position: relative;margin-left:20px'>
           <img src="img/FriendsPlus/Page52/E4/1.jpg" />
           <span style='position: absolute; top: 165px; left: 169px'>#</span>
           <span style='position: absolute; top: 200px; left: 207px'>#</span>
           <span style='position: absolute; top: 348px; left: 199px'>#</span>
           <span style='position: absolute; top: 383px; left: 211px'>#</span>
       </div>
          `,
				answer: ['had', 'lived', "wouldn't", 'would'],
			},
		],
	},

	6: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P52-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page52/Key/E6answerKey.png',

		titleQuestion: [
			{
				num: '6',
				title: ` Complete the first line of each mini-dialogue with the
verbs in brackets. Use the past simple or would / could +
infinitive without to. Complete the other lines with the
second conditional.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: ['<b>Compound nouns</b>', ''],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// maxLength: 10,
		isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 145,
		stylesTextInput: { fontSize: 22, height: 23 },
		textAlign: 'center',
		// recorder: true,
		// //hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
        <div style='line-height:50px'>
          <b>A.</b>&ensp;I wish my dad <sup>1</sup>#(come) home.<br>
          <b>B.</b>&ensp;Why?<br>
          <b>C.</b>&ensp;Because he <sup>2</sup>#(give) us a lift into town if he <sup>3</sup>#(be) here.<br>
          <b>D.</b>&ensp;If only you <sup>4</sup>#(live) near the town centre.<br>
          <b>E.</b>&ensp;Why?<br>
          <b>F.</b>&ensp;Because if your house <sup>5</sup>#(be) near the centre, we <sup>6</sup>#(get) the same bus to school.<br>
        
        </div>
                 
          `,
				answer: ['would come', 'would give', 'was / were', 'lived', 'was / were', 'would get'],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P52-E7',
		audio: '',
		video: '',
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page52/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b>  In pairs, talk about what you would do if you.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 40,
		textAlign: 'center',
		recorder: true,
		//hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
          <div>
            <b>1</b>&ensp;owned Castle Howard.<br>
            <b>2</b>&ensp;had a swimming pool in your garden.<br>
            <b>3</b>&ensp;gave a party for hundreds of people.<br>
            <b>4</b>&ensp;had to allow the public into your home.<br>
          </div>
          <img src='img/FriendsPlus/Page52/E7/1.jpg'>
          `,
				answer: [''],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P52-E8',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '8',
				title: `<b style="color:rg#(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page52/E8/1.pNg' }]],
	},
}

export default json
