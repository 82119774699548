import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P86-E1',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    hideBtnFooter: true,

    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
       Discuss this question.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=''>
          Why is a perfect forgery worth less than an original work of art?
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P86-E2',
    // exerciseKey: 'img/FriendsPlus/Page86/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 130,
    maxLength: 20,
    hideBtnFooter: true,
    textAlign: 'center',

    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
         Read the article above. Was it right to send Beltracchi to prison? Why? / Why not?
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='margin-top:30px'>
      <img style="width:25cm" src='img/FriendsPlus/Page86/E2/1.jpg' />
        </div>

        `,
        answer: [''],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P86-E3',
    exerciseKey: 'img/FriendsPlus/Page86/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 800,
    // maxLength: 20,
    textAlign: 'left',
    textareaStyle: {
      // background: 'none',
      width: 500,
      fontSize: 24,
      height: 120,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style='font-size:22px'>
        Match the highlighted passive forms in the article with 1–9 below. Then find two examples of 9
        </div>
         
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='font-size:20px '>
        <img style="width:23cm" src='img/FriendsPlus/Page86/E3/1.jpg' />
        <div><i style='font-size:16px; color: rgb(152,198,204)'>*Write the selected words in the sorted order of the list above</i></div>
        <div style='display:flex'>
        <div style='margin-right:30px'>
        <div><b>1 </b>present simple passive<textarea id='0' row='2'></textarea></div>
        <div><b>2 </b>present continuous passive<textarea id='1' row='2'></textarea></div>
        <div><b>3 </b>past simple passive<textarea id='2' row='2'></textarea></div>
        <div><b>4 </b>past continuous passive<textarea id='3' row='2'></textarea></div>
        </div>

        <div>
        <div><b>5 </b>present perfect passive<textarea id='4' row='2'></textarea></div>
        <div><b>6 </b>past perfect passive<textarea id='5' row='2'></textarea></div>
        <div><b>7 </b>will + passive<textarea id='6' row='2'></textarea></div>
        <div><b>8 </b>modal verb + passive<textarea id='7' row='2'></textarea></div>
        <div><b>9 </b>by + agent<textarea id='8' row='2'></textarea></div>
        </div>
        </div>

        </div>

        `,
        answer: [
          'are not signed',
          'are being examined',
          'was he finally caught',
          "wasn't being used",
          'have been identified',
          'had been bought',
          'will be returned',
          'might never be discovered',
          'had been bought by a company, have been identified by police, several more are being examined by experts',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P86-E4',
    exerciseKey: 'img/FriendsPlus/Page86/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 800,
    maxLength: 20,
    textAlign: 'left',
    isHiddenCheck: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 22,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '4',
        title: `<div style='font-size:23px'>Read the
        <span style='color:rgb(255, 171, 76);'>Learn this! </span>
       box. Make the active sentences passive. Use by where necessary.
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <img style="width:15cm; margin-bottom:10px" src='img/FriendsPlus/Page86/E4/1.jpg' />
        <div style='display:flex: flex-direction:column; padding:10px; background-color:rgb(253,233,208); width:100%, border-radius: 15px; font-size: 20px'>
        <div><b>1 </b>William Shakespeare wrote Macbeth.</div>
        <div style='color: rgb(124,181,220)'><i>Macbeth was written by William Shakespeare.</i></div>
        
        <div><b>2 </b>They are performing a ballet at the concert hall this evening.</div>
         <div> # .</div>   
        <div><b>3 </b>Does Lady Gaga design the costumes?</div>
        <div> # .</div>  
        <div><b>4 </b>How many Hobbit films have they made?</div>
        <div> # .</div>  
        <div><b>5 </b>The artist ought to sign the painting.</div>
        <div> # .</div>  
        <div><b>6 </b>Thousands of people will visit the gallery this year.</div>
        <div> # .</div>  
        
        </div>
          
        `,
        answer: [
          'A ballet is being performed at the concert hall this evening.',
          'Are the costumes designed by Lady Gaga?',
          'How many Hobbit films have been made?',
          'The painting ought to be signed by the artist.',
          'The gallery will be visited by thousands of people this year.',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P86-E5',
    exerciseKey: 'img/FriendsPlus/Page86/E5/Key/E5answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 200,
    maxLength: 20,
    textAlign: 'center',
    isHiddenCheck: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '5',
        title: `<div style='font-size:23px'>Complete the text with the verbs in brackets. Use active 
        or passive verbs, as appropriate.
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative'>
        <img style="width:23cm;height:15cm; margin-bottom:10px" src='img/FriendsPlus/Page86/E5/1.jpg' />
        <div style='width: 591px; position: absolute; top:65px; left:48px; font-size:23px'>
        John Myatt is a British artist. He makes 
        copies of famous works of art, but they 
        <sub>1</sub> # (not sell) as originals. 
        They <sub>2</sub> # (paint) with ordinary 
        decorator’s paint, and the word ‘fake’ 
        <sub>3</sub> #  (write) on the back. But 
        it wasn’t always like that. In the 1990s, 
        Myatt <sub>4</sub> #  (forge) about 200 
        paintings. In 1998, he <sub>5</sub> # 
        (catch) and <sub>6</sub> #  (send) to prison 
        for a year. Since his release from jail, 
        Myatt <sub>7</sub> #  (be) very successful, 
        and his paintings <sub>8</sub> #  (buy) by 
        wealthy people all over the world. He 
        <sub>9</sub> #  (be) now a rich man!
        
        </div>
        
        </div>
          
        `,
        answer: [
          "aren't sold",
          'are painted',
          'is written',
          'forged',
          'was caught',
          'was sent/sent',
          'has been',
          'have been bought',
          'is',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P86-E6',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    hideBtnFooter: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
      Work in pairs. Use the passive form of the 
      verbs below to ask and answer five questions about 
      famous works of art.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=''>
        <div style='color:rgb(249,217,176); font-size:30px; margin-bottom:30px'>
        &ensp;compose
        &ensp;direct
        &ensp;paint
        &ensp;perform
        &ensp;sing
        &ensp;write
        </div>
          <img style="width:20cm" src='img/FriendsPlus/Page86/E6/1.jpg' />
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
