import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P27-E1',
		audio: '',
		video: '',
		component: T6,
		inputSize: 200,
		maxLength: 10,
		textAlign: 'center',
		// recorder: true,
		// hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page27/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title:
					'<span style="color: #8e258c">VOCABULARY</span> Match each photo (A–D) with a type of dish from the list below.Which dishes do you like or dislike?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
				<div>
					<div>
					 <img src='img/FriendsPlus/Page27/E1/2.jpg'>
					 </div>
					<div style='display:flex'>
					 	<div><img style='width: 708px;height: 300px;' src='img/FriendsPlus/Page27/E1/1.jpg'></div>
						<div style="margin-left: 35px; margin-top: 76px;">
							<div><b>A</b>#</div>
							<div><b>B</b>#</div>
							<div><b>C</b>#</div>
							<div><b>D</b>#</div>
						</div>
					</div>
					 
				</div>
          `,
				answer: ['salad', 'curry', 'banh mi', 'pie'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P27-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page27/key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: '<span style="color:green"> SPEAKING </span>Work in pairs. Do the food quiz',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		isHiddenCheck: true,
		component: Circle_Write,
		hintBox: [
			{
				src: ['eggs', 'fruit', 'milk', 'pork', 'wheat'],
				width: 450,
				borderColor: 'none',
				fontColor: '#749b28',
				backgroundBox: 'none',
				paddingBlock: 0,
			},
		],

		question: {
			Write: {
				isHiddenCheck: true,

				inputStyle: { width: 200, textAlign: 'center' },
				answers: ['curry', 'wheat', 'pork', 'milk', 'eggs', 'fruit', 'b', 'a', 'd', 'c'],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					borderRadius: "50%",
					borderColor: 'transparent',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					padding: "2px 5px",
					border: 'solid 3px',
					borderColor: 'black',
				},
				limitSelect: 1,
				listWords: [
					'a|&ensp;steak &emsp;&emsp; b|&ensp;avocado &emsp;&emsp; c|&ensp;peanuts &emsp;&emsp; d|&ensp;chocolate', //2
				],
				answers: ['0-12'],
				initialValue: [],
			},
			Layout: `
  <div style='position: relative;'>
	   			<img style='height:19cm; width:248mm' src='img/FriendsPlus/Page27/E2/1.jpg'>
           <div style=" position: absolute; top: 30px; left: 107px; ">
							Which food from exercise 1 is Britain’s favourite dish?
							<input id='0'></input>
	  			</div>
           <div style=" position: absolute; top: 80px; left: 107px; ">
							Match the dishes (a–d) with the countries (1–4) they 
							come from originally.<br>
							<b>1</b>&ensp;France<br>
							<b>2</b>&ensp;Italy<br>
							<b>3</b>&ensp;Mexico<br>
							<b>4</b>&ensp;Japan<br>
	  			</div>
           <div style=" position: absolute; top: 274px; left: 107px; ">
							Complete the sentences about special diets with the 
							words below.
							<hintbox id=0></hintbox>
	  			</div>
           <div style=" position: absolute; top: 350px; left: 107px; ">
							<b>a</b>&ensp;If you follow a gluten-free diet, you can’t eat<input id='1' width='100px'></input>.<br>
							<b>b</b>&ensp;Muslims don’t eat<input id='2' width='100px'></input>.<br>
							<b>c</b>&ensp;If you’re lactose intolerant, you avoid<input id='3' width='100px'></input>.<br>
							<b>d</b>&ensp;Vegans don’t eat<input id='4' width='100px'></input>, but most vegetarians do.<br>
							<b>e</b>&ensp;<input id='5' width='100px'></input>is a good snack if you’re following a low-fat diet.<br>
	  			</div>
           <div style=" position: absolute; top: 545px; left: 107px; ">
							Which of these foods contains the most calories?
							100 grams of<br>
           	<input id='0' type='Circle'/>

	  			</div>
          <div style=" position: absolute; top: 120px; left: 370px">
							<b>a</b>&ensp;lasagne<br>
							<b>b</b>&ensp;chocolate mousse<br>
							<b>c</b>&ensp;miso soup<br>
							<b>d</b>&ensp;tacos<br>
	  			</div>
          <div style=" position: absolute; top: 120px; left: 650px;">
							<u style='font-weight:bold;font-style:italic'>Answer:</u><br>
	  			</div>
          <div style=" position: absolute; top: 120px; left: 770px;">
							<b>1</b> <input id='6' width='40px'></input><br>
							<b>2</b> <input id='7' width='40px'></input><br>
							<b>3</b> <input id='8' width='40px'></input><br>
							<b>4</b> <input id='9' width='40px'></input><br>
	  			</div>

	</div>
      `,
		},
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P27-E3',
		audio: 'Audios/1.21-audio.mp3',
		video: '',
		component: T6,
		inputSize: 300,
		maxLength: 22,
		titleImage: '',
		textAlign: 'left',
		exerciseKey: 'img/FriendsPlus/Page27/key/E3answerKey.png',
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '3',
				title:
					'<span style="margin- right: 10px;"><headphone1 name="1.21" src="Audios/1.21-tieude.mp3"></headphone1></span> Read the <span style="color:rgb(238, 53, 95)">Listening Strategy</span>. Then listen to two extracts and answer the questions.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div>
					<div>
						<div> <b>Extract 1</b> </div>
							<div>
								<div> <b>1.</b> Why is the man talking to the waiter?</div> 
									#
								<div> <b>2.</b> What time of the day is the dialogue taking place?</div> 
									#
							</div>
						<div style="margin-top: 25px;"><b>Extract 2</b></div>
						<div>
						<div> <b>3.</b> Who is speaking?</div> 
						#
						<div> <b>4.</b> Where is the speaker?</div> 
						#
						</div>

					</div>
					
				</div>
          `,
				answer: ['to complain', 'lunchtime', 'a teacher', 'in a classroom'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P27-E4',
		audio: 'Audios/1.22-audio.mp3',
		video: '',
		component: T6,
		inputSize: 170,
		textareaStyle: { width: 700 },
		// inputSize: 50,
		isHiddenCheck: true,
		maxLength: 1,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page27/key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title:
					'<span style="margin- right: 10px;"><headphone1 name="1.22" src="Audios/1.22-tieude.mp3"></headphone1></span> Listen to a dialogue between two teenagers. For each question, write the correct speaker: Matthew(M) or Scarlett(S).Make a note of the words that helped you to decide on the answers',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div>
					Which person 
						<div><b>1</b> is in the city centre?  #</div>
						<div><b>2</b> recently finished some exams?  #</div>
						<div><b>3</b> is not confident about finding the restaurant alone? #</div>
						<div><b>4</b> is likely to be late? #</div>
						<div><b>5</b> wants a new phone?  #</div>
        </div>
          `,
				answer: ['M', 'M', 'S', 'S', 'S'],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P27-E5',
		// audio: 'Audios/Friends Global Grade 10 SB_CD1_25.mp3',
		video: '',
		component: T6,
		inputSize: 220,
		maxLength: 20,

		isHiddenCheck: true,
		// hideBtnFooter: true,
		// recorder: true,
		textAlign: 'center',
		// titleImage: 'img/FriendsPlus/Page27/E5/1.jpg',
		exerciseKey: 'img/FriendsPlus/Page27/key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: 'Put the phrases below into the correct group (A, B or C). ',
				color: '#8e258c',
			},
		],
		hintBox: [
			{
				src: [
					'a bit special',
					'a real let - down',
					'fine',
					'nothing special',
					'not up to standard',
					'out of this world',
					'pretty average',
				],
				width: 900,
				borderColor: 'none',
				fontColor: 'rgb(238, 53, 95)',
				justifyContent: 'left',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
					<div style='margin-left:100px'><hintbox id='0'></hintbox></div> 
					<div style='line-height:55px'>
						<div><b>A.</b>  Good #,#</div>
						<div><b>B.</b>  Ok #,#,#</div>
						<div><b>C.</b>  Bad #,#</div>
        </div>

				`,

				answer: [
					'a bit special / out of this world',
					'a bit special / out of this world',
					'fine / nothing special / pretty average',
					'fine / nothing special / pretty average',
					'fine / nothing special / pretty average',
					'a real let-down / not up to standard',
					'a real let-down / not up to standard',
				],
			},
		],
	},
	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P27-E6',
		audio: 'Audios/1.22-audio.mp3',
		video: '',
		component: T6,
		inputSize: 700,
		textAlign: 'left',
		maxLength: 62,
		// titleImage: 'img/FriendsPlus/Page27/E5/1.jpg',
		exerciseKey: 'img/FriendsPlus/Page27/key/E6answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title:
					'<span style="margin- right: 10px;"><headphone1 name="1.22" src="Audios/1.22-tieude.mp3"></headphone1></span> Listen again. Match the phrases in exercise 5 with the different restaurants mentioned: Italian, French,Mexican and Vietnamese. ',
				color: '#8e258c',
			},
		],

		questionImage: [],
		questions: [
			{
				title: `
				
				<div>
						<div><b>Italian</b>  #</div>
						<div><b>French</b>   #</div>
						<div><b>Mexican</b>   #</div>
						<div><b>Vietnamese</b>  #</div>
        </div>

          
          `,
				answer: [
					'a real let-down, nothing special, not up to standard',
					'a bit special, out of this world',
					'fine',
					'pretty average',
				],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P27-E7',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '7',
				title:
					'<span style="color:rgb(10 127 64)">SPEAKING</span> Discuss these questions in pairs. Try to use phrases from exercise 5 in your answers.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'a bit special,',
					'a real let - down,',
					'fine,',
					'nothing special,',
					'not up to standard,',
					'out of this world,',
					'pretty average.',
				],
				width: 900,
				borderColor: 'none',
				fontColor: 'rgb(238, 53, 95)',
				justifyContent: 'left',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
  
				<div>
				<div style='margin-left:100px'><hintbox id='0'></hintbox></div>
						<div><b>1.</b>  Tell your partner about the last time you were in a restaurant. How were the food and service?</div>
						<div><b>2.</b> Which local restaurants would you recommend to a foreign visitor and why?</div>
        </div>
          `,
				answer: [
					'a real let-down, nothing special, not up to standard',
					'a bit special, out of this world',
					'fine',
					'pretty average ',
				],
			},
		],
	},
}

export default json
