import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
const json = {
	1: {
		unit: 'Introduction',
		id: 'FGF11-I-P11-E1',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		hideBtnFooter: true,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>
Look at the photo in pairs. Is this an activity you enjoy? Why? / Why not?`,
				color: '#92278f',
			},
		],

		textareaStyle: { width: 750 },
		questions: [
			{
				title: `
               <img  src='img/FriendsPlus/Page11/E1.jpg' />
        `,
				answer: [''],
			},
		],
	},
	2: {
		unit: 'Introduction',
		id: 'FGF11-I-P11-E2',
		audio: '',
		video: '',
		component: T6,
		maxLength: 12,
		// stylesTextInput: {
		//   border: 'none',
		//   fontSize: '22px',
		// },
		// textAlign: 'center',
		inputSize: 150,
		isHiddenCheck: true,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page8/E6/Key/answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
			 In pairs, read the dialogue and decide whether each gap should be <i>a / an, the or –</i> (no article). Write your answers.
				`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		textAlign: 'center',
		questions: [
			{
				title: `
         
        <div style='display:flex;font-size:23px'>
          <div style='font-weight:bold;'>
             Leah<br>
             Toby<br><br>
             Leah<br><br>
             Toby<br><br><br>
             Leah<br><br>
             Toby<br><br>
             Leah<br><br>
             Toby<br><br>
             Leah<br>
          </div>
          <div style='width:20cm;margin:0px 30px;'>
             What are you up to at <sup>1 </sup>#weekend, Toby?<br>
             I’m going to go for <sup>2 </sup>#bike ride on Saturday. Do 
            you fancy coming too?<br>
             I can’t, I’m afraid. I’m going to help my dad with 
            some gardening. We’re going to do some work for 
            a neighbour.<br>
             That doesn’t sound like <sup>3 </sup>#best way to spend 
            your weekend. Gardening is <sup>4 </sup>#hard work! And 
            according to the forecast, <sup>5 </sup>#weather isn’t 
            going to be good.<br>
             I know. But <sup>6 </sup>#neighbour is going to pay us for it. 
            My dad’s <sup>7 </sup>#gardener, so he’s got the right tools.<br>
             Really? I’ll come and help you. I mean, if that’s OK 
            with you and your dad …<br>
             Sure. We’ll share <sup>8 </sup>#money with you: £10 <sup>9 </sup>#
            hour. But what about <sup>10 </sup>#bike ride?<br>
             I’ll go on Sunday instead. The weather will probably 
            be better then. Do you want to come?<br>
             Yes, please. I love <sup>11 </sup>#bike rides. But let’s go in <sup>12 </sup>#afternoon. I’ll be exhausted when I wake up!
          </div>
          
          
        </div>

        
       
        
        `,
				answer: ['', '', '', '', '', '', '', '', '', '', '', ''],
			},
		],
	},
	3: {
		unit: 'Introduction',
		id: 'FGF11-I-P11-E3',
		audio: 'Audios/1.06-audio.mp3',
		video: '',
		component: T6,
		inputSize: 100,
		textAlign: 'center',
		isHiddenCheck: true,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page11/Key/E3answerkey.png',
		titleQuestion: [
			{
				num: '3',
				title: `<span style='margin-right: 10px'><headphone1 name="1.06" src='Audios/1.06-tieude.mp3'></headphone1></span>
Listen and check your answers. When are Toby and Leah going to do the activity in the photo? `,
				color: '#92278f',
			},
		],

		textareaStyle: { width: 550 },
		// hintBox: [{
		//   src: [
		//     "<b>Adjectives describing feelings</b>",
		//     "anxious", "ashamed", "bored", "confused",
		//     "cross", "delighted", "disappointed", "embarrassed", "envious", "excited", "frightened", "proud", "relieved", "shocked", "suspicious", "terrified", "upset"
		//   ],
		//   borderColor: "none",
		//   width: 800,
		//   fontColor: "Purple",

		// }],

		questions: [
			{
				title: `

 <div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px;">

					  <div style="display: flex; gap: 10px;">
          		<span style="font-weight: bold; margin-right: 10px;">Leah</span>
                <div>What are you up to at <sup>1</sup># weekend, Toby?</div>
        </div>
					  <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">Toby</span>
                <div> I’m going to go for <sup>2</sup># bike ride on Saturday. Do you fancy coming too?  
</div>
        </div>
					  <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">Leah</span>
                <div> I can’t, I’m afraid. I’m going to help my dad with  some gardening. We’re going to do some work for  a neighbour.    
</div>
        </div>
					  <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">Toby</span>
                <div>That doesn’t sound like <sup>3</sup># best way to spend your weekend. Gardening is <sup>4</sup># hard work! And according to the forecast, <sup>5</sup># weather isn’t going to be good.   
</div>
        </div>
					  <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">Leah</span>
                <div>I know. But <sup>6</sup># neighbour is going to pay us for it. My dad’s <sup>7</sup># gardener, so he’s got the right tools. 
</div> 
  
        </div>
					  <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">Toby</span>
                <div>  Really? I’ll come and help you. I mean, if that’s OK with you and your dad …  
</div>             
        </div>
					  <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">Leah</span>
                <div>   Sure. We’ll share <sup>8</sup># money with you: £10 <sup>9</sup># hour. But what about <sup>10</sup># bike ride? 
</div>
  
        </div>
					  <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">Toby</span>
                <div> I’ll go on Sunday instead. The weather will probably be better then. Do you want to come?   
</div>
        </div>
					  <div style="display: flex; gap: 10px;">
              <span style="font-weight: bold; margin-right: 10px;">Leah</span>
                <div>  Yes, please. I love <sup>11</sup>#bike rides. But let’s go in  <sup>12</sup># afternoon. I’ll be exhausted when I wake up! 
</div>
  
        </div>
        <b>When are Toby and Leah going to do the activity in the photo? </b>
        <textarea id="12" rows="2"></textarea>
        
       </div>
				
        
        `,
				answer: [
					'the',
					'a',
					'the',
					'',
					'the',
					'the',
					'a',
					'the',
					'an',
					'the',
					'',
					'the',
					'Toby and Leah are going cycling on sunday afternoon.',
				],
			},
		],
	},
	4: {
		unit: 'Introduction',
		id: 'FGF11-I-P11-E4',
		audio: '',
		video: '',
		component: T6v2,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page11/Key/E4answerkey.png',
		inputSize: 80,
		maxLength: 10,
		textAlign: 'center',
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '4',
				title: ` Study the use of articles in the dialogue. Complete the  <span style="margin-right: 10px; color: orange">Learn this!</span>box with <i>a / an, the or –</i> (no article).`,
				color: '#92278f',
			},
		],
		stylesTextInput: { background: "none" },
		textareaStyle: { width: 250 },
		questions: [
			{
				title: `
<div style=" position: relative; padding-top:5px ">
<div> <img style='height: 125mm;width:18cm' src='img/FriendsPlus/Page11/4/1.jpg' />
</div>
    <div style="  position: absolute; top:62px; left: 164px;  "><input id='0' font-size='20px' width='75px' border-bottom='0px'></input></div>

    <div style=" position: absolute; top:96px; left: 228px;  "><input id='0' font-size='20px' width='80px' border-bottom='0px'></input></div>
    <div style=" position: absolute; top:133px; left: 164px;  "><input id='1' font-size='20px' width='80px' border-bottom='0px'></input></div>
    <div style=" position: absolute; top:203px; left: 167px; "><input id='2' font-size='20px' width='75px' border-bottom='0px'></input></div>
    <div style=" position: absolute; top:241px; left: 163px;  "><input id='3' font-size='20px' width='110px' border-bottom='0px'></input></div>
    <div style=" position: absolute; top:281px; left: 166px; "><input id='4' font-size='20px' width='75px' border-bottom='0px'></input></div>
     <div style="position: absolute; top:352px; left: 250px;  "><input id='5' font-size='20px' width='90px' border-bottom='0px'></input></div>
     <div style="position: absolute; top:384px; left: 285px;  "><input id='6' font-size='20px' width='100px' border-bottom='0px'></input></div>
 
       
       </div>
				
        
        `,
				answer: ['a / an | a | an', 'the', 'the', 'a / an | a | an', 'no article', 'a / an | a | an', 'the', 'the'],
			},
		],
	},
	5: {
		unit: 'Introduction',
		id: 'FGF11-I-P11-E5',
		audio: '',
		video: '',
		component: T6,
		textAlign: 'center',
		inputSize: 100,
		maxLength: 3,
		isHiddenCheck: true,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page11/Key/E5answerkey.png',
		titleQuestion: [
			{
				num: '5',
				title: `Complete the sentences with <i>a / an, the or – </i> (no article). Use rules a–f in the <span style="margin-right: 10px; color: orange">Learn this!</span>box to explain your answers. `,
				color: '#92278f',
			},
		],

		textareaStyle: { width: 550 },

		questions: [
			{
				title: `

 <div style="margin-top: 20px; margin-bottom: 150px; display: flex; flex-direction: column; gap: 20px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>My mum is # violin teacher. In fact, she’s # only violin teacher in our town. </div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div> I love # Chinese food and there’s # great Chinese restaurant near my house. </div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div> My brother, who is # accountant with one of # largest companies in London, earns £100 # hour.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div> If you’re hungry, there’s # sandwich in # fridge. </div>
					</div>
				</div>

        
        `,
				answer: ['a', 'the', '-', 'a', 'an', 'the', 'an', 'a', 'the'],
			},
		],
	},
	6: {
		unit: 'Introduction',
		id: 'FGF11-I-P11-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page11/Key/E6answerKey.png',
		component: Circle_Write,
		// hideBtnFooter: true,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '6',
				title: `
				Read the <span style="color: rgb(246, 141, 31);">Learn this!</span> box. 
				 Then underline an example of each of the uses of <i>will and be going to</i> in the dialogue. 
				`,
				color: '#92278f',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { border: 'none', borderColor: 'transparent' },
				// initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { borderBottom: '2px solid', borderColor: 'gray' },
				limitSelect: 10,
				listWords: [
					`What_are_you_up_to_at_the_weekend,_Toby?<br> I’m_going_to_go_for_a_bike_ride_on_Saturday. Do_you_fancy_coming_too? <br>
          I_can’t_,_I’m_afraid. I’m_going_to_help_my_dad_with_some_gardening. We’re_going_to_do_some_work_for_a_neighbour.<br>
          That_doesn’t_sound_like_the_best_way_to_spend_your_weekend. Gardening_is_hard_work! And_according_to_the_forecast, the_weather_isn’t_going_to_be_good.<br>   
          I_know. But_the_neighbour_is_going_to_pay_us_for_it. My_dad’s_a_gardener_so_he’s_got_all_the_right_tools.<br> 
          Really? I’ll_come_and_help_you. I_mean,_if_that’s_OK_with_you_and_your_dad_… <br> Sure. We’ll_share_the_money_with_you:_£10_an_hour. But_what_about_the_bike_ride?<br> I’ll_go_on_Sunday_instead. The_weather_will_probably_be_better_then. Do_you_want_to_come?<br> Yes,_please. I_love_bike_rides. But_let’s_go_in_the_afternoon. I’ll_be_exhausted_when_I_wake_up!`,
				],
				answers: ['0-2', '0-30', '0-32', '0-60', '0-90', '0-118', '0-126', '0-130', '0-132', '0-142'],

				initialValue: [],
			},
			Layout: `
      <img style="width:15cm" src='img/FriendsPlus/Page11/E6/1.jpg' />
			<div style='display:flex;'>
          <div style='font-weight:bold;'>
            Leah<br>
            Toby<br>
            Leah<br><br>
            Toby<br><br>
            Leah<br><br>
            Toby<br>
            Leah<br>
            Toby<br><br>
            Leah<br>
          </div>
          
          <div style='width:24cm;margin-left:30px'>
            <input id='0'  type='Circle' />
          </div>
        </div>
        `,
		},
	},
	7: {
		unit: 'Introduction',
		id: 'FGF11-I-P11-E7',
		audio: '',
		video: '',
		component: T6,
		inputSize: 250,
		maxLength: 23,
		isHiddenCheck: true,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page11/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `Complete the sentences with the correct form of <i>will or be going to</i> and the verbs in brackets. Write which rule in the  <span style="margin-right: 10px; color: orange">Learn this!</span>box you are following. `,
				color: '#92278f',
			},
		],
		textAlign: 'center',
		questions: [
			{
				title: `

 <div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>That train # (not stop) here – it’s going too fast. </div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>  ‘We # (spend) the weekend in Paris.’ <br> ‘Lucky you. You # (have) a great time!’  </div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div> ‘Help! I # (drop) one of these boxes.’ <br> ‘Don’t worry. I # (take) one for you.’ </div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">4</span>
						<div>  I # (go) into town this afternoon. But I # (not be) home late, I promise.  </div>
					</div>
				</div>

        
        `,
				answer: [
					"isn't going to stop",
					'are going to spend',
					"'ll have",
					"'m going to drop",
					"'ll take",
					"'m going to go",
					`won't be`,
				],
			},
		],
	},
	8: {
		unit: 'Introduction',
		id: 'FGF11-I-P11-E8',
		audio: '',
		video: '',
		component: T6,
		// inputSize: 100,
		recorder: true,
		isHiddenCheck: true,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '8',
				title: `<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING PRONUNCIATION </span>
  Work in pairs. Take turns to ask and tell your partner about your plans for your next summer, using <i>will and be going to</i>. Remember to pronounce the weak or strong forms of <i>will, am, is, are</i> appropriately.`,
				color: '#92278f',
			},
		],

		textareaStyle: { width: 550 },
		questions: [
			{
				title: `
     <div>

     </div>
				
        
        `,
				answer: ['', ''],
			},
		],
	},
}

export default json
