import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P88-E1',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    component: T6,
    hideBtnFooter: true,
    isHiddenCheck: true,
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 70,
    },
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
        Work in pairs. Look at the photo. Do you like the 
        tattoo? Is it a form of art? Does it tell you anything about 
        the woman’s personality or not?
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
          
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P88-E2',
    exerciseKey: 'img/FriendsPlus/Page88/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    textareaStyle: {
      // background: 'none',
      width: 700,
      fontSize: 25,
      height: 100,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
        <div style='font-size:23px'>
        Read the article. Why do some people think it is necessary 
        to hide their tattoos when they have an interview?
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
      <div>
      <div style='display:flex; margin-bottom:30px'>
      <img style='width:15cm; height: 15cm' src='img/FriendsPlus/Page88/E2/1.jpg' />
      <img style='width:15cm; height: 15cm' src='img/FriendsPlus/Page88/E2/2.jpg' />
      </div>
        <div><b>ANSWER:</b></div>
        <div><textarea id='0' rows='1'></div>
      </div>
          
        `,
        answer: ['Because many employers have a negative attitude towards tattoos.'],
      },
    ],
  },

  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P88-E3',
    exerciseKey: 'img/FriendsPlus/Page88/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 24,
      height: 200,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style='font-size:23px'>
        Read the <span style='color: rgb(243,148,46)'>Learn this!</span> box. What examples of have 
        something done can you find in the article in exercise 2?
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
      <div>
      <div style='display:flex; margin-bottom:30px'>
      <img style='width:14cm; height: 14cm; ' src='img/FriendsPlus/Page88/E2/1.jpg' />
      <img style='width:15cm; height: 10cm' src='img/FriendsPlus/Page88/E3/1.jpg' />
      </div>
        <div><b>ANSWER:</b></div>
        <div><textarea id='0' row='4'></div>
      </div>
          
        `,
        answer: [
          'Amanda recently had a colourful face tattooed on the top of her arm. Brad had the names of his two daughters tattooed on his neck under his hair. His friend Doug had his back decorated with a large tattoo of a shield. Nevertheless, they deliberately had them done in places …; … they probably had their hair dyed a bright colour to shock their parents!',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P88-E4',
    exerciseKey: 'img/FriendsPlus/Page88/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 800,
    // maxLength: 800,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
        <div style='font-size:23px'>
        Use the prompts to write sentences with the correct form of have something done. Then match each sentence with 
        rule a or b in the <span style='color: rgb(243,148,46)'>Learn this!</span> box.
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
      
      <div style=' margin-bottom:30px; font-size: 22px; line-height:2'>
      <div style='display: flex'>
      <img style='width:16cm; height: 10cm' src='img/FriendsPlus/Page88/E3/1.jpg' />
      <div>
        <div style=''><b>ANSWER:</b></div>
        <div><i style='font-size:18px; color: rgb(152,198,204)'>*Ex: a - 1, 2, 3</i></div>
        <div><b>a - <input id='0' width='100px'></input></b></div>
        <div><b>b - <input id='1' width='100px'></input></b></div>
      </div>  
      </div>
        
      
        <div><b>1 </b>Olivia / her hair / dye / red / for charity</div>
        <div> # .</div>
        <div><b>2 </b>Josh / his bike / steal / at the weekend </div>
        <div> # .</div>
        <div><b>3 </b>the house / its roof / blow off / in the storm</div>
        <div> # .</div>
        <div><b>4 </b>we / the carpets / clean / after the party last weekend</div>
        <div> # .</div>
        <div><b>5 </b>my sister / always / her nails / do / on Fridays </div>
        <div> # .</div>
        <div><b>6 </b>she / her visa application / refuse / last month </div>
        <div> # .</div>
        </div>
     
      </div>
          
        `,
        answer: [
          '1, 4, 5',
          '2, 3, 6',
          'Olivia had her hair dyed red for charity',
          'Josh had his bike stolen at the weekend',
          'The house had its roof blown off in the storm',
          'We had the carpets cleaned after the party last weekend',
          'My sister always has her nails done on Fridays',
          'She had her visa application refused last month',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P88-E5',
    exerciseKey: 'img/FriendsPlus/Page88/E5/Key/E5answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 800,
    // maxLength: 800,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
        <div style='font-size:23px'>
        Correct the mistake in each of the following sentences.
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
      <div><i style='font-size:18px; color: rgb(152,198,204)'>*Rewrite the full sentence.</i></div>
      <div style=' margin-bottom:30px; font-size: 22px'>
        <div><b>1 </b> Tom has had his house breaking into twice.</div>
        <div> # .</div>
        <div><b>2 </b>Where do you usually have your hair to be cut?</div>
        <div> # .</div>
        <div><b>3 </b>The teacher had the exercise rewrite the third time.</div>
        <div> # .</div>
        <div><b>4 </b>The movie star made his nose broken during the fighting scene.</div>
        <div> # .</div>
        </div>
     
      </div>
          
        `,
        answer: [
          'Tom has had his house broken into twice',
          'Where do you usually have your hair cut',
          'The teacher had the exercise rewritten the third time',
          'The movie star had his nose broken during the fighting scene',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P88-E6',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    hideBtnFooter: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
      Work in pairs. Which of these things has your 
      partner done or experienced? Use Have you ever had 
      your … ? Ask follow-up questions if appropriate.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex'>
        <div style='margin-right:80px'>
        <div><b>1 </b> ears / pierce</div>
        <div><b>2 </b> hair / dye</div>
        <div><b>3 </b> email account / hack</div>
        </div>
        <div>
        <div><b>4 </b> phone / steal</div>
        <div><b>5 </b> portrait / paint</div>
        <div><b>6 </b> fortune / tell</div>
        </div>
        </div>
          <img style="width:17cm" src='img/FriendsPlus/Page88/E6/1.jpg' />
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
