import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
const json = {
  1: {
    // Exercise num
    unit: 'Unit 10',
    id: 'FG10-U10-P77-E1',
    exerciseKey: '/img/FriendsPlus/Page77/E1/Key/E1answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    textareaStyle: { width: 800, height: 150 },
    titleQuestion: [
      {
        num: '1',
        title: 'Read the article. What is the problem Boyan Slat is trying to solve? What is his solution?',
        color: '#2E479B',
      },
    ],
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page77/E1/1.jpg' },
      ],
    ],
    questions: [
      {
        title: `
          <div style="flex: 1;">
            <div style="font-weight: bold; font-style: italic;">Answer: </div>
            <div style='display:flex'><b>1</b>&ensp;<textarea id="0" rows="2"></textarea></div>
            <div style='display:flex'><b>2</b>&ensp;<textarea id="1" rows="2"></textarea></div>
            <div><img src="img/FriendsPlus/Page77/E1/1.jpg" ;" /></div>
          </div>
          
        `,
        answer: [
          `The problem is that plastic in the ocean kills millions of sea creatures every year`,
          `His solution is an invention that would float on the surface of the ocean and gradually clean it by collecting around 20 billion tonnes of plastic, which could then be recycled and sold for about $500 million a year.`,
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 10',
    id: 'FG10-U10-P77-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page77/Key/E2answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '2',
        title:
          ' Circle the correct words to complete the <span style="color: rgb();">Learn this</span> box.Use the examples in the box and the article to help you. ',
        color: '#2E479B',
      },
    ],

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, borderRadius: '50%', border: '2px solid', borderColor: 'gray' },
        // selectWordStyle: { padding: 5, borderBottom: '2px solid', borderColor: 'gray' },
        limitSelect: 1,
        listWords: [`after / before`, `after / before`, `separable / inseparable`],
        answers: ['1-0', '2-4', '0-4'],
        initialValue: [],
      },
      Layout: `
			<div style="position: relative;">
				<div><img src='img/FriendsPlus/Page77/E3/1.jpg' width='102%' ;/></div>
				<div style="position: absolute; top: 75px; left: 70px; display: flex; flex-direction: column; gap: 5px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">a</span>
						<div>Two-part phrasal verbs can be separable or inseparable.<br/> With separable phrasal verbs, the object can come before <br/> or after the particle (for, up, with, etc.)</div>
					</div>
        <div style="margin-left: 34px; color: rgb(10, 111, 143);">We must work out the answer.<br/>
              OR We must work the answer out.</div>

					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">b</span>
						<div>When the object is a pronoun (her, it, them, etc.) it can only <br/> come<strong><sup>1</sup><input id='0' type='Circle' /></strong> <br />
						the particle.</div>
					</div>
        <div style="margin-left: 34px; color: rgb(10, 111, 143);">What kind of course did you sign up for?</div>
          
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">c</span>
						<div>With inseparable phrasal verbs, the object always comes<strong><sup>2</sup><input id='1' type='Circle' /></strong> the <br />
						particle, even when it is a pronoun.</div>
					</div>
        <div style="margin-left: 34px; color: rgb(10, 111, 143);">She looks after her dad. She looks after him.</div>

					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">d</span>
						<div>Three-part phrasal verbs are always<strong><sup>3</sup><input id='2' type='Circle' /></strong> 
						usually stay together.</div>
					</div>
				<div style="margin-left: 34px; color: rgb(10, 111, 143);">We won’t run out of energy. We won’t run out of it.</div>
				</div>	
			</div>
      `,
    },
  },

  3: {
    unit: 'Unit 10',
    id: 'FG10-U10-P77-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    textareaStyle: { width: 800 },
    exerciseKey: 'img/FriendsPlus/Page77/E3/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: 'Find the phrasal verbs in the article. Are they separable or inseparable? How do you know?',
        color: '#394893',
      },
    ],

    questions: [
      {
        title: `
          <div style="flex: 1;">
            <div style="font-weight: bold; font-style: italic;">Answer: </div>
            <div style='display:flex'><b>separable</b>&ensp;<textarea id="0" rows="2"></textarea></div>
            <div style='display:flex'><b>inseparable</b>&ensp;<textarea id="1" rows="2"></textarea></div>
            <div style='display:flex'><b>I know</b>&ensp;<textarea id="2" rows="2"></textarea></div>
            <div><img src="img/FriendsPlus/Page77/E1/1.jpg" ;" /></div>
          </div>
          
        `,
        answer: [
          `break up, work out, clean up, throw away`,
          `come up with, end up, call for, run out of `,
          'You know if they are separable or inseparable by the position of the object when it is a pronoun.',
        ],
      },
    ],
  },

  4: {
    // Exercise num
    unit: 'Unit 10',
    id: 'FG10-U10-P77-E4',
    audio: '',
    video: '',
    component: T6v2,
    isHiddenCheck: true,
    textareaStyle: { width: 340 },
    inputSize: 670,
    padding: 0,
    textAlign: 'left',
    //maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page77/key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `<span style="color:#8e258c">DICTIONARY WORK</span>  Read the dictionary entries. Then 
                    answer the questions below.`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page77/E4/1.jpg" style='max-width:60%' />
       <div style='display:flex; line-height:2'>
       <b>1</b>
       <div style='margin-left:10px'>
        Which phrasal verbs are two-part and which are three-part?<br>
        two-part: <input/> <br>
        three-part: <input/> <br>
       </div>
       </div>
      <div style='display:flex;padding-top:10px;line-height:2'>
       <b>2</b>
       <div style='margin-left:10px'>
       Which two-part phrasal verb is separable and which is inseparable? <br>
        separable: <input/> <br>
        inseparable: <input/> <br>
        How do you know? <br>
        <textarea id='4' width='750px' row='2'></textarea>
       </div>
       </div>

        
           
        
          `,
        answer: [
          'look after sb/sth/yourself, look sth up',
          'look down on sb/sth, look up to sb',
          'look sth up',
          'look after sb/sth/yourself, look down on sb/sth, look up to sb',
          'You know whether the verbs are separable or inseparable because of the position of the object (sb/sth).',
        ],
      },
    ],
  },

  5: {
    // Exercise num
    unit: 'Unit 10',
    id: 'FG10-U10-P77-E5',
    audio: '',
    video: '',
    component: T6,
    // maxLength: 15,
    exerciseKey: 'img/FriendsPlus/Page77/E5/Key/E5answer.png',
    textAlign: 'center',
    titleQuestion: [
      {
        num: '5',
        title: `<b style="color:#8e258c">VOCABULARY</b> Match the phrasal verbs below with definitions 
        1–9. Is each phrasal verb separable or inseparable? `,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    inputSize: 350,
    hintBox: [
      {
        src: [
          '<b>Separable and inseparable phrasal verbs</b>',
          'ask sb out',
          'bring sth up',
          'call sth off jumping',
          'come across sth',
          'hang-count on sb',
          'give sth up',
          'hold sb up',
          'take after sb',
          'turn into sth',
        ],
        width: 1100,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <hintbox id=0></hintbox>
            <div style='line-height:50px'>
              <b>1.</b>&emsp;Stop doing something#<br>
              <b>2.</b>&emsp;Mention something#<br>
              <b>3.</b>&emsp;Be similar to somebody:#<br>
              <b>4.</b>&emsp;Rely on somebody#<br>
              <b>5.</b>&emsp;Delay somebody#<br>
              <b>6.</b>&emsp;Invite sombody to go on a date#<br>
              <b>7.</b>&emsp;Find something accidentally#<br>
              <b>8.</b>&emsp;Become something else#<br>
              <b>9.</b>&emsp;Cancel something#<br>
            </div>
           
          `,
        answer: [
          'give sth up (separable)',
          'bring sth up (separable)',
          'take after sb (inseparable) ',
          'count on sb (inseparable)',
          'hold sb up (separable)',
          'ask sb out (separable)',
          'come across sth (inseparable)',
          'turn into sth (inseparable)',
          'call sth off (separable)',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P77-E6',
    exerciseKey: '/img/FriendsPlus/Page77/E6/Key/E6answerKey.png',
    audio: '',
    video: '',
    component: T6v2,
    inputSize: 500,
    // textareaStyle: { width: 300 },
    // maxLength: 1,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 25,
    },
    hintBox: [
      {
        src: [
          '<b>Separable and inseparable phrasal verbs</b>',
          'ask sb out',
          'bring sth up',
          'call sth off jumping',
          'come across sth',
          'hang-count on sb',
          'give sth up',
          'hold sb up',
          'take after sb',
          'turn into sth',
        ],
        width: 1100,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '6',
        title: `
          Complete each question using a phrasal verb from exercise 4 
          or 5. Complete the follow-up question with the same 
          phrasal verb and the correct pronoun.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div>
            <div>
              <b>1)</b>&ensp; Which person from history do you # to?<br>
              &ensp; Why do you # ?<br>
              <div style="margin-left: 34px; color: rgb(10, 111, 143);">Which person from history do you look up to? Why do …</div>
            </div>
            <div>
              <b>2)</b>&ensp;Which food or habit would you like to # ?<br>
              &ensp;Why do you want to # ?<br>
            </div>
            <div>
              <b>3)</b>&ensp;If you could # a famous person, who would<br>
              you choose? Why would you want to # ? 
              
            </div>
            <div>
              <b>4)</b>&ensp;Have you ever # some money in the street? <br>
              &ensp;If so, what did you do when you # ?<br>
              
            </div>
            <div>
              <b>5)</b>&ensp; Which member of your family do you # ? <br/>
             &ensp;In what way do you # ?<br>
              
            
            
         
      </div>
        `,
        answer: [
          'look up',
          'look up to them',
          'give up',
          'give it up',
          ' ask out',
          'ask her / him out | ask her | him out',
          'come across',
          'came across it',
          'take after',
          'take after them / take after him / take after her | take after them | take after him | take after her',
        ],
      },
    ],
  },

  7: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P77-E7',
    exerciseKey: '/img/FriendsPlus/Page77/E6/Key/E6answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 200,
    recorder: true,
    maxLength: 1,

    // stylesTextInput: {
    //   textAlign: 'center',
    //   // fontSize: 25,
    // },
    hideBtnFooter: true,

    titleQuestion: [
      {
        num: '7',
        title: `
          Work in pairs. Ask and answer the questions and 
follow-up questions from exercise 6. 
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
            <div>
              <b>1</b>&ensp; Which person from history do you look up  to?<br>
              &ensp; Why do you look up to them ?<br>
              <div style="margin-left: 34px; color: rgb(10, 111, 143);">Which person from history do you look up to? Why do …</div>
            </div>
            <div>
              <b>2</b>&ensp;Which food or habit would you like to give up ?<br>
              &ensp;Why do you want to give it up ?<br>
            </div>
            <div>
              <b>3</b>&ensp;If you could ask out a famous person, who would<br>
              you choose? Why would you want to ask her ? 
              
            </div>
            <div>
              <b>4</b>&ensp;Have you ever come across money in the street? <br>
              &ensp;If so, what did you do when you came across it ?<br>
              
            </div>
            <div>
              <b>5</b>&ensp; Which member of your family do you take after ? <br/>
             &ensp;In what way do you take after them ?<br>
              
            
            
         
      </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
