import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P48-E1',
    exerciseKey: 'img/FriendsPlus/Page56/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 350,
    maxLength: 20,
    textAlign: 'left',
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 90,
    },
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style="color: rgb(99,185,139)">SPEAKING</span>  
        
        Which items from below can you see in the photos?
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
				<img style="width:13cm" src='img/FriendsPlus/Page56/E1/1.jpg' />
          <div style='font-size:21px;'>
              <div style=" gap: 20px; color:rgb(99,185,139); margin-bottom:10px  ">
                <b>In the house</b>
                &ensp;bedside table
                &ensp;bookcase
                &ensp;bunk bed
                &ensp;curtains
                &ensp;cushion
                &ensp;double bed
                &ensp;duvet
                &ensp;fridge
                &ensp;hook
                &ensp;kitchen cupboard
                &ensp;lamp
								&ensp;pillow
              </div>
              
							<b>ANSWER:</b>
							<div><i style='font-size:18px; color: rgb(152,198,204)'>*Write the selected words in the sorted order of the list above</i></div>
							<div style=" text-align: left">
                  <div style=" gap: 20px">
                    <b>In the house: </b><textarea id='0' row='3'>
                  </div>
              </div>
            </div>
              
          </div>
          
        `,
        answer: [
          'bedside table, bunk bed, curtains, cushion, double bed, duvet, fridge, hook, kitchen cupboard, lamp, pillow',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P56-E2',
    exerciseKey: 'img/FriendsPlus/Page56/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: 'Audios/2.12-audio.mp3',
    component: T6,
    inputSize: 250,
    maxLength: 20,
    textAlign: 'center',
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 19,
      height: 90,
    },
    titleQuestion: [
      {
        num: '2',
        title: `
        <span style="margin-bottom: 10px;font-size: 22px;"> <headphone1 name="2.12" src="Audios/2.12-tieude.mp3"></headphone1> Read the task above and the <span style='color: rgb(99,185,139)'>Speaking 
				Strategy 1.</span>Then listen to a student doing the task. 
				Which room does she prefer? Does she use any linking 
				words when contrasting the photos</div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative'>
				<img style="width:13cm" src='img/FriendsPlus/Page56/E2/1.jpg' />
          <div style='font-size:21px; margin-top:10px'>
              
							<b>ANSWER:</b>
							<div><b>Which room does she prefer?</b>&ensp;# .</div>
							<div><b>Does she use any linking words when contrasting the photos?(Yes/No)</b>&ensp;# .</div>
							
            </div>
              
          </div>
          
        `,
        answer: ['Room A', 'Yes/Yes, she does'],
      },
    ],
  },
  3: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P56-E3',
    audio: 'Audios/2.12-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page56/E3/Key/E3answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: `
				 <headphone1 name="2.12" src="Audios/2.12-tieude.mp3"></headphone1> Choose the best linking words to complete the sentences.<br>Then listen again and check your answers.
				`,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderColor: 'transparent' },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          borderBottom: '2px solid',
          borderColor: 'gray',
        },
        limitSelect: 1,
        listWords: [
          `unless / whereas`, //0
          `but / when`, // 1
          `While / When`, // 2
          `However, / Whereas`, // 3
          `although / despite`, // 4
        ],
        answers: [`0-4`, `1-0`, `2-0`, `3-0`, `4-4`],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 10px">
						
            <div style='margin-bottom:20px'><b>1&ensp;</b>Photo A is of a room in a hostel, I think, <b><input id='0' type='Circle' /></b> the second shows a small flat.</div>
            <div style='margin-bottom:20px'><b>2&ensp;</b>There are bunk beds in the room in the hostel, <b><input id='1' type='Circle' /></b> the other room has got a double bed.</div>
            <div style='margin-bottom:20px'><b>3&ensp;</b> <b><input id='2' type='Circle' /></b> photo A shows a rather untidy room, the room in photo B is very tidy</div>
            <div style='margin-bottom:20px'><b>4&ensp;</b>The room in the hostel looks quite cosy.<b><input id='3' type='Circle' /></b> the furniture looks a bit old and worn out.</div>
            <div style='margin-bottom:20px'><b>5&ensp;</b>I like the idea of sharing with other students, <b><input id='4' type='Circle' /></b> the lack of privacy.</div>

					</div>
					
        `,
    },
  },
  4: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P56-E4',
    // exerciseKey: 'img/FriendsPlus/Page56/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 350,
    maxLength: 20,
    textAlign: 'left',
    hideBtnFooter: true,
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 90,
    },
    titleQuestion: [
      {
        num: '4',
        title: `
        <div style="margin-bottom: 10px;font-size: 22px">
        Read the task above. For each of the four points, think 
        of one advantage of staying with a host family and one 
        advantage of staying in university accommodation.
        </div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
				<img style="width:16cm" src='img/FriendsPlus/Page56/E4/1.jpg' />
							<div style=" text-align: left; font-size: 22px">  
                <div><b>Convenience and cost </b><br><textarea id='0' row='3'><br></div> 
                <div><b>Social life </b><br><textarea id='1' row='3'><br></div> 
                <div><b>Opportunities to practise your English </b><br><textarea id='2' row='3'><br></div> 
                <div><b>Getting to know Britain and British culture </b><br><textarea id='3' row='3'><br></div>    
              </div>      
        </div>
          
        `,
        answer: ['', '', '', ''],
      },
    ],
  },
  5: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P56-E5',
    // exerciseKey: 'img/FriendsPlus/Page56/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: 'Audios/2.13-audio.mp3',
    component: T6,
    hideBtnFooter: true,
    inputSize: 350,
    maxLength: 20,
    textAlign: 'left',
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 200,
    },
    titleQuestion: [
      {
        num: '5',
        title: `
        <headphone1 name="2.13" src="Audios/2.13-tieude.mp3"></headphone1>
        <span style="margin-bottom: 10px;font-size: 22px">
         Listen to two students doing the task. Did they mention any of your ideas from exercise 4? Do you agree with their opinions? Why? / Why not?
        </span>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
				<img style="width:16cm" src='img/FriendsPlus/Page56/E4/1.jpg' />
        <div style=" text-align: left; font-size: 22px">  
                <div><textarea id='0' row='3'><br></div> 
              </div>  
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
  6: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P56-E6',
    exerciseKey: 'img/FriendsPlus/Page56/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 350,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 35,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '6',
        title: `
        <div style="font-size: 22px; "><span style='color:#92278f'>KEY PHRASES</span> Read <span style='color:rgb(96,186,136)'>Speaking Strategy 2.</span>Then complete the phrases with the words below.</div>  
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 800px; position: relative'>
          <div style='font-size:22px;'>
              <div style=" gap: 20px; color:rgb(96,186,136);  ">
                &ensp;considered 
                &ensp;detached house
                &ensp;else
                &ensp;point
                &ensp;see
                &ensp;suppose
                &ensp;thinking
              </div>
              <div style="gap: 20px; margin-top:20px; padding-left:20px; border-left: 2px solid rgb(96,186,136)">
                <div><b style='color:rgb(96,186,136)'>Phrases for gaining time</b></div>
                <div>Let me <span style='font-size: 13px; padding-top:30px'>1</span> # .</div>
                <div><span style='font-size: 13px; padding-top:30px'>2</span> # about it,...</div>
                <div>All things <span style='font-size: 13px; padding-top:30px'>3</span> # , ...</div>
                <div><span style='font-size: 13px; padding-top:30px'>4</span> # the thing is,...</div>
                <div>What <span style='font-size: 13px; padding-top:30px'>5</span> # ? Well,...</div>
                <div>That's a good <span style='font-size: 13px; padding-top:30px'>6</span> # .</div>
                
              </div>
              
            </div>
              
          </div>
          
        `,
        answer: ['see', 'Thinking', 'considered', 'suppose', 'else', 'point'],
      },
    ],
  },
  7: {
    unit: 'Unit 4',
    id: 'FGF11-SB-U4-P56-E7',
    audio: 'Audios/2.13-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page56/E7/Key/E7answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '7',
        title: `
        <div><headphone1 name="2.13" src="Audios/2.13-tieude.mp3"></headphone1> Listen again to both answers. Which phrases from exercise 6 did the students use?</div>
				
				`,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderColor: 'transparent' },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          borderBottom: '2px solid',
          borderColor: 'gray',
        },
        limitSelect: 6,
        listWords: [
          `Let_me_see.<br> Thinking_about_it,...<br> All_things_considered,...<br> Suppose_the_thing_is,...<br> What_else?_Well,...<br> That's_a_good_point.`, //0
        ],
        answers: [`0-0`, `0-2`, `0-4`, `0-8`, `0-10`],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 20px">
						
            <div style=''><input id='0' type='Circle' /></div>

					</div>
					
        `,
    },
  },
  8: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P56-E8',
    exerciseKey: 'img/FriendsPlus/Page56/E8/Key/E8answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.12.mp3',
    component: T6,
    inputSize: 250,
    maxLength: 20,
    hideBtnFooter: true,
    textAlign: 'center',
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 19,
      height: 90,
    },
    titleQuestion: [
      {
        num: '8',
        title: `
        <div style="margin-bottom: 10px;font-size: 22px;"><span style='color: rgb(99,185,139)'>SPEAKING  
				</span>Work in pairs. Read the task above and make 
        notes for each of the four points that you have to cover. 
        Do the task in exercise 7. </div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative'>
				  <img style="width:17cm" src='img/FriendsPlus/Page56/E8/1.jpg' />
          
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
