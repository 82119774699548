import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P87-E1',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    component: T6,
    hideBtnFooter: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
      Work in pairs. Can you recite any poems, lines of poetry or song 
      lyrics by heart in Vietnamese? Were they written recently or a long time 
      ago? Why do you remember them?
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        
          
        `,
        answer: [''],
      },
    ],
  },

  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P87-E2',
    exerciseKey: 'img/FriendsPlus/Page87/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: '/Audios/3.03-audio.mp3',
    // recorder: true,
    component: T6,
    inputSize: 800,
    textareaStyle: { width: 700 },
    // maxLength: 20,
    textAlign: 'left',
    isHiddenCheck: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '2',
        title: `
        <div><headphone1 name="3.03" src="-tieude.mp3"></headphone1>
        Read and listen to the poem. Do you think its message is optimistic 
        or pessimistic? What do you think the last line is telling us to do?
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div >
        <img style="width:18cm; position: relative" src='img/FriendsPlus/Page87/E2/1.jpg' />
        <div><b style='margin-top:20px'>ANSWER:</b><br></div>
        </div>
        <textarea id='0' rows='4'></textarea>
        `,
        answer: [
          `pessimistic (it predicts life will get worse unless we make changes), The last line encourages us to think about how to make these changes.`,
          // 'pessimistic (it predicts life will get worse unless we make changes)',
          // 'The last line encourages us to think about how to make these changes.',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P87-E3',
    exerciseKey: 'img/FriendsPlus/Page87/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/3.03.mp3',
    // recorder: true,
    component: T6,
    inputSize: 200,
    // maxLength: 20,
    textAlign: 'center',
    isHiddenCheck: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style='font-size: 23px'>
        Match the underlined words in the poem with definitions a–h.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div >
          <div style='display:flex; font-size:22px'>
          <img style="width:18cm; position: relative" src='img/FriendsPlus/Page87/E2/1.jpg' />
           <div style='margin-right:60px; margin-top: 179px;'>
           <div><b>a </b> a period of time in history</div>
           <div><b>b </b> not interested in anything</div>
           <div><b>c </b> in the right order</div>
           <div><b>d </b> people who are the same age</div>
           
           <div><b>e </b> an easy solution, but not a good one</div>
           <div><b>f </b> not clever or intelligent</div>
           <div><b>g </b> something that is typical or usual</div>
           <div><b>h </b> lazy – not wanting to do anything</div>
           </div>
          </div>

        <div style=''><b>ANSWER:</b><br></div>
        <div>
        <div>
          <b>a - #&ensp; </b><b>b - #&ensp; </b><b>c - #&ensp; </b><b>d - #&ensp; </b>
        </div>
        <div>
          <b>e - #&ensp; </b><b>f - #&ensp; </b><b>g - #&ensp; </b><b>h - #&ensp; </b>
        </div>

        </div>
        </div>
        `,
        answer: ['era', 'apathetic', 'straight', 'peers', 'quick fix', 'foolish', 'the norm', 'lethargic'],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P87-E4',
    exerciseKey: 'img/FriendsPlus/Page87/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: '/Audios/3.04-audio.mp3',
    // recorder: true,
    component: T6,
    inputSize: 800,
    // maxLength: 20,
    textAlign: 'left',
    isHiddenCheck: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '4',
        title: `
        <div><headphone1 name="3.04" src="-tieude.mp3"></headphone1>
        Listen to the poem again. How does starting with the last line 
        change the meaning? Which version of the poem do you agree with more?
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div >
        <img style="width:18cm; position: relative" src='img/FriendsPlus/Page87/E2/1.jpg' />
        <div><b style='margin-top:20px'>ANSWER:</b><br></div>
        <div> # </div>
        </div>
        `,
        answer: ['It makes the poem optimistic about the future.'],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P87-E5',
    audio: '/Audios/3.05-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page87/E5/Key/E5answerKey.png',
    component: Circle_Write,
    totalInput: 4,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
        <div><headphone1 name="3.05" src="-tieude.mp3"></headphone1> Read the 
        <span style='color: rgb(215,105,140)'>Listening Strategy. </span>
        Then listen to four short extracts and circle the correct implication: a or b.
        </div>
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b `, //1
          `a <br> b `, //1
          `a <br> b `, //1
          `a <br> b `, //1
        ],
        answers: ['0-4', '1-0', '2-4', '3-0'],
        initialValue: [],
      },
      Layout: `
			<div style='line-height:2'>
			 <img src='img/FriendsPlus/Page87/E5/1.jpg' style='margin-left:200px' />
				<div>
            <div style="display: flex;">
              <div style="margin-right:20px"> <b>1.</b> </div>
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              He wishes he hadn’t gone to the gym.<br>
              He was much stronger in the past.
            	</div>
            </div>

					 <div style="display: flex;">
              <div style="margin-right:20px"> <b>2.</b> </div>
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
              She is a big fan of Robbie Williams.<br>
              She is not a big fan of Robbie Williams.
            	</div>
            </div>

					 <div style="display: flex;">
              <div style="margin-right:20px"> <b>3.</b> </div>
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              He often tries food from other countries.<br>
              He rarely tries food from other countries.
            	</div>
            </div>
			
				<div>
				 <div style="display: flex;">
              <div style="margin-right:20px"> <b>4.</b> </div>
		          <div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
              He does not like the jumper very much.<br>
              His sister does not usually buy him a birthday present.
            	</div>
            </div>
				</div>
			
			</div> 
      `,
    },
  },
  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P87-E6',
    exerciseKey: 'img/FriendsPlus/Page87/E6/Key/E6answerKey.png',
    audio: '/Audios/3.06-audio.mp3',
    video: '',
    // recorder: true,
    component: T6,
    inputSize: 200,
    // maxLength: 20,
    textAlign: 'center',
    isHiddenCheck: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '6',
        title: `
        <div style='font-size: 23px'><headphone1 name="3.06" src="-tieude.mp3"></headphone1>
         Listen to four speakers. Match sentences A–E below with the 
        speakers (1–4). There is one extra sentence.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div >
          <div style='font-size:22px'>
          <div>This speaker believes that</div>
          <div><b>A </b>poetry was better in previous eras. </div>
          <div><b>B </b>men are not as good as women at writing poems.</div>
          <div><b>C </b>poetry is still popular with young people.</div>
          <div><b>D </b>young people like poetry more than adults do.</div>
          <div><b>E </b>good poems tell us about the poet’s feelings.</div>
          </div>

        <div style='margin-top:40px; font-size:22px'><b>ANSWER:</b><br></div>
        <div style='font-size:22px'>
          <b>Speaker 1 - #&ensp; </b><br><b>Speaker 2 - #&ensp; </b><br><b>Speaker 3 - #&ensp; </b><br><b>Speaker 4 - #&ensp; </b><br>
        </div>
        </div>
        `,
        answer: ['C', 'E', 'A', 'B'],
      },
    ],
  },
  7: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P87-E7',
    audio: '/Audios/3.06-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page87/E7/Key/E7answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '7',
        title: `
        <div><headphone1 name="3.06" src="-tieude.mp3"></headphone1>
         Listen again. Circle the words which best sum up the speakers’opinions.
        </div>
				 
				`,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: '3px solid black',
          fontWeight: 'bold',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, borderBottom: '2px solid', borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          `are / aren’t`, //0
          `would / wouldn’t`, // 1
          `has / doesn’t`, // 2
          `is / isn’t`, // 3
        ],
        answers: [`0-0`, `1-4`, `2-0`, `3-4`],

        initialValue: [],
      },
      Layout: `
      
					<div style="  padding-top: 10px">
						
            <div style='margin-bottom:20px'><b>1&ensp;</b>These days, rap artists <input id='0' type='Circle' /> the best poets.</div>
            <div style='margin-bottom:20px'><b>2&ensp;</b>Writing poems for greetings cards <input id='1' type='Circle' /> be a good job.</div>
            <div style='margin-bottom:20px'><b>3&ensp;</b>A good poem <input id='2' type='Circle' /> to rhyme and make sense.</div>
            <div style='margin-bottom:20px'><b>4&ensp;</b>Learning poems by heart <input id='3' type='Circle' /> a waste of time.</div>

					</div>
					
        `,
    },
  },
  8: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P87-E8',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    component: T6,
    hideBtnFooter: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '8',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
        Work in pairs. Discuss the opinions in exercise 7. Do you 
        agree with the affirmative or negative version? Give reasons
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div>
        <img style="width:20cm" src='img/FriendsPlus/Page87/E8/1.jpg' />
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
