import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P50-E1',
    // exerciseKey: '/img/FriendsPlus/Page50/E6/Key/E6answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    // audio: 'Audios/2.06.mp3',
    video: '',
    recorder: true,
    component: T6,
    inputSize: 700,
    hideBtnFooter: true,
    // maxLength: 150,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      textAlign: 'left',
      fontSize: 25,
      height: 22,
    },
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style='color: rgb(99,185,139)'>SPEAKING</span>
          Describe the photo. What are the people doing? What might they be saying?
          
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="gap :50px; position: relative">
        
        <img  style='width: 13cm; margin-bottom: 20px ' src='img/FriendsPlus/Page50/E1/1.jpg' />

        </div>
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P50-E2',
    exerciseKey: '/img/FriendsPlus/Page50/E2/Key/E2answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    // audio: 'Audios/2.04.mp3',
    video: '',
    component: T6,
    inputSize: 150,
    maxLength: 30,
    textareaStyle: { width: 500 },
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
    },
    titleQuestion: [
      {
        num: '2',
        title: `
          <span>
          Read the dialogue, ignoring the gaps, and check your ideas from exercise 1.
          </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="display:flex">
          <img  style='width: 16cm ; height: 16cm; ' src='img/FriendsPlus/Page50/E2/1.jpg' />
          <div><i>ANSWER:</i> <textarea id='0' rows='3'></textarea></div>
        </div>
        
        `,
        answer: [
          'The people are looking at property to rent. They are discussing which would be the best for them.',
          // '',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P50-E3',
    exerciseKey: '/img/FriendsPlus/Page50/E3/Key/E3answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    // audio: 'Audios/2.04.mp3',
    video: '',
    component: T6,
    inputSize: 200,
    maxLength: 13,
    stylesTextInput: {
      background: 'none',
      borderBottom: 'none',
      textAlign: 'center',
      fontSize: 22,
      height: 22,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
          <span>
          Complete the dialogue with the comparative or superlative form of the adjectives in brackets
          </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="gap :50px; position: relative">
          <img  style='width: 17cm ; height: 16cm; ' src='img/FriendsPlus/Page50/E3/1.jpg' />
          <input position='absolute' id='0' top='-179px' left='-557px'></input>
          <input position='absolute' id='1' top='-73px' left='-260px'></input>
          <input position='absolute' id='2' top='-38px' left='-320px'></input>
          <input position='absolute' id='3' top='-3px' left='-245px'></input>
          <input position='absolute' id='4' top='104px' left='-520px'></input>
        </div>
        `,
        answer: ['nearest', 'worst', 'highest', 'further / farther', 'more spacious'],
      },
    ],
  },
  4: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P50-E4',
    exerciseKey: '/img/FriendsPlus/Page50/E4/Key/E4answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    // audio: 'Audios/2.04.mp3',
    video: '',
    component: T6,
    inputSize: 750,
    textareaStyle: { width: 500 },
    maxLength: 300,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      textAlign: 'left',
      fontSize: 22,
      height: 22,
    },
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
          <span>
          Read the <span style='color: orange'>Learn this!</span> box. Match each highlighted phrase in the dialogue with a rule (a-f)
          </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="display:flex ;gap :50px;">
          <img  style='width: 15cm; height: 17cm' src='img/FriendsPlus/Page50/E4/1.jpg' />
          <div style=''>
            <div> <b>ANSWER:&ensp;</b></div>
              <div style='display:flex'><b>a&ensp;</b><textarea id='0' rows='2'></textarea></div>
              <div style='display:flex'><b>b&ensp;</b><textarea id='1' rows='2'></textarea></div>
              <div style='display:flex'><b>c&ensp;</b><textarea id='2' rows='2'></textarea></div>
              <div style='display:flex'><b>d&ensp;</b><textarea id='3' rows='2'></textarea></div>
              <div style='display:flex'><b>e&ensp;</b><textarea id='4' rows='2'></textarea></div>
              <div style='display:flex'><b>f&ensp;</b><textarea id='5' rows='2'></textarea></div>
           </div>
        </div>
        `,
        answer: [
          `the best we've seen`,
          `higher and higher `,
          `The more we spend on rent, the less we'll have for other things.`,
          `more easily`,
          `the least expensive; the least space, less expensive than it seems`,
          `the fewest rooms`,
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P50-E5',
    exerciseKey: '/img/FriendsPlus/Page50/E5/Key/E5answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    audio: 'Audios/2.06-audio.mp3',
    video: '',
    component: T6,
    // hideBtnFooter: true,
    inputSize: 200,
    maxLength: 150,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      textAlign: 'center',
      fontSize: 20,
      height: 22,
    },
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
          <headphone1 name="2.06" src="Audios/2.06-tieude.mp3"></headphone1> <span>
          Look at the table. Then listen and decide if the sentences are true or false. Correct the false sentences.
          </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="gap :50px; position: relative">
         <div>
         <img  style='width: 13cm; margin-bottom: 20px ' src='img/FriendsPlus/Page50/E5/1.jpg' />
         <div><b>1 </b>Flat 1 has got the highest rent.</div>
        <div style='color:rgb(152,198,204); font-size: 17px'>F -> Flat 1 has got the lowest rent.</div>
        <div><b>2 </b><input id='0' width='60px'></input>&ensp;->&ensp;<input id='1' width='660px'></input></div>
        <div><b>3 </b><input id='2' width='60px'></input>&ensp;->&ensp;<input id='3' width='660px'></input></div>
        <div><b>4 </b><input id='4' width='60px'></input>&ensp;->&ensp;<input id='5' width='660px'></input></div>
        <div><b>5 </b><input id='6' width='60px'></input>&ensp;->&ensp;<input id='7' width='660px'></input></div>

        <div><b>6 </b><input id='8' width='60px'></input>&ensp;->&ensp;<input id='9' width='660px'></input></div>
        <div><b>7 </b><input id='10' width='60px'></input>&ensp;->&ensp;<input id='11' width='660px'></input></div>
        <div><b>8 </b><input id='12' width='60px''></input>&ensp;->&ensp;<input id='13' width='660px'></input></div>
        <div><b>9 </b><input id='14' width='60px''></input>&ensp;->&ensp;<input id='15' width='660px'></input></div>
         </div>
          
          
        </div>
        `,
        answer: [
          'T',
          '',
          'F',
          'Flat 3 is the most comfortable of the three.',
          'F',
          'Flat 2 is furthest from the centre.',
          'F',
          'You’ll pay the least rent for flat 1.',
          'F',
          'Flat 3 is nearer to the station than flats 1 and 2.',
          'T',
          '',
          'F',
          'Flat 2 has got the most rooms.',
          'F',
          'Flat 3 is more comfortable and more spacious than flat 2.',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P50-E6',
    exerciseKey: '/img/FriendsPlus/Page50/E6/Key/E6answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    // audio: 'Audios/2.06.mp3',
    video: '',
    component: T6,
    inputSize: 700,
    // maxLength: 150,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      textAlign: 'left',
      fontSize: 22,
      height: 22,
    },
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
          <div><span style='color: #92278f'>USE OF ENGLISH</span>
          Complete the second sentence so that it means the same as the first using the word in brackets and the correct comparative or superlative form.
          </div>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="gap :50px;line-height:2">
        
        <div><b>1 </b>Houses sell less fast in December. (slowly)</div>
        <div style='margin-left:20px'>Houses # .</div>
        <div><b>2 </b>The cottage isn’t as spacious as the villa. (less)</div>
        <div style='margin-left:20px'>The cottage # .</div>

        <div><b>3 </b>Houses are becoming increasingly expensive. (more)</div>
        <div style='margin-left:20px'>Houses # .</div>
        <div><b>4 </b>As the flat gets older, it becomes more dilapidated. (the)</div>
        <div style='margin-left:20px'>The # .</div>
        <div><b>5 </b>I've never seen a cosier living room! (the)</div>
        <div style='margin-left:20px'>This is # !</div>
  
        </div>
        `,
        answer: [
          'sell more slowly in December',
          'is less spacious than the villa',
          'are becoming more expensive/are becoming more and more expensive',
          'older the flat gets, the more dilapidated it becomes',
          `the cosiest living room I've ever seen`,
        ],
      },
    ],
  },

  7: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P50-E7',
    // exerciseKey: '/img/FriendsPlus/Page50/E6/Key/E6answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    // audio: 'Audios/2.06.mp3',
    video: '',
    recorder: true,
    component: T6,
    hideBtnFooter: true,
    inputSize: 700,
    // maxLength: 150,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      textAlign: 'left',
      fontSize: 25,
      height: 22,
    },
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '7',
        title: `
          <span style='color: rgb(99,185,139)'>SPEAKING</span>
          Work in pairs. Ask and answer using a superlative form (-est / most / least) and the present perfect with ever.
          
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="gap :50px; position: relative">
        
        <div><b>1 </b>Impressive building / visit</div>
        
        <div><b>2 </b>Interesting person / know </div>
       
        <div><b>3 </b>Interesting person / know </div>
        
        <div><b>4 </b>Exciting film / see</div>
        <img style="width:15cm; margin-top:20px" src='img/FriendsPlus/Page50/E7/1.jpg' />
        </div>
        `,
        answer: [
          'sell more slowly in December',
          'is less spacious than the villa',
          'are becoming more expensive/are becoming more and more expensive',
          'older the flat gets, the more dilapidated it becomes',
          'the cosiest living room I’ve ever seen',
        ],
      },
    ],
  },
};
export default json;
