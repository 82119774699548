import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P42-E1',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page42/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `<span style='color:rgb(2, 146, 97);'> SPEAKING </span> Work in pairs. Ask and answer the questions.
			  `,
        color: '#92278f',
      },
    ],
    recorder: true,

    hideBtnFooter: true,
    titleImage: '',
    questionImage: [],
    inputSize: 240,
    textAlign: 'left',
    questions: [
      {
        title: `
       	<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div> What time do you usually go to bed a) on weekdays and  b) at the weekend? </div>
					</div>
          <div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>  What time do you usually get up a) on weekdays and  b) at the weekend?  </div>
					</div>
          <div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div> Have those times changed much since you were younger? </div>
					</div>
          </div>
			  `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P42-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page42/key/E2answerKey.png',

    titleQuestion: [
      {
        num: '2',
        title: `
				Read the <span style='color:#7372B5;'> Reading Strategy. </span>  Then read the article to get an idea of the general meaning. Which is the best summary:  a, b, c or d? 
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    // //hideBtnFooter: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 900, fontSize: 25 },
        initialValue: [],
        answers: [``],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [`a <br> b <br> c <br><br> d`],
        answers: ['0-8'],
        initialValue: [],
      },
      Layout: `

			<div style="display:flex" >
		    <img src='img/FriendsPlus/Page42/E1/1.jpg' style="width:50%"/>
 					<div style="margin-top:15px; margin-left:25px">
						The writer’s main purpose is to explain
            <div style="display: flex;">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      why children need more sleep than adults.<br>
              why we should always get lots of sleep <br>
              why our bodies feel more or less tired at different times of day. <br>
              why some people feel more alert than others. 
		          </div>
            </div>

					</div>
          
          </div>
 
      `,
    },
  },

  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P42-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page42/key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				Match the paragraphs (A–H) with questions 1–10 below.  Two of the paragraphs match more than one question.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    inputSize: 50,
    isHiddenCheck: true,

    // //hideBtnFooter: true,

    questions: [
      {
        title: `
       <div style='display: flex;'>
       <div style='flex:2'>
        <img src='img/FriendsPlus/Page42/E3/3.jpg' alt='' style=' max-width:100%;'/>


       </div>

       <div  style='flex:3'>
        <img src='img/FriendsPlus/Page42/E3/1.jpg' alt='' style=' max-width:100%;'/>
       </div>

        </div>
        <div style=' margin:10px 100px;display:flex; justify-content: space-between;'>

          <b>1</b>#&emsp;
          <b>2</b>#&emsp;
          <b>3</b>#&emsp;
          <b>4</b>#&emsp;
          <b>5</b>#&emsp;
          <b>6</b>#&emsp;
          <b>7</b>#&emsp;
          <b>8</b>#&emsp;
          <b>9</b>#&emsp;
          <b>10</b>#&emsp;</div>
       </div>
			  `,
        answer: [`G`, `D`, `E`, `C`, `G`, `B`, `D`, `A`, `H`, `F`],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P42-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page42/key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				Read the article again and answer the questions.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    inputSize: 1150,
    // hideBtnFooter: true,

    questions: [
      {
        title: `
			 <div style='display: flex; margin-top:30px'>
          <div style='margin-right: 10px;'><b> 1 </b> </div>
          <div>
            <div>How much more sleep does a young child need than  an adolescent?  </div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
           <div> What’s the difference between ‘larks’ and ‘owls’?  </div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div>  Why is it better not to exercise early in the morning? </div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
            <div> When do sportspeople think is a good time to try to  break a record?
 </div>
            <div>#</div>
          </div>
        </div>
          <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>
            <div> If you want to lose weight, which is more important:  when you eat or how much you eat?
 </div>
            <div>#</div>
          </div>
        </div>
          <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>
            <div> Why is it not a good idea to use gadgets such as  tablets and mobiles late at night?
 </div>
            <div>#</div>
          </div>
        </div>
        </div>

			  `,
        answer: [
          `About an hour.`,
          `A lark likes to go to bed early and get up early, whereas an owl prefers to go to bed late and get up late.`,
          `Because your blood pressure is at its highest at that time.`,
          `3p.m - 6p.m.`,
          `How much you eat is more important than when you eat.`,
          `Because they emit large amounts of blue light, which keeps us awake.`,
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P42-E5',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '5',
        title: `
				<span style='color:rgb(2, 146, 97);'>SPEAKING</span> Read the Work in pairs. Ask and answer.
			  `,
        color: '#92278f',
      },
    ],
    recorder: true,

    titleImage: '',
    inputSize: 890,
    hideBtnFooter: true,
    questions: [
      {
        title: `
			 <div style='line-height:2'>
        <div style='display: flex; margin-top:30px;'>
          <div style='margin-right: 10px;'><b> 1 </b> </div>
          <div>
            <div>Do you get enough sleep? If not, why not?  </div>
            
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
           <div> How do you feel if you don’t get enough sleep? What things are more difficult to do? </div>
            
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div>  Do you use gadgets such as mobiles late at night?  What for? Do you find that they keep you awake? </div>
            
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
            <div>   When are you most alert? Are you a ‘lark’, an ‘owl’, or in between the two? Explain your answer.
 </div>
            
          </div>
        </div>
        </div>
       </div>

			  `,
        answer: [``, ``, ``, ``],
      },
    ],
  },
};

export default json;
