import React from 'react';
import styles from './audioimage.module.css';

function AudioImage({ name='', colorText = '#231f20', colorAudio = '#231f20' }) {
  return (
    <div className={styles.container} style={{ backgroundColor: colorText }}>
      <div
        className={styles.icon_container}
        style={{ backgroundColor: colorAudio, color: '#d3b6d7' }}
      >
        <i
          className={`fa fa-headphones ${styles.icon_container_content}`}
        />
      </div>
      <div className={styles.name_container}>
        <span
          className={styles.name_container_content}
        >{` ${name}`}</span>
      </div>
    </div>
  );
}

export default AudioImage;
