import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    // Exercise num
    unit: 'Review 2',
    id: 'FGF11-U2-P35-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    recorder: true,
    exerciseKey: 'img/FriendsPlus/Page35/Key/E1answerKey.png',
    question: [],
    titleQuestion: [
      {
        num: '',
        title: `<span style="color:#007092">READING</span> `,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    totalInput: 2,
    isHiddenCheck: true,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page35/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page35/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/3.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page35/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/6.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page35/E1/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/9.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page35/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/12.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/14.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page35/E1/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/17.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page35/E1/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page35/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/20.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page35/E1/21.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page35/E1/22.jpg' }],
      [
        { url: 'img/FriendsPlus/Page35/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/24.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page35/E1/25.jpg' },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: 'Review 2',
    id: 'FGF11-U2-P35-E2',
    audio: 'Audios/1.29-audio.mp3',
    video: '',
    titleQuestion: [
      {
        num: '',
        title: `<span style="color:#007092">LISTENING</span> <br> <headphone1 name='1.29' src='Audios/1.29-tieude.mp3'></headphone1> You will hear 4 short extracts. There is one
      question for each extract. Choose the best answer (A–C). `,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page35/Key/E2answerKey.png',
    // recorder: true,
    totalInput: 4,
    component: Circle_Write,
    isHiddenCheck: true,
    // isAllowSubmit: false,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1

        ],
        answers: ['0-0', '1-4', '2-8', '3-0'],
        initialValue: [],
      },
      Layout: `
			<div>
				<div>
					<b>1</b>&ensp;
           		 Who is the speaker?<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
             	A hairdresser<br>
              A customer at ahairdresser’s<br>
              A fashion reporter<br>
            	</div>
            </div>

						<b>2</b>&ensp;
           		  How does the woman explain why she likes audiobooks?<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
            	 Her father records them.<br>
              They bring back a good feeling.<br>
              They helped her learn to read.
            	</div>
            </div>

						<b>3</b>&ensp;
           		  What does the woman say about the sport she liked at school?<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
               Her children are better at it than she was.<br>
               She was never very good at it.<br>
               She has stopped playing it.
            	</div>
            </div>
				</div>
			
				<div>
					<b>4</b>&ensp;
           		  Where is the speaker?<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
               In a classroom<br>
               At a job training centre<br>
               In a library
            	</div>
            </div>
				</div>
			
			</div> 
      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Review 2',
    id: 'FGF11-U2-P35-E3',
    recorder: true,
    hideBtnFooter: true,
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page35/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: `<span style="color:#007092">SPEAKING</span>  `,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Compound nouns</b>',
          'flood lights',
          'football pitch',
          'main road',
          'mountain range',
          'safety net',
          'sea shore',
          'swimming pool',
          'tennis court',
          'tennis player',
          'tower block',
        ],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    // maxLength: 27,
    isHiddenCheck: true,
    textareaStyle: { width: 700 },
    inputSize: 40,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
					<div style='width:15cm;text-align:center'>
          		 Work in pairs. You and a friend are planning a day trip
              cycling to the countryside. Discuss with your friend what
              you are going to do. Give reasons for your opinions. Agree
              on an activity.
						</div>
           
          `,
        answer: [''],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Review 2',
    id: 'FGF11-U2-P35-E4',
    audio: '',
    video: '',
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page35/Key/E4answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: `<span style="color:#007092">WRITING</span>  `,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],

    component: T6,
    // maxLength: 27,
    isHiddenCheck: true,
    textareaStyle: { width: 700 },
    inputSize: 40,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
					<div style='width:15cm;text-align:center'>
          		 You have just attended a meeting of your school’s science
               club. Write a blog post (150–180 words) about it for the
               school website.
          </div>
          <div style='width:17cm'>
              <ul>
                  <li>Where and when did the event take place?</li>
                  <li>Who attended the event?</li>
                  <li>What happened at the event?</li>
                  <li>Give your personal opinion of the event and say what
                      effects the event has had.</li>
              </ul>
           </div>
           <textarea id='0'  rows='4'></textarea>
          `,
        answer: [''],
      },
    ],
  },
};

export default json;
