import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P109-U2-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '',
        title: `<strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>2C</span>Diets</strong> <br />
         <strong style='color:#860052'>1</strong> Circle the correct answers: a, b or c. `,

        color: '#860052',
      },
    ],
    textAlign: 'left',
    // inputSize: 510,
    // maxLength: 200,
    exerciseKey: 'img/FriendsPlus/Page109/Key/U2E1answerKey.png',
    // questionImage: [],
    // isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
        ],
        answers: ['0-0', '1-8', '2-0', '3-4', '4-0', '5-0', '6-8'],
        initialValue: [],
      },
      Layout: `

      <div style='display:flex;font-size:20px'>
  <div style='flex: 1'>
    <b>1</b>&ensp;If you’re on a low-salt diet, you should avoid … .
    <div style="display: flex;">
      <div style="display: flex; margin-left: 40px">
        <div><input id='0' type='Circle'/></div>
        <div style='margin-left:20px'>
          bacon <br> tomatoes <br> eggs
        </div>
      </div>
    </div>

    <div style ='flex:1;'>
      <b>2</b>&ensp; A vegetarian would not eat … .   <br>
      <div style="display: flex;margin-left:40px">
        <div><input id='1' type='Circle'/></div>
        <div style='margin-left:20px'>
          pasta <br> mushrooms <br> tuna
        </div>
      </div>

      <b>3</b>&ensp;We There are lots of vitamins and minerals in … .  <br>
      <div style="display: flex;margin-left:40px">
        <div><input id='2' type='Circle'/></div>
        <div style='margin-left:20px'>
          vegetables <br> crisps <br> butter
        </div>
      </div>

      <b>4</b>&ensp; A good source of iron is … .
    <div style="display: flex;margin-left:40px">
      <div><input id='3' type='Circle'/></div>
      <div style='margin-left:20px'>
             peanuts <br> steak <br> cheese

      </div>
    </div>

    </div>
  </div>

  <div style='flex: 1'>
    <b>5</b>&ensp; Brown rice and wholemeal bread contain more … than white rice and white bread.
    <div style="display: flex;margin-left:40px">
      <div><input id='4' type='Circle'/></div>
      <div style='margin-left:20px'>
      fibre <br> calcium <br> fat
      </div>
    </div>
    <b>6</b>&ensp; Which of these is not a source of carbohydrate?
    <div style="display: flex;margin-left:40px">
      <div><input id='5' type='Circle'/></div>
      <div style='margin-left:20px'>
       chicken <br> rice <br> cereal
      </div>
    </div>
 
   

<b>7</b>&ensp;Organic food does not usually contain … .
    <div style="display: flex;margin-left:40px">
      <div><input id='6' type='Circle'/></div>
      <div style='margin-left:20px'>
        nutrients <br> protein <br> additives
      </div>
    </div>

      
    </div>
  </div>
</div>

 

        
      `,
    },
  },
  2: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P109-U3-E1',
    exerciseKey: 'img/FriendsPlus/Page109/Key/U3E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 950,
    // maxLength: 20,
    checkUppercase: true,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '',
        title: `
<strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>3G</span> Describing appearance </strong> <br />
         <strong style='color:#860052'>1</strong> Describe the people in photos A–C using as many of the words and phrases below as possible. `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
        <div style ='display:flex'>
        <div style ='flex:1'>
        <div style='width: 500px;font-size:19px;'>
          <div>
              <div style=" gap: 20px; color:rgb(146,39,143);  ">
                <b>Hair styles</b>
                &ensp;a bob
                &ensp;a fringe
                &ensp; highlights
                &ensp; a parting
                &ensp;a ponytail
                &ensp;dreadlocks
                &ensp;plaits
                &ensp;spiky hair



              </div>
               <div style=" gap: 20px; color:rgb(146,39,143);  ">
                <b>Facial hair</b>
                &ensp;a beard
                &ensp; a goatee
                &ensp;a moustache
                &ensp;sideburns
                &ensp;stubble
                &ensp; clean-shaven

              </div>
               <div style=" gap: 20px; color:rgb(146,39,143);  ">
                <b>Skin</b>
                &ensp;dark skin
                &ensp;light skin
                &ensp;freckles
                &ensp;rosy cheeks
                &ensp;wrinkles
                &ensp;a scar
                &ensp;a tattoo
                &ensp;spots


              </div>



            </div>

          </div>
        </div>

        <div style ='flex:1'>
   <div>
          <img src='img/FriendsPlus/Page109/U3-E1/1.jpg' style='max-width:62%'>
          </div>
        </div>
        </div>
        <strong>A</strong>&ensp; <input/> <br>
        <strong>B</strong>&ensp; <input/> <br>
        <strong>C</strong>&ensp; <input/>



        
       
          
        `,
        answer: [
          'He has thick black hair, a beard and a moustache. He also has a tattoo on his arm.',
          'She has brown hair in a short ponytail. She also has a fringe. Her skin is quite dark.',
          'She has brown hair with two plaits and a centre parting. She has light skin, freckles and rosy cheeks.',
        ],
      },
    ],
  },
  3: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P109-U3-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title:
          'Write five sentences describing yourself and your friends or family. Use words and phrases from exercise 2.',
        color: '#92278f',
      },
    ],
    hideBtnFooter: true,
    exerciseKey: '',
    stylesTextInput: {
      // fontSize: 18,
      // height: 24,
      // borderBottom: "none",
      backgroundColor: 'transparent',
    },
    textAlign: 'center',
    inputSize: 190,
    maxLength: 13,
    titleImage: '',
    questionImage: [],
    textareaStyle: { width: 1000 },
    questions: [
      {
        title: `
        <textarea id='0' rows='5'></textarea>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P108-U4-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page109/Key/U4E1answerKey.png',
    titleQuestion: [
      {
        num: '',
        title: `<strong style='font-size: 800'><span style='color:#860052; font-weight: 800; margin-right: 10px'>4E</span>Collocations: do or make? </strong> <br />
         <strong style='color:#860052'>1</strong> Underline the correct verb: do or make.  `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderBottom: '1px solid #000000', // Sửa giá trị border thành "1px solid #000000"
          borderColor: 'transparent',
          color: '#00aeef',
        },
        selectWordStyle: {
          padding: 5,
          borderColor: '#00aeef',
        },

        limitSelect: 1,
        listWords: [
          'do / make', //0
          'do / make', //1
          'do / make', //2
          'do / make', //3
          'do / make', //4
          'do / make', //5
          'do / make', //6
          'do / make', //7
        ],
        answers: ['0-4', '1-0', '2-0', '3-4', '4-4', '5-0', '6-4', '7-0'],
        initialValue: [],
      },
      Layout: `
        <div style ='line-height:2;'>
        <div><b>1</b> Can I <b><input id='0' type='Circle' /> </b> a suggestion?</div>
        <div><b>2</b> We need to  <b><input id='1' type='Circle' /> </b> more research before we buy a car.</div>
        <div><b>3</b>  I helped my parents to  <b><input id='2' type='Circle' /> </b>the housework.</div>
        <div><b>4</b> We didn’t  <b><input id='3' type='Circle' /></b>  a sound as we entered the house. </div>
        <div><b>5</b>  Let’s try not to <b><input id='4' type='Circle' /></b> a mess in the living room. </div>
        <div><b>6</b>  A car drove into our wall, but it didn’t  <b><input id='5' type='Circle' />  </b> much damage.</div>
        <div><b>7</b> Before we start eating, I’d like to  <b><input id='6' type='Circle' />  </b> an announcement.</div>
        <div><b>8</b>  I often  <b><input id='7' type='Circle' /></b> the cooking at weekends. </div>
        </div>


      `,
    },
  },
  5: {
    // Exercise num
    unit: 'Vocabulary Builder',
    id: 'FGF11-VB-P109-U2-E2',
    // recorder: true,
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page109/Key/U4E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: ` Complete the sentences with the correct form of do or make.  `,
        color: '#860052',
      },
    ],
    component: T6v2,
    // //hideBtnFooter: true,
    // isHiddenCheck: true,
    // textareaStyle: { width: 700 },
    inputSize: 250,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height:2.5;'>
           <div style='display:flex;'>
            <b>1</b>
            <div style='margin-left:20px'>
             I didn’t enjoy the exercise class, but it probably # me good.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>
               He finds exams difficult, but he always # his best.

            </div>
          </div>
           <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:20px'>
               It wasn’t my birthday yesterday – you # a mistake.

            </div>
          </div>
           <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:20px'>
              Both hotels look great, but we have to # a choice.
            </div>
          </div>
           <div style='display:flex'>
            <b>5</b>
            <div style='margin-left:20px'>
           I like your new haircut. It # a big difference to your appearance.
            </div>
          </div>
           <div style='display:flex'>
            <b>6</b>
            <div style='margin-left:20px'>
          Did you see that motorbike? It was # about 200 km/h!
            </div>
          </div>
          </div>
  `,
        answer: [`did `, `does `, `have made`, `make `, `makes | has made | makes / has made`, `doing`],
      },
    ],
  },
};

export default json;
