import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'unit 5',
    id: 'FGF11-A-P63-E1',
    // audio: '',
    // video: '',
    // exerciseKey: 'img/FriendsPlus/Page63/E1/Key/answerKey.png',
    component: T6,
    inputSize: 530,
    maxLength: 49,
    recorder: true,
    hideBtnFooter: true,
    //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Look at the photo and explain the meaning of the headlines below.
					 Why do you think people make this kind of mistake?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					 <img src="img/FriendsPlus/Page63/E1/1.jpg"/>
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'unit 5',
    id: 'FGF11-A-P63-E2',
    audio: 'Audios/2.18-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page63/Key/E2answerKey.png',
    component: T6,
    inputSize: 100,
    // maxLength: 6,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '2',
        title: `
				<span style="margin-right: 10px;"><headphone1 name="2.18" src="Audios/2.18-tieude.mp3"></headphone1></span>
				Listen to two people discussing a news item. 
				Which of the headlines in exercise 1 is the main idea of the 
				news items?
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				         <img src="img/FriendsPlus/Page63/E1/2.jpg" style="margin-left:160px" />
								 <div style="margin-top:20px">#</div>
				`,
        // title: `
        //   <div style="position: relative; width: 900px;">
        //     <div>
        //       <img src="img/FriendsPlus/Page63/E3/1.jpg" style="max-width: 100%;" />
        //     </div>

        //     <div style="position: absolute; top: 30px; left: 401px; font-size: 15px; font-weight: bold; background-color: rgb(234,62,167); color: white; border-radius: 50%; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">A</div>
        //     <div style="position: absolute; top: 361px; left: 386px; font-size: 15px; font-weight: bold; background-color: rgb(234,62,167); color: white; border-radius: 50%; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">B</div>

        //     <div style="position: absolute; top: 26px; left: 479px;"><i>Answer:</i> # is more positive.</div>
        //   </div>
        // `,
        answer: ['B'],
      },
    ],
  },
  3: {
    unit: 'unit 5',
    id: 'FGF11-A-P63-E3',
    audio: 'Audios/2.18-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page63/Key/E3answerKey.png',
    component: T6,
    inputSize: 700,
    // maxLength: 16,
    stylesTextInput: {
      textAlign: 'left',
      fontSize: 25,
    },
    textareaStyle: { width: 700 },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: '3',
        title: `
				<span style="margin-right: 10px;"><headphone1 name="2.18" src="Audios/2.18-tieude.mp3"></headphone1></span>
					Read the <span style="color: rgb(246, 141, 31)">Listening Strategy</span>. Then listen again 
					and decide whether each sentence below is a fact or an 
					opinion. Which words introduce the opinions?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<div style="display:flex;flex-direction:row; ">
				<img src="img/FriendsPlus/Page63/E1/3.jpg" width=50%; />
				<img src="img/FriendsPlus/Page63/E3/1.png" />
				</div>
					 <div style="margin-left:200px">
					 	<div style="display:flex"><b>1.</b> <textarea id='0' row='1'></textarea></div>
						<div style="display:flex"><b>2.</b> <textarea id='1' row='1'></textarea></div>
					 	<div style="display:flex"><b>3.</b> <textarea id='2' row='1'></textarea></div>
					 	<div style="display:flex"><b>4.</b> <textarea id='3' row='1'></textarea></div>
					 	<div style="display:flex"><b>5.</b> <textarea id='4' row='1'></textarea></div>
					 	<div style="display:flex"><b>6.</b> <textarea id='5' row='1'></textarea></div>
					 </div>
				`,
        answer: [
          'fact',
          'fact',
          'opinion (I reckon she just wanted to spend a couple of days on her own ',
          'fact',
          'opinion (But in my view, she invented the whole story about the satnav)',
          'opinion (I expect hundreds of people end up in completely the wrong place because of their satnav)',
        ],
      },
    ],
  },
  4: {
    unit: 'unit 5',
    id: 'FGF11-A-P63-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page63/Key/E4answerKey.png',
    component: T6,
    inputSize: 350,
    titleQuestion: [
      {
        num: '4',
        title: `
					<span style="color:rgb(146, 39, 144)">VOCABULARY</span> Work in pairs. Match three of the gadgets in the list with photos A–C below.
					 Check that you understand the meaning of all the other words.

			`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					 <div>
					 	<img src="img/FriendsPlus/Page63/E1/4,1.jpg" style="width:60%" />
						
						
					<div style="display:flex">
							<img src="img/FriendsPlus/Page63/E1/4.jpg" style="width:60%" />
						<div>
							<div><b>A.</b> #</div>
							<div><b>B.</b> #</div>
							<div><b>C.</b> #</div>
						</div>
					</div>
						
					 </div>
					
					 
				`,
        answer: ['smartwatch', 'digital radio', 'games console'],
      },
    ],
  },
  5: {
    unit: 'unit 5',
    id: 'FGF11-A-P63-E5',
    audio: 'Audios/2.19-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page63/Key/E5answerKey.png',
    component: T6,
    inputSize: 250,
    maxLength: 37,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
				<span style="margin-right: 10px;"><headphone1 name="2.19" src="Audios/2.19-tieude.mp3"></headphone1></span>
					Listen to four dialogues. Which gadgets from exercise 4 are mentioned in each one?
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div>
							<img src="img/FriendsPlus/Page63/E1/4.jpg" 	 />
						<div style="display:flex; padding:10px;">
							<span><b>1.</b> #</span>
							<span><b>2.</b> #</span>
							<span><b>3.</b> #</span>
							<span><b>4.</b> #</span>
						</div>

					</div>
        `,
        answer: ['smartphone', 'satnav', 'MP3 player', 'camcorder'],
      },
    ],
  },
  6: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P63-E6',
    audio: 'Audios/2.19-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page63/Key/E6answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '6',
        title: `
					<span style="margin-right: 10px;"><headphone1 name="2.19" src="Audios/2.19-tieude.mp3"></headphone1></span>
       Listen to three helpline dialogues. Circle the correct answers (a–c).
			  `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ['0-4', '1-0', '2-8', '3-8'],
        initialValue: [],
      },
      Layout: `
      <div >
        <div style ='flex:1;width:100%'>


 						<b>1</b>&ensp;
		      It is a fact, not an opinion, that the problem
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      was caused by a child. <br>
              cannot be solved in the shop. <br>
              will take several days to solve..
		          </div>
            </div>


          
            <b>2</b>&ensp;
		      	Where does the dialogue take place?
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	     Outside the man’s house<br>
             Outside the Victoria Hotel <br>
             Outside the Empire Hotel
		          </div>
            </div>

              <b>3</b>&ensp;
		         Why is the girl angry with the boy?
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      He won’t let her use his MP3 player. <br>
              He accused her of stealing something from a friend. <br>
              He accidentally deleted something that she wanted.
		          </div>
            </div>

						   <b>4</b>&ensp;
		         The dialogue takes place a short while before
            <div style="display: flex;margin-left:40px">
		          <div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      a dance performance. <br>
              a football match. <br>
              a social event.
		          </div>
            </div>

		          </div>
            </div>
           </div>
      </div>

    </div>


        
      `,
    },
  },
  7: {
    unit: 'unit 5',
    id: 'FGF11-A-P63-E7',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page63/Key/E5answerKey.png',
    component: T6,
    inputSize: 250,
    maxLength: 37,
    isHiddenCheck: true,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '7',
        title: `
					 <span style="color:rgb(45,206,137)">SPEAKING</span> <span style="color:rgb(146, 39, 144)">KEY PHRASES</span> Choose one of the questions below and discuss it with your partner. Use the phrases to help you.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div>
							<img src="img/FriendsPlus/Page63/E1/7.jpg" style="margin-left:150px"	 />
						

					</div>
        `,
        answer: [``],
      },
    ],
  },
};

export default json;
