export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_FAILURE = 'POST_LOGIN_FAILURE';

export const POST_LOGOUT = 'POST_LOGOUT';

export const POST_CODE_LOGIN_REQUEST = 'POST_CODE_LOGIN_REQUEST';
export const POST_CODE_LOGIN_SUCCESS = 'POST_CODE_LOGIN_SUCCESS';
export const POST_CODE_LOGIN__FAILURE = 'POST_CODE_LOGIN__FAILURE';

export const POST_REGISTER_REQUEST = 'POST_REGISTER_REQUEST';
export const POST_REGISTER_SUCCESS = 'POST_REGISTER_SUCCESS';
export const POST_REGISTER_FAILURE = 'POST_REGISTER_FAILURE';

export const POST_LOGIN_SACHSO_REQUEST = 'POST_LOGIN_SACHSO_REQUEST';
