import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P117-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: 'Write the past participles of the verbs below.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 23,
			textAlign: 'center',
			// borderBottom: "none",
			backgroundColor: 'transparent',
		},
		inputSize: 120,
		// maxLength: 7,
		exerciseKey: '/img/FriendsPlus/Page117/key/E1answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style="display:flex">
            <div style="margin-right:80px">
              <p><span style="font-weight:bold;margin-right:10px">1. </span> <span style="margin-right:20px">see</span> # </p>
              <p><span style="font-weight:bold;margin-right:10px">2. </span> <span style="margin-right:20px">have</span> # </p>
              <p><span style="font-weight:bold;margin-right:10px">3. </span> <span style="margin-right:20px">speak</span> # </p>
              <p><span style="font-weight:bold;margin-right:10px">4. </span> <span style="margin-right:20px">break</span> # </p>

            </div>
            <div>
              <p><span style="font-weight:bold;margin-right:10px">5. </span> <span style="margin-right:20px">ride</span> # </p>
              <p><span style="font-weight:bold;margin-right:10px">6. </span> <span style="margin-right:20px">dance</span> # </p>
              <p><span style="font-weight:bold;margin-right:10px">7. </span> <span style="margin-right:20px">stop</span> # </p>
              <p><span style="font-weight:bold;margin-right:10px">8. </span> <span style="margin-right:20px">hurry</span> # </p>
              
            </div>

          </div>
				`,
				answer: ['seen', 'had', 'spoken', 'broken', 'ridden', 'danced', 'stopped', 'hurried'],
			},
		],
	},
	2: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P117-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title:
					'Write questions with <span style="font-style:italic">yet</span> and answers with <span style="font-style:italic">already</span> or <span style="font-style:italic">yet.</span>',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'center',
			// borderBottom: "none",
			backgroundColor: 'transparent',
		},
		inputSize: 460,
		// maxLength: 7,
		exerciseKey: '/img/FriendsPlus/Page117/key/E2answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div>
            <p><span style="font-weight:bold;margin-right:10px">1. </span> Jake / finish his homework? ✓</p>
            <div style="display:flex">
            <p style="color:rgb(60,172,218);margin-right:30px">Has Jake finished his homework yet?</p>
            <p style="color:rgb(60,172,218)">Yes, he’s already finished it.</p>
            </div>
            <p><span style="font-weight:bold;margin-right:10px">2. </span> Sam / eat? ✗</p>
            <div style="display:flex">
            <p style="color:rgb(60,172,218);margin-right:30px">Has Sam eaten yet?</p>
            <p style="color:rgb(60,172,218)">No, he hasn’t eaten yet.</p>
            </div>
            <p><span style="font-weight:bold;margin-right:10px">3. </span> Alice and David’s plane / land? ✓</p>
            <div style="display:flex">
            <p style="margin-right:50px">#</p>
            <p>#</p>
            </div>
            <p><span style="font-weight:bold;margin-right:10px">4. </span> Jim / read The Hobbit? ✗</p>
            <div style="display:flex">
            <p style="margin-right:50px">#</p>
            <p>#</p>
            </div>
            <p><span style="font-weight:bold;margin-right:10px">5. </span> Fran / tidy her bedroom? ✓</p>
            <div style="display:flex">
            <p style="margin-right:50px">#</p>
            <p>#</p>
            </div>
            <p><span style="font-weight:bold;margin-right:10px">6. </span>  Simon and Clare / arrive at school? ✗</p>
            <div style="display:flex">
            <p style="margin-right:50px">#</p>
            <p>#</p>
            </div>
            <p><span style="font-weight:bold;margin-right:10px">7. </span> Terry / see / the latest Batman film? ✓</p>
            <div style="display:flex">
            <p style="margin-right:50px">#</p>
            <p>#</p>
            </div>
          </div>
				`,
				answer: [
					"Has Alice and David's plane landed?",
					'Yes, it has already landed.',
					'Has Jim read The Hobbit?',
					"No, he hasn't read it yet.",
					'Has Fran tidied her bedroom?',
					"Yes, she's already tidied it.",
					'Have Simon and Clare arrived at school?',
					`No, they haven’'t arrived yet.`,
					'Has Terry seen the latest Batman film?',
					"Yes, he's already seen it.",
				],
			},
		],
	},
	3: {
		unit: 'Grammar Builder and  Reference',
		id: 'FGF11-GBAR-P117-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title: 'Write <span style="font-style:italic">been</span> or <span style="font-style:italic">gone</span>. ',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'center',
			// borderBottom: "none",
			backgroundColor: 'transparent',
		},
		inputSize: 100,
		// maxLength: 7,
		exerciseKey: '/img/FriendsPlus/Page117/key/E3answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div>
            <p><span style="margin-right:10px;font-weight:bold">1.</span>Hannah isn’t at school. She’s # home.</p>
            <p><span style="margin-right:10px;font-weight:bold">2.</span>You’re late. Where have you #?</p>
            <p><span style="margin-right:10px;font-weight:bold">3.</span> ‘Is Jackie coming to the party this evening?’ ‘No, she’s # to London.'</p>
            <p><span style="margin-right:10px;font-weight:bold">4.</span>Jack's # shopping, but he’ll be back soon</p>
            <p><span style="margin-right:10px;font-weight:bold">5.</span>Nice suntan! Have you # on holiday?</p>
          </div>
				`,
				answer: ['gone', 'been', 'gone', 'gone', 'been'],
			},
		],
	},
	4: {
		unit: 'Grammar Builder and Reference',
		id: 'FGF11-GBAR-P117-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title: 'Complete the sentences. Use the past simple or present perfect form of the verbs in brackets.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 23,
			textAlign: 'center',
			// borderBottom: "none",
			backgroundColor: 'transparent',
		},
		inputSize: 250,
		// maxLength: 7,
		exerciseKey: '/img/FriendsPlus/Page117/key/E4answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div>
            <p><span style="margin-right:10px;font-weight:bold">1.</span>Joe # (live) in London between 2009 and 2012.</p>
            <p><span style="margin-right:10px;font-weight:bold">2.</span>‘Emeli Sandé # (just / bring out) a new record. <br>  # (you / hear) it yet?’<br>‘Yes, I # (download) it last night.’ </p>
            <p><span style="margin-right:10px;font-weight:bold">3.</span>‘Sorry I’m late! # (you / be) here long?’ <br>‘No, I # (just / arrive).’</p>
            <p><span style="margin-right:10px;font-weight:bold">4.</span>‘# (you / ever / visit) the USA?’<br> ‘Yes, I # (go) there last summer.’</p>
            <p><span style="margin-right:10px;font-weight:bold">5.</span>‘# (you / eat) before you # (leave) home?’<br>‘Yes, I #.’</p>
            <p><span style="margin-right:10px;font-weight:bold">6.</span> I # (have) this MP3 player for a year.</p>
          </div>
				`,
				answer: [
					'lived',
					'has just brought out',
					'Have you heard',
					'downloaded',
					'Have you been',
					'have just arrived',
					'Have you ever visited',
					'went',
					'Did you eat',
					'left',
					'did',
					'have had',
				],
			},
		],
	},
	5: {
		unit: 'Grammar Builder and  Reference',
		id: 'FGF11-GBAR-P117-2.2-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title:
					'Complete the sentences with the verbs below. Use the correct affirmative, negative or interrogative form of the present perfect continuous.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 23,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 250,
		maxLength: 200,
		exerciseKey: '/img/FriendsPlus/Page117/key/E1_1answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div>
            <div>
              <p style="color:rgb(247,144,61);word-spacing:20px">answer cook make play save use watch worry</p>
            </div>
            <div>
              <p><span style="font-weight:bold;margin-right:10px">1. </span> My eyes are sore. I # computer games for too long!</p>
              <p><span style="font-weight:bold;margin-right:10px">2. </span> We # for weeks so that we can pay for our next holiday.</p>
              <p><span style="font-weight:bold;margin-right:10px">3. </span> I’m so happy you phoned. I # about you all day.</p>
              <p><span style="font-weight:bold;margin-right:10px">4. </span> I love the new sitcom on BBC1.# you # it? </p>
              <p><span style="font-weight:bold;margin-right:10px">5. </span> She recently bought a new bike, but she # it much.</p>
              <p><span style="font-weight:bold;margin-right:10px">6. </span> Maybe her phone is broken. She # my calls.</p>
              <p><span style="font-weight:bold;margin-right:10px">7. </span> My hands are cold because we # a snowman.</p>
              <p><span style="font-weight:bold;margin-right:10px">8. </span> I hope they enjoy the food. I # for hours!</p>
            </div>
          </div>
          
				`,
				answer: [
					'have been playing',
					'have been saving',
					'have been worrying',
					'Have',
					'been watching',
					"hasn't been using",
					"hasn't been answering",
					'have been making',
					'have been cooking',
				],
			},
		],
	},
	6: {
		unit: 'Grammar Builder and  Reference',
		id: 'FGF11-GBAR-P117-2.2-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title:
					' Complete the second sentence in each pair to mean the same as the first.Use the correct affirmative or negative form of the present perfect continuous.',
				color: '#860052',
			},
		],
		stylesTextInput: {
			fontSize: 22,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 250,
		maxLength: 200,
		exerciseKey: '/img/FriendsPlus/Page117/key/E2_1answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div>
            <div>
              <p><span style="font-weight:bold;margin-right:10px">1. </span> It started snowing just a short time ago. It # for very long.</p>
              <p><span style="font-weight:bold;margin-right:10px">2. </span> I put these jeans on yesterday afternoon, and I haven’t taken them off yet!<br>I # these jeans since yesterday afternoon!</p>
              <p><span style="font-weight:bold;margin-right:10px">3. </span> I started feeling unwell two days ago. I # well for two days.</p>
              <p><span style="font-weight:bold;margin-right:10px">4. </span> She sat down in the sun three hours ago, and she hasn’t moved.<br>She # in the sun for three hours now.</p>
              <p><span style="font-weight:bold;margin-right:10px">5. </span> I began this book nearly a year ago, and I haven’t finished it yet.<br>I # this book for nearly a year.</p>
              <p><span style="font-weight:bold;margin-right:10px">6. </span> We moved to Scotland three years ago.<br>We # in Scotland for three years now.</p>

            </div>
          </div>
          
				`,
				answer: [
					"hasn't been snowing",
					'have been wearing',
					"haven't been feeling",
					'has been sitting',
					'have been reading',
					'have been living',
				],
			},
		],
	},
}

export default json
