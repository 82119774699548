import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P119-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title:
          ` Complete the first conditional sentences. Use the verbs in brackets.`,
        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 23,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 190,
    maxLength: 100,
    exerciseKey: '/img/FriendsPlus/Page119/key/E1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div>
						<p><span style="font-weight:bold;margin-right:10px">1. </span>If I #(not phone) my parents, they  #(be) upset.</p>
						<p><span style="font-weight:bold;margin-right:10px">2. </span>If I #(tell) you a secret, #(you / promise) not to tell anybody?</p>
						<p><span style="font-weight:bold;margin-right:10px">3. </span>We #(have) a barbecue tomorrow if it #(not rain).</p>
						<p><span style="font-weight:bold;margin-right:10px">4. </span>My mum #(not buy) me a new phone if I #(lose) this one.</p>
						<p><span style="font-weight:bold;margin-right:10px">5. </span>What #(you / do) if you #(feel) unwell tomorrow morning?</p>
						<p><span style="font-weight:bold;margin-right:10px">6. </span>If you #(give) me your number, I #(be able to) text you.</p>
						<p><span style="font-weight:bold;margin-right:10px">7. </span>Joe # (make) Sally very happy if he #(send) her some chocolates.</p>
						<p><span style="font-weight:bold;margin-right:10px">8. </span>If you #(not shout) at me, I #(not get) angry.</p>
					</div>			
				`,
        answer: [
          "don't phone", "will be", "tell", "will you promise", "will have", "doesn't rain", "won't buy", "lose", "will you do", "feel", "give", "will be able to", "will make", "sends", "don't shout", "won't get"
        ],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P119-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title:
          `  Complete the sentences. Use the future perfect form of the verbs in brackets. `,
        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 23,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 250,
    maxLength: 20,
    exerciseKey: '/img/FriendsPlus/Page119/key/E2answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div>
						<p><span style="font-weight:bold;margin-right:10px">1.. </span>Harry and Luke are going travelling for a month, but they #(return) before September.</p>
						<p><span style="font-weight:bold;margin-right:10px">2.. </span>They’re building a new football stadium, but they #(not finish) it in time for next season.</p>
						<p><span style="font-weight:bold;margin-right:10px">3.. </span>I’ve sent grandad a postcard, but he #(not receive) it by the time we get home.</p>
						<p><span style="font-weight:bold;margin-right:10px">4.. </span>By this time next year, we #(do) all our exams.</p>
						<p><span style="font-weight:bold;margin-right:10px">5.. </span>Don’t call for me before 7 p.m. because I #(not have) time to get changed.</p>
						<p><span style="font-weight:bold;margin-right:10px">6.. </span>When my dad retires next month, he #(be) with the same company for 36 years.</p>
						
					</div>			
				`,
        answer: [
          "will have returned", "won't have finished", "won't have received", "will have done", "won't have had", "will have been",
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P119-U3-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title:
          `Complete the sentences with the future continuous form of the verbs below. `,
        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 23,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    maxLength: 100,
    exerciseKey: '/img/FriendsPlus/Page119/key/E1_1answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
         <div>
            <div>
              <p style="color:rgb(247,144,61);word-spacing:15px">have pack shine stay study visit</p>
            </div>
            <div>
              <p><span style="font-weight:bold;margin-right:10px">1. </span>When I’m twenty, I # medicine at university.</p>
              <p><span style="font-weight:bold;margin-right:10px">2. </span> Don’t call me at 8 p.m. because we # dinner.</p>
              <p><span style="font-weight:bold;margin-right:10px">3. </span> When I step off the plane in Jamaica, the sun #.</p>
              <p><span style="font-weight:bold;margin-right:10px">4. </span> My cousin’s in New York this week. Next week, she # San Francisco.</p>
              <p><span style="font-weight:bold;margin-right:10px">5. </span> Tomorrow, my parents # their bags to go on holiday.</p>
              <p><span style="font-weight:bold;margin-right:10px">6. </span> Come and see me in London. I # at the Savoy Hotel.</p>
              
            </div>
          </div>		
				`,
        answer: [
          'will be studying', 'will be having', 'will be shining', 'will be visiting', 'will be packing', 'will be staying',
        ],
      },
    ],
  },

};

export default json;
