import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P14-E1',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page14/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `Look at the photo. How old do you think the woman is? 
				Why do you think she is famous?`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 550 },
		questions: [
			{
				title: `
				<div style="display: flex; flex-wrap: wrap; gap: 30px;">
					<div><img src='img/FriendsPlus/Page14/E1/1.jpg' width='100%';/></div>
					<textarea id="0" rows="15"></textarea>
				</div>
				
        
        `,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P14-E2',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		inputSize: 450,
		stylesTextInput: {
			fontSize: '22px',
		},
		exerciseKey: 'img/FriendsPlus/Page14/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `Read the text and check your ideas.`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="display: flex; flex-wrap: wrap; gap: 10px;">
					<div style="flex: 2;"><img src='img/FriendsPlus/Page14/E2/1.jpg' width='90%';/></div>
					<div style="flex: 1; display: flex; gap: 10px; flex-direction: column; margin-top: 50px; font-size: 22px;">
						<div>
							How old do you think the woman is? <br />#
						</div>
						<div>
							Why do you think she is famous? <br />#
						</div>
					<div>
				</div>
        `,
				answer: [`She's 122 years old.`, 'She had the longest lifespan in the world.'],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P14-E3',
		audio: '',
		video: '',
		maxLength: 18,
		exerciseKey: 'img/FriendsPlus/Page14/E3/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '3',
				title: `
				Complete the <span style="color: rgb(246, 141, 31);">Learn this!</span> box with the tenses below. 
				Then underline an example of each of the rules (a–d) in the text in exercise 2.
				`,
				color: '#92278f',
			},
		],
		question: {
			Write: {
				isHiddenCheck: true,
				inputStyle: { width: 190, fontSize: '24px', textAlign: 'center', maxLength: 15, },
				answers: [`past simple`, `past continuous`, `past simple`, `past continuous`, `past perfect`],
				initialValue: ['a'],
			},
			Circle: {
				initialWordStyle: { border: 'none', borderColor: 'transparent' },
				// selectWordStyle: { padding: 5, borderRadius: '50%', border: '2px solid', borderColor: 'gray' },
				selectWordStyle: { padding: 0, borderBottom: '2px solid', borderColor: 'gray' }, // underline
				limitSelect: 3,
				listWords: [
					`<div> Jeanne_Calment, the_person_with_the_longest_lifespan_ever,_was_born_in_1875,_in_Arles,_France.
 Her_parents_ran_a_shop_in_the_town_and_she_worked_there_when_she_was_a_teenager.
 While_she_was_serving_in_the_shop_in_1888,_she_met Vincent_van_Gogh, who_had_come_in_to_buy_pencils. 
 She_thought_he_was_‘dirty,_ugly_and_badly_dressed’! </div>
 <br>
In_1896, at_the_age_of_21, she_married_Fernand_Calment_and_then_gave_birth to_a_daughter,_Yvonne.
 Fernand_was_very_wealthy_so_Jeanne_never_needed_to_work. 
 She_lived_in_Arles_for_the_rest_of_her_life, dying_on_5_August_1997_at_the_age_of_122.
 So_how_did_Jeanne_manage_to_live_so_long? 
 The_French_have_their_own_theories,_noting_that_she_ate_more_than_two_pounds_of_chocolate_a_week_and_rode_a_bicycle_until_she_was_100!`,
				],
				answers: ['0-12', '0-16', '0-34'],
				initialValue: [],
			},
			Layout: `
			<div style="max-width: 900px; color: rgb(246, 141, 31); display: flex; gap: 5px 30px; flex-wrap: wrap;">
				<span>past continuous</span><span>past perfect</span><span>past simple</span>
			</div>

			<div style="position: relative;">
				<div><img src='img/FriendsPlus/Page14/E3/1.jpg' width='67%';/></div>
				<div style="position: absolute; top: 54px; left: 68px; display: flex; flex-direction: column; gap: 5px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">a</span>
						<div>
							<div>
								We use the <sup>1</sup><input id="0" /> for a sequence of events <br />
								that happened one after another.
							</div>
							<div style="color: rgb(10, 111, 143);">In 1989, my parents met, fell in love and got married.</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">b</span>
						<div>
							<div>
								We use the <sup>2</sup><input id="1" /> to describe a scene in the <br />
								past. The events were in progress at the same time.
							</div>
							<div style="color: rgb(10, 111, 143);">It was raining and people were rushing home from work.</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">c</span>
						<div>
							<div>
								We use the <sup>3</sup><input id="2" /> for a single event that<br />
								interrupted a longer event in the past. We use the<br />
								<sup>4</sup><input id="3" /> for the longer event.
							</div>
							<div style="color: rgb(10, 111, 143);">My parents got engaged while they were living in Wales.</div>
						</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">d</span>
						<div>
							<div>
								We use the <sup>5</sup><input id="4" /> for an event that<br />
								happened before another event in the past.
							</div>
							<div style="color: rgb(10, 111, 143);">He had started a business before he left school.</div>
						</div>
					</div>

				</div>	
			</div>

			<div style="position: relative;">
				<div><img src='img/FriendsPlus/Page14/E3/2.jpg' style='height:21cm;width:27cm';/></div>
				<div style="position: absolute; top: 86px; left: 81px;width:145mm">
						<input id='0' type='Circle'/>
				</div>
				<div style="position: absolute; top:486px; left: 77px;width:16cm">
					
				</div>
			</div>

      `,
		},
	},
	4: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P14-E4',
		audio: '',
		video: '',
		component: T6v2,
		inputSize: 700,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page14/E4/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `
  			Complete this sentence in three different ways
  			using the three tenses in the <span style="color: rgb(246, 141, 31);">Learn this!</span> box.
  			Use the verb <i>learn</i>. How does the meaning change?
  			`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
  			<div style="margin-top: 20px;">When Tom left school, he _____ / _____ / _____ to drive.</div>

  			<div style="margin-top: 20px;"><img src='img/FriendsPlus/Page14/E4/1.jpg' width='60%';/></div>

  			<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
  				<div style="display: flex; gap: 10px;">
  					<span style="font-weight: bold; margin-right: 10px;">1</span>
  					<div>
  						<div style="display: flex; flex-wrap: wrap;"><div style="width: 210px; font-weight: bold;">Past Simple:</div> <span>#</span></div>
  						<div style="display: flex; flex-wrap: wrap;"><div style="width: 210px; font-weight: bold;">Meaning:</div> <span>#</span></div>
  					</div>
  				</div>
  				<div style="display: flex; gap: 10px;">
  					<span style="font-weight: bold; margin-right: 10px;">2</span>
  					<div>
  						<div style="display: flex; flex-wrap: wrap;"><div style="width: 210px; font-weight: bold;">Past Continuous:</div> <span>#</span></div>
  						<div style="display: flex; flex-wrap: wrap;"><div style="width: 210px; font-weight: bold;">Meaning:</div> <span>#</span></div>
  					</div>
  				</div>
  				<div style="display: flex; gap: 10px;">
  					<span style="font-weight: bold; margin-right: 10px;">3</span>
  					<div>
  						<div style="display: flex; flex-wrap: wrap;"><div style="width: 210px; font-weight: bold;">Past Perfect:</div> <span>#</span></div>
  						<div style="display: flex; flex-wrap: wrap;"><div style="width: 210px; font-weight: bold;">Meaning:</div> <span>#</span></div>
  					</div>
  				</div>
  			</div>
  			`,
				answer: [
					`When Tom left school, he learned to drive.`,
					`First Tom left school, then learned to drive.`,
					`When Tom left school, he was learning to drive.`,
					`Tom left school during the period when he was learning to drive.`,
					`When Tom left school, he had learned to drive.`,
					`When Tom left school, he already knew how to drive.`,
				],
			},
		],
	},
	5: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P14-E5',
		audio: '',
		video: '',
		component: T6v2,
		maxLength: 18,
		textAlign: 'center',
		inputSize: 270,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page14/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `
  			Complete the sentences with the correct past simple, 
        past continuous or past perfect form of the verbs in brackets.
  			`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
  			<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px;">
  				<div style="display: flex; gap: 10px;">
  					<span style="font-weight: bold; margin-right: 10px;">1</span>
  					<div>We # (move) house a lot while I # (grow up).</div>
  				</div>
  				<div style="display: flex; gap: 10px;">
  					<span style="font-weight: bold; margin-right: 10px;">2</span>
  					<div>After Joe # (learn) to drive, he # (buy) a car.</div>
  				</div>
  				<div style="display: flex; gap: 10px;">
  					<span style="font-weight: bold; margin-right: 10px;">3</span>
  					<div>George # (leave) school, # (go) to university and # (study) engineering.</div>
  				</div>
  				<div style="display: flex; gap: 10px;">
  					<span style="font-weight: bold; margin-right: 10px;">4</span>
  					<div>My parents # (get) engaged in 1990. They # (fall) in love two years before, while they # (work) in London.</div>
  				</div>
  			</div>
        `,
				initialValue: ['hello'],

				answer: [
					'moved',
					'was growing up',
					'had learned / learned | learned / had learned | learned | had learned',
					'bought',
					'left',
					'went',
					'studied',
					'got',
					'had fallen',
					'were working',
				],
				// answer: ['moved', 'was growing up', 'had learned / learned', 'bought','left','went','studied','got','had fallen','were working'],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P14-E6',
		audio: '',
		video: '',
		component: T6,
		maxLength: 14,
		stylesTextInput: {
			border: 'none',
			fontSize: '22px',
		},
		textAlign: 'center',
		inputSize: 147,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page14/E6/Key/answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title: `
				Complete the text with the past simple, past continuous or past perfect form of the verbs below.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
        <div style="max-width: 1000px; color: rgb(246, 141, 31); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span>be</span><span>be</span><span>become</span><span>die</span>
          <span>get</span><span>leave</span><span>live</span><span>meet</span>
          <span>not stop</span><span>retire</span><span>say</span><span>work</span>
        </div>
				
				<div style="position: relative">
					<div><img src='img/FriendsPlus/Page14/E6/1.jpg' width='50%';/></div>
					<div style="position: absolute; top: 138px; left: 25px">#</div>
					<div style="position: absolute; top: 166px; left: 25px">#</div>
					<div style="position: absolute; top: 193px; left: 60px">#</div>
					<div style="position: absolute; top: 220px; left: 120px">#</div>
					<div style="position: absolute; top: 247px; left: 56px">#</div>
					<div style="position: absolute; top: 274px; left: 56px">#</div>
					<div style="position: absolute; top: 302px; left: 115px">#</div>
					<div style="position: absolute; top: 329px; left: 27px">#</div>
					<div style="position: absolute; top: 329px; left: 299px">#</div>
					<div style="position: absolute; top: 383px; left: 30px">#</div>
					<div style="position: absolute; top: 411px; left: 240px">#</div>
					<div style="position: absolute; top: 465px; left: 33px">#</div>
				</div>
        `,
				answer: [
					'was',
					'left',
					'got',
					'was working',
					'met',
					'had been',
					'retired',
					`didn't stop`,
					'became',
					'died',
					'had lived',
					'said',
				],
			},
		],
	},
	7: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P14-E7',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		// hideBtnFooter: true,
		textAlign: 'center',
		inputSize: 140,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page14/E7/Key/E7answerKey.png',
		textareaStyle: {
			// background: 'none',
			width: 800,
			fontSize: 23,
			height: 170,
		},

		titleQuestion: [
			{
				num: '7',
				title: `
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>
				Tell the class about a real or invented person from a previous generation <br> (e.g. a parent, grandparent). 
				Use the headings below and make use of past tenses.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="max-width: 617px; color: rgb(246, 141, 31); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span>Born when / where?</span><span>Education?</span><span>Job?</span><span>Married?</span>
          <span>Family?</span><span>Moved?</span><span>Other interesting fact?</span>
        <textarea id='0' row='3'>
        
          </div>
        `,
				answer: [
					// '',
					'She was born in 1934 in France. At the age of 17, she left school and got a job in a large department store. While she was living in Paris, she met my grandfather. She got married in 1960',
				],
			},
		],
	},
}

export default json
