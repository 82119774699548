import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P34-E1',
		audio: '',
		video: '',
		component: T6,
		recorder: true,
		// hideBtnFooter: true,
		maxLength: 16,
		exerciseKey: 'img/FriendsPlus/Page34/Key/E1answerKey.png',
		question: [],
		textAlign: 'center',
		titleQuestion: [
			{
				num: '1',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Look at the photo above. Which
        natural phenomenon is it? What happens if the
        greenhouse gases keep increasing? `,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 450 },
		inputSize: 450,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
           <div style='display:flex'>
            <img  src="img/FriendsPlus/Page34/E1/1.jpg" />
              <div>
								<div style='margin:70px 0px'>
									Which natural phenomenon is it?<br>
									<textarea id='0' rows='3'></textarea>
								</div>
									 Which is not related to global warming?
									 <br><textarea id='1' rows='3'></textarea>
							</div>
           </div>
          `,
				answer: [
					'The photo is about the greenhouse effect.',
					'If greenhouse effects keep increasing, the Earth will be warmer. That will lead to climate change.',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P34-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page34/Key/E2answerKey.png',
		// checkUppercase:true,
		component: MatchDots,

		titleQuestion: [
			{
				num: '2',
				title: ` What greenhouse gases are produced through
        human activities? Match column A with column B
        to have an answer.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		question: {
			DrawLines: {
				// multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '103px',
							left: '174px',
							width: 309,
							height: 56,
							// backgroundColor: "white",
							border: '1px solid',
							// borderRadius: '50%',
						},
					}, //0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '186px',
							left: '174px',
							width: 309,
							height: 56,
							// backgroundColor: 'white',
							border: '1px solid',
							// borderRadius: '50%',
						},
					}, //1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '269px',
							left: '174px',
							width: 309,
							height: 56,
							// backgroundColor: "white",
							border: '1px solid',
							// borderRadius: '50%',
						},
					}, //2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '352px',
							left: '174px',
							width: 309,
							height: 56,
							// backgroundColor: "white",
							border: '1px solid',
							// borderRadius: '50%',
						},
					}, //3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '103px',
							left: '501px',
							width: 118,
							height: 56,
							// backgroundColor: "white",
							border: '1px solid',
							// borderRadius: '50%',
						},
					}, //4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '186px',
							left: '501px',
							width: 118,
							height: 56,
							// backgroundColor: "white",
							border: '1px solid',
							// borderRadius: '50%',
						},
					}, //5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '269px',
							left: '501px',
							width: 118,
							height: 56,
							// backgroundColor: "white",
							border: '1px solid',
							// borderRadius: '50%',
						},
					}, //6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '352px',
							left: '501px',
							width: 118,
							height: 56,
							// backgroundColor: "white",
							border: '1px solid',
							// borderRadius: '50%',
						},
					}, //7
				],
				answers: ['0-7', '1-4', '2-6', '3-5'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page34/E2/1.jpg'>
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />

      `,
		},
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P34-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page34/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `Check the meaning of the following
        words or phrases. Which is not related to global warming?`,
				color: '#8e258c',
				prefix: { icon: [''], text: 'VOCABULARY' },
			},
		],
		maxLength: 19,
		hintBox: [
			{
				src: [
					'deforestation',
					'industrialisation',
					'climate change',
					'emissions',
					'habitat destruction',
					'animal poaching',
					'ice melting',
					'population growth',
				],
				width: 800,
				fontColor: '#4ba8f3',
				borderColor: 'none',
			},
		],
		component: T6,
		// isHiddenCheck: true,
		textareaStyle: { width: 640 },
		inputSize: 220,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
          <hintbox id='0'></hintbox>
          <div>
            <u style='font-style:italic;font-weight:bold'>Answers:</u> #
          </div>

          `,
				answer: ['animal poaching'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P34-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page34/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `Read paragraph A of the text. Which of the ideas in exercise 3 are mentioned in the reading?
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		hintBox: [
			{
				src: [
					'deforestation',
					'industrialisation',
					'climate change',
					'emissions',
					'habitat destruction',
					'animal poaching',
					'ice melting',
					'population growth',
				],
				width: 1000,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 19,
		isHiddenCheck: true,
		textareaStyle: { width: 530 },
		inputSize: 250,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <hintbox id='0'></hintbox>

      <div style='display:flex'>
        <div>
			    <div style='width:15cm; background: rgb(244 228 202);padding:3mm 5mm'>
            <b style='border-radius:5px; background:white; border:2px solid rgb(0 188 212); padding:2px 5px'>A</b>&ensp;Home (2009) is a 90-minute documentary about
              human threats to the Earth’s ecological balance. It is made
              up of aerial shots of various places across the globe. Aiming
              to raise people’s awareness of the alarming environmental
              destruction, this French movie shows human huge impacts
              on the Earth and the consequences of climate change.
              Viewers of the documentary particularly enjoy the footage
              of human origins, the Earth’s water cycle, population growth,
              the melting Arctic ice cap and the coral reef.

          </div>
        </div>
          <div style='margin-left:10px;line-height:50px'>Which of the ideas in exercise 3 are mentioned in the reading?<br>#,#<br>#,#</div>
      </div>

          `,
				answer: [
					'ice melting / population growth / climate change / habitat destruction',
					'ice melting / population growth / climate change / habitat destruction',
					'ice melting / population growth / climate change / habitat destruction',
					'ice melting / population growth / climate change / habitat destruction',
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P34-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page34/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `Read the text. Find the details related to the following numbers.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		component: T6v2,
		// maxLength: 19,
		isHiddenCheck: true,
		textareaStyle: { width: 700, fontSize: 22, height: 40 },
		inputSize: 500,
		stylesTextInput: { fontSize: 22 },
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
      <div style='display:flex; font-size:22px'>
			    <div style='width:12cm; background: rgb(244 228 202);padding:3mm 5mm'>
            <b style='border-radius:5px; background:white; border:2px solid rgb(0 188 212); padding:2px 5px'>A</b>&ensp;Home (2009) is a 90-minute documentary about
              human threats to the Earth’s ecological balance. It is made
              up of aerial shots of various places across the globe. Aiming
              to raise people’s awareness of the alarming environmental
              destruction, this French movie shows human huge impacts
              on the Earth and the consequences of climate change.
              Viewers of the documentary particularly enjoy the footage
              of human origins, the Earth’s water cycle, population growth,
              the melting Arctic ice cap and the coral reef.

          </div>
          <div style='width:12cm; background: rgb(244 228 202);padding:3mm 5mm'>
            <b style='border-radius:5px; background:white; border:2px solid rgb(0 188 212); padding:2px 5px'>B</b>&ensp;The film was completed in eighteen months and the film
              crew did their filming of 488-hour raw footage by flying in a
              helicopter over various regions in more than fifty countries.
              At a press conference in Paris on March 9, 2009, its director,
              Yann Arthus-Bertrand, announced that its released versions
              would include theatre, television, DVD and the internet.
              About two months later, the film’s worldwide release date
              was confirmed to be June 5, 2009. Despite its availability in
              various forms, Home (2009) had its world festival premiere
              at the Dawn Breakers International Film Festival in 2012.
          </div>

      </div>

      <div style='display:flex'>

          <div style='font-size:22px ;width:15cm;height:11cm; background: rgb(244 228 202);padding:3mm 5mm'>
                <b style='border-radius:5px; background:white; border:2px solid rgb(0 188 212); padding:2px 5px'>C</b>&ensp;Home (2009) is available for free access in 14
                  languages. Director Yann Arthus-Bertrand has insisted
                  that the film have no copyright. He has also stressed the
                  educational benefits of the film over its financial gains. With
                  this aim in mind, the film producer made the film available
                  to as many people as possible. The film can be downloaded
                  to your smart TV or any kind of digital device, with the
                  proceeds all going to the GoodPlanet Foundation.

              </div>
              <div style='margin-left:5px; font-size:22px'>
               <div style='display:flex'>14:<textarea id='0' ></textarea></div>
                <div style='display:flex'>50:<textarea id='1' ></textarea></div>

                 <div style='display:flex'>
                  488:<textarea id='2' ></textarea>
                 </div>

                 <div style='display:flex'>
                  2009:<textarea id='3'></textarea>
                 </div>

                 <div style='display:flex'>
                  2012:<textarea id='4' height='130px'></textarea>
                 </div>
              </div>
      </div>

          `,
				answer: [
					'the number of languages that the film is available in',
					'the number of countries where the filming is done | the number of nations where the filming is done',
					'the length of the raw footage | the length in hours of the raw footage',
					'the year when the film was made',
					'the year when the film had its world festival premiere | the year when the film had its world festival premiere (at the Dawn Breakers International Film)',
				],
			},
		],
	},

	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P34-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page34/Key/E6answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title: `Answer the questions in your own words.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		component: T6,
		maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 840, fontSize: 22, height: 130 },
		inputSize: 40,
		textAlign: 'center',
		questionImage: [],
		questions: [
			{
				title: `
     <div style='font-size:22px'>
        <b>1</b>&ensp;What images can be seen in the film?<br>
        <textarea id='0' rows='5'></textarea>
     </div>
     <div>
        <b>2</b>&ensp;Where can Home (2009) be seen?<br>
        <textarea id='1' rows='3'></textarea>
     </div>
     <div>
        <b>3</b>&ensp;Why doesn’t Home (2009) have any copyright?<br>
        <textarea id='2' rows='3'></textarea>
     </div>
          `,
				answer: [
					'The film depicts pictures of the starts of humans, the cycle of water, the increasing number of people on Earth, the melting ice at the poles and the coral reef.',
					'Viewers can see the film at home (on television, on the internet) or at the cinema.',
					'The director and producer of the film emphasise its educational benefits and purposes.',
					// 'The film depicts pictures of the starts of humans, the cycle of water, the increasing number of people on Earth, the melting ice at the poles and the coral reef.',
					// 'Viewers can see the film at home (on television, on the internet) or at the cinema.',
					// 'The director and producer of the film emphasise its educational benefits and purposes.',
				],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P34-E7',
		audio: '',
		video: '',
		// exerciseKey: 'img/FriendsPlus/Page34/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b>  Work in groups. Discuss the question and then
share with the class.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 40,
		textAlign: 'center',
		recorder: true,
		hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
      <div style='font-style:italic;width:20cm'>
        Which do you think is more effective in raising people’s
awareness of protecting the environment: a film or a book?
Justify your answer.
      </div>
          `,
				answer: [''],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P34-E8',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '8',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page34/E8/1.pNg' }]],
	},
}

export default json
