import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P124-U6.1-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page124/key/E1.1answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
				Match 1–8 with a–h to make meaningful sentences.
			  `,
        color: '#92278f',
      },
    ],
    maxLength: 1,
    textAlign: 'center',
    titleImage: '',
    inputSize: 50,
    isHiddenCheck: true,

    // hideBtnFooter: true,

    questions: [
      {
        title: `
       <div style='display: flex;'>
       <div style='flex:1;line-height:2.25'>
          <div style='display:flex'>
                 <b>1</b>
                 <div style='margin-left:15px'>
It was in 1937                  </div>
         </div>
           <div style='display:flex'>
                 <b>2</b>
                 <div style='margin-left:15px'>
It is Florence, not her sister                 </div>
         </div>
           <div style='display:flex'>
                 <b>3</b>
                 <div style='margin-left:15px'>
It is sports activities after school                  </div>
         </div>
           <div style='display:flex'>
                 <b>4</b>
                 <div style='margin-left:15px'>
It was not until the police officer came                  </div>
         </div>
           <div style='display:flex'>
                 <b>5</b>
                 <div style='margin-left:15px'>
It is with language abilities and flexibility                  </div>
         </div>
           <div style='display:flex'>
                 <b>6</b>
                 <div style='margin-left:15px'>
It was because the tickets were too expensive                  </div>
         </div>
           <div style='display:flex'>
                 <b>7</b>
                 <div style='margin-left:15px'>
It is success stories in newspapers                  </div>
         </div>
           <div style='display:flex'>
                 <b>8</b>
                 <div style='margin-left:15px'>
It was Rafa Nadal                 </div>
         </div>

       </div>

       <div  style='flex:1'>
          <div style='display:flex'>
                 <b>a</b>
                 <div style='margin-left:15px'>
that bring all of the children together.                 </div>
         </div>

           <div style='display:flex'>
                 <b>b</b>
                 <div style='margin-left:15px'>
whom the spectators gave the nickname ‘raging bull’.                </div>
         </div>

           <div style='display:flex'>
                 <b>c</b>
                 <div style='margin-left:15px'>
that give me enough confidence and patience to start my own business.        
         </div>
         </div>
           <div style='display:flex'>
                 <b>d</b>
                 <div style='margin-left:15px'>
that the drunk man decided to leave.                </div>
         </div>
           <div style='display:flex'>
                 <b>e</b>
                 <div style='margin-left:15px'>
that RKO Radio Pictures produced the comedy film  ‘High Flyers’.                  </div>
         </div>
           <div style='display:flex'>
                 <b>f</b>
                 <div style='margin-left:15px'>
that an interpreter works efficiently.                 </div>
         </div>
           <div style='display:flex'>
                 <b>g</b>
                 <div style='margin-left:15px'>
who will meet us at the airport when we arrive in England.                 </div>
         </div>
           <div style='display:flex'>
                 <b>h</b>
                 <div style='margin-left:15px'>
that the show became a poor turnout.              </div>
         </div>

       </div>
        </div>
        <div style=' margin:10px 100px;display:flex; justify-content: space-between;'>

          <b>1</b>#&emsp;
          <b>2</b>#&emsp;
          <b>3</b>#&emsp;
          <b>4</b>#&emsp;
          <b>5</b>#&emsp;
          <b>6</b>#&emsp;
          <b>7</b>#&emsp;
          <b>8</b>#&emsp;
        </div>
       </div>
			  `,
        answer: [`E`, `G`, `A`, `D`, `F`, `H`, `C`, `B`],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P124-U6.1-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page124/Key/E2answerKey.png',
    component: T6,
    stylesTextInput: {
      textAlign: 'left',
      inputSize: 20,
      fontSize: 21,
    },
    inputSize: 950,
    maxLength: 700,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
        Rewrite the following sentences using cleft sentences.
        `,
        color: '#860052',
      },
    ],
    // isHiddenCheck: true,

    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
        <div style='width: max-content; font-size: 21px'>
          <div><b>1</b> Many patients can recover from illnesses because they are always full of optimism</div>
          <div style="padding-left:20px;">It is because # </div>

          <div><b>2</b> Sarah, not Elly won the first prize in our school Art Competition.</div>
          <div style="padding-left:20px;">It was Sarah #</div>

          <div><b>3</b> She may not have passed the exam.</div>
          <div style="padding-left:20px;">It is thanks to #</div>

          <div><b>4</b> Wangari Maathai, one of the greatest environmentalists, won the Nobel Peace Prize in 2004.</div>
          <div style="padding-left:20px;">It was in 2004 #.</div>

          <div><b>5</b> Amelia Humfress came up with the idea of a business when she was looking for a design course.</div>
          <div style="padding-left:20px;">It was when #.</div>

          <div><b>6</b> Lack of confidence prevents young people from making success.</div>
          <div style="padding-left:20px;">It is lack #.</div>

          <div><b>7</b> Instrument makers create their products with great accuracy.</div>
          <div style="padding-left:20px;">It is with #.</div>

          <div><b>8</b>  The architect Dang Viet Nga created the Crazy House.</div>
          <div style="padding-left:20px;">It was the Crazy House #.</div>
        </div>
				`,
        answer: [
          'many patients are always full of optimism that they can recover from illnesses.',
          ', not Elly who won the first prize in our school Art Competition. /  who won the first prize in our school Art Competition, not Elly.',
          'self-motivation that high-flyers have made success of their original ideas.',
          'that Wangari Maathai, one of the greatest environmentalists, won the Nobel Peace Prize.',
          'Amelia Humfress was looking for a design course that she came up with the idea of a business.',
          'of confidence that prevents young people from making success.',
          'great accuracy that instrument makers create their products.',
          'that the architect Dang Viet Nga created.',
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P124-U5.3-E1',
    audio: '',
    video: '',
    exerciseKey: '/img/FriendsPlus/Page124/Key/E1answerKey.png',
    component: T6,
    stylesTextInput: {
      //fontSize: 21,
      textAlign: 'center',
    },
    inputSize: 280,
    maxLength: 10,
    titleQuestion: [
      {
        num: '1',
        title: `
          <div>Complete the sentences with despite / in spite of or although / even though</div>
        `,
        color: '#860052',
      },
    ],
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
         <div style ='line-height:2.5'>
          <div><b>1</b> # exams are important, students also need to continue doing their hobbies.</div>

          <div><b>2</b> Many young people enjoy extreme sports, # the danger</div>

          <div><b>3</b> Cooking is an important life skill, # many teenagers cannot do it.</div>

          <div><b>4</b> Most teenagers relax in the evenings,# having large amounts of homework</div>

          <div><b>5</b> You learn as much from extra-curricular activities as you do in lessons, # you don't do exams in them</div>

          <div><b>6</b> You can learn a musical instrument at any age,# it's easier when you are young</div>

         </div>

        `,
        answer: [
          'Although / Even though',
          'despite / in spite of',
          'although / even though',
          'despite / in spite of',
          'although / even though',
          'although / even though',
        ],
      },
    ],
  },
};

export default json;
