import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P90-E1',
    exerciseKey: 'img/FriendsPlus/Page90/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // hideBtnFooter: true,
    component: T6v2,
    inputSize: 130,
    // maxLength: 20,
    textareaStyle: { width: 900 },

    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
        <div style='font-size:25px'>
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
        Work in pairs. Choose one of the photos and describe it to your partner. Use the words below to help you. 
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:22px; position: relative'>
              <div style="color:rgb(118,117,181); margin-bottom:10px  ">
                <div><b>Nouns</b>
                &ensp;boxer
                &ensp;bus
                &ensp;cover
                &ensp;message
                &ensp;parking meter
                &ensp;statue
                &ensp;street
                &ensp;wool
                &ensp;writing</div>
                <div><b>Verbs</b>
                &ensp;wear
                &ensp;kneel
                &ensp;cover
                &ensp;knit</div>
                <div><b>Adjectives</b>
                &ensp;brightly
                &ensp;coloured
                &ensp;patterned
                &ensp;striped</div>
              </div>
              <div style='position:relative; display:flex; flex-direction: column'>
              <img style="width:13cm" src='img/FriendsPlus/Page90/E1/1.jpg' />
              <div style='display:flex;'>
              <img style=" width:10cm; height:6cm; margin-top:51px" src='img/FriendsPlus/Page90/E1/2.jpg' />
              <img style=" width:16cm; height:10.1cm; margin-top:32px" src='img/FriendsPlus/Page90/E1/3.jpg' />
              </div>
              </div>
              <textarea id='0' rows='3'></textarea>
              
          </div>
          
        `,
        answer: [
          'Photo A shows a bus with a brightly-coloured patterned wool cover.Photo B shows the statue of a boxer wearing a pink knitted top.Photo C shows a woman kneeling down next to a parking meter and putting a striped wool cover on it.',
        ],
      },
    ],
  },

  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P90-E2',
    exerciseKey: 'img/FriendsPlus/Page90/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 80,
    // maxLength: 800,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 22,
      textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
        <div style='font-size:23px'>
        Read the article and match the photos (A–C) with two of the artists
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
      <div>
      <div style='position:relative; display:flex; flex-direction: column; margin-bottom:20px'>
        <div style='display:flex;'>
        <img style=" width:10cm; height:6cm; margin-top:51px" src='img/FriendsPlus/Page90/E1/2.jpg' />
        <img style=" width:16cm; height:10.1cm; margin-top:32px" src='img/FriendsPlus/Page90/E1/3.jpg' />
        </div>
       
      </div>
      <div style='display:flex'>
        <img style="width:25cm" src='img/FriendsPlus/Page90/E2/1.jpg' />
        <div style='font-size:22px'>
          <div><b>ANSWER:</b></div>
          <div><i style='font-size:16px; color: rgb(152,198,204)'>*Ex:Jessie Hemmons:A, B</i></div>
          <div><b>Jessie Hemmons - # </b></div>
          <div><b>Magda Sayeg - # </b></div>
        </div>
      </div>
      </div>
          
        `,
        answer: ['B', 'A, C'],
      },
    ],
  },

  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P90-E3',
    exerciseKey: 'img/FriendsPlus/Page90/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 550,
    // maxLength: 800,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 22,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style='font-size:23px'>
        Read the <span style='color:rgb(118,117,181)'> Reading Strategy.</span>
        Is there a question about the 
        main idea or the writer’s overall opinion in the questions in exercise 4?
        </div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `

      <div style='margin-top:20px'>
      <div style='display: flex'>
      <img style="width:14cm" src='img/FriendsPlus/Page90/E3/1.jpg' />
      <div style='font-size:20px; margin-top:20px'>
        <div><b>ANSWER:</b></div>
        <div><i style='font-size:16px; color: rgb(152,198,204)'>*Answer:No/Yes, question 1 is about the writer’s overall opinion.</i></div>
        <div> # </div>
      </div>
      </div>

      <img style="width:100%;" src='img/FriendsPlus/Page90/E3/2.jpg' />
      </div>
      
          
        `,
        answer: ["Yes, question 5 is about the writer's overall opinion."],
      },
    ],
  },

  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P90-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page90/E4/Key/E4answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `<div style='font-size:22px'>For questions 1–5, circle the correct answers (a–c).</div>
  		  `,
        color: '#92278f',
        // prefix: { icon: [''], text: '' },
      },
    ],

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //0 c
          `a <br> b <br> c`, //1a
          `a <br> b <br> c`, //2c
          `a <br> b <br> c`, //3c
          `a <br> b <br> c`, //4b
        ],
        answers: ['0-8', '1-0', '2-8', '3-8', '4-4'],
        initialValue: [],
      },
      Layout: `
       <div style='display:flex; flex-direction:column; background-color: rgb(199,234,254); height:886px'>
	        <img style='width:23cm; height: 10cm' src='img/FriendsPlus/Page90/E4/1.jpg'>
          <div style='position:relative; height:fit-content'>
            <div style=" position: absolute; top: 15px; left: 28px; font-size:17px; width:47%">
            <div><b>1 </b>Jessie Hemmons ‘yarn bombed’ the statue of Rocky because</div>
            <div style="display: flex;">
              <div><input id='0' type='Circle'/></div>
              <div style='margin-left:20px'>
                she wants people to take photos of it.<br>
                she thinks too many tourists come and see it.<br>
                she wants more people to go and see the paintings in the museum.
              </div>
            </div>
          </div>
          <div style=" position: absolute; top: 195px; left: 28px; font-size:17px; width:47%">
            <div><b>2 </b>Why did Magda Sayeg knit a cover for the door handle of her shop?</div>
            <div style="display: flex;">
              <div><input id='1' type='Circle'/></div>
              <div style='margin-left:20px'>
                For her own amusement<br>
                To attract customers<br>
                Because someone passing the shop suggested it
              </div>
            </div>
          </div>
          <div style=" position: absolute; top: 355px; left: 28px; font-size:17px; width:47%">
            <div><b>3 </b>Jessie thinks that yarn bombing is different from other forms of street art because</div>
            <div style="display: flex;">
              <div><input id='2' type='Circle'/></div>
              <div style='margin-left:20px'>
                its popularity has spread via the internet.<br>
                it’s temporary and soon disappears.<br>
                it’s temporary and soon disappears.
              </div>
            </div>
          </div>

          <div style=" position: absolute; top: 15px; left: 454px; font-size:17px; width:47%">
            <div><b>4 </b>What is the police’s usual attitude to yarn bombing?</div>
            <div style="display: flex;">
              <div><input id='3' type='Circle'/></div>
              <div style='margin-left:20px'>
                They make it clear that it is against the law.<br>
                They think that yarn bombers are strange people.<br>
                They hardly ever try to arrest yarn bombers.
              </div>
            </div>
          </div>
          <div style=" position: absolute; top: 195px; left: 454px; font-size:17px; width:47%">
            <div><b>5 </b>What is the writer’s overall opinion of yarn bombing?</div>
            <div style="display: flex;">
              <div><input id='4' type='Circle'/></div>
              <div style='margin-left:20px'>
                It is wrong because it’s against the law.<br>
                The writer doesn’t express a personal view.<br>
                It deserves to be called 'art'.
              </div>
            </div>
          </div>

          </div>
      </div>
      `,
    },
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P90-E5',
    exerciseKey: 'img/FriendsPlus/Page90/E5/Key/E5answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 550,
    maxLength: 20,
    textAlign: 'left',
    textareaStyle: {
      // background: 'none',
      width: 600,
      fontSize: 22,
      height: 80,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
        <div style='font-size:23px; width:80%'>
        <span style='color:rgb(146,39,143);'>VOCABULARY</span>
        In paragraphs 1 and 2, underline four things 
        (excluding vehicles) that you might find in the street. 
        How many more items can you add to the list?
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=''>
        <img style="width:28cm" src='img/FriendsPlus/Page90/E5/1.jpg' />
        <div style=' margin-top:10px'>
          <div style='font-size:22px; display:flex'><b>ANSWER:</b><textarea id='0' row='2'></div>
        </div>
      </div>
          
        `,
        answer: ['statue, stop sign, lamp post, parking meter'],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P90-E6',
    // exerciseKey: 'img/FriendsPlus/Page84/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    hideBtnFooter: true,
    inputSize: 130,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <div style='margin-bottom: 10px;font-size: 23px'><span style='color: rgb(45,206,137)'>SPEAKING </span> <span style='color: rgb(146,39,143)'>VOCABULARY </span>  
        Work in pairs. Discuss the questions. Use the phrases below to help you.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 900px; position: relative'>
          <div style='font-size:24px; position: relative'>
							
							<div style='border-left: 3px solid rgb(172,168,207); padding: 10px; margin-bottom:10px'>
                <div style='color: rgb(134,132,182)'>Arguing your point</div>
                <div>In my opinion, … As I see it, …</div>
                <div>It could be argued that … I agree with you.</div>
                <div>I see your point, but … That may be true, but …</div>
							</div>

              <div style='margin-top:30px'><b>1 </b>Do you think that yarn bombing is art, or vandalism and littering? Why?</div>
              <div style='margin-top:30px'><b>2 </b>What about other forms of street art, such as graffiti 
              and chalk drawings on the pavement? Are they art or 
              vandalism? Give reasons for your opinion.
              </div>
              
          </div>
              
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
