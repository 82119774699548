import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P32-E1',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		maxLength: 15,
		exerciseKey: 'img/FriendsPlus/Page32/Key/E1answerKey.png',
		question: [],
		textAlign: 'center',
		titleQuestion: [
			{
				num: '1',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Look at the photos. Match the activities with
four of the adventure sports. `,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Adventure activities</b>',
					'abseiling',
					'bodyboarding',
					'bungee jumping',
					'climbing',
					'hang-gliding',
					'hiking',
					'karting',
					'kayaking',
					'mountain biking',
					'parkour',
					'snowboarding',
					'surfing',
				],
				width: 1100,
				fontColor: 'rgb(10 127 64)',
				borderColor: 'none',
			},
		],
		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 200,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
            <hintbox id=0></hintbox>
           <div style='position: relative;margin-left:20px'>
            <img style='height:15cm' src="img/FriendsPlus/Page32/E1/1.jpg" />
              <span style='position: absolute; top: 264px; left: -21px;line-height:70px'>
                <u style='font-style:italic;font-weight:bold'>Picture A:</u>#<br>
                <u style='font-style:italic;font-weight:bold'>Picture B:</u>#<br>
                <u style='font-style:italic;font-weight:bold'>Picture C:</u>#<br>
                <u style='font-style:italic;font-weight:bold'>Picture D:</u>#<br>
              </span>
           </div>
          `,
				answer: ['climbing', 'karting', 'kayaking', 'hiking'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P32-E2',
		audio: '',
		video: '',
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page32/Key/E2answerKey.png',
		// checkUppercase:true,
		component: T6,
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '2',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. Ask and answer the questions.
        Give reasons for your opinions.`,
				color: '#8e258c',
				// prefix: { icon: [''], text: 'VOCABULARY' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 900 },
		inputSize: 760,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
         <b>1</b>&ensp;Which of the activities in the photos looks
        <div style='margin-left:20px'>
          <div><b>a</b>&ensp;most fun?<br>#</div>
          <div><b>b</b>&ensp;most challenging?<br>#</div>
          <div><b>c</b>&ensp;most dangerous?<br>#</div>
        </div>
         
        <div style=''>
            <b>2</b>&ensp;Have you ever tried any of the adventure activities in
            exercise 1? If so, did you enjoy them?<br> If not, would you
            like to try them?
            <textarea id='3' rows='5'></textarea>
        </div>
          `,
				answer: ['', '', '', ''],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P32-E3',
		audio: 'Audios/1.27-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page32/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `<headphone1 name='1.27' src='Audios/1.27-tieude.mp3'></headphone1> Read the task below. Then listen to two students
				doing the task. Which activities do they consider? Which
				one do they agree on?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// maxLength: 27,
		// isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 740,
		// textAlign: 'center',
		questionImage: [],
		questions: [
			{
				title: `
        <img style='margin:10px' src='img/FriendsPlus/Page32/E3/1.jpg'>
				<div >
          <b>1</b>&ensp;Which activities do they consider?<br>#
				</div>
				<div>
          <b>2</b>&ensp;Which one do they agree on?<br>#
				</div>  
          `,
				answer: ['They consider kayaking, surfing, parkour, karting and hiking.', 'They agree on karting.'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P32-E4',
		audio: 'Audios/1.27-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page32/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `<headphone1 name='1.27' src='Audios/1.27-tieude.mp3'></headphone1>&ensp;<span style="color:#8e2a8d">KEY PHRASES</span>  Read the <span style="color:rgb(10 127 64)">Speaking Strategy</span> and the
        phrases below. Check that you understand them all. Then
        listen again. Which of the phrases did the students use?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		textareaStyle: { width: 530 },
		inputSize: 740,
		questionImage: [],
		questions: [
			{
				title: `
			<div style='position: relative;margin-left:20px'>
        <img style='margin:10px' src="img/FriendsPlus/Page32/E4/1.jpg" />
        <span style='position: absolute; top: 180px; left: 21px'>
          Which of the phrases did the students use?<br>
          <textarea id='0' rows='4'></textarea>
        </span>
      </div>
           
          `,
				answer: [
					"I'm quite keen on … ; I'd rather (go surfing) than (kayaking). ; Sorry, but I don't really fancy … ; I like the idea of … ; Overall; … would be better. ; That's settled then.",
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P32-E5',
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '5',
				title: `Work in pairs. Prepare to do the task in exercise 3.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page32/E5/1.jpg' }]],
	},

	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P32-E6',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '6',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. Do the task in exercise 3 using
your notes and the phrases in exercise 4 to help you.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page32/E6/1.jpg' }]],
	},
}

export default json
