import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
const json = {
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P25-E3',
		video: '',
		component: T6,
		textareaStyle: { width: 450, fontSize: 22 },
		inputSize: 1010,
		isHiddenCheck: true,
		maxLength: 200,
		titleImage: '',
		textAlign: 'left',
		fontSize: 22,
		exerciseKey: 'img/FriendsPlus/Page25/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title:
					'Read the <span style="color: purple">Learn this!</span>. box. Which verbs do we use with the blue  activities and <br> sports  in exercise 2: <i>do, play or go</i>?',
				color: '#8e258c',
			},
		],
		hintBox: [
			{
				src: [
					'<b>do, play or go</b>',
					'ballet',
					'ballroom dancing',
					'basketball',
					'board games',
					'bowling',
					'camping',
					'cards',
					'chess',
					'cycling',
					'drama',
					'gymnastics',
					'ice hockey  ',
					'martial arts',
					'a musical instrument',
					'photography ',
					'rollerblading',
					'running',
					'shopping',
					'skateboarding',
					'weights',
				],
				width: 600,
				borderColor: 'none',
				fontColor: 'Blue',
				justifyContent: 'left',
				fontSize: 22,
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
			<div style='display:flex'>
				
					<div style='margin-right:10px'>
     				<hintbox id='0'></hintbox>

						<img style="margin-left:-50px; height:70mm " src='img/FriendsPlus/Page25/E1/3.jpg'>
					</div>
					<div>
						<div style=""> <b>Do</b>  <textarea id='0' rows='2'></textarea></div>
						<div style=""> <b>Play</b>  <textarea id='1' rows='2'></textarea></div>
						<div style=""> <b>Go</b>  <textarea id='2' rows='3'></textarea></div>
					</div>
			</div>


          `,
				answer: [
					`ballet, drama, gymnastics, martial arts, photography, weights`,
					`basketball, board games, cards, chess, ice hockey, a musical instrument`,
					`ballroom dancing, bowling, camping, cycling, rollerblading, running, shopping, skateboarding`,
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P25-E4',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page25/Key/E4answerKey.png',
		inputSize: 160,
		textareaStyle: { width: 550, fontSize: 23, fontWeight: "bold" },
		// inputSize: 50,
		isHiddenCheck: true,
		titleImage: '',
		textAlign: 'center',
		titleQuestion: [
			{
				num: '4',
				title:
					'Put the activities and sports in exercise 2 into groups A-G. You can put some of them into more than one group. How many more activities can you add?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
			<div style='width:22cm'>
        <div style='display: flex'>
        <img style='width: 16cm' src='img/FriendsPlus/Page25/E4/1.jpg'/>
        <img style='width: 16cm' src='img/FriendsPlus/Page25/E4/2.jpg'/>
        </div>
        
        
        <div style=' width:25cm; font-size: 20px; display: flex'>
        
          <div>
          <div style='margin-right: 70px'>
                <b>A</b> Games.
                <textarea id='0' rows='1' width='550px' ></textarea>
              </div> 
 
              <div style=''>
                <b>B</b> Music. 
                <textarea id='1' rows='1' width='550px' ></textarea>
              </div>

              <div style=''>
                <b>C</b> Computer-based activities. 
                <textarea id='2' rows='1' width='550px' ></textarea>
              </div>
							<div style='font-size: 20px;'>
                <b>D</b> Home-based activities. 
             		 <textarea id='3' rows='4' ></textarea>
              </div>  
          </div>
          <div>
            <div style=''>
               <b>E</b>  Outdoor leisure activities.
               <textarea id='4' rows='2' width='550px' ></textarea> 
              </div>

              <div style=''>
                <b>F</b> Activities and sports you usually do on your own. 
               <textarea id='5' rows='2' width='550px' height='130px'></textarea>
                </div>  
   
              <div style=x'>
              <b>G</b> Sports you do with another person or in a team.  
              <textarea id='6' rows='1' width='550px' height='80px'></textarea>
              </div> 
          </div>
        </div>
        
      </div>  
          `,
				answer: [
					'board games, cards, chess',
					'ballet, ballroom dancing, a musical instrument',
					'use social media, video blog, watch videos online',
					'bake cakes, collect figures, cards, stamps, etc., draw, hang out with friends, make clothes, read books, read magazines, text your friends, use social media, video blog, watch videos online, play board games, cards, chess, a musical instrument',
					'cycling, camping, hang out with friends, ice skating, photography, rollerblading, running, skateboarding',
					'bake cakes, collect figures, cards, stamps, etc., cycling, draw, make clothes, read books, read magazines, photography, running, weights',
					'basketball, gymnastics, bowling, ice hockey, martial arts, weights',
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P25-E5',
		video: '',
		component: T6,
		hideBtnFooter: true,
		inputSize: 60,
		textareaStyle: { width: 700 },
		// inputSize: 50,
		isHiddenCheck: true,
		maxLength: 10,
		titleImage: '',

		textAlign: 'center',
		// recorder: true,
		titleQuestion: [
			{
				num: '5',
				title:
					'<span style="color: rgb(2, 146, 97)">SPEAKING</span>. Which of the activities and sports in exercise 2.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <img src='img/FriendsPlus/Page24/E1/2.jpg'>
       <div>
       </div>
        <div style='width:20cm; line-height:46px'>
            <div style='display:flex;'>
              <b>a</b>
              <div style='margin-left:20px'>
                have you tried and enjoyed?
              </div>  
            </div>
            <div style='display:flex;'>
              <b>b</b>
              <div style='margin-left:20px'>
                have you tried but didn’t enjoy?  
              </div>  
            </div>
            <div style='display:flex;'>
              <b>c</b>
              <div style='margin-left:20px'>
                have you tried but didn’t enjoy?
              </div>  
            </div>
            <div style='display:flex;'>
              <b>d</b>
              <div style='margin-left:20px'>
                would you prefer not to try? Why?
              </div>  
            </div>
        </div>
          `,
				answer: ['T', 'T', 'F', 'F', 'F', 'T'],
			},
		],
	},
	6: {
		unit: 'Unit 2',
		id: 'FGF11-U2-P25-E6',
		audio: 'Audios/1.20-audio.mp3',
		video: '',
		component: T6,
		inputSize: 500,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page25/Key/E6answerKey.png',

		titleQuestion: [
			{
				num: '6',
				title: `
        <span style='margin-right: 10px;'><headphone1 name="1.20" src="Audios/1.20-tieude.mp3"></headphone1></span>
				Listen to five people talking about why they hate certain things. What sports or activities do they talk about?
				`,
				color: '#92278f',
			},
		],
		hintBox: [
			{
				src: ['be', 'buy', 'go', 'play', 'use'],

				width: 380,
				fontColor: 'purple',
			},
		],
		questions: [
			{
				title: `
        <div>
        <div><b>Speaker 1 </b> #</div>
        <div><b>Speaker 2  </b> #</div>
        <div><b>Speaker 3 </b> #</div>
        <div><b>Speaker 4 </b> #</div>
        <div><b>Speaker 5 </b> #</div>
        </div>
				`,
				answer: [
					`going camping`,
					` watching horror films `,
					`using social media`,
					`going shopping `,
					`playing a musical instrument`,
				],
			},
		],
	},
	7: {
		unit: 'Unit 2',
		id: 'FGF11-U2-P25-E7',
		audio: 'Audios/1.20-audio.mp3',
		video: '',
		component: T6v2,
		inputSize: 400,
		maxLength: 25,
		isHiddenCheck: true,
		textAlign: 'center',
		exerciseKey: ['img/FriendsPlus/Page25/Key/E7answerKey.png'],
		titleQuestion: [
			{
				num: '7',
				title: `
        <span style='margin-right: 10px;'><headphone1 name="1.20" src="Audios/1.20-tieude.mp3"></headphone1></span>
				<span>Read the <span style="margin-right: 10px; color: #92278f "> Recycle!</span> box. Then listen again. Complete the sentences with the present simple form of the verbs below and adverbs of frequency.</span> 
				`,
				color: '#92278f',
			},
		],
		hintBox: [
			{
				src: ['be', 'buy', 'go', 'play', 'use'],

				width: 380,
				fontColor: 'purple',
			},
		],
		questions: [
			{
				title: `
        <hintbox id=0></hintbox>
				<div style='line-height:46px'>
            <div style='display:flex;'>
              <b>a</b>
              <div style='margin-left:20px'>
                Speaker 1 # camping with his parents.
              </div>  
            </div>
            <div style='display:flex;'>
              <b>b</b>
              <div style='margin-left:20px'>
                Speaker 2 thinks that horror films # unrealistic and unconvincing.
              </div>  
            </div>
            <div style='display:flex;'>
              <b>c</b>
              <div style='margin-left:20px'>
                Speaker 3 # social media.
              </div>  
            </div>
            <div style='display:flex;'>
              <b>d</b>
              <div style='margin-left:20px'>
                Speaker 4 # things online.
              </div>  
            </div>
            <div style='display:flex;'>
              <b>e</b>
              <div style='margin-left:20px'>
                Speaker 5 # the guitar at school.
              </div>  
            </div>
        </div>
				
				`,
				answer: [`never goes`, ` are always `, `rarely uses | doesn't often use`, ` usually buys `, `sometimes plays`],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P25-E8',
		audio: '',
		video: '',
		component: T6,
		inputSize: 700,
		maxLength: 20,
		titleImage: '',
		hideBtnFooter: true,
		textAlign: 'center',
		hideBtnFooter: true,
		// recorder: true,
		titleQuestion: [
			{
				num: '8',
				title:
					'<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span>Work in pairs. Do you agree with the opinions of the speakers in exercise 7? Why ? / Why not?',
				color: '#8e258c',
				prefix: { icon: [''], text: ' ' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <img style='width:18cm' src='img/FriendsPlus/Page25/E1/11.jpg'>
        <div>
       <ul>
       <li>
        In pairs, students discuss the speakers’ opinions and say whether or not they agree with them, giving reasons.
        </li>
        <li>Ask a few students to share their ideas with the class.</li>
       </ul>
       </div>
          `,
				answer: [],
			},
		],
	},
}

export default json
