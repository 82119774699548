import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    // Exercise num
    unit: 'Unit 4',
    id: 'FGF11-U4-P54-E1',
    audio: '',
    video: '',
    component: T6,
    // recorder: true,
    hideBtnFooter: true,
    maxLength: 16,
    exerciseKey: 'img/FriendsPlus/Page54/Key/E1answerKey.png',
    question: [],
    textAlign: 'center',
    titleQuestion: [
      {
        num: '1',
        title: `<span style="color:rgb(10 127 64)">SPEAKING</span> Look at the photos and the titles of texts A–C.
What do you think the texts are about?
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Nouns</b>',
          'cliff',
          'glass',
          'metal',
          'ocean',
          'rock',
          'view',
          '<b>Adjectives</b>',
          'modern',
          'spacious',
          'spectacular',
          'unique',
        ],
        width: 700,
        fontColor: '#8d268a',
        borderColor: 'none',
      },
    ],
    // maxLength: 14,
    recorder: true,
    isHiddenCheck: true,
    textareaStyle: { width: 800 },
    inputSize: 450,
    // textAlign: 'center',
    //hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
				<div style='display:flex'>
					<div>
						<img src="img/FriendsPlus/Page54/E1/1.jpg" /><br>
						<img src="img/FriendsPlus/Page54/E1/3.jpg" />
					</div>
					<img style='height:14cm' src="img/FriendsPlus/Page54/E1/2.jpg" /><br>
				</div>

          `,
        answer: [''],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 4',
    audio: '',
    id: 'FGF11-U4-P54-E2',
    exerciseKey: 'img/FriendsPlus/Page54/Key/E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `Quickly read texts A–C and check your ideas.`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    // maxLength: 4,
    hintBox: [
      {
        src: ['contact', 'enjoy', 'ignore', 'talk', 'try'],
        width: 600,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    // isHiddenCheck: true,
    textareaStyle: { width: 580 },
    inputSize: 80,
    textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
				<div style='position: relative;'>
						<div style='display:flex'>
								<div>
									<img src="img/FriendsPlus/Page54/E1/1.jpg" /><br>
									<img src="img/FriendsPlus/Page54/E1/3.jpg" />
								</div>
								<img style='height:14cm' src="img/FriendsPlus/Page54/E1/2.jpg" /><br>
						</div>

    				<span style='position: absolute; top: 535px; left: 505px'>
							<img src="img/FriendsPlus/Page54/E2/1.jpg" />
							<textarea id='0' rows='6'></textarea>
						</span>
				</div>
          

           
          `,
        answer: [
          'Text A is about a sculptor who makes shelters for homeless people out of rubbish. Text B is about a weird architectural structure in Da Lat, designed by a Vietnamese architect. Text C is about converting shipping containers into homes for homeless people.',
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 4',
    id: 'FGF11-U4-P54-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page54/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `Read <span style="color:#433f9b">Reading Strategy</span>. Match two of the texts with
the photos below. Say what evidence you found to
support your answers.
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    // maxLength: 6,
    component: T6,
    // isHiddenCheck: true,
    textareaStyle: { width: 630 },
    inputSize: 630,
    // textAlign: 'center',

    questionImage: [],
    questions: [
      {
        title: `
        <div style='position: relative;'>
						<div style='display:flex'>
								<div>
									<img src="img/FriendsPlus/Page54/E1/1.jpg" /><br>
									<img src="img/FriendsPlus/Page54/E1/3.jpg" />
								</div>
								<img style='height:14cm' src="img/FriendsPlus/Page54/E1/2.jpg" /><br>
								<img style='height:14cm; width:50%'  src="img/FriendsPlus/Page54/E3/1.jpg" />
						</div>

    				<span style='position: absolute; top: 535px; left: 505px'>
							<u style='font-style:italic;font-weight:bold'>Picture 1:</u>#<br>
							<u style='font-style:italic;font-weight:bold'>Picture 2:</u><textarea id='1' rows='3'></textarea>
							
						</span>
				</div>
          

           
          `,
        answer: [
          'Text B: closer to nature, no straight lines, no right angles',
          'Text C: old shipping containers; stackable; The containers will sit on top of one another with stairs connecting them.',
        ],
      },
    ],
  },

  4: {
    // Exercise num
    unit: 'Unit 4',
    id: 'FGF11-U4-P54-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page54/Key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `Match the texts (A–C) with the sentences (1–4) below.
Make a note of the evidence you found to support your
answers.
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],

    // maxLength: 11,
    component: T6,

    // isHiddenCheck: true,
    textareaStyle: { width: 400 },
    inputSize: 650,
    textAlign: 'center',
    // recorder: true,
    questionImage: [],
    questions: [
      {
        title: `
         <div style='position: relative;'>
						<div style='display:flex'>
								<div>
									<img  style="width:101%" src="img/FriendsPlus/Page54/E1/1.jpg" />
								</div>
								<img style='height:14cm' src="img/FriendsPlus/Page54/E1/2.jpg" /><br>
                <img  style="width:35%" src="img/FriendsPlus/Page54/E1/3.jpg" />
							</div>
						<div>
              
							<img  src="img/FriendsPlus/Page54/E4/1.jpg" />
            </div>		

    				
				</div>
					<div style='display:flex'>
						<div>
								<div style='display:flex'>
									<u style='font-style:italic;font-weight:bold'>2:</u>
									<textarea id='0' rows='5'></textarea>
								</div>
								<div style='display:flex'>
									<u style='font-style:italic;font-weight:bold'>4:</u>
									<textarea id='2' rows='4'></textarea>
								</div>
						</div>
						<div style='display:flex;margin-left:30px'>
							<u style='font-style:italic;font-weight:bold'>3:</u>
							<textarea id='1' rows='7'></textarea>
						</div>
					</div>
				
          
          `,
        answer: [
          `A (Gregory searches on rubbish dumps to find pieces to use. A washing machine door … A fridge door … has useful shelves on the inside.)`,
          `B (In one room, guests are watched over by the glowing red eyes of a kangaroo. In another room, the window looks like the cobweb of a giant spider and the fireplace is in the form of an eagle's egg.)`,
          `C (They are also stackable. The containers will sit on top of one another with stairs connecting them.)`,
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 4',
    id: 'FGF11-U4-P54-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page54/Key/E5answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `<span style="color:#8e258c">DICTIONARY WORK</span> Read a short extract from a
dictionary entry for take. Answer the questions.
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    // stylesTextInput: { fontSize: 25, height: 25, borderBottom: 'none', background: 'none' },
    // maxLength: 11,
    component: T6,
    isHiddenCheck: true,
    // isHiddenCheck: true,
    textareaStyle: { width: 340 },
    inputSize: 200,
    textAlign: 'center',
    // recorder: true,
    questionImage: [],
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page54/E5/1.jpg" />
        <div>
            <b>1</b>&ensp;What do ‘pt’ and ‘pp’ stand for?<br>
            pt:#&emsp;&emsp;pp:#
        </div>
        <div>
            <b>2</b>&ensp;How many different meanings are included?<br>
            <input id='1' width='500px'></input>
        </div>
        <div style='width:17cm'>
            <b>3</b>&ensp;Do the words printed in bold in the examples show a
grammatical rule, a useful collocation, or a spelling rule?<br>
            <input id='2' width='500px'></input>
        </div>
          `,
        answer: ['past tense', 'past participle', 'Three meanings are included.', 'they show a useful collocation.'],
      },
    ],
  },

  6: {
    // Exercise num
    unit: 'Unit 4',
    id: 'FGF11-U4-P54-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page54/Key/E6answerKey.png',

    titleQuestion: [
      {
        num: '6',
        title: `<span style="color:#8e258c">USE OF ENGLISH</span> Fill in the gap in each pair of sentences
with the correct form of do, make and take. Use a
dictionary to check the meaning of the verbs.
`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: ['<b>Compound nouns</b>', ''],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    maxLength: 10,
    // isHiddenCheck: true,
    textareaStyle: { width: 540 },
    inputSize: 145,
    // stylesTextInput: { fontSize: 22, height: 23, borderBottom: 'none', background: 'none' },
    textAlign: 'center',
    // recorder: true,
    // //hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:25px'>
                <b>a</b>&ensp;I#French classes for a year, but I can’t speak it well.<br>
                <b>b</b>&ensp;I#my Spanish exam last week and passed.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:25px'>
                <b>a</b>&ensp;Thanks for inviting me to your party, but I’m afraid I can’t#it.<br>
                <b>b</b>&ensp;What’s the time? I#it 7.30
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:25px'>
                <b>a</b>&ensp;The waves are#a lot of damage to the cliffs.<br>
                <b>b</b>&ensp;He was#150 km/h when the police stopped him.
            </div>
          </div>
          <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:25px'>
                <b>a</b>&ensp;That noise has been going on all night. I can’t#it any more!<br>
                <b>b</b>&ensp;I wish you would#your schoolwork more seriously
            </div>
          </div>
        
        </div>
                 
          `,
        answer: ['would come', 'would give', 'was / were', 'lived', 'was / were', 'would get'],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 4',
    id: 'FGF11-U4-P54-E7',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page54/Key/E7answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `<b style="color:rgb(10 127 64)">SPEAKING</b>  Tell your partner about a time when you

`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    hintBox: [
      {
        src: [
          '<b>Compound nouns</b>',
          'flood lights',
          'football pitch',
          'main road',
          'mountain range',
          'safety net',
          'sea shore',
          'swimming pool',
          'tennis court',
          'tennis player',
          'tower block',
        ],
        width: 800,
        fontColor: '#8e258c',
        borderColor: 'none',
      },
    ],
    component: T6,
    maxLength: 1,
    // isHiddenCheck: true,
    textareaStyle: { width: 540 },
    inputSize: 40,
    textAlign: 'center',
    recorder: true,
    //hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
          
         <div>
          <b>1</b>&ensp;did your best.<br>
          <b>2</b>&ensp;took it easy.<br>
          <b>3</b>&ensp;didn’t take something seriously.  <br>
         </div>
          `,
        answer: [''],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 4',
    id: 'FGF11-U4-P54-E8',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
    question: [],
    titleQuestion: [
      {
        num: '8',
        title: `<b style="color:rg#(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page54/E8/1.pNg' }]],
  },
};

export default json;
