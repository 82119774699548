import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P51-E1',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		maxLength: 16,
		exerciseKey: 'img/FriendsPlus/Page51/Key/E1answerKey.png',
		question: [],
		textAlign: 'center',
		recorder: true,
		titleQuestion: [
			{
				num: '1',
				title: `<span style="color:rgb(10 127 64)">SPEAKING</span> Work in pairs. Look at the photo, title and
slogan opposite. What happens on a ‘Big Sleep Out’, do
you think? Read the article and check your answer
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 500 },
		inputSize: 450,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: ` 
				<div style='display:flex'>
        <div style='flex:1'>
        					<img style='width:80%' src="img/FriendsPlus/Page51/E1/1.jpg" />

        </div>
        <div style='flex:1'>
							<u style='font-style:italic;font-weight:bold'>Answers:</u>
							<textarea id='0' rows='5'></textarea>
					</div>

          

           

           </div>
          `,
				answer: [
					'The Big sleep out is a charity event which raises money for young homeless people. People sleep rough for one night to understand what life is like for homeless people in the uK.',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		audio: '',
		id: 'FGF11-U4-P51-E2',
		exerciseKey: 'img/FriendsPlus/Page51/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `Read <span style="color:#d33d6c">Listening Strategy 1</span>. Complete the definitions
with the words below.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		maxLength: 7,
		hintBox: [
			{
				src: ['contact', 'enjoy', 'ignore', 'talk', 'try'],
				width: 500,
				fontColor: '#d33d6c',
				borderColor: 'none',
			},
		],
		component: T6,
		isHiddenCheck: true,
		inputSize: 120,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <div>
          <div><img    src="img/FriendsPlus/Page51/E2/1.jpg" style='max-width:80%' /></div>
        
          <div style='font-weight:bold'><hintBox id="0"></hintBox></div>

          <div>
          <u style='font-style:italic;font-weight:bold'>Answers:</u> <br>
            <div> <b>1</b>&ensp; to turn a blind eye to something = to # something</div>
            <div> <b>2</b>&ensp; to make a big effort = to # hard</div>
            <div> <b>3</b>&ensp; to have the time of your life = to # yourself a lot</div>
            <div> <b>4</b>&ensp; to have a word with somebody = to # to somebody</div>
            <div> <b>5</b>&ensp; to get in touch with somebody = to # somebody</div>
          </div>

        </div>
           
          `,
				answer: ['ignore', 'try', 'enjoy', 'talk', 'contact'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P51-E3',
		audio: 'Audios/2.07-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page51/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `<headphone1 name="2.07" src="Audios/2.07-tieude.mp3"></headphone1>
          Listen to two short recordings. Answer the questions. Use the verbs and phrases in exercise 2 to  help you.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		// maxLength: 6,
		component: T6v2,
		// isHiddenCheck: true,
		inputSize: 320,
		textAlign: 'left',
		stylesTextInput: {
			fontSize: 25,
		},

		questionImage: [],
		questions: [
			{
				title: `
       <div style='line-height:1.75'>
      <b>Speaker 1</b> <br>
      <b>1</b>&ensp;Did she enjoy her Big Sleep Out?<br>
      <input/> <br>
      <b>2</b>&ensp;Did she talk to the organisers?<br>
      <input/> <br>
      <br>
      <b>Speaker 2</b> <br>
      <b>3</b>&ensp; Does he think the organisers tried hard to publicise the event this year? <br>
      <input/> <br>
      <b>4</b>&ensp;Did they contact the local newspaper last year?
<br>
      <input/>


       </div>
           
          `,
				answer: [`No, she didn't enjoy it.`, `No, she didn't.`, 'Yes, he does.', `No, they didn't.`],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P51-E4',
		audio: 'Audios/2.08-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page51/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `<headphone1 name="2.08" src="Audios/2.08-tieude.mp3"></headphone1> Read <span style="color:#d33d6c">Listening Strategy 2</span>.  Then listen to three recordings. Which excerpts contain formal language? Use the table below to help you identify them. `,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		component: T6,

		isHiddenCheck: true,
		inputSize: 450,
		textAlign: 'center',
		// recorder: true,
		questionImage: [],
		questions: [
			{
				title: `
         <div style='display:flex'>
         <div style='flex:1'>
         <img src='img/FriendsPlus/Page51/E4/1.jpg'>
         </div>

          <div style='flex:1'>
          <img src='img/FriendsPlus/Page51/E4/2.jpg'>

         </div>
         
         </div>
         <u><i>Answer:</i></u>&ensp;<input/>
           
          `,
				answer: ['Excerpts 1 and 3 use formal language.'],
			},
		],
	},
	5: {
		unit: 'Unit 4',
		id: 'FGF11-U4-P51-E5',
		audio: 'Audios/2.08-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page51/Key/E5answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '5',
				title: `<headphone1 name="2.08" src="Audios/2.08-tieude.mp3"></headphone1>  Listen again. Choose the correct answers (a–c).  `,
				color: '#8e258c',
			},
		],
		question: {
			Write: {
				isHiddenCheck: true,
				maxLength: 11,
				inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
				answers: [''],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					borderRadius: '50%',
					fontWeight: 'bold',
					borderColor: 'transparent',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					// borderColor: '#00aeef',
				},
				limitSelect: 1,
				listWords: [
					`A <br> B <br> C`, //1
					`A <br> B <br> C`, //1
					`A <br> B <br> C`, //1
				],
				answers: ['0-0', '1-8', '2-8'],
				initialValue: [],
			},
			Layout: `
    <div style='line-height:1.75'>
      <b>1</b>&ensp;
           		  Who is Speaker 1 speaking to? <br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
              An audience at a conference <br> A friend who works for a charity <br> A young homeless person
                    </div>
                       </div>

			

    <b>2</b>&ensp;
    Speaker 2 thinks that older people
    <div style="display: flex;margin-left:40px">
      <div><input id='1' type='Circle' /></div>
      <div style='margin-left:20px'>
        care more about homelessness. <br> are more likely to take part in the Big Sleep Out. <br> often don’t have time to think about homelessness.
        </div>
      </div>
      <b>3</b>&ensp;
      Who does Speaker 3 work for?
      <div style="display: flex;">
        <div style="display: flex;margin-left:40px">
          <div><input id='2' type='Circle' /></div>
          <div style='margin-left:20px'>
            A youth hostel <br> A local business <br> A city council

        
      `,
		},
	},

	6: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P51-E6',
		audio: '',
		video: '',
		// exerciseKey: '',

		titleQuestion: [
			{
				num: '6',
				title: ` <span style="color:rgb(10 127 64)">SPEAKING</span>  Discuss these questions with your partner. `,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		recorder: true,
		hintBox: [
			{
				src: [
					'The weather',
					'Other people’s attitudes',
					'Being alone',
					'Being uncomfortable',
					'Personal safety',
					'Hygiene',
				],
				width: 800,
				fontColor: '#d33d6c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 12,
		hiddenbtnfooter: true,
		isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 145,
		// stylesTextInput: { fontSize: 22, height: 23, borderBottom: 'none', background: 'none' },
		textAlign: 'center',
		hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
     <div style='line-height:2'>
        <b>1</b>&ensp;What would be the worst thing about being homeless? <br>
<div style='font-weight:bold'><hintbox id='0'></hintbox> <br>
</div>
        <b>2</b>&ensp;What should governments do to tackle the problem of homelessness? <br>
        <b>3</b>&ensp;What can individuals do to help the homeless?<br>
<div >        <img src='img/FriendsPlus/Page51/E6/1.jpg' style='max-width:75%'>
</div>
        </div>

                 
          `,
				answer: [],
			},
		],
	},
}

export default json
