import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P31-E1',
		audio: 'Audios/1.26-audio.mp3',
		video: '',
		//exerciseKey: 'img/FriendsPlus/Page30/Key/E2answerKey.png',
		// checkUppercase:true,
		titleQuestion: [
			{
				title: `<headphone1 name='1.26' src='Audios/1.26-tieude.mp3'></headphone1>`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		component: T6,
		// maxLength: 14,
		isHiddenCheck: true,
		hideBtnFooter: true,
		textareaStyle: { width: 700 },
		inputSize: 760,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
          <img style='margin:10px' src='img/FriendsPlus/Page30/E1/1.jpg'>
          `,
				answer: [''],
			},
		],
	},
}

export default json
