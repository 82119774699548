import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E1',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '1',
        title: `
				Look at the photos. Which fitness class would you prefer  to do? Why?

			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    hideBtnFooter: true,
    // recorder: true,
    textareaStyle: { width: 500 },
    questions: [
      {
        title: `
			  <div style='display:flex;' >
        <div style='flex:2;'>
                <img src='img/FriendsPlus/Page44/E1/1.jpg' alt='' style=' max-width:100%;'/>

        </div>
         <div style='flex:3;margin-left:20px ' >
                <textarea id='0' rows='12'></textarea>
</div>
        </div>        
       
			  `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E2',
    audio: 'Audios/1.36-audio.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/key/E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
          <headphone1 name='1.36' src='Audios/1.36-tieude.mp3' ></headphone1> Read the <span style='color:rgb(2, 146, 97);'> Speaking Strategy </span>  Then listen to two students describing the photos in exercise 1. Which student follows the strategy better? Explain your answer.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 260,
    textAlign: 'center',
    // maxLength: 20,
    textareaStyle: { width: 700 },

    questions: [
      {
        title: `
        <div>
           <div style='display: flex;justify-content: center;align-items: center; '>

	 <img src='img/FriendsPlus/Page44/E2/1.jpg' style=' max-width:60%'  />
        </div>
        <div>
        Which student follows the strategy better? Explain your answer.
 <br>
        <u style='font-style:italic'>Answer:</u> <br>
        <textarea id='0' rows='2' ></textarea>

        </div>
        </div>
     
			  `,
        answer: [
          'Student 2. She covers all the points, gives her opinion and her answer is more detailed, focused and structured.',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/key/E3answerKey.png',
    inputSize: 570,
    titleQuestion: [
      {
        num: '3',
        title: `
				<span style='color:rgb(124, 8, 79);'> KEY PHRASES </span> Look at the useful phrases below. Are you more likely to use them for general or detailed descriptions?

			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
    },
    // selectOptionRandom: true,
    selectOptionValues: ['T', 'F'],
    questions: [
      {
        title: `
        <div style='margin-left:150px; text-align:center;' >
        <table style="border-collapse: collapse; width: 100%;" >
    <tr>
      <th style="background-color: rgb(2, 146, 97); color: white; padding-left:10px" colspan="4">Identifying people in photos</th>
    </tr>
   
    <tr>
      <td style="border: 1px solid black; padding: 8px; text-align: center;" rowspan="3">The man / woman / <br> boy / girl
</td>
      <td style="border: 1px solid black; padding: 8px; text-align: center;">wearing / in / with</td>
      <td style="border: 1px solid black; padding: 8px; text-align: center;">purple leggings <br> a red top <br> casual / smart clothes
</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; padding: 8px; text-align: center;">with</td>
      <td style="border: 1px solid black; padding: 8px; text-align: center;">a beard / a moustache <br>  his / her foot in the air <br> his / her hands on his / her hips <br> bare feet
</td>
      
    </tr>
    <tr>
      <td style="border: 1px solid black; padding: 8px; text-align: center;">who is</td>
      <td style="border: 1px solid black; padding: 8px; text-align: center;">jumping / crouching / walking / <br> holding (some weights) <br> on the ground
</td>

    </tr>
   
  </table>
        </div>
      <div style='margin-top:10px'>
        Are you more likely to use them for general or detailed descriptions? <br>
                <u style='font-style:italic'>Answer:</u> # <br>
      </div>
			  `,
        answer: ['For detailed descriptions.'],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E4',
    audio: 'Audios/1.36-audio.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/key/E4answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
          <headphone1 name='1.36' src='Audios/1.36-tieude.mp3' ></headphone1> Read the<span style='color:rgb(124, 8, 79);'> KEY PHRASES </span>   Listen again. Which of the underlined phrases from the list below does each student use?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    inputSize: 450,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'left',

    // checkDuplicated: true,

    questions: [
      {
        title: `
       <div style='display:flex'>
       <div style=' flex:4.5;' >
        <div style=' border-left: 5px solid rgb(192, 232, 198);width:100%'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(2, 146, 97);'>Expressing likes and dislikes</b></div>
          <div><u>It looks like some kind of</u> dance class.</div>
          <div>They’re in a park,<u> or maybe </u> in the countryside. </div>
          <div><u>I think it’s </u>a fitness class <u>of some kind. </u></div>
          <div><u>There’s a sort of </u> climbing frame. </div>
          <div><u>It’s most likely</u> in the evening.</div>
          <div>Two men are doing pull-ups, <u> or something like that. </u></div>
          <div>She’s the instructor,  <u> I would say. </u></div>
          <div><u> I’d say that  </u>she’s tired.</div>
          </div>
        </div>
       </div>
       
        <div style='flex:2;margin-left:50px; padding:10px' >
                    <div>
                       <u style='font-style:italic'>Student 1:</u>
                       <div>#</div>
                       <div>#</div>
                       <div>#</div>
                       <div>#</div>
                       </div>
                        <div>
                        <u style='font-style:italic'>Student 2:</u>
                       <div>#</div>
                       <div>#</div>
                       <div>#</div>
                       <div>#</div>
                        </div>

        </div>
       </div>
        

			  `,
        answer: [
          'It looks like some kind of',
          `Or maybe`,
          `It's most likely`,
          `I'd say that`,
          `I think it's a fitness class of some kind`,
          `There's a sort of`,
          `Or something like that`,
          `I would say`,
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E5',
    audio: 'Audios/1.37-audio.mp3',
    video: '',
    component: T6v2,
    exerciseKey: 'img/FriendsPlus/Page44/key/E5answerKey.png',
    inputSize: 650,
    titleQuestion: [
      {
        num: '5',
        title: `
 <headphone1 name='1.37' src='Audios/1.37-tieude.mp3' ></headphone1> Read question 1 below. Then listen to a student’s answer. Which photo from exercise 1 is he referring to? How do you know?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    // //hideBtnFooter: true,
    // recorder: true,
    questions: [
      {
        title: `
       <div style='display:flex'>
                       <div style='flex:3'>
                         <img src='img/FriendsPlus/Page44/E1/1.jpg' alt='' style=' max-width:80%;'/>
                       </div>


                  <div style='flex:2;margin-top:30px'>
                  <b>1</b>&ensp;  Do you think the people are enjoying the class?  Why do you think so? <br>

                  <p style='font-style:italic'>Which photo from exercise 1 is he referring to?</p>
                  <div>#</div>
                  <p style='font-style:italic'> How do you know?</p>
                  <div>#</div>



                  </div>
                       </div>
       
			  `,
        answer: [`Photo a || Photo A`, `The student refers to lots of sunshine and fresh air.`],
      },
    ],
  },
  6: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    recorder: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <b style='color:rgb(2, 146, 97);'>SPEAKING</b> In pairs, ask and answer the same question about the other photo in exercise 1.

			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `

          <div style='display: flex; gap:30px; margin-left: 0px;'>
            <div style='padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div> Do you think the people are enjoying the class? <br>  Why do you think so?
</div>
              </div>
             
         </div>

			  `,
        answer: [],
      },
    ],
  },
  7: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/key/E7answerKey.png',
    inputSize: 40,
    titleQuestion: [
      {
        num: '7',
        title: `
				Read questions 2 and 3 below. Then look at phrases a–h. Which phrases would be useful for each of the questions?

			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    // //hideBtnFooter: true,
    // recorder: true,
    questions: [
      {
        title: `
        
                <div><b>2</b>&ensp;Do you think men care as much about their appearance  as women? Why do you think that?
</div>
 <div style=' margin:10px 100px;display:flex; justify-content: space-between;'>

        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>
         
       </div>
 
          
            
                <div><b>3</b>&ensp;Tell me about an occasion when you wanted to look  your best.

</div>
 <div style=' margin:10px 100px;display:flex; justify-content: space-between;'>

        <div>#</div>
        <div>#</div>
        <div>#</div>
        <div>#</div>


       </div>

         </div>
			  <div style='display:flex; width:1100px; margin-top:100px' >
        <div style='flex:2.5;'>
        <div>
        <b>a</b>&ensp; I remember once when … <br>
        <b>b</b>&ensp; As I see it, … <br>
        <b>c</b>&ensp; A few months ago, … <br>
        <b>d</b>&ensp; My view is that … 
        </div>

        </div>
         <div style=' display: flex; flex:2.5;margin-left:20px;  ' >
                <div>
        <b>e</b>&ensp; The way I look at it, … <br>
        <b>f</b>&ensp; Some time last year, … <br>
        <b>g</b>&ensp; In my opinion, … <br>
        <b>h</b>&ensp; On one occasion, …
                </div>
</div>
        </div>        
       
			  `,
        answer: [`b`, `d`, `e`, `g`, `a`, `c`, `f`, `h`],
      },
    ],
  },
  8: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E8',
    audio: 'Audios/1.38-audio.mp3',
    video: '',
    component: T6v2,
    exerciseKey: 'img/FriendsPlus/Page44/key/E8answerKey.png',
    inputSize: 720,
    titleQuestion: [
      {
        num: '8',
        title: `
 <headphone1 name='1.38' src='' ></headphone1>  Listen to three students answering questions 2 and 3 from exercise 7. Answer the questions below.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    // //hideBtnFooter: true,
    // recorder: true,
    questions: [
      {
        title: `
      <div style='display: flex; gap:30px; margin-left: 0px;'>
            <div style='padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div>  What is each student’s overall answer to question 2:  yes, no, or maybe? <br> #
</div> 
</div>
              </div> 
              </div>

              <div style='padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div> Which student does not really give reasons for his or her answer to question 2? <br> #
              </div>
              </div>
              </div>
              </div>
              <div style='padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 </b></div>
                <div>  What three occasions do the students mention for question 3? <br> #

</div>
              </div>
         </div>

         <div style='display:flex; width:1100px; margin-top:8px; color: rgb(2, 146, 97);' >
        <div style='flex:2.5;'>
        <div>
         <b> A festival  </b> <br>
         <b> A job interview </b>
       
        </div>

        </div>
         <div style=' display: flex; flex:2.5;margin-left:20px;  ' >
                <div>
      <b>  A friend’s party  </b>   <br>
     <b>  A wedding  </b>
       
                </div>
</div>
        </div>
       
			  `,
        answer: [
          `1: no, 2: yes, 3: maybe`,
          `Student 2 || 2`,
          ` a job interview (1), a wedding (2), a friend's party (3) `,
        ],
      },
    ],
  },
  9: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P44-E9',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    recorder: true,
    titleQuestion: [
      {
        num: '9',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span> Work in pairs. Ask and answer the questions  in exercise 7.

			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `

          <div style='display: flex; gap:30px; margin-left: 0px;'>
            <div style='padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div> Do you think men care as much about their appearance  as women?  <br>  Why do you think that?
</div>
              </div>
              
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 </b></div>
                <div>  Tell me about an occasion when you wanted to look  your best.

</div>
              </div>
         </div>

			  `,
        answer: [],
      },
    ],
  },
};

export default json;
