import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	5: {
		unit: 'Unit 5',
		id: 'FGF11-U4-P55-E5',
		audio: 'Audios/2.11-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page55/Key/E5answerKey.jpg',
		titleQuestion: [
			{
				num: '5',
				title: `<span style="color:rgb(149,54,159)">VOCABULARY </span> Match a–h with 1–8 to make compound nouns. They are all in the texts.
`,
				color: 'rgb(149,54,159)',
				// prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: ['<b>Compound nouns</b>', ''],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: MatchDots,
		stylesTextInput: { borderBottom: 'dotted' },
		inputSize: 700,
		fontSize: 25,
		maxLength: 200,
		// checkUppercase: true,
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '28px',
							left: '170px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '88px',
							left: '170px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '170px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '203px',
							left: '170px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '264px',
							left: '170px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '320px',
							left: '170px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '376px',
							left: '170px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '434px',
							left: '170px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '28px',
							left: '335px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '88px',
							left: '335px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 9
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '335px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 10
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '203px',
							left: '335px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 11
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '264px',
							left: '335px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 12
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '320px',
							left: '335px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 13
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '376px',
							left: '335px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 14
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '434px',
							left: '335px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #2eb6e1',
							background: 'white',
						},
					}, // 15
				],
				answers: ['1-8', '15-3', '4-9', '13-6', '14-7', '12-5', '0-11', '10-2'],
				initialValue: [],
			},
			Layout: `
      <div style='display:flex'>
        <input id='0' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='1' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='2' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='3' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='4' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='5' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='6' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='7' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='8' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='9' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='10' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='11' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='12' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='13' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='14' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='15' style="margin-bottom:20px" type= 'boxMatch' />
        
        <div style="display:flex; font-size:29px; line-height:2">
					<div style='margin-right: 200px'>
						<b>1</b>&emsp;rubbish.<br>
						<b>2</b>&emsp;sofa.<br>
						<b>3</b>&emsp;front.<br>
						<b>4</b>&emsp;dining.<br>
            <b>5</b>&emsp;shipping. <br>
          	<b>6</b>&emsp;housing.<br>
            <b>7</b>&emsp;studio.<br>
            <b>8</b>&emsp;guest.<br>
              
				 
					</div>

						<div style="">

								<b>a</b>&emsp;bed<br>
								<b>b</b>&emsp;containers<br>
								<b>c</b>&emsp;door<br>
								<b>d</b>&emsp;dump<br>
                <b>e</b>&emsp;estate<br>
                <b>f</b>&emsp;flat<br>
                <b>g</b>&emsp;house<br>
                <b>h</b>&emsp;table<br></div>
						</div>
				</div>
       <div style='display:flex'>
								<div>
									<img src="img/FriendsPlus/Page54/E1/1.jpg" /><br>
									<img src="img/FriendsPlus/Page54/E1/3.jpg" />
								</div>
								<img style='height:14cm' src="img/FriendsPlus/Page54/E1/2.jpg" /><br>
						</div>

      </div>
        
      `,
		},
	},
	6: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P55-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page55/Key/E6answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title: `Read the text again. Answer the questions.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: 'VOCABULARY' },
			},
		],
		// stylesTextInput: { fontSize: 25, height: 25, borderBottom: 'none', background: 'none' },
		// maxLength: 11,
		component: T6,
		isHiddenCheck: true,
		// isHiddenCheck: true,
		textareaStyle: { width: 340 },
		inputSize: 520,
		// textAlign: 'center',
		// recorder: true,
		questionImage: [],
		questions: [
			{
				title: `
        <div style='position: relative;'>
						<div style='display:flex'>
								<div>
									<img src="img/FriendsPlus/Page54/E1/1.jpg" /><br>
									<img src="img/FriendsPlus/Page54/E1/3.jpg" />
								</div>
								<img style='height:14cm' src="img/FriendsPlus/Page54/E1/2.jpg" /><br>
						</div>

    				<span style='position: absolute; top: 535px; left: 505px; width:15cm'>
              <div>
                <b>1</b>&ensp;Where does Gregory Kloeh hn find the part to make his house?<br>#
              </div>
              <div>
                <b>2</b>&ensp;What does Gregory sometimes use as a front door?<br>#
              </div>
              <div>
                <b>3</b>&ensp;In what way does staying in the Crazy House bring guests closer to nature? <br>#
              </div>
              <div>
                <b>4</b>&ensp;Apart from the UK, in which country have containers been made in to flats?<br>#
              </div>
						</span>
				</div>
          `,
				answer: [
					'He finds them in rubbish dumps.',
					'He sometimes uses a fridge door.',
					'Each of the rooms has features of an animal.',
					'In the Netherlands.',
				],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P55-E7',
		audio: '',
		video: '',
		// exerciseKey: 'img/FriendsPlus/Page55/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b>  Work in pairs. Discuss these questions.

`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 1,
		isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 40,
		textAlign: 'center',
		recorder: true,
		hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
          
         <div style='line-height:2'>
          <b>1</b>&ensp;Which home is the most original and clever, do you think? Why?<br>
          <b>2</b>&ensp;Which is the most  useful from a social point of view? Why?<br>
          <b>3</b>&ensp;Which of the homes would you like to live in? Why?<br>
          <b>4</b>&ensp;Which of the homes would you not like to live in? Why not?  
         </div>
          `,
				answer: [''],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 4',
		id: 'FGF11-U4-P55-E8',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '8',
				title: `<b style="color:rg#(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page55/E8/1.pNg' }]],
	},
}

export default json
