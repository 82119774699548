import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P29-E1',
		audio: '',
		video: '',
		component: T6,
		maxLength: 45,
		isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 500,
		// maxLength: 1,
		// textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page29/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `<span style='color:#099649'>SPEAKING</span> Look at the photos. What do they have in
        common? Which is the most spectacular, in your opinion?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
            
          <img style='height:13cm;margin:10px' src='img/FriendsPlus/Page29/E1/1.jpg'>
           <div>
            <u style='font-weight:bold;'>ANSWERS:</u> #
           </div>
          `,
				answer: [
					// 'They are sports facilities in unusual places.'
					'',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P29-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page29/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: 'Match the nouns below with the photos in exercise 1. Some nouns go with more than one photo',
				color: '#8e258c',
				prefix: { icon: [''], text: 'VOCABULARY' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 200,
		// maxLength: 1,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
            <hintbox id=0></hintbox>
          <img style='height:13cm;margin:10px' src='img/FriendsPlus/Page29/E1/1.jpg'>
           <div>
            <u style='font-weight:bold;'>ANSWERS:</u> <br>
            Picture A:&ensp;#,#,#<br>
            Picture B:&ensp;#,#,#<br>
            Picture C:&ensp;#,#
           </div>
          `,
				answer: [
					'sea shore / tennis court / tennis player',
					'sea shore / tennis court / tennis player',
					'sea shore / tennis court / tennis player',
					'main road / swimming pool / tower block',
					'main road / swimming pool / tower block',
					'main road / swimming pool / tower block',
					'football pitch / mountain range',
					'football pitch / mountain range',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P29-E3',
		audio: 'Audios/1.24-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page29/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `<headphone1 src='Audios/1.24-tieude.mp3' name='1.24'></headphone1> <span style='color:#099649'>PRONUNCIATION</span> Listen and repeat the compound
        nouns from exercise 2. Which word is stressed: the first or second?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 27,
		isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 300,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
            <hintbox id=0></hintbox>
          
          <div>
            <u style='font-size:italic;font-weight:bold'>Answers:</u> #          
          </div>
          `,
				answer: ['The first word is stressed.'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P29-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page29/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `Read the <span style='color:#8e258c'>Learn this!</span> ! box. Then look at the compound
        nouns in exercise 2. Underline at least one example of each
        type (1, 2 and 3). Which type has the most examples?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: Circle_Write,
		question: {
			Write: {
				inputStyle: { width: 370, fontSize: 24 },
				answers: ['Type 1 has the most examples.'],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					// borderRadius: "50%",
					borderColor: 'transparent',
					color: '#8e258c',
				},
				selectWordStyle: {
					padding: 0,
					borderBottom: 'solid 2px',
					borderColor: '#8e258c',
				},
				limitSelect: 8,
				listWords: [
					'flood_lights &ensp; football_pitch &ensp; main_road &ensp; mountain_range &ensp; safety_net &ensp; sea_shore &ensp; swimming_pool &ensp; tennis_court &ensp; tennis_player &ensp; tower_block',
					'flood_lights &ensp; football_pitch &ensp; main_road &ensp; mountain_range &ensp; safety_net &ensp; sea_shore &ensp; swimming_pool &ensp; tennis_court &ensp; tennis_player &ensp; tower_block',
					'flood_lights &ensp; football_pitch &ensp; main_road &ensp; mountain_range &ensp; safety_net &ensp; sea_shore &ensp; swimming_pool &ensp; tennis_court &ensp; tennis_player &ensp; tower_block',
				],
				answers: ['1-24', '2-8', '0-0', '0-20', '0-4', '0-28', '0-12', '0-32', '0-36', '0-16'],
				initialValue: [],
			},
			Layout: `
        <div style='display:flex'>
          <img style='height:85mm' src='img/FriendsPlus/Page29/E4/1.jpg'>
          <div> Which type has the most examples?<br><input id='0'></input></div>
        </div>
        <div style='width:80%;text-align:center'>
            <b>TYPE 1</b>&emsp; <input id='0' type='Circle' /><br>
            <div style='margin:20px 0px'><b>TYPE 2</b>&emsp; <input id='1' type='Circle' /><br></div>
            <b>TYPE 3</b>&emsp; <input id='2' type='Circle' /><br>
        </div>
        
        
      `,
		},
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P29-E5',
		audio: '',
		video: '',
		component: T6,

		// maxLength: 1,
		borderBottom: 'dash',
		// inputSize: 60,
		textareaStyle: { width: 700 },
		inputSize: 100,
		// isHiddenCheck: true,
		// maxLength: 1,
		hintBox: [
			{
				src: [
					'<b>A</b>',
					'athletics',
					'basketball',
					'bowling',
					'boxing',
					'climbing',
					'dance',
					'golf',
					'ice',
					'weights',
				],
				width: 990,
				borderColor: 'none',
				fontColor: '#8e258c',
			},
			{
				src: [`<b>B</b>`, 'alley', 'course', 'court', 'ring', 'rink', 'room', 'studio', 'track', 'wall'],
				width: 800,
				borderColor: 'none',
				fontColor: '#8e258c',
			},
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 950,
				borderColor: 'none',
				fontColor: '#8e258c',
			},
		],
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page29/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `Work in pairs. Match the words in A and B to form sports
venues, using a dictionary to help you. Find three more
sports venues in exercise 2.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <hintbox id='2'></hintbox>
      <div style='margin:10px 0px'>
       <hintbox id='0'></hintbox>
      </div>
       <hintbox id='1'></hintbox>
     
      <div style='display:flex;margin:15px 0px; line-height:45px'>
        <div>
          athletics#<br>
          basketball#<br>
          bowling#
        </div>
        <div style='margin:0px 100px'>
          boxing#<br>
          climbing#<br>
          dance#
        </div>
        <div>
          golf#<br>
          ice#<br>
          weights#
        </div>
      </div>  
      <div style=''>
        Find three more sports venues in exercise 2:<br>
        <input id='0' width='200px'></input>,
        <input id='1' width='200px'></input>,
        <input id='2' width='200px'></input>
      </div>
        
          `,
				answer: [
					'track / court / alley / ring / wall / studio / course / rink / room',
					'track / court / alley / ring / wall / studio / course / rink / room',
					'track / court / alley / ring / wall / studio / course / rink / room',
					'track / court / alley / ring / wall / studio / course / rink / room',
					'track / court / alley / ring / wall / studio / course / rink / room',
					'track / court / alley / ring / wall / studio / course / rink / room',
					'track / court / alley / ring / wall / studio / course / rink / room',
					'track / court / alley / ring / wall / studio / course / rink / room',
					'track / court / alley / ring / wall / studio / course / rink / room',
					'football pitch / swimming pool / tennis court',
					'football pitch / swimming pool / tennis court',
					'football pitch / swimming pool / tennis court',
				],
			},
		],
	},
	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P29-E6',
		audio: '',
		video: '',
		component: T6,
		borderBottom: 'dash',
		textareaStyle: { width: 700 },
		inputSize: 200,
		// isHiddenCheck: true,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page29/Key/E6answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title: `Work in pairs. Read the <span style='color:#8e258c'>Learn this!</span> box.
        Decide which sports venues from exercises 2 and 5 you
        are likely to describe with these compound adjectives.`,
				color: '#8e258c',
				prefix: { icon: [''], text: 'VOCABULARY' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 600,
				borderColor: 'none',
				fontColor: '#8e258c',
			},
			{
				src: [
					'<b>Compound adjectives</b>',
					'25-metre',
					'400-metre',
					'air-conditioned',
					'brightly lit',
					'eight-lane',
					'full-sized',
					'open-air',
					'solar-heated',
					'soundproof',
					'well-equipped',
				],
				width: 600,
				borderColor: 'none',
				fontColor: '#8e258c',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style='position: relative'>
            <div >
              <hintbox id='0'></hintbox>
              <hintbox id='1'></hintbox>
            </div>
            <img style='position: absolute; top: 15px; left: 562px' src='img/FriendsPlus/Page29/E6/1.jpg'>
        </div>
        <div>
          25-metre:&ensp;#<br>
          400-metre:&ensp;#<br>
          air-conditioned:&ensp;#,#,#,#<br>
          brightly lit:&ensp;#,#,#,#,#,#,#<br>
          eight-lane:&ensp;#<br>
          fule-size:&ensp;#<br>
          open-air:&ensp;#,#<br>
          solar-heated:&ensp;#<br>
          sound-proof:&ensp;#<br>
          well-equipped:&ensp;#,#,#<br>
          
        </div>
          `,
				answer: [
					'swimming pool',
					'athletics track',
					'basketball court / bowling alley / dance studio / weights room',
					'basketball court / bowling alley / dance studio / weights room',
					'basketball court / bowling alley / dance studio / weights room',
					'basketball court / bowling alley / dance studio / weights room',
					'athletics track / basketball court / boxing ring / dance studio / climbing wall / football pitch / ice rink',
					'athletics track / basketball court / boxing ring / dance studio / climbing wall / football pitch / ice rink',
					'athletics track / basketball court / boxing ring / dance studio / climbing wall / football pitch / ice rink',
					'athletics track / basketball court / boxing ring / dance studio / climbing wall / football pitch / ice rink',
					'athletics track / basketball court / boxing ring / dance studio / climbing wall / football pitch / ice rink',
					'athletics track / basketball court / boxing ring / dance studio / climbing wall / football pitch / ice rink',
					'athletics track / basketball court / boxing ring / dance studio / climbing wall / football pitch / ice rink',
					'swimming pool',
					'swimming pool',
					'athletics track / basketball court',
					'athletics track / basketball court',
					'swimming pool',
					'dance studio',
					'bowling alley / dance studio / weights room',
					'bowling alley / dance studio / weights room',
					'bowling alley / dance studio / weights room',
				],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P29-E7',
		audio: 'Audios/1.25-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page29/Key/E7answerKey.png',
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 23,
			height: 22,
			// textTransform: 'uppercase',
			width: 100,
		},
		titleQuestion: [
			{
				num: '7',
				title: `<headphone1 src='Audios/1.25-tieude.mp3' name='1.25'></headphone1> Listen to four students arguing in favour of a
        new facility for their school. Match the facilities below(a–d) with the speakers (1–4). `,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 40,
		textAlign: 'center',
		questionImage: [],
		questions: [
			{
				title: `
           <div style='line-height:50px'>
            <b>a</b>&ensp;A state-of-the-art recording studioS<br>
            <b>b</b>&ensp;A well-equipped art and design studioS<br>
            <b>c</b>&ensp;A high-speed Wi-Fi networkS<br>
            <b>d</b>&ensp;An all-weather football pitchS<br>
           </div>
           <div style='margin-top: 50px; display:flex; flex-direction: column; '>
              <b>ANSWER:</b>
              &ensp;<b>a - #</b>
              &ensp;<b>b - #</b>
              &ensp;<b>c - #</b>
              &ensp;<b>d - #</b>
            </div>
          `,
				answer: ['4', '3', '1', '2'],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P29-E8',
		hideBtnFooter: true,
		audio: '',
		video: '',
		component: UI,
		// recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '8',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
        would you like most for your school? Why? Can the whole
        class agree on one choice?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page29/E8/1.pNg' }]],
	},
}

export default json
