import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page68/Key/E1answerKey.png',
    component: T6,
    inputSize: 600,
    // maxLength: 60,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Look at photo A of students using their  tablets in a lesson. Speculate about the questions below.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				 <div style=" max-width: 700px; color: rgb(0, 147, 69); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <b>School subjects</b><span>art</span><span>design and technology</span>
          <span>drama</span><span>English</span><span>geography</span><span>history</span><span>I.C.T. (information and communication technology) maths</span>
          <span>music</span><span>P.E. (physical education) </span><span science </span><span>science</span>
        </div>
          <div style="margin-top: 20px; display: flex; justify-content: center; align-items: center;">
					<div style='flex:1'>
					            <img src="img/FriendsPlus/Page68/E1/1.jpg" style="max-width: 100%;" />

					</div>
					<div style='flex:1; margin-left:50px'>
						<b>1</b>&ensp;What subject is it? (Choose from the list below.) <br> 
						# <br>
						<b>2</b>&ensp;What are they doing with their tablets? <br>
						# <br>
					 <img src="img/FriendsPlus/Page68/E1/2.jpg" style="max-width: 80%; margin-top:50px" />


					
					</div>
          </div>
					
				`,
        answer: [
          `science`,
          'The may be recording some results./The might be recording some results./The could be recording some results.',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E2',
    audio: 'Audios/2.22-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page68/E2/Key/answerKey.png',
    component: T6,
    inputSize: 65,
    maxLength: 5,
    textareaStyle: { width: 700 },
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
    },
    // recorder: true,
    hideBtnFooter: true,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '2',
        title: `
          <headphone1 name="2.22" src="Audios/2.22-tieude.mp3"></headphone1> Listen to a student describing photo A. Does she agree with your ideas from exercise 
       
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-top: 20px; justify-content: center; align-items: center;">
					<div style='flex:1'>
					            <img src="img/FriendsPlus/Page68/E1/1.jpg" style="max-width: 80%;" />
											<textarea id='0' rows='1'></textarea>

					</div>
				`,
        answer: [],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E3',
    audio: 'Audios/2.22-audio.mp3',
    video: '',
    component: T6v2,
    exerciseKey: 'img/FriendsPlus/Page68/Key/E3answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
          <headphone1 name='2.22' src='Audios/2.22-tieude.mp3' ></headphone1><span style='color:rgb(124, 8, 79);'> KEY PHRASES </span>  Listen again. Which of the phrases below does the candidate use for speculating?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    // hideBtnFooter:true,
    inputSize: 450,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'left',

    // checkDuplicated: true,

    questions: [
      {
        title: `
       <div>
        <div style=' border-left: 5px solid rgb(192, 232, 198);width:100%'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(2, 146, 97);'>Speculating</div>
          <div>It looks like …</div>
          <div>They look / don’t look (to me) as if / as though they’re … </div>
          <divThey look / don’t look (to me) as if / as though they’re … </div>
          <div>They look / don’t look like they’re (+ -ing) …  </div>
          <div>They seem quite (+ adj) … </div>
          <div>They don’t look / don’t seem very (+ adj) …</div>          
          </div>
        </div>
       </div>
			 <div>Which of the phrases below does the candidate use for speculating? <br> #</div>
       
       
        

			  `,
        answer: [`It looks like … `],
      },
    ],
  },
  4: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E4',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page68/E4/Key/answerKey.png',
    component: T6,
    inputSize: 300,
    maxLength: 27,
    hideBtnFooter: true,
    recorder: true,
    //hideBtnFooter: true,
    // isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '4',
        title: `
					<span style='color:rgb(0, 147, 69);'> SPEAKING </span> Work in pairs. Take turns to describe photo B. Include some speculation in your description.
  			`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				 <div style="margin-top: 20px; justify-content: center; align-items: center;">
					<div>
					            <img src="img/FriendsPlus/Page68/E2/1.jpg" style="max-width: 100%;" />

					</div>
				
				`,
        answer: [``],
      },
    ],
  },
  5: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E5',
    audio: 'Audios/2.23-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page68/	Key/E5answerKey.png',
    component: T6,
    inputSize: 540,
    // maxLength: 1,
    titleQuestion: [
      {
        num: '5',
        title: `
					<headphone1 name="2.23" src="Audios/2.23-tieude.mp3"></headphone1> 
					Read the task above. Listen to a student doing the task. Which of the three points does he forget to mention?

				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style=" padding: 20px; background-color: rgb(222, 239, 225);">
              <div>
                These photos (A and B) show students in lessons. Compare and contrast the photos. Include the following points.
              </div>
              <div>
							<span class="black-dot-list" style='list-style-type: disc;display: inline;margin: 0;padding-left: 10px;'>
							<li>Typical school subjects at primary level </li>
							<li> A typical classroom in a primary school </li>
							<li>Why it is important for students to enjoy lessons </li></span></div>

            </div>
						<div style ='margin-top:10px'>Which of the three points does he forget to mention? <br> #</div>
				`,
        answer: [`Typical school subjects at primary level.`],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E6',
    audio: 'Audios/2.23-audio.mp3',
    video: '',
    component: T6v2,
    exerciseKey: 'img/FriendsPlus/Page68/key/E6answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
          <headphone1 name='2.23' src='Audios/2.23-tieude.mp3' ></headphone1><span style='color:rgb(124, 8, 79);'> KEY PHRASES </span>Read the<span style='color:rgb(0, 147, 69);'> Speaking Strategy.</span> Then listen again. What is the missing word in each of the phrases below?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    inputSize: 250,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    // checkDuplicated: true,

    questions: [
      {
        title: `
			 <img src="img/FriendsPlus/Page68/E6/1.jpg" style="max-width: 45%;" />

        <div style=' border-left: 5px solid rgb(192, 232, 198);width:100%'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(2, 146, 97);'>Comparing photos</b></div>
          <div>The common theme in the photos is <sup>1</sup>#. </div>
          <div>Both photos show a <sup>2</sup># of some kind. </div>
          <div>In the first photo, the students are outside, whereas in</div>
          <div>the second photo they’re in a <sup>3</sup> # .  </div>
          <div>Unlike the second photo, the first photo does not show </div>
          <div>the <sup>4</sup>#. </u></div>
          <div>In the second photo, they’re listening to the teacher </u></div>
          <div>rather than looking at <sup>5</sup>#. </div>
          </div>
        </div>
       
      
        

			  `,
        answer: ['education', ' lesson ', ' classroom', ' teacher', 'tablets'],
      },
    ],
  },
  7: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E7',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page68/Key/E1answerKey.png',
    component: T6v2,
    inputSize: 600,
    hideBtnFooter: true,
    recorder: true,
    // maxLength: 60,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '7',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
Work in pairs. Discuss the question below. Use evidence from the photo A.         `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<div><i>Do you think it is possible to learn without a teacher?</i></div>
			 <img src="img/FriendsPlus/Page68/E1/1.jpg" style="max-width: 70%; margin-top:10px" />

					
				`,
        answer: [``],
      },
    ],
  },
  8: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E8',
    audio: 'Audios/2.24-audio.mp3',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page68/	Key/E5answerKey.png',
    component: T6,
    inputSize: 540,
    textareaStyle: { width: 700 },
    hideBtnFooter: true,
    // maxLength: 1,
    titleQuestion: [
      {
        num: '8',
        title: `
					<headphone1 name="2.24" src="Audios/2.24-tieude.mp3"></headphone1> 
					Now listen to the student answering the question from exercise 7. Does he agree or disagree with your opinions? Does he mention any of the same evidence?

				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<div>
					<i>Answer:</i>
				<textarea id='0' rows='4'></textarea>
				
				</div>
				`,
        answer: [``],
      },
    ],
  },

  9: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P68-E9',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page68/Key/E9answerKey.png',
    component: T6v2,
    inputSize: 600,
    hideBtnFooter: true,
    // maxLength: 60,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '9',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
Work in pairs. Discuss the question below. Use evidence from the photo B.         `,
        color: '#860052',
      },
    ],
    textareaStyle: { width: 500 },

    questions: [
      {
        title: `
			 <div style='display:flex'>
			 <div style='flex:1'>
			 <img src="img/FriendsPlus/Page68/E2/1.jpg" style="max-width: 100%; margin-top:10px" />

			 
			 </div>
			 <div style='flex:1;margin-left:10px'>
									<div><i>Do you think it is possible to learn without a teacher?</i></div>
									<textarea id='0' rows ='12'></textarea>

			 </div>
			 </div>

					
				`,
        answer: [` `],
      },
    ],
  },
};

export default json;
