import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P94-E1',
    // exerciseKey: 'img/FriendsPlus/Page92/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 130,
    hideBtnFooter: true,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
        <div style='margin-bottom: 10px;font-size: 23px'><span style='color: rgb(45,206,137)'>SPEAKING </span> 
        Look at the photos. Do you know what this art form is? Did you use to have any toy of this kind?
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=' position: relative'>
         <img style="width:14cm; height:9cm" src='img/FriendsPlus/Page94/E1/1.jpg' />
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P94-E2',
    exerciseKey: 'img/FriendsPlus/Page94/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 150,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
        <div style='font-size: 23px'>
        Read the text. Match the headings with the paragraphs A–C.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=' position: relative'>
         <img style="width:28cm; height:15cm" src='img/FriendsPlus/Page94/E2/1.jpg' />
         <div style='font-size:22px; display:flex; flex-direction: column '>
              <b>ANSWER:</b>
              <div>- The artist &ensp;&ensp; - Paragraph  #</div>
              <div>- The process   - Paragraph #</div>
              <div>- The tradition - Paragraph #</div>
          </div>
        </div>
          
        `,
        answer: ['B', 'A', 'C'],
      },
    ],
  },

  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P94-E3',
    exerciseKey: 'img/FriendsPlus/Page94/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 100,
    maxLength: 1,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style='font-size: 23px'>
        Read the text again and write True or False to the statements 1–5.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=' position: relative'>
         <img style="width:29cm; height:15cm" src='img/FriendsPlus/Page94/E2/1.jpg' />
         <div style='font-size:22px; display:flex; flex-direction: column '>
              <div><i style='font-size:18px; color: rgb(152,198,204)'>*Answer: T/F</i></div>
              <div>#&ensp;<b>1. </b><i>To he</i> is creatively produced from a combination of flour and colour</div>
              <div>#&ensp;<b>2. </b>Sticky rice can make better dough for <i>to he</i> than rice.</div>
              <div>#&ensp;<b>3. </b><i>To he</i> products are mainly sold at village fairs.</div>
              <div>#&ensp;<b>4. </b>Villagers can use <i>to he</i> as decorators.</div>
              <div>#&ensp;<b>5. </b><i>To he</i> is created as toys but it is edible. </div>
              
          </div>
        </div>
          
        `,
        answer: ['T', 'F', 'F', 'T', 'T'],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P94-E4',
    exerciseKey: 'img/FriendsPlus/Page94/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    audio: '/Audios/3.10-audio.mp3',
    component: T6,
    inputSize: 100,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
        <div style='font-size: 23px'><headphone1 name='3.10' src='Audios/3.10.mp3'></headphone1>
        Put the following sentences A–C into the correct gaps 1–3. Then listen and check.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='font-size:22px'>
         <img style="width:29cm; height:15cm" src='img/FriendsPlus/Page94/E2/1.jpg' />
         <div style='margin-top:15px'>
         <div><b>A </b>It is for passion, not for money that the artists work. </div>
         <div><b>B </b>The eyes of a monkey, for example, can be made by adding two black peas.</div>
         <div><b>C </b>Despite the popularity of some modern forms of entertainment, children are still attracted to <i>to he</i>.</div>
         </div>
         <div style='font-size:22px; display:flex; flex-direction: row; margin-top:15px'>
              <b>ANSWER:&ensp;&ensp;</b>
              <div><b>1 -</b> #&ensp;&ensp;</div>
              <div><b>2 -</b> #&ensp;&ensp;</div>
              <div><b>3 -</b> #&ensp;&ensp;</div>
          </div>
        </div>
          
        `,
        answer: ['B', 'A', 'C'],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P94-E5',
    exerciseKey: 'img/FriendsPlus/Page94/E5/Key/E5answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/3.10.mp3',
    component: T6,
    inputSize: 200,
    maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      textTransform: 'lowercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
        <div style='font-size: 23px'>
        Match the highlighted words in the text with the following words or phrases.
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='font-size:22px'>
         <img style="width:26cm; height:12cm" src='img/FriendsPlus/Page94/E2/1.jpg' />
         <div style='font-size:22px; margin-top:15px'>
              <div>- limited to an amount: #</div>
              <div>- exactly like a real thing: #</div>
              <div>- a mixture of flour and water: #</div>
              <div>- showing strong feeling of love: #</div>
              <div>- unbelievable: #</div>
          </div>
        </div>
          
        `,
        answer: ['restricted', 'lifelike', 'dough', 'passionately', 'extraordinary'],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P94-E6',
    // exerciseKey: 'img/FriendsPlus/Page92/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 130,
    maxLength: 20,
    textAlign: 'center',
    hideBtnFooter: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <div style='margin-bottom: 10px;font-size: 25px'><span style='color: rgb(45,206,137)'>SPEAKING </span> 
        Work in groups. Discuss the question. 
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=' position: relative'>
        <i>What are the benefits which chidren’s traditional games like to he can bring about?</i>
         <img style="width:13cm; height:9cm" src='img/FriendsPlus/Page94/E1/1.jpg' />
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
