import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P102-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/Key/E1answerKey.png',
		component: T6,
		inputSize: 900,
		maxLength: 900,
		////hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 500, fontSize: 20 },
		titleQuestion: [
			{
				num: '1',
				title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b>
				Are you good at learning new technologies?
				Do you know what <i style='color:rgb(33,164,194);'>3G, 4G, app, IoT</i> stand for?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>#</div>
				`,
				answer: ['third generation, fourth generation, application, the internet of things'],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P102-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/Key/E2answerKey.png',
		component: T6,
		inputSize: 50,
		maxLength: 20,
		////hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 500, fontSize: 20 },
		titleQuestion: [
			{
				num: '2',
				title: `
				Look at the pictures and match them with the ideas below.
				Look at the title of the text. Which of the ideas of future
				cities do you think are mentioned in the text?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<img style="padding-left:90px" src="/img/FriendsPlus/Page102/E2/1.jpg" >
				<div>- Sky cities will be built with solar-run buildings.#</div>
				<div>- Internet will control every human activity.#</div>
				<div>- People will work in mobile offices.#</div>
				<div>- Underground trips will become safer and faster than ever.#</div>
				`,
				answer: ['A', 'D', 'B', 'C'],
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P102-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/Key/E3answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 11,
		textareaStyle: { width: 400 },
		titleQuestion: [
			{
				num: '3',
				title: `
					Read the <span style="font-weight: bold; color: rgb(68, 64, 159);">Speaking Strategy</span>. 
					Match the facts A–E with the means of transport 1, 2 or 3. Which fact relates to all three?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display:flex;">
						<img style="padding-left:60px" src="/img/FriendsPlus/Page102/E3/1.jpg" >
						<div style="padding-top:10px">
							<div style=" padding: 2px; margin-left: 30px;  border-radius: 10px; border: 1px solid rgb(0, 147, 69); box-shadow: 5px 3px 4px rgba(0,0,0,0.4);">
								<b>1</b> Air taxis #<br>
								<b>2</b> Undergrounds #<br>
								<b>3</b> Cars #<br>
								<textarea id='3' rows='2'></textarea>
							</div>
						</div>
					</div>
					<div style="padding-left:100px">
						<b>A</b> They can be a good solution to traffic congestion.<br>
						<b>B</b> They stop using routine fuel.<br>
						<b>C</b> They travel on three-dimensional roads with great speed.<br>
						<b>D</b> They can be autonomously controlled.<br>
						<b>E</b> They provide intercity trips in extremely short time.<br>
					</div>
				`,
				answer: [
					"B E D",
					"A C D",
					"D",
					"The idea D can match three means of transport."
				],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P102-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/Key/E4answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 14,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 22,
		},

		titleQuestion: [
			{
				num: '4',
				title: `
				Match the highlighted words in the article with their equivalents.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div>
					<img style="padding-left:60px;width:120%;" src="/img/FriendsPlus/Page102/E4/1.jpg" >
					<div><b>1 </b>reach #</div>
					<div><b>2 </b>a popular and well-accepted idea #</div>
					<div><b>3 </b>incredible #</div>
					<div><b>4 </b>good value #</div>
					<div><b>5 </b>warn #</div>
					<div><b>6 </b>introduction #</div>
				</div>
				`,
				answer: [
					'attain',
					'mainstream',
					'extraordinary',
					'cost-effective',
					'alert',
					'rollout',
				],
			},
		],
	},

	5: {
		unit: 'Science',
		id: 'FG10-S-P102-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/Key/E5answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 10,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 24,
			fontWeight: 'bold',
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
				Read the article again and complete the summary with one word for each blank.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div style=''> 
				<div style='width:50%; margin:auto;'>
							All of the highways and roadways in cities will become
							<b><sup>1</sup></b> # with loT sensors which will process data
							of weather, crashes, traffic conditions and send them 
							directly to users. Users will not have to drive. Cars as 
							well as other vehicles will go <b><sup>2</sup></b> # . The word 
							‘Driver’ will no longer be used. Traffic <b><sup>3</sup></b> # will 
							be solved by tunnels. A <b><sup>4</sup></b> # system will run 
							along 3D underground roads at high speed. Travelling 
							from one city to another will not take much time 
							thanks to air taxis. Green energy will be used for all 
							means of transport, and the first concern is how to 
							make effective <b><sup>5</sup></b> #.
					</div>				
					</div>
					
					
						
				`,
				answer: [
					'smarter', 'autonomous', 'congestion', 'lift', 'laws'
				],
			},
		],
	},

	6: {
		unit: 'Science',
		id: 'FG10-S-P102-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E6/Key/answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 9,
		recorder: true,
		hideBtnFooter: true,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
         <span style='color:green;'>SPEAKING</span> Work in pairs. Discuss the following questions
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style='line-height:2'>
					<ul>
					 <li><p>What do you think is the biggest problem when <br>building smart tunnels?</p></li>
					 <li><p>How many years may it take to put air taxis into <br>popular use?</p></li>
					</ul>
					
					
					</div>
				`,
				answer: [

				],
			},
		],
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P102-E7',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_20.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E7/Key/answerKey.png',
		component: T6,
		inputSize: 160,
		maxLength: 15,
		//hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
					<headphone1 name="3.20" src="-tieude.mp3"></headphone1> 
          <span style="color: rgb(0, 103, 179)">PRONUNCIATION</span> 
					Listen again and notice how the student pronounces the phrases in exercise 6. 
					Work in pairs and practise saying them.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="color: rgb(0, 147, 69);">
						<div>
							<span>broken</span>
							<span style="padding-left: 20px;">come</span>
							<span style="padding-left: 20px;">exchange</span>
							<span style="padding-left: 20px;">happy</span>
							<span style="padding-left: 20px;">manager</span>
						</div>
						<div>
							<span>money</span>
							<span style="padding-left: 20px;">problem</span>
							<span style="padding-left: 20px;">repair</span>
							<span style="padding-left: 20px;">something</span>
							<span style="padding-left: 20px;">stopped</span>
						</div>
						<div>
							<span>work</span>
							<span style="padding-left: 20px;">write</span>
						</div>
					</div>

					<div style="margin-top: 20px; padding-left: 10px; border-left: 5px solid rgb(5, 201, 97);">
						<div style="font-weight: bold; color: rgb(0, 147, 69);">Making a complaint</div>
						<div>There’s a <sup>1</sup>__________ with …</div>
						<div>There’s <sup>2</sup>__________ wrong with …</div>
						<div>It doesn’t <sup>3</sup>__________.</div>
						<div>It has <sup>4</sup>__________ working.</div>
						<div>It’s <sup>5</sup>__________ .</div>
						<div>The (dial) has <sup>6</sup>__________ off.</div>
						<div>Can I have my <sup>7</sup>__________ back, please?</div>
						<div>Can I <sup>8</sup>__________ it, please?</div>
						<div>Can you <sup>9</sup>__________ it?</div>
						<div>I’m not <sup>10</sup>__________ about that.</div>
						<div>Can I see the <sup>11</sup>__________ , please?</div>
						<div>I’m going to <sup>12</sup>__________ to (customer services).</div>
					</div>
				`,
				answer: ['invention', 'recordings', 'movement', 'arrangement', 'discovery', 'reconstructions'],
			},
		],
	},
	8: {
		unit: 'Science',
		id: 'FG10-S-P102-E8',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E8/Key/answerKey.png',
		component: MatchDots,
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(146, 39, 144)">KEY PHRASES</span> 
					Match the sentence halves.
			  `,
				color: '#860052',
			},
		],
		stylesTextInput: { borderBottom: '10px dashed', textAlign: 'center' },
		inputSize: 50,
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '55px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '96px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '137px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '174px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '215px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '253px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '292px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '332px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '55px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // a
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '96px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // b
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '137px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // c
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '174px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // d
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '215px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // e
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '253px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // f
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '292px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // g
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '332px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // h
				],
				answers: ['0-13', '1-15', '11-2', '3-8', '12-4', '10-5', '6-9', '14-7'],
				initialValue: [],
			},
			Layout: `
				<div style=" padding-left: 20px; border-left: 5px solid rgb(5, 201, 97);">
					<div style="font-weight: bold; color: rgb(0, 147, 69); font-size: 26px">Dealing with a complaint</div>
          <div style="display: flex; font-size: 26px;">
            <div>
                <div><span style="font-weight: bold; padding-right: 10px;">1</span>What’s wrong</div>
                <div><span style="font-weight: bold; padding-right: 10px;">2</span>When did you</div>
                <div><span style="font-weight: bold; padding-right: 10px;">3</span>Have you got</div>
                <div><span style="font-weight: bold; padding-right: 10px;">4</span>Would you like</div>
                <div><span style="font-weight: bold; padding-right: 10px;">5</span>We don’t give</div>
                <div><span style="font-weight: bold; padding-right: 10px;">6</span>I can give you</div>
                <div><span style="font-weight: bold; padding-right: 10px;">7</span>We can</div>
                <div><span style="font-weight: bold; padding-right: 10px;">8</span>There’s nothing</div>
            </div>    
            <div style="margin-left: 250px;">
              <div><span style="font-weight: bold; padding-right: 10px;">a</span>a refund?</div>
              <div><span style="font-weight: bold; padding-right: 10px;">b</span>repair it.</div>
              <div><span style="font-weight: bold; padding-right: 10px;">c</span>a credit note.</div>
              <div><span style="font-weight: bold; padding-right: 10px;">d</span>the receipt?</div>
              <div><span style="font-weight: bold; padding-right: 10px;">e</span>refunds.</div>
              <div><span style="font-weight: bold; padding-right: 10px;">f</span>with it?</div>
              <div><span style="font-weight: bold; padding-right: 10px;">g</span>I can do.</div>
              <div><span style="font-weight: bold; padding-right: 10px;">h</span>buy it?</div>
            </div>     
          </div>
				</div>
				<input id='0' type= 'boxMatch' />
				<input id='1' type= 'boxMatch' />
				<input id='2' type= 'boxMatch' />
				<input id='3' type= 'boxMatch' />
				<input id='4' type= 'boxMatch' />
				<input id='5' type= 'boxMatch' />
				<input id='6' type= 'boxMatch' />
				<input id='7' type= 'boxMatch' />
				<input id='8' type= 'boxMatch' />
				<input id='9' type= 'boxMatch' />
				<input id='10' type= 'boxMatch' />
				<input id='11' type= 'boxMatch' />
				<input id='12' type= 'boxMatch' />
				<input id='13' type= 'boxMatch' />
				<input id='14' type= 'boxMatch' />
				<input id='15' type= 'boxMatch' />
      `,
		},
	},
	9: {
		unit: 'Science',
		id: 'FG10-S-P102-E9',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 80,
		maxLength: 5,
		//hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

		titleQuestion: [
			{
				num: '9',
				title: `
					Work in pairs. Do the exam task in exercise 2. 
					Take turns to be the shop assistant and the customer. 
					Use words and phrases from this lesson to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				`,
				answer: [],
			},
		],
	},
};

export default json;
