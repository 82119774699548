import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P58-E1',
    exerciseKey: 'img/FriendsPlus/Page58/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.12.mp3',
    component: T6,
    inputSize: 850,
    // maxLength: 20,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 21,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '1',
        title: `
        <div style="margin-bottom: 10px;font-size: 23px;"><span style='color: rgb(99,185,139)'>SPEAKING  
				</span>Work in pairs. Discuss the questions.</div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
				<img style="width:14cm" src='img/FriendsPlus/Page58/E1/1.jpg' />
        <div style='width: 100%; font-size: 20px'>
				 
          <div><b>1 </b>Have you ever visited any castles or palaces in Viet Nam? Which are the most famous ones? Why?</div>
					<div> # </div>
					<div><b>2 </b> Do you recognise the palace in the photo?</div>
					<div> # </div> 
					<div>What do you know about it?</div>
					<div> # </div> 
        </div>
          
        `,
        answer: ['', 'Hue Citadel, the royal residence of the Nguyen Dynasty', ''],
      },
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P58-E2',
    video: '',
    // audio: 'Audios/2.12.mp3',
    component: T6,
    hideBtnFooter: true,
    inputSize: 850,
    // maxLength: 20,
    textAlign: 'left',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 21,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '2',
        title: `
        Cover the fact file. Discuss and try to guess the answers to the quiz about Hue Citadel.
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
				<div>
					<b>1</b>&ensp;When was Hue Citadel built?
					<div style='display:flex;margin-left:50px; justify-content: space-between;width:15cm'>
						<span><b>a</b>&ensp;17<sup>th</sup>c</span>
						<span><b>b</b>&ensp;Early 19<sup>th</sup>c</span>
						<span><b>c</b>&ensp;Late 18<sup>th</sup>c</span>
					</div>
				</div>
				<div>
					<b>2</b>&ensp;Which emperor started its construction?
					<div style='display:flex;margin-left:50px; justify-content: space-between;width:15cm'>
						<span><b>a</b>&ensp;Minh Mang</span>
						<span><b>b</b>&ensp;Gia Long</span>
						<span><b>c</b>&ensp;Bao Dai</span>
					</div>
				</div>
				<div>
					<b>3</b>&ensp;How many workers were involved in the building of Hue Citadel?
					<div style='display:flex;margin-left:50px; flex-direction:column'>
						<span><b>a</b>&ensp;More than 10,000</span>
						<span><b>b</b>&ensp;At least 100,000</span>
						<span><b>c</b>&ensp;Just under 1,000</span>
					</div>
				</div>
				<div>
					<b>4</b>&ensp;The height of the outer walls measures:
					<div style='display:flex;margin-left:50px; justify-content: space-between;width:15cm'>
						<span><b>a</b>&ensp;10 km</span>
						<span><b>b</b>&ensp;6.6 m</span>
						<span><b>c</b>&ensp;21.5 m</span>
					</div>
				</div>
				<div>
					<b>5</b>&ensp;How many gates does Hue Citadel have?
					<div style='display:flex;margin-left:50px; justify-content: space-between;width:15cm'>
						<span><b>a</b>&ensp;8</span>
						<span><b>b</b>&ensp;6</span>
						<span><b>c</b>&ensp;10</span>
					</div>
				</div>
				<div>
					<b>6</b>&ensp;What was the function of the Purple Forbidden City?
					<div style='display:flex;margin-left:50px; flex-direction:column'>
						<span><b>a</b>&ensp;The Emperor lived there</span>
						<span><b>b</b>&ensp;The Emperor and his family lived there</span>
						<span><b>c</b>&ensp;The Emperor’s family worshipped there</span>
					</div>
				</div>
				
          
        `,
        answer: [''],
      },
    ],
  },
  3: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P58-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page58/E3/Key/E3answerKey.png',
    component: Circle_Write,
    totalInput: 6,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        Read the fact file and check your answers to exercise 2. 
				What do you think is the most interesting or surprising fact?
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ['0-4', '1-4', '2-0', '3-4', '4-8', '5-4'],
        initialValue: [],
      },
      Layout: `
			<div>
			 <img src='img/FriendsPlus/Page58/E3/57.jpg' style='margin-left:200px' />
				<div>
					<b>1</b>&ensp;
           		 When was Hue Citadel built?<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
             	17<sup>th</sup>c<br>
              Early 19<sup>th</sup>c<br>
              Late 18<sup>th</sup>c<br>
            	</div>
            </div>

						<b>2</b>&ensp;
           		  Which emperor started its construction?<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
            	Minh Mang<br>
              Gia Long<br>
               Bao Dai
            	</div>
            </div>

						<b>3</b>&ensp;
           		  How many workers were involved in the building of Hue Citadel?<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
               More than 10,000<br>
               At least 100,000<br>
              	Just under 1,000
            	</div>
            </div>
				</div>
			
				<div>
					<b>4</b>&ensp;
           		  The height of the outer walls measures:<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
               10 km<br>
               6.6 m<br>
               21.5 m
            	</div>
            </div>

						<b>5</b>&ensp;
           		  The height of the outer walls measures:<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='4' type='Circle'/></div>
		          <div style='margin-left:20px'>
               8<br>
               6<br>
               10
            	</div>
            </div>


						<b>6</b>&ensp;
           		 What was the function of the Purple Forbidden City?<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='5' type='Circle'/></div>
		          <div style='margin-left:20px'>
               The Emperor lived there<br>
               The Emperor and his family lived there<br>
               The Emperor’s family worshipped there
            	</div>
            </div>


				</div>
			
			</div> 
      `,
    },
  },
  4: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P58-E4',
    exerciseKey: 'img/FriendsPlus/Page58/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    audio: 'Audios/2.14-audio.mp3',
    component: T6,
    inputSize: 300,
    isHiddenCheck: true,
    // maxLength: 20,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      borderBottom: 'none',
      fontSize: 23,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '4',
        title: `
        <div style="margin-bottom: 10px;font-size: 23px;"><headphone1 name="2.14" src="Audios/2.14-tieude.mp3"></headphone1>
        Listen to a radio programme. Complete the 
				information about the Queen’s residences. Use the places 
				below in the first column</div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
				<div style='position: relative'>
            <div style='display:flex; color: rgb(94,114,228)'>
              <div style='margin-right: 20px'>
                 <div>Belfast, Northern Ireland</div>
                 <div>the Highlands, Scotland</div>
                 <div>near London, England</div>
              </div>
              <div>
                 <div>Edinburgh, Scotland</div>
                 <div>London, England</div>
                 <div>Norfolk, England</div>
               </div>
            </div>
					<img style="width:21cm; height: 8cm" src='img/FriendsPlus/Page58/E4/1.jpg' />
					
          <div style='position:absolute; top:170px ;left:203px '><input id='0'</input></div>
					<div style='position:absolute; top:170px ;left:500px '><input id='1'></input></div>

					<div style='position:absolute; top:211px ;left:203px '><input id='2'></input></div>
					<div style='position:absolute; top:211px ;left:500px '><input id='3'></input></div>

					<div style='position:absolute; top:250px ;left:203px '><input id='4'></input></div>
					<div style='position:absolute; top:250px ;left:500px '><input id='5'></input></div>

					<div style='position:absolute; top:290px ;left:203px '><input id='6'></input></div>
					<div style='position:absolute; top:290px ;left:500px '><input id='7'></input></div>

					<div style='position:absolute; top:330px ;left:203px '><input id='8'></input></div>
					<div style='position:absolute; top:330px ;left:500px '><input id='9'></input></div>

					<div style='position:absolute; top:370px ;left:203px '><input id='10'></input></div>
					<div style='position:absolute; top:370px ;left:500px '><input id='11'></input></div>

				</div>
  
        `,
        answer: [
          'London, England',
          'state-owned',
          'near London, England',
          'state-owned',
          'Norfolk, England',
          'private',
          'the Highlands, Scotland',
          'private',
          'Edinburgh, Scotland',
          'state-owned',
          'Belfast, Northern Ireland',
          'state-owned',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P58-E5',
    // exerciseKey: 'img/FriendsPlus/Page58/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',
    hideBtnFooter: true,
    component: T6,
    inputSize: 850,
    // maxLength: 20,
    textAlign: 'left',
    textareaStyle: {
      // background: 'none',
      width: 700,
      fontSize: 20,
      height: 340,
    },
    titleQuestion: [
      {
        num: '5',
        title: `
        <div style="margin-bottom: 10px;font-size: 23px;"><span style='color: rgb(99,185,139)'>INTERNET RESEARCH  
				</span>
        Work in groups. Find out about Imperial Citadel of Thang Long, Ha Noi. Present the fact file to the class.</div>  
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
				<img style="width:14cm" src='img/FriendsPlus/Page58/E5/1.jpg' />
        <textarea id='0' row='20'></textarea>
          
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
