import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'unit 5',
    id: 'FGF11-A-P62-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page62/E1/Key/answerKey.png',
    component: T6,
    recorder: true,
    inputSize: 530,
    maxLength: 49,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(45,206,137)">SPEAKING</span> Look at the photo and the title of the article. What do you think all the man’s wearable gadgets are for? 
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					
						<img style="margin-left:150px;" src="img/FriendsPlus/Page62/E1/1.jpg" />
					
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'unit 5',
    id: 'FGF11-A-P62-E2',
    audio: '',
    video: '',
    inputSize: 1000,
    exerciseKey: 'img/FriendsPlus/Page62/Key/E2answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: `
					 Read the article and check your ideas.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					
						<img style="margin-left:150px;" src="img/FriendsPlus/Page62/E1/1.jpg" />
							#
					
        `,
        answer: ['The gadgets monitor data about his lifestyle.'],
      },
    ],
  },
  3: {
    unit: 'unit 5',
    id: 'FGF11-A-P62-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page62/Key/E3answerKey.png',
    component: T6,
    inputSize: 720,
    maxLength: 7,
    textAlign: 'left',
    // //hideBtnFooter: true,
    isHiddenCheck: true,
    stylesTextInput: {
      fontSize: 22,
    },
    titleQuestion: [
      {
        num: '3',
        title: `
				 Study the highlighted quantifiers and the type of noun 
				 they go with in the article. Then read the
					<span style="color: rgb(246, 141, 31);">Learn this!</span> box. 
					Complete the table with the quantifiers below
        `,
        color: '#860052',
      },
    ],
    hintBox: [
      {
        src: ['a few,', 'a little,', 'all,', 'any,', 'any,', 'both,', 'each,', 'every,', 'many,', 'no,', 'some,'],
        width: 700,
        borderColor: 'black',
        fontColor: 'rgb(246, 141, 31)',
        justifyContent: 'left',
      },
    ],
    questions: [
      {
        title: `
					<hintBox id=0></hintBox>
					
			<div style=" position: relative; ">
      <div style="margin-left:150px;"><img src="img/FriendsPlus/Page62/E1/3.jpg" /></div>
        <div style="  position: absolute; top: 101px; left: 244px; width: 92px;"><input id='0' font-size='27px' width='92px' border-bottom='0px'></input></div>
    		<div style="  position: absolute; top: 101px; left: 349px; width: 92px "><input id='1' font-size='27px' width='92px' border-bottom='0px'></input></div>
    		<div style="  position: absolute; top: 237px; left: 240px; width: 92px; "><input id='2' font-size='27px' width='92px' border-bottom='0px'></input></div>
        <div style="  position: absolute; top: 237px; left: 352px; width: 92px;"><input id='3' font-size='27px' width='92px' border-bottom='0px'></input></div>
        <div style="  position: absolute; top: 237px; left: 457px; width: 92px; "><input id='4' font-size='27px' width='92px' border-bottom='0px'></input></div>
        <div style="  position: absolute; top: 237px; left: 557px; width: 92px "><input id='5' font-size='27px' width='92px' border-bottom='0px'></input></div>
        <div style="  position: absolute; top: 290px; left: 477px; width: 92px; "><input id='6' font-size='27px' width='92px' border-bottom='0px'></input></div>
				<div style="  position: absolute; top: 290px; left: 590px; width: 92px; "><input id='7' font-size='27px' width='92px' border-bottom='0px'></input></div>
				<div style="  position: absolute; top: 333px; left: 248px; width: 92px; "><input id='8' font-size='27px' width='92px' border-bottom='0px'></input></div>
				<div style="  position: absolute; top: 333px; left: 354px; width: 92px; "><input id='9' font-size='27px' width='92px' border-bottom='0px'></input></div>
				<div style="  position: absolute; top: 333px; left: 465px; width: 92px; "><input id='10' font-size='27px' width='92px' border-bottom='0px'></input></div>
      </div>

        `,
        answer: ['each', 'every', 'a little', 'all', 'any', 'no', 'a few', 'any', 'both', 'many', 'some'],
      },
    ],
  },
  4: {
    unit: 'Unit 5',
    id: 'FGF11-U5-P62-E4',
    exerciseKey: 'img/FriendsPlus/Page62/E4/Key/E4answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // audio: 'Audios/2.12.mp3',

    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
        <div style="margin-bottom: 10px;">Read the <span style='color: rgb(246,169,89)'>Look out!</span> 
				 box and underline three examples of <i>of</i> with the quantifiers in the article</div>
        
			  `,
        color: 'rgb(146,39,143)',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 149, fontSize: 20, borderBottom: 'none', textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 14,
        listWords: [
          `Chris_Dancy_loves_technology. A_few_years_ago,_he_noticed_that_he_had_very_little_information_about_his_habits,_health_and_Lifestyle,_and_he_wanted_to_lose_some_weight. So_he_bought_some_gadgets_that_could_collect_this_information_and_help_him_understand_himself_better. Now_he_is_connected_to_700_sensors_that_record_every_detail_of_his_life. He_monitors_what_he_eats_and_drinks,_how_long_he_sleeps,_how_much_he_spends,_his_mood..._even_the_activity_of_his_dogs! Few,_if_any,_people_collect_as_much_data_about_themselves_as_Chris.<br> Most_of_his_gadgets_are_attached_to_him. He_wears_gadgets_on_both_arms,_a_heart_rate_monitor_and_a_device_that_counts_calories. He_has_a_smartwatch_too,_which_he_can_wear_on_either_wrist. His_house_and_car_are_also_full_of_gadgets. Every_one_of_them_sends_data_to_his_computer,_and_at_the_end_of_each_day_Chris_spends_a_little_time_analysing_it. The_smartwatch_is_the_only_gadget_he_wears_all_the_time. He_doesn't_wear_all_of_his_gadgets_24/7,_but_he_has_so_many_systems_that_automatically_track_his_activity_that_there_aren't_any_days_when_there_is_no_monitoring_at_all.`, //0
        ],
        answers: ['0-12', '0-20', '0-24'],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative; font-size:20px'>
          <img style='height:' src='img/FriendsPlus/Page62/E4/1.jpg' />
          <img style='height:587px; width: 850px; margin-left: 34px' src='img/FriendsPlus/Page62/E4/2.jpg' />
          <div style=" position: absolute; top: 347px; left: 84px; width:20cm "><input id='0'  type='Circle' /></div>
          
        </div> 
      `,
    },
  },
  5: {
    unit: 'Ambition',
    id: 'FG10-A-P62-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page62/Key/E5answerKey.png',
    component: T6,
    inputSize: 970,
    maxLength: 92,
    titleQuestion: [
      {
        num: '5',
        title: `
					Study the sentences below. What is the difference 
between few and a few, little and a little?
				`,
        color: '#860052',
      },
    ],
    textareaStyle: {
      width: 1000,
    },
    questions: [
      {
        title: `
					<div style=" display: flex; flex-direction:column">

				
						<div ><strong>1 a</strong> I've got few high-tech gadgets.I want more!</div>
						<textarea id="0"></textarea>
						<div style="margin-left:20px" ><strong>b</strong>   I've got a few high-tech gadgets.I love them!</div>
						<textarea id="1"></textarea>
						<div ><strong>2 a</strong> Unfortunately, I have little time for computer games.</div>
						<textarea id="2"></textarea>
						<div style="margin-left:20px" ><strong>b</strong>  I have a little time. Let’s play a computer game.</div>
						<textarea id="3"></textarea>
						
					</div>
					
        `,
        answer: [
          "This means 'I've got only a very small number of high - tech gadgets.'",
          "This means 'I've got a number of high - tech gadgets.'",
          `This means "I have a very limited amount of time." / This means "I don't have enough time for computer games."`,
          `This means "I have some time."`,
        ],
      },
    ],
  },
  6: {
    unit: 'unit 5',
    id: 'FGF11-A-P62-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page62/Key/E6answerKey.png',
    component: T6,
    inputSize: 100,
    maxLength: 209,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 25,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '6',
        title: `
					Complete the sentences with the quantifiers below. 
				`,
        color: '#860052',
      },
    ],
    hintBox: [
      {
        src: ['any,', 'both,', 'few,', 'little,', 'most,', 'none,', 'some,'],
        width: 600,
        borderColor: 'black',
        fontColor: 'rgb(246, 141, 31)',
        justifyContent: 'left',
      },
    ],
    questions: [
      {
        title: `
				<hintBox id= 0></hintBox>
			<div style="margin-top:20px; line-height: 50px" >
						<div>
						 	<b style="margin:10px">1</b> 
							<span> Unfortunately, there are # tablets at school, so we don’t often use them. </span>
						</div>
						<div>
						 	<b style="margin:10px">2</b> 
							<span> # of the students use social media. That’s how they stay in touch with one another. </span>
						</div>
						<div>
						 	<b style="margin:10px">3</b> 
							<span> I downloaded # music from Amazon last night. </span>
						</div>
						<div>
						 	<b style="margin:10px">4</b> 
							<span> There aren’t # interactive whiteboards in our school. </span>
						</div>
						<div>
						 	<b style="margin:10px">5</b> 
							<span> You need to hold the games console controller with # hands. </span>
						</div>
						<div>
						 	<b style="margin:10px">6</b> 
							<span> # of my friends know the answer. I’ll have to ask a teacher. </span>
						</div>
						<div>
						 	<b style="margin:10px">7</b> 
							<span> Jason’s always on his computer. He spends # time on other hobbies. </span>
						</div>
				</div>
		    `,
        answer: [`few`, `Most`, `some`, `any`, `both`, `None`, `little`],
      },
    ],

    // questions: [
    // 	// For image capturing purpose only
    // 	{
    // 		title: `
    // 			<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    // 				<div style="margin-left: 20px; display: flex; justify-content: center; align-items: center;">
    // 					<img src="img/FriendsPlus/Page62/E6/1.jpg" style="max-width: 100%;" />
    // 				</div>

    // 				<div style="margin-top: 20px; width: 500px; background-color: rgb(253, 249, 231); padding: 10px; border-radius: 10px; border: 3px solid rgb(138, 139, 142); box-shadow: 10px 10px rgb(222, 222, 222); font-size: 21px;">
    // 					Alan Bacon, <span style="background-color: rgb(252, 194, 135);">who left university in July,</span> was invited to an interview with a major retailer.
    // 					He prepared thoroughly for the interview, <span style="background-color: rgb(252, 194, 135);">which was for a role in the marketing department.</span>
    // 					But Alan, <span style="background-color: rgb(252, 194, 135);">whose degree is in film and TV studies,</span> was shocked that the interview included dancing.
    // 					The retailer has apologised and said that they are investigating managers at the store in <span style="background-color: rgb(252, 194, 135);">Cardiff,
    // 					where the interview took place.</span>
    // 				</div>
    // 			</div>

    // 			<div style="margin-top: 20px; font-size: 21px;">
    // 				<i>Possible answer:</i> <br/>
    // 				# <br/> # <br/> # <br/>
    // 			</div>
    //     `,
    // 		answer: [
    // 			"No, it wasn't, because he was asked to do something which ",
    // 			'had nothing to do with the job for which he was being interviewed. ',
    // 			"He was embarrassed and didn't want to work for the company after his bad experience.",
    // 		],
    // 	},
    // ],
  },
  7: {
    unit: 'unit 5',
    id: 'FGF11-A-P62-E7',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page62/Key/E7answerKey.png',
    component: T6,
    inputSize: 1000,
    maxLength: 209,
    stylesTextInput: {
      textAlign: 'left',
      fontSize: 25,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '7',
        title: `
					<span style="color:#92278f">USE OF ENGLISH</span> Rewrite the sentences so that they have got a similar meaning. Use the word(s) in brackets. 
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `

			<div style="margin-top:20px; " >
						<div>
						 	<b style="margin:10px">1.</b> 
							<span>I haven’t got much IT homework this weekend. (little) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">2.</b> 
							<span> Almost all of the students own a computer. (most) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">3.</b> 
							<span> Not many of my friends use Twitter. (few) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">4.</b> 
							<span> Sam loves Facebook and Ben loves Facebook. (both) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">5.</b> 
							<span> I will text you on Saturday or on Sunday. (either) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">6.</b> 
							<span> There aren’t any documents in the folder. (no) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">7.</b> 
							<span> Marlon has got a gadget on his left wrist and his right wrist. (each) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">8.</b> 
							<span> I downloaded all the apps to my new phone. (every one) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">9.</b> 
							<span> I don’t spend a lot of time using social media. (much) </span>
						</div>
						#
						<div>
						 	<b style="margin:10px">10.</b> 
							<span> I haven’t got any gadgets that are expensive. (none) </span>
						</div>
						#
				</div>
		    `,
        answer: [
          `I have little IT homework this weekend.`,
          `Most (of the) students own a computer.`,
          `Few of my friends use Twitter.`,
          `Both Sam and Ben love Facebook.`,
          `I will text you either on Saturday or on Sunday.`,
          `There are no documents in the folder.`,
          `Marlon has a gadget on each wrist.`,
          `I downloaded every one of the apps to my new phone.`,
          `I don't spend much time using social media.`,
          `None of my gadgets are expensive.`,
        ],
      },
    ],

    // questions: [
    // 	// For image capturing purpose only
    // 	{
    // 		title: `
    // 			<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    // 				<div style="margin-left: 20px; display: flex; justify-content: center; align-items: center;">
    // 					<img src="img/FriendsPlus/Page62/E6/1.jpg" style="max-width: 100%;" />
    // 				</div>

    // 				<div style="margin-top: 20px; width: 500px; background-color: rgb(253, 249, 231); padding: 10px; border-radius: 10px; border: 3px solid rgb(138, 139, 142); box-shadow: 10px 10px rgb(222, 222, 222); font-size: 21px;">
    // 					Alan Bacon, <span style="background-color: rgb(252, 194, 135);">who left university in July,</span> was invited to an interview with a major retailer.
    // 					He prepared thoroughly for the interview, <span style="background-color: rgb(252, 194, 135);">which was for a role in the marketing department.</span>
    // 					But Alan, <span style="background-color: rgb(252, 194, 135);">whose degree is in film and TV studies,</span> was shocked that the interview included dancing.
    // 					The retailer has apologised and said that they are investigating managers at the store in <span style="background-color: rgb(252, 194, 135);">Cardiff,
    // 					where the interview took place.</span>
    // 				</div>
    // 			</div>

    // 			<div style="margin-top: 20px; font-size: 21px;">
    // 				<i>Possible answer:</i> <br/>
    // 				# <br/> # <br/> # <br/>
    // 			</div>
    //     `,
    // 		answer: [
    // 			"No, it wasn't, because he was asked to do something which ",
    // 			'had nothing to do with the job for which he was being interviewed. ',
    // 			"He was embarrassed and didn't want to work for the company after his bad experience.",
    // 		],
    // 	},
    // ],
  },
  8: {
    unit: 'unit 5',
    id: 'FGF11-A-P62-E8',
    audio: '',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page62/E8/Key/answerKey.png',
    component: T6,
    inputSize: 250,
    maxLength: 19,
    recorder: true,
    hideBtnFooter: true,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 22,
    },
    titleQuestion: [
      {
        num: '8',
        title: `
				<span style="color: rgb(45,206,137);">SPEAKING</span> 
					 Work in pairs. Make predictions about students 
					in your class, using all of them, most of them, some of 
					them, a few of them, very few of them and none of them.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="display:flex; margin-top:20px">
						<div>
							<div> <b>1</b> Use Facebook</div>
							<div> <b>2</b> Own a computer</div>
							<div> <b>3</b> Download music</div>
						</div>
							<div style="margin-left:200px;">
							<div> <b>4</b> Have a smartphone</div>
							<div> <b>5</b> Shop online </div>
							<div> <b>6</b> Have a YouTube channel</div>
						</div>
					</div>
        `,
        answer: ['whose dad works for', 'who is a talented', 'where she used to'],
      },
    ],
  },
  9: {
    unit: 'unit 5',
    id: 'FGF11-A-P62-E9',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page62/E7/Key/answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '9',
        title: `
					Look at the highlighted examples of non-defining relative clauses in the text. 
					Circle the correct words below.
				`,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
          fontWeight: 'bold',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
          padding: '5px',
        },
        limitSelect: 1,
        listWords: ['before / after', 'makes_sense / does_not_make_sense', "has / doesn't_have"],
        answers: ['0-4', '1-0', '2-0'],
        initialValue: [],
      },
      Layout: `
				<div style="display: flex; flex-direction: column; align-items: start;">
					<div style="width: 610px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); background-image: radial-gradient(rgb(250 244 244), rgb(254, 230, 204)); font-size: 20px;">
						<div>
							<span style="color: rgb(246, 141, 31); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
							<strong>Non-defining relative clauses</song>
						</div>
						<div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">a</strong>
								<span>
									In non-defining relative clauses, 
									we use <i>who, which, where</i> and <i>whose</i>, 
									but we do not use <i>that</i>.
								</span>			
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">b</strong>
								<span>
									A non-defining relative clause:
									<ul>
										<li>
											comes immediately <sup>1</sup><input id='0' type='Circle' /> a noun 
											and gives us information about that noun.
										</li>
										<li>
											adds extra information to the sentence; 
											the sentence <sup>2</sup><input id='1' type='Circle' /> without it.
										</li>
										<li>
											<sup>3</sup><input id='2' type='Circle' /> a comma at the start. 
											It has a comma or a full stop at the end.
										</li>
									</ul>
								</span>			
							</div>
						</div>
					</div>

					<div style="margin-top: 30px; padding: 0 10px; border-radius: 10px; box-shadow: 0 0 10px rgb(246, 141, 31); display: flex; justify-content: center; align-items: center;">
						<img src="img/FriendsPlus/Page60/E3/1.jpg" style="max-width: 100%;" />
						<span style="margin-left: 10px; color: rgb(246, 141, 31); font-size: 20px;"><strong>Grammar Builder 5D</strong> Exercise 8: page 116</span>
					</div>
				</div>
      `,
    },
  },
};

export default json;
