import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P98-E1',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page98/key/E1answerKey.png',
		component: T6,
		inputSize: 1080,
		maxLength: 23,
		recorder: true,
		hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 600, fontSize: 24 },
		titleQuestion: [
			{
				num: '1',
				title: `
         <span style="color:rgb(4,149,79)">SPEAKING</span> Describe the photo. What are the people 
discussing, do you think?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-left:150px;margin-top:20px">
							<div style="display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page98/E1/1.jpg" style="max-width: 100%;" />

							</div>
							<div style="margin-top:30px">
								<h1>Answer: </h1>
								<p><textarea id="0" rows="2"> </p>
							</div>
					</div>
				`,
				answer: ['The people are having an argument because they are lost. They are discussing the best route to take.'],
			},
		],
	},
	2: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P98-E2',
		audio: '/Audios/3.13-audio.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page98/key/E2answerKey.png',
		component: T6,
		inputSize: 900,
		// maxLength: 12,
		textareaStyle: { width: 400, fontSize: 20 },
		titleQuestion: [
			{
				num: '2',
				title: `
          <headphone1 name="3.13"></headphone1> Read and listen to the dialogue. Look at the 
highlighted sentences and answer the questions. Then 
find one more third conditional sentence in the dialogue.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-bottom:30px;margin-left:180px">
					<img src="/img/FriendsPlus/Page98/E2/1.jpg" width='80%'>
				</div>
					<div>
						<p><span style="font-weight: bold;margin-right:10px">1 </span> Did Ben remember the satnav?</p>
						<p>#</p>
						<p><span style="font-weight: bold;margin-right:10px">2 </span> Did they leave home on time?</p>
						<p>#</p>
						<p><span style="font-weight: bold;margin-right:10px">3 </span> Did they make sandwiches for the journey?</p>
						<p>#</p>
						<p style="font-style:italic">Third conditional sentence: </p>
						<p>#</p>
					</div>
				`,
				answer: [
					'No, Ben didn’t remember the satnav..', 'No, they didn’t leave home on time.', 'No, they didn’t make sandwiches for the journey.', 'We’d have left on time if your mum hadn’t phoned just as we were leaving.'
				],
			},
		],
	},
	3: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P98-E3',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page98/key/E3answerKey.png',
		component: T6,
		inputSize: 230,
		// maxLength: 1,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 18,
			fontWeight: 'bold',
			background: 'transparent',
			border: 'none'
		},
		textareaStyle: { width: 400, textAlign: 'center', fontSize: 25, },
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
					Look at the highlighted sentences in the dialogue and 
answer the questions below. Then read the <span style="color:rgb(249,158,37)">Learn this!</span> box 
and complete the examples.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div style='position: relative;'>
							<div style='display:flex'>
									<img src="/img/FriendsPlus/Page98/E3/1.jpg" width='50%'>
									<img src="/img/FriendsPlus/Page98/E3/2.jpg" style='height:10cm'  >

							</div>
          		<div style=" position: absolute; top: 202px; left: 850px; "><input id='0' ></input></div>
          		<div style=" position: absolute; top: 285px; left: 670px; "><input id='1'></input></div>
          		<div style=" position: absolute; top: 314px; left: 610px; "><input id='2'></input></div>
          
						</div>
						
						<div style="margin-top:30px">
								<p><span style="font-weight:bold;margin-right:10px">1 </span> What tense is used in the if clause?</p>
																<textarea id='3' rows='1'></textarea>

								<p><span style="font-weight:bold;margin-right:10px">2 </span> What form is used in the main clause?</p>
																								<textarea id='4' rows='1'></textarea>

								<p><span style="font-weight:bold;margin-right:10px">3 </span> How is have pronounced?</p>
																								<textarea id='5' rows='1'></textarea>

							</div>
	
						
					</div>				
					
						
				`,
				answer: ["wouldn't have arrived", "had left", "wouldn't have been", "past perfect", "would have + past participle", "/həv/",],
			},
		],
	},
	4: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P98-E4',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page98/key/E4answerKey.png',
		component: T6,
		inputSize: 930,
		// maxLength: 18,
		stylesTextInput: {
			// textAlign: 'center',
			fontWeight:"bold",
			fontSize: 23,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
					Write sentences and questions in the third conditional.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<b>1</b>&ensp; Tom / take / a taxi / if / he / have / some cash on him <br>
						<div style='margin-left:32px;color:rgb(42 132 195)'>Tom would have taken a taxi if he’d had some cash on him.</div>
						
					<div style='margin:15px 0px'>
						<b>2</b>&ensp; if / I / know / it / be / your birthday / I / buy / you / a present<br>#
					</div>
					<b>3</b>&ensp; if / we / walk / all the way home / we / be / exhausted<br>#<br>
					<div style='margin:15px 0px'>
						<b>4</b>&ensp; if / I / not want / to work on this journey / I / not take / the train<br>#
					</div>
					<b>5</b>&ensp; if / we / have / more time / we / can / change / some money at the airport<br>#<br>
					<div style='margin:15px 0px'>
						<b>6</b>&ensp; what / you / do / you / oversleep / this morning ?<br>#
					</div>
				`,
				answer: ["If I had known it was your birthday, I would have bought you a present.",
					"If we had walked all the way home, we would have been exhausted.",
					"If I hadn't wanted to work on this journey, I wouldn't have taken the train.",
					"If we had had more time, we could have changed some money at the airport.",
					"What would you have done if you had overslept this morning?"],
			},
		],
	},
	5: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P98-E5',
		audio: '/Audios/3.14-audio.mp3',
		video: '',
		// exerciseKey: '',
		component: T6,
		hideBtnFooter: true,
		inputSize: 200,
		maxLength: 19,
		//hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          <headphone1 name="3.14"></headphone1><span style="color:rgb(44,132,201)">  PRONUNCIATION</span> Read the <span style="color:rgb(249,158,37)">Look out!</span> box. Then 
read out the sentences in exercise 4 using contractions 
where appropriate. Listen and check.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top:20px; margin-left:180px">
						<img src="/img/FriendsPlus/Page98/E5/1.jpg" alt="">
					</div>
				`,
				answer: [''],
			},
		],
	},
	6: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P98-E6',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page98/key/E6answerKey.png',
		component: T6,
		inputSize: 1055,
		maxLength: 18,
		// hideBtnFooter: true,
		stylesTextInput: {
			// textAlign: '',
			fontSize: 24,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
					Read the sentences about what actually happened. Then 
write sentences about how things might have been 
different. Use the third conditional.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top:10px">
						<p><span style="font-weight:bold;margin-right:10px">1 </span> We turned left and we got lost.</p>
						<p style="font-weight:100; font-style:italic;color:blue">If we hadn’t turned left, we wouldn’t have got lost.</p>					
						<p><span style="font-weight:bold;margin-right:10px">2 </span> I didn’t go by train because the ticket was so expensive.</p>
						<p>#</p>					
						<p><span style="font-weight:bold;margin-right:10px">3 </span> I didn’t meet you at the airport because I didn’t know when you were arriving.</p>
						<p>#</p>					
						<p><span style="font-weight:bold;margin-right:10px">4 </span> We stopped at the service station because we were nearly out of petrol.</p>
						<p>#</p>					
						<p><span style="font-weight:bold;margin-right:10px">5 </span> I wasn’t late for the meeting because I took a taxi.</p>
						<p>#</p>					
						<p><span style="font-weight:bold;margin-right:10px">6 </span> I bought the tickets on the train because there wasn’t a ticket office at the station.</p>
						<p>#</p>									
					</div>
				`,
				answer: ["If the ticket hadn't been so expensive, I would have gone by train.",
					"If I had known when you were arriving, I would have met you at the airport.",
					"If we hadn't been nearly out of petrol, we wouldn't have stopped at the service station.",
					"If I hadn't taken a taxi, I would have been late for the meeting.",
					"If there had been a ticket office at the station, I wouldn't have bought the tickets on the train."],
			},
		],
	},
	7: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P98-E7',
		audio: '',
		video: '',
		// exerciseKey: '',
		component: T6,
		inputSize: 650,
		maxLength: 56,
		hideBtnFooter: true,
		recorder: true,
		titleQuestion: [
			{
				num: '7',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Use the third conditional to 
complete the sentences. Ask and answer about the 
information in the sentences.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-left:150px;margin-top:30px">
					<img src="/img/FriendsPlus/Page98/E7/1.jpg" alt="">
					</div>
				`,
				answer: [
					''
				],
			},
		],
	},

};

export default json;
