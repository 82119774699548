import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P39-E1',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		maxLength: 16,
		exerciseKey: 'img/FriendsPlus/Page39/Key/E1answerKey.png',
		question: [],
		textAlign: 'center',
		titleQuestion: [
			{
				num: '1',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Describe and compare the photos. What are
				the similarities and differences between the activities?
				Use the words below to help you.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'become dehydrated',
					'climber',
					'desert',
					'food and water',
					'frostbite',
					'high altitude',
					'oxygen',
					'tent',
				],
				width: 750,
				fontColor: '#be1e54',
				borderColor: 'none',
			},
		],
		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 950 },
		inputSize: 450,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: ` 
          <hintbox id='0'></hintbox>
          <img style='height:12cm'  src="img/FriendsPlus/Page39/E1/1.jpg" />
           <div style='display:flex'>
            <u style='font-style:italic;font-weight:bold'>Answers:</u>
            <textarea id='0' rows='5'></textarea>
           </div>
          `,
				answer: [
					`Both show people doing physically challenging activities in extreme conditions. In photo A, the people are walking in a desert in extreme heat. They are lightly dressed and carrying rucksacks. If they aren't careful, they could become dehydrated and even die. In photo B, there is a climber sitting outside a tent in the mountains. He's wearing an oxygen mask to help him breathe at this high altitude.`,
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 3',
		audio: 'Audios/1.31-audio.mp3',
		id: 'FGF11-U3-P39-E2',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `<headphone1 name='1.31' src='Audios/1.31-tieude.mp3'></headphone1> <span style='font-size: 23px'> Read the <span style="color:#c8164e">Listening Strategy</span>. Then try
        to say the numbers and measurements below. Listen and check.</span>`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		maxLength: 9,
		hideBtnFooter: true,
		hintBox: [
			{
				src: ['<b>Parts of the body</b>', 'ankle', 'wrist'],
				width: 900,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// isHiddenCheck: true,
		textareaStyle: { width: 740 },
		inputSize: 150,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <img style='width: 21cm' src="img/FriendsPlus/Page39/E2/1.jpg" />
          <div>
            <div>
              <textarea id='0' rows='3'></textarea>
            </div>

           </div>
           
          `,
				answer: [''],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P39-E3',
		audio: 'Audios/1.32-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page39/Key/E3answerKey.png',
		stylesTextInput: { fontSize: 20, height: 23, borderBottom: 'none', background: 'none' },
		titleQuestion: [
			{
				num: '3',
				title: `<headphone1 name='1.32' src='Audios/1.32-tieude.mp3'></headphone1>  Read and listen to the article. Complete the
        article with numbers and measurements from exercise 2.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		maxLength: 6,
		isHiddenCheck: true,
		hintBox: [
			{
				src: ['<b>Parts of the body</b>', 'ankle', 'blood', 'bottom', 'brain', 'calf', 'cheek', 'chin', 'LOL'],
				width: 900,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6v2,
		// isHiddenCheck: true,
		textareaStyle: { width: 340 },
		inputSize: 70,
		textAlign: 'center',
		isHiddenCheck: true,
		questionImage: [],
		questions: [
			{
				title: `
        <img style='width: 14cm' src="img/FriendsPlus/Page39/E3/3.jpg" />
        <div style='position: relative;margin-left:20px'>
            <img  src="img/FriendsPlus/Page39/E3/1.jpg" />
            <span style='position: absolute; top: 116px; left: 208px'>#</span>
            <span style='position: absolute; top: 171px; left: 106px'>#</span>
            <span style='position: absolute; top: 171px; left: 359px'>#</span>
            <span style='position: absolute; top: 226px; left: 349px'>#</span>
            <span style='position: absolute; top: 33px; left: 706px'>#</span>
            <span style='position: absolute; top: 60px; left: 811px'>#</span>
            <span style='position: absolute; top: 172px; left: 761px'>#</span>
            <span style='position: absolute; top: 199px; left: 708px'>#</span>
        </div>
           
          `,
				answer: ['-40°C', '5°C', '15°C', '50%', '57%', '4,500', '1/5', '1/10'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P39-E4',
		audio: 'Audios/1.33-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page39/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `<headphone1 name='1.33' src='Audios/1.33-tieude.mp3'></headphone1>   Listen to an interview with a scientist. Which
				of the people he talks about tested the body’s limits deliberately?
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		maxLength: 15,
		component: T6,

		// isHiddenCheck: true,
		textareaStyle: { width: 340 },
		inputSize: 550,
		// textAlign: 'center',
		// recorder: true,
		questionImage: [],
		questions: [
			{
				title: `
          <div style='line-height:50px'>
            Which
of the people he talks about tested the body’s limits
deliberately?<br>#
          </div>
           
          `,
				answer: ['Randy Gardner'],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P39-E5',
		audio: 'Audios/1.33-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page39/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `<headphone1 name='1.33' src='Audios/1.33-tieude.mp3'></headphone1>   Read the sentences aloud, paying attention to
				the numbers. Then listen again and decide whether the sentences are true or false. Write T or F and correct the false sentences.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// maxLength: 1,
		isHiddenCheck: true,
		textareaStyle: { width: 530, fontSize: 22 },
		inputSize: 620,
		// stylesTextInput: { fontSize: 22, height: 23, borderBottom: 'none' },
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
         <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>
            When a Russian space capsule had a major problem in
              1971, the cosmonauts died in less than 30 seconds.<br> #
          </div>
         </div>
         <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>
              In 1966, a scientist passed out after 15 seconds in a vacuum.<br> #
          </div>
         </div>
         <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>
              The scientist passed out for 27 seconds.<br> # 
          </div>
         </div>
         <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>
            In the 1960s, Randy Gardner stayed awake for more than 250 hours.<br> #
          </div>
         </div>
         <div style='display:flex'>
          <b>5</b>
          <div style='margin-left:20px'>
              After staying awake for so long, Randy Gardner then
              slept for almost 50 hours. # 
          </div>
         </div>
                 
           
          `,
				answer: [
					'F They died after 30-40 seconds.',
					'T',
					'F The pressure dropped to almost zero for 27 seconds.',
					'T',
					'F He slept for almost 15 hours.',
				],
			},
		],
	},

	6: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P39-E6',
		audio: 'Audios/1.34-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page39/Key/E6answerKey.png',

		titleQuestion: [
			{
				num: '6',
				title: `<headphone1 name='1.34' src='Audios/1.34-tieude.mp3'></headphone1> <span style="color:#2184c5">PRONUNCIATION</span> Listen and mark the words
				that are stressed in the sentences from exercise 5. Then practise saying the sentences yourself.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 100,
		isHiddenCheck: true,
		checkUppercase: true,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 21,
			height: 27,
			width: 900,
			// textTransform: 'uppercase',
		},
		textareaStyle: {
			// background: 'none',
			width: 950,
			fontSize: 21,
			height: 80,
		},
		inputSize: 245,
		textAlign: 'left',

		questionImage: [],
		questions: [
			{
				title: `
        <div style='line-height: 40px; font-size: 21px'>
          <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>
            When a Russian space capsule had a major problem in
              1971, the cosmonauts died in less than 30 seconds.<br> <textarea id='4' row='3'>
          </div>
         </div>
         <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>
              In 1966, a scientist passed out after 15 seconds in a vacuum.<br> <input id='0'>
          </div>
         </div>
         <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>
              The scientist passed out for 27 seconds.<br> <input id='1'> 
          </div>
         </div>
         <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>
            In the 1960s, Randy Gardner stayed awake for more than 250 hours.<br> <input id='2'>
          </div>
         </div>
         <div style='display:flex'>
          <b>5</b>
          <div style='margin-left:20px'>
              After staying awake for so long, Randy Gardner then
              slept for almost 50 hours. <input id='3'> 
          </div>
         </div>
        
        </div>
        
          `,
				answer: [
					'In 1966, a SCIENTIST PASSED out after 15 SECONDS in a VACUUM.',
					'The SCIENTIST PASSED out for 27 SECONDS.',
					'In the 1960s, Randy Gardner STAYED awake for more than 250 HOURS.',
					'After STAYING awake for so long, Randy Gardner then SLEPT for almost 50 HOURS.',
					'When a Russian space CAPSULE had a major PROBLEM in 1971, the COSMONAUTS DIED in less than 30 SECONDS.',
				],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P39-E7',
		audio: '',
		video: '',
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b>  Discuss the questions in pairs.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 40,
		textAlign: 'center',
		recorder: true,
		//hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
          <div style='display:flex;width:18cm'>
            <b>1</b>
            <div style='margin-left:20px'>
              Have you ever been awake all night or most of the night?
              If so, when / where / why?
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>
              Have you ever felt very cold? If so, when / where / why?
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:20px'>
              Have you ever experienced high altitude? If so, when /
              where / why? How did you feel?
            </div>
          </div>

          `,
				answer: [''],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P39-E8',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '8',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page39/E8/1.pNg' }]],
	},
}

export default json
