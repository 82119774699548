import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Unit 6',
		id: 'FGF11-U6X1-P80-E1',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(45,206,137);">SPEAKING</span> 
          Why do people go abroad to work? Think of at 
					least three reasons.
        `,
				color: '#92278f',
			},
		],
		recorder: true,
		questionImage: [],
	},
	2: {
		unit: 'Unit 6',
		id: 'FGF11-U6X1-P80-E2',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 600,
		maxLength: 50,
		recorder: true,
		hideBtnFooter: true,
		textareaStyle: { width: 700 },
		titleQuestion: [
			{
				num: '2',
				title: `
          <span style="color: #2dce89">SPEAKING</span> Compare and contrast the photos of people 
working abroad. What are the people doing?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          
            <div style="display: flex; justify-content: center;">
						<img src="/img/FriendsPlus/Page80/E2/1.jpg" width="600px" style="margin-left: 290px;" />
						
						</div>
						<div  style="margin-left: 200px;"> <textarea id='0' rows='2'></textarea></div>
         
        `,
				answer: [],

			},
		],
	},
	3: {
		unit: 'Unit 6',
		id: 'FGF11-U6X1-P80-E3',
		exerciseKey: '/img/FriendsPlus/Page80/key/E3answerKey.png',
		audio: 'Audios/2.35-audio.mp3',
		video: '',
		component: T6,
		inputSize: 600,
		maxLength: 108,
		stylesTextInput: {
			fontSize: 25,
		},
		// textareaStyle: { width: 450, fontSize: '21px' },
		titleQuestion: [
			{
				num: '3',
				title: `
           <headphone1 name="2.35" src="Audios/2.35-tieude.mp3"></headphone1 > Read the <span style="color:  #2dce89">Speaking Strategy</span> above and the task 
below. Then listen to a student doing the task. Which 
phrases from the strategy does she use?
        `,
				color: '#860052',
			},
		],
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		questions: [
			{
				title: `
          <div>
           <img style="margin-left: 50px; margin-bottom: 20px" src="/img/FriendsPlus/Page80/E3/1.jpg" alt="">
					  <img style="margin-left: 50px;" src="/img/FriendsPlus/Page80/E3/2.jpg" alt="">
						<h1 style="margin-left: 110px;margin-top: 20px">Answers</h1>
							<div style="margin-left: 110px;">
								<P>#</P>
								<P>#</P>
								<P>#</P>
							</div>

            </div>
        `,
				answer: [
					"Something else I'd like to ask about is … ",
					"Moving on to the question of …",
					"Could I ask you about …?",

				],
			},
		],
	},
	4: {
		unit: 'Unit 6',
		id: 'FGF11-U6X1-P80-E4',
		exerciseKey: '/img/FriendsPlus/Page80/key/E4answerKey.png',
		audio: 'Audios/2.35-audio.mp3',
		video: '',
		component: T6,
		inputSize: 300,
		// maxLength: 1,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
           <headphone1 name="2.35" src="Audios/2.35-tieude.mp3"></headphone1 > <span style="color: rgb(86 28 122)">KEY PHRASES</span> Listen again. Complete the 
					questions that the student asked with the phrases below
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
        <div>
      	<span style="color: rgb(45 206 137) ;margin-right: 20px"><b>Indirect questions</b></span>
      	<span style="color: rgb(45 206 137) "> Could you tell me … ?</span>
    		</div>
				<div>
      	<span style="color: rgb(45 206 137) ;margin-right: 20px">I was wondering …</span>
      	<span style="color: rgb(45 206 137) ;margin-right: 20px">  I'd like to know … (x 2)</span>
				<span style="color: rgb(45 206 137) ;margin-right: 20px"> May I ask … ?</span>
    		</div>
				<div>
      	<span style="color: rgb(45 206 137) ;margin-right: 20px">I'd be interested to know … </span>
    		</div>
				<div style="margin-top: 50px;">
				<P><span style="font-weight:bold">1</span> #  if I could discuss it with you.</P>
				<P><span style="font-weight:bold">2</span> #  what the job involves.</P>
				<P><span style="font-weight:bold">3</span> #  what I would be doing in the restaurant?</P>
				<P><span style="font-weight:bold">4</span> #  when I would start and finish work exactly.</P>
				<P><span style="font-weight:bold">5</span> #  what the salary is?</P>
				<P><span style="font-weight:bold">6</span> #  if accommodation is included.</P>
				
    </div>
        `,
				answer: ['I was wondering', "I'd like to know", 'Could you tell me ', "I'd be interested to know", 'May I ask', "I'd like to know"],
			},
		],
	},
	5: {
		unit: 'Unit 6',
		id: 'FGF11-U6X1-P80-E5',
		exerciseKey: '/img/FriendsPlus/Page80/key/E5answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 800,
		// maxLength: 1,
		titleQuestion: [
			{
				num: '5',
				title: `
          Read the <span style="color: rgb(45 206 137)">Learn this!</span> box. Why does the student choose 
					to use indirect questions in exercise 4?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div>
            <img style="margin-left: 100px;" src="/img/FriendsPlus/Page80/E5/1.jpg" alt="">
						
						<h1 style="margin-left: 100px; margin-top:50px">Answers</h1>
						<span style="margin-left: 100px; margin-top:50px">#</span>
          </div>
        `,
				answer: ['The student uses indirect questions to sound more polite.'],
			},
		],
	},
	6: {
		unit: 'Unit 6',
		id: 'FGF11-U6X1-P80-E6',
		exerciseKey: '/img/FriendsPlus/Page80/key/E6answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 900,
		// maxLength: 1,
		titleQuestion: [
			{
				num: '6',
				title: `
          Read the task below and think of at least six indirect 
questions that you could ask the café manager. Make 
sure you cover all four points in the task.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div>
            <p style="margin-left: 350px;color: rgb(45 136 193)">Could you tell me what the job involves?</p>
						<img style="margin-left: 300px;" src="/img/FriendsPlus/Page80/E6/1.jpg" alt="">
						<div style="margin-top:50px; margin-left:100px">
						<p style="font-weight: bold">Answer</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						<p>#</p>
						</div>
						
          </div>
        `,
				answer: [
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",
					"Could you tell me what the job involves? / May I ask if I would work both in the kitchen and as a waiter? / I was wondering what exactly I would be doing in the kitchen. / I was wondering if I need to have any working experience. / Could you tell me what personal qualities the job requires? / I'd like to know what the hours of work are. / I'd like to know if I would get any days off. / I'd be interested to know what the salary is. / May I ask if accommodation is included?",

				]
			},
		],
	},
	7: {
		unit: 'Unit 6',
		id: 'FGF11-U6X1-P80-E7',
		// exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 900,
		hideBtnFooter: true,
		// maxLength: 1,
		//hideBtnFooter: true,
		textareaStyle: { width: 700 },
		titleQuestion: [
			{
				num: '7',
				title: `
          Work in pairs. Swap the questions you wrote in exercise 6 
				and write answers to them. Use the phrases in exercises 4 
				to help you.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div >
            <p style="color: rgb(45 136 193); margin-bottom:0px">Could you tell me what the job involves?</p>
						<p style="color: rgb(45 136 193); margin-bottom:0px">- It involves greeting customers and taking orders. </p>
						<p style="color: rgb(45 136 193); margin-bottom:0px">- Sometimes you will have to work in the kitchen.</p>				
          </div>
					<div>
						<u><i>Answer:</i></u>
						<textarea id='0' rows='6'></textarea>
					</div>
					
        `,
				answer: [

				]
			},
		],
	},
	8: {
		unit: 'Unit 6',
		id: 'FGF11-U6X1-P80-E8',
		// exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 900,
		// maxLength: 1,
		hideBtnFooter: true,
		recorder: true,
		titleQuestion: [
			{
				num: '8',
				title: `
          <span style="color:rgb(45 206 137)">SPEAKING</span> Take turns to do the task in exercise 6. Use the 
questions and answers you prepared in exercises 7.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="margin-top:50px">
            <img style="margin-left: 200px;" src="/img/FriendsPlus/Page80/E8/1.jpg" alt="">						
          </div>
        `,
				answer: [

				]
			},
		],
	},
};

export default json;
