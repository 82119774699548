import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	6: {
		unit: 'Unit 8',
		id: 'FG10-S-P97-E6',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page97/key/E6answerKey.png',
		component: T6,
		inputSize: 200,
		textAlign: 'center',
		maxLength: 17,

		hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
					Read the <span style="color:rgb(161 44 148)">Recycle!</span> box. Complete the questions using the 
passive and the verb in the brackets. 
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-left:180px">
						<img src="/img/FriendsPlus/Page97/E6/1.jpg" width="80%">
					</div>
					<div style="margin-top:20px">
						<p><span style="font-weight:bold; margin-right:10px">1 </span> Where # Wat Arun # ? (locate)</p>
						<p><span style="font-weight:bold; margin-right:10px">2 </span> Why # Wat Arun # the ‘Temple of Dawn’? (call)</p>
						<p><span style="font-weight:bold; margin-right:10px">3 </span> What # in bread to make difference to banh mi? (can / fill)</p>
						<p><span style="font-weight:bold; margin-right:10px">4 </span> Why # vegetables # to banh mi? (add)</p>
						<p><span style="font-weight:bold; margin-right:10px">5 </span> When # the buses # with passengers? (pack)</p>
						<p><span style="font-weight:bold; margin-right:10px">6 </span> Which opportunities # to the man’s family? (can / offer)</p>
					</div>
				`,
				answer: ['is', 'located', 'is', 'called', 'can be filled', 'are', 'added', 'are', 'packed', 'can be offered'],
			},
		],
	},
	7: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P97-E7',
		audio: '/Audios/3.12-audio.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page97/key/E7answerKey.png',
		component: T6,
		// inputSize: 800,
		textareaStyle: { width: 700 },
		// maxLength: 17,
		stylesTextInput: {
			// textAlign: 'center',
			// fontSize: 20,
		},
		hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
          <headphone1 name="3.12"></headphone1>  Listen to three speakers and answer the 
questions in exercise 6.

				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top:20px">
						<p><span style="font-weight:bold; margin-right:10px">1 </span> Where is Wat Arun located ? </p>
						<textarea id='0' rows='2'></textarea>
						<p><span style="font-weight:bold; margin-right:10px">2 </span> Why is Wat Arun called the ‘Temple of Dawn’?</p>
						<textarea id='1' rows='2'></textarea>
						<p><span style="font-weight:bold; margin-right:10px">3 </span> What can be filled in bread to make difference to banh mi?</p>
						<textarea id='2' rows='2'></textarea>
						<p><span style="font-weight:bold; margin-right:10px">4 </span> Why are vegetables added to banh mi?</p>
						<textarea id='3' rows='2'></textarea>
						<p><span style="font-weight:bold; margin-right:10px">5 </span> When are the buses packed with passengers?</p>
						<textarea id='4' rows='2'></textarea>
						<p><span style="font-weight:bold; margin-right:10px">6 </span> Which opportunities can be offered to the man’s family?</p>
						<textarea id='5' rows='2'></textarea>
					</div>
				`,
				answer: [
					"It is located on the west bank of the Chao Phraya River.",
					"It is also called the 'Temple of Dawn' because the first light of the morning reflects off the surface of the temple with glory.",
					"Meat balls, sliced ham, pork roll, fried eggs, sausages, fried fish.", "Vegetables are added to the bread to give a fresh and healthy taste.",
					"At rush hour.",
					"His wife can get a better job and his children can be offered better chances of education."],
			},
		],
	},
	8: {
		unit: 'Unit 8',
		id: 'FGF11-U8-P97-E8',
		audio: '',
		video: '',
		// exerciseKey: '',
		component: T6,
		hideBtnFooter: true,
		inputSize: 1400,
		recorder: true,
		// maxLength: 17,
		stylesTextInput: {
			// textAlign: 'center',
			// fontSize: 20,
		},
		//hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '8',
				title: `
          <span style='color:rgb(4 149 79)'>SPEAKING</span> Which city in exercise 2 have you been to? 
Which city do you like to visit most? Why?

				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					
				`,
				answer: [''],
			},
		],
	},
};

export default json;
