import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'unit 5',
    id: 'FGF11-A-P66-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/Key/E1answerKey.png',
    component: T6,
    inputSize: 800,
    maxLength: 1,
    stylesTextInput: {
      textAlign: 'left',
      fontSize: 25,
    },
    titleQuestion: [
      {
        num: '1',
        title: `
         	<span style="color:rgb(45,206,137)">SPEAKING</span> Read the conversation between a human and a computer, guess which is the human.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="margin-left:250px">
						 <img src="img/FriendsPlus/Page66/E1/1.png" style="width: 600px"  />
						 
					</div>
					<div style="margin-left:100px; margin-top:10px">#</div>
        `,
        answer: ['A is the digital tutor and B is the human'],
      },
    ],
  },
  2: {
    unit: 'unit 5',
    id: 'FGF11-A-P66-E2',
    audio: '',
    video: '',
    titleQuestion: [
      {
        num: '2',
        title: `
          Now scan the article. Which of the following is the main idea?
				`,
        color: '#860052',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page66/Key/E2answerKey.png',
    component: Circle_Write,
    inputSize: 40,
    maxLength: 1,
    // Not answer (Hide two buttons: 'Try again' and ' Submit')

    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 900, fontSize: 25 },
        initialValue: [],
        answers: [``],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [`a <br> b `],
        answers: ['0-4'],
        initialValue: [],
      },
      Layout: `

			<div>
			
				<div><img src="img/FriendsPlus/Page66/E1/2.jpg" style="max-width: 70%;"  /></div>

				
 					<div style="margin-top:15px">
						
            <div style="display: flex;">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      Ann Sullutor - a typical day at work.<br>
              Ann Sullutor - a multitasking companion. 
		          </div>
            </div>
			
					</div>
			
			</div>
 
      `,
    },
  },
  3: {
    unit: 'unit 5',
    id: 'FGF11-A-P66-E3',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_21.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/Key/E3answerKey.png',
    component: Circle_Write,

    titleQuestion: [
      {
        num: '3',
        title: `
          Read the <span style="color:rgb(75, 142, 168)">Reading Strategy.</span> Then complete these tasks.
          
        `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 900, fontSize: 25 },
        initialValue: [],
        answers: [`an invention of the 22nd century = the brainchild of a 22nd century scientist`],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [`a <br> b <br> c <br> d`],
        answers: ['0-0'],
        initialValue: [],
      },
      Layout: `

			<div>
				<div><b>1.</b> Look at question 1 in exercise 4. Read the second paragraph of the text.</div>
				<div><b>2.</b> Circle the correct option and identify the sentence of the paragraph which gives the answer.</div>
				<div><b>3.</b> Which words are used in the sentence instead of the words in the correct option?</div>
				<div><img src="img/FriendsPlus/Page66/E1/3.jpg" style="max-width: 70%; margin-top:10px"  /></div>

				
 					<div style="margin-top:15px">
						<b>1</b>&ensp;
		       Ann Sullutor
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      is an invention of the 22<sup>nd</sup> century.<br>
              costs a huge sum of money. <br>
              takes a 20-hour energy charge. <br>
							can’t be controlled orally.
		          </div>
            </div>
				<div style="width:100%; margin-top:25px">
					<span><i>ANSWER:</i></span> <input id='0' ></input>
				<div/>
					</div>
			
			</div>
 
      `,
    },
  },
  4: {
    unit: 'unit 5',
    id: 'FGF11-A-P66-E4',
    audio: 'Audios/Friends Global Grade 10 SB_CD2_21.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/E4/Key/answerKey.png',
    component: T6,
    inputSize: 120,
    maxLength: 12,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 20,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '4',
        title: `
        <headphone1 name = "2.21" src="-tieude.mp3"></headphone1> <span style="color: rgb(146, 39, 144)">KEY PHRASES</span> 
        Read the <span style="color: rgb(0, 147, 69);">Speaking Strategy</span>.<br/>
        Then listen again and complete the sentences using the words below.
 
			`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-top: 20px; width: 600px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(0, 147, 69);; background-color: rgb(229, 240, 228);">
            <span style="font-weight: bold; color: rgb(0, 147, 69);">Listening Strategy</span>
            <div>
              When you discuss a topic and give reasons for your opinions, 
              try to include some complex sentences. 
              For example, use words like <i>although / even though, nevertheless</i> 
              and <i>however</i> to make contrasts.
            </div>
          </div>

          <div style="margin-top: 20px; color: rgb(0, 147, 69);">
            <div>
              <span>choosing</span>
              <span style="margin-left: 46px;">hours</span>
              <span style="margin-left: 43px;">kitchen</span>
              <span style="margin-left: 51px;">long</span>
              <span style="margin-left: 52px;">pay</span>
              <span style="margin-left: 30px;">tiring<span/>
            </div>
            <div>
              <span>well-paid</span>
              <span style="margin-left: 48px;">well-paid</span>
            </div>
          </div>

          <div style="margin-top: 20px; width: 700px; border-left: 5px solid rgb(0, 147, 69); padding-left: 15px;">
            Although it’s <sup>1</sup># , it’ll be very <sup>2</sup># . 
            I’m sure I’ll enjoy it even though the <sup>3</sup># are <sup>4</sup># . 
            The job is quite <sup>5</sup># . 
            However, I really don’t want to work in a <sup>6</sup># . 
            The <sup>7</sup># is not very good. 
            Nevertheless, I’m <sup>8</sup># this job because I’m interested in fashion.
          </div>
				`,
        answer: ['well-paid', 'tiring', 'hours', 'long', 'well-paid', 'kitchen', 'pay', 'choosing'],
      },
    ],
  },
  5: {
    unit: 'unit 5',
    id: 'FGF11-A-P66-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/E5/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '5',
        title: `
					Read the <span style="color: rgb(0, 147, 69);">Look out!</span> box.
					Then work in pairs and say which job in exercise 1 you would choose and why.
					Include the structures from the <span style="color: rgb(0, 147, 69);">Look out!</span> box.
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style="margin-top: 20px; display: flex; justify-content: center; align-items: start;">
	          <div>
							<div style="display: flex; width: 430px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(0, 147, 69);; background-image: radial-gradient(rgb(250 244 244), rgb(206, 226, 206)); font-size: 20px;">
								<div style="margin-right: 20px; margin-top: 20px; font-weight: bold; font-size: 50px; color: rgb(0, 147, 69);">!</div>
								<div>
									<span style="color: rgb(0, 147, 69); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LOOK OUT!</span>
									<div>
										We often use <i>find</i> + noun + adjective and <i>find it</i> + adjective (+ infinitive) to give a personal reaction or opinion.
										<div style="color: rgb(10, 111, 143); font-family: 'Brush Script MT', cursive;">
											I will find the job stressful. <br/>
											I find it difficult to concentrate.
										</div>
									</div>
								</div>
							</div>
							<div style="margin-top: 30px;"><img src="img/FriendsPlus/Page66/E1/1.jpg" style="max-width: 100%;" /></div>
						</div>
						<div style="margin-left: 20px;"><img src="img/FriendsPlus/Page66/E1/2.jpg" style="max-width: 100%;" /></div>
					</div>
	      `,
        answer: [],
      },
    ],
  },
};

export default json;
