import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P76-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <b style='color:rgb(0,147,70);'>SPEAKING</b> 
          Read the passage. Would you like to take part in 
          such a field trip?
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page76/E1/1.jpg' }]],
  },
  2: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P76-E2',
    exerciseKey: '/img/FriendsPlus/Page76/Key/E2answerKey.png',
    audio: '',
    video: '',
    component: Circle_Write,
    inputSize: 600,
    maxLength: 50,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: '2',
        title: `
           Read the article again. Underline the verbs in the -ing form.
        `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 149, fontSize: 25, borderBottom: 'none', textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 14,
        listWords: [
          `Katie moved to Viet Nam after her mother found a job here. Having spent the first few months fitting to the new school, Katie now has an interesting school life. Last week, she joined her school’s field trip to Con Dao island. It involved releasing sea turtles into the sea. Having listened to careful instructions from the scientists, Katie and her friends received little turtles to care for before they took the turtles to the beach. Katie then learned how to release her little turtles to the sea. Though it was a short one, participating in the trip helped Katie make new friends. She loved having done something to protect the wildlife.`, //0
        ],
        answers: ['0-24', '0-36', '0-88', '0-100', '0-188', '0-210'],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative; font-size:20px;'>
          <img style='height:' src='img/FriendsPlus/Page76/E2/1.jpg'  />
          <div>
          <img style='height:350px; width: 850px; margin-left: 34px' src='img/FriendsPlus/Page76/E2/2.jpg' />
          <div style=" position: absolute; top:580px; left: 84px; width:20cm; line-height:1.8 "><input id='0'  type='Circle' /></div>
          </div>
          
        </div> 
      `,
    },
  },
  3: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P76-E3',
    exerciseKey: '/img/FriendsPlus/Page76/Key/E3answerKey.png',
    audio: '',
    video: '',
    component: T6v2,
    inputSize: 200,
    maxLength: 18,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 21,
    },
    textareaStyle: { width: 600 },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '3',
        title: `
          Read the Learn this! box. Can you find examples of <br>
          a) rules a and b; b) rules c and d in the article?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style='display:flex'>
              <div>
                <div><b>Rules a:</b>&ensp;<textarea id='0' rows='2'></textarea></div>          
                <div><b>Rules b</b>&ensp;<textarea id='1' rows='2'></textarea></div>          

                <div><b>Rules c</b>&ensp;<textarea id='2' rows='2'></textarea></div>          
                <div><b>Rules d</b>&ensp;<textarea id='3' rows='2'></textarea></div>          
              </div>

              <div style="margin-top: 20px; display: flex; gap: 30px;">

                <div style="flex: 3"><img src="img/FriendsPlus/Page76/E2/1.jpg" style="max-width: 100%;" /></div>
              </div>
          </div>
        `,
        answer: [
          'participating in the trip helped Katie make new friends / It involves releasing sea turtles to the sea.',
          'Having spent the first few months fitting to the new school',
          'She loved having done something to protect the wildlife.',
          'Having listened to careful instructions from the scientists / Having spent the first few months fitting in the new school | Having listened to careful instructions from the scientists | Having spent the first few months fitting in the new school',
        ],
      },
    ],
  },
  4: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-G-P125-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page76/Key/E4answerKey.png',
    component: Circle_Write,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '4',
        title: "<span style='font-size:30px'>Choose the correct options to complete the sentences.</span>",
        color: 'rgb(144 38 143)',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '',
        },
        limitSelect: 5,
        listWords: [
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
        ],
        answers: ['3-8', '0-0', '1-0', '2-0', '4-4'],
        initialValue: [],
      },
      /*html */
      Layout: `
       <div style="display:flex; flex-direction:column">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong >1</strong>  <div style="margin-left:30px" >  Google is famous for ___ fantastic working conditions 
for its employees.</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="0" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span>providing</span>
            <span style="margin-left: 170px">to provide</span>
            <span style="margin-left: 115px">being provided</span>
          
          </div>
        </div>
       </div>
       </div>



        <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong>2</strong> <div  style="margin-left:30px">___   on time is one of our school requirements.</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="1" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span> Being</span>
            <span style="margin-left: 190px">To be</span>
            <span style="margin-left: 170px">Having been</span>
          
          </div>
        </div>
       </div>
       </div>
       

         <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong style="margin-right:10px">3</strong> <div>   ___  at the beautiful works of art for hours, we left 
the exhibition with satisfaction.</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="2" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span>  Having looked</span>
            <span style="margin-left: 80px">Looking</span>
            <span style="margin-left: 110px">Look</span>
          </div>
        </div>
       </div>
       </div>



          <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong style="margin-right:10px">4</strong> <div>  The boy admitted ___ his homework last night.
</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="3" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span> not to finish</span>
            <span style="margin-left: 120px">not finished</span>
            <span style="margin-left: 56px">not finishing</span>
          </div>
        </div>
       </div>
       </div>



       
          <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong style="margin-right:10px">5</strong> <div> The man had to ask a policeman for help after ___ in 
the wrong direction for two hours.
</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="4" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span> drive</span>
            <span style="margin-left: 210px">having driven</span>
            <span style="margin-left: 65px">driving</span>
          </div>
        </div>
       </div>
       </div>



     
     
       </div>
				`,
      answer: [],
    },
  },
  5: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P76-E5',
    exerciseKey: 'img/FriendsPlus/Page76/Key/E5answerKey.png',
    // audio: '/Audios/Friends Global Grade 10 SB_CD2_32.mp3',
    video: '',
    component: T6,
    inputSize: 250,
    maxLength: 16,
    textAlign: 'center',
    //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '5',
        title: `
        	Complete the sentences with the correct form of the verbs in brackets.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<div style='line-height:2'>
            <div><b>1.</b>&ensp; Hotel receptionists must be good at # with others. (communicate)</div>

            <div><b>2.</b>&ensp; I decided to buy this Fiat after # to the sales assistant. (listen)</div>

            <div><b>3.</b>&ensp; His wife mentioned # some new furniture for the room. (buy)</div>

            <div><b>4.</b>&ensp; # in this school has been a fantastic experience. (Study)</div>
          
          
          
          </div>
        `,
        answer: ['communicating', 'having listened', 'buying', 'Studying'],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P76-E6',
    exerciseKey: '/img/FriendsPlus/Page76/Key/E6answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 500,
    textareaStyle: { width: 1000 },
    maxLength: 1,
    stylesTextInput: {
      // fontSize: 20,
    },
    titleQuestion: [
      {
        num: '6',
        title: `
				Rewrite each pair of sentences as a single sentence, using perfect participle or perfect gerund 
        `,
        color: '#860052',
      },
    ],
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    questions: [
      {
        title: `
      <div style='line-height:2'>
          <div><b>1.</b>&ensp; I met the violin teacher at a music club. I started to have lessons with him once a week.</div>
          <textarea id='0' rows='1'></textarea>
          <div><b>2.</b>&ensp; She has completed the training course. She is more confident about doing the job well.</div>
          <textarea id='1' rows='1'></textarea>
          <div style='display:flex'><b>3.</b>&ensp; <span>Our grandparents have lived in the countryside for more than 40 years.<br>They don’t want to leave their hometown.</span></div>
          <textarea id='2' rows='1'></textarea>
          <div><b>4.</b>&ensp; I met the violin teacher at a music club. I started to have lessons with him once a week.</div>
          <textarea id='3' rows='1'></textarea>
      </div>
        `,
        answer: [
          `Having met the violin teacher at a music club, I started to have lessons with him once a week`,
          `Having completed the training course, she is more confident about doing the job well`,
          `Having lived in the countryside for more than 40 years, our grandparents don't want to leave their hometown`,
          `Having arrived at the training session late, I missed the warm up`,
        ],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P76-E7',
    exerciseKey: '/img/FriendsPlus/Page76/Key/E7answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 140,
    // maxLength: 12,
    textareaStyle: { width: 500 },
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: '20px',
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '7',
        title: `
					<span style="color:green">SPEAKING</span> Work in pairs. Tell the story of Thomas Edison 
from the following cues, using gerund and perfect 
participle where it is possible.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
				  <div style="display:flex;">
						<div style="flex: 1;">
            <div style="font-weight: bold; font-style: italic;">Answer: </div>
            <div style='display:flex'><b>1</b>&ensp;<textarea id="0" rows="2"></textarea></div>
            <div style='display:flex'><b>2</b>&ensp;<textarea id="1" rows="2"></textarea></div>
            <div style='display:flex'><b>3</b>&ensp;<textarea id="2" rows="2"></textarea></div>
            <div style='display:flex'><b>4</b>&ensp;<textarea id="3" rows="2"></textarea></div>
            <div style='display:flex'><b>5</b>&ensp;<textarea id="4" rows="2"></textarea></div>
            <div style='display:flex'><b>6</b>&ensp;<textarea id="5" rows="2"></textarea></div>
            <div style='display:flex'><b>7</b>&ensp;<textarea id="6" rows="2"></textarea></div>
          </div>

						<div style="margin-top: 20px;"><img src="img/FriendsPlus/Page76/E7/1.jpg" style="width: 150%;" /></div>
				  </div>
				`,
        answer: [
          'Thomas Edison was born in Ohio, in 1847.',
          'He moved to Michigan with his family at the age of 7. He quit school after 2 months',
          'Having learnt with his mum at home, he loved experimenting with electrical and mechanical things.',
          'He had worked as a newsboy on a train for some time before he accidentally set fire to the floor of a baggage car while doing experiments.',
          'Having spent a lot of time experimenting, he got his first patent in 1868.',
          'Having made so many inventions to improve the quality of human life was his great success. ',
          'He died in 1931.',
        ],
      },
    ],
  },
  8: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P76-E8',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '8',
        title: `
					<b style='color:rgb(0,147,70);'>SPEAKING</b> 
						Work in pairs. Ask and answer these questions. 
						Give reasons for your answers and ask follow-up questions. 
	        <div>
          <div style="margin-top: 10px;">
            <span>If you were a multi-millionaire, …</span>
          </div>
          <ul style="margin-top: 10px;">
            <li> would you live in a big house?</li>
            <li> would you work very long hours?</li>
            <li>would you go on expensive holidays?</li>
          </ul>
        </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
