import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	3: {
		unit: 'Unit 1',
		id: 'FG11-U1-P19-E3',
		audio: '',
		video: '',
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page19/E3/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '3',
				title: `Read the <span style='color:rgb(65 63 150)'>Reading Strategy</span>. Then read the sentences below and the highlighted words in the text. Say which sentence links to which highlighted word and underline the part of the sentence which helped you to decide.`,
				color: '#8e258c',
			},
		],
		question: {
			Write: {
				isHiddenCheck: true,
				inputStyle: { textAlign: "center", width: 200, maxLength: 13, },
				answers: [
					'opinions / idealistic',
					'Extra sentence',
					'Secondly',
					'communication',
					'Physically',
					'made decisions',
					'Extra sentence',
					'opinions / idealistic',
				],
				maxLength: 16,
				initialValue: [],
			},
			Circle: {
				selectWordStyle: { padding: 0, borderBottom: 'solid 3px', borderColor: 'black' },
				limitSelect: 1,
				listWords: [
					`You see_the_world_differently,_develop_your_own views and your own sense of right and wrong.`,
					`Firstly, let your parents know that you still love and value them.`,
					`Keep talking_to_your_parents.`,
					`In general, emotionally_and_socially, the adolescents like you are experiencing profound changes in terms of freedom, privacy, interests, decisions and opinions.`,
					`You hate_being_told_what_to_do all the time.`,
				],
				answers: ['0-2', '1-0', '4-2', '2-2', '3-4'],
				initialValue: [],
			},
			Layout: `
        <div style='line-height:47px'>
          <div style='display:flex'>
            <b>A</b> 
            <div style='margin-left:20px'>
              <input id='0' type='Circle' /><br><b>links to</b> <input id='0'></input>, <input id='7'></input>
            </div>
          </div>
          <div style='display:flex'>
            <b>B</b> 
            <div style='margin-left:20px'>
              They may not say so, but they are probably feeling a sense of loss and may even feel rejected by you.<br><b>links to</b> <input id='1'></input>
            </div>
          </div>
          <div style='display:flex'>
            <b>C</b> 
            <div style='margin-left:20px'>
              <input id='1' type='Circle' />&emsp;<b>links to</b> <input id='2'></input>
            </div>
          </div>
          <div style='display:flex'>
            <b>D</b> 
            <div style='margin-left:20px'>
              <input id='2' type='Circle' />&emsp;<b>links to</b> <input id='3'></input>
            </div>
          </div>
          <div style='display:flex'>
            <b>E</b> 
            <div style='margin-left:20px'>
              <input id='3' type='Circle' />&emsp;<b>links to</b> <input id='4'></input>
            </div>
          </div>
          <div style='display:flex'>
            <b>F</b> 
            <div style='margin-left:20px'>
              <input id='4' type='Circle' />&emsp;<b>links to</b> <input id='5'></input>
            </div>
          </div>
          <div style='display:flex'>
            <b>G</b> 
            <div style='margin-left:20px'>
              Despite this, you should always listen carefully.&emsp;<b>links to</b> <input id='6'></input>
            </div>
          </div>


        </div>
          <img style='width:16cm' src='img/FriendsPlus/Page19/E3/1.jpg' /> 
 
      `,
		},
	},
	4: {
		unit: 'Unit 1',
		id: 'FG11-U1-P19-E4',
		audio: 'Audios/1.15-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page19/E4/Key/answerKey.png',
		component: T6,
		isHiddenCheck: true,
		inputSize: 50,
		stylesTextInput: {
			// background: 'red',
			// height: 25,
			// fontSize: 20,
			textAlign: 'center',
		},
		maxLength: 1,
		hintBox: [
			{
				src: [
					'bedtime',
					'clothes',
					'going out with friends',
					'homework',
					'housework',
					'staying up late',
					'using computers or tablets',
				],
				borderColor: 'white',
				fontColor: 'rgb(67 63 151)',
				width: '700px',
			},
		],

		titleQuestion: [
			{
				num: '4',
				title: `
        <headphone1 name='1.15' src='Audios/1.15-tieude.mp3'></headphone1> Use your answers to exercise 3 to match sentences A–G 
        with gaps 1–5 in the text. There are two extra sentences.`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		questions: [
			{
				title: `
        <img src='img/FriendsPlus/Page19/E4/1.png'  />

        <div style=' margin:10px 100px;display:flex;flex: space-between'>
          <div><b>1</b>#&emsp;</div>
          <div><b>2</b>#&emsp;</div>
          <div><b>3</b>#&emsp;</div>
          <div><b>4</b>#&emsp;</div>
          <div><b>5</b>#&emsp;</div>
        </div>
        `,
				answer: ['E', 'F', 'A', 'C', 'D'],
			},
		],
	},

	5: {
		unit: 'Unit 1',
		id: 'FG11-U1-P19-E5',
		audio: '',
		video: '',
		component: T6,
		inputSize: 200,
		maxLength: 11,
		textAlign: 'center',
		hintBox: [
			{
				src: [
					'bedtime',
					'clothes',
					'going out with friends',
					'homework',
					'housework',
					'staying up late',
					'using computers or tablets',
				],
				borderColor: 'white',
				fontColor: 'rgb(67 63 151)',
				width: '700px',
			},
		],

		exerciseKey: 'img/FriendsPlus/Page19/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `
         <b style='color:rgb(154 62 154)'>VOCABULARY</b>
        Complete the stems to make a noun and
				an adjective. Use a dictionary to help you. Either the
				noun or the adjective is in the text. (Sometimes you do
				not need to add anything.)`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		questions: [
			{
				title: `
        <span style='color:rgb(65 138 159)'>1&emsp;adolescence, adolescent</span>

        <div style=';border-left: 5px solid rgb(67 63 151)'>
          <b style='color:rgb(67 63 151);margin-left:20px'>Noun and adjective endings</b>

          <div style='display:flex;margin-left:20px'>
              <div >
                <b>1</b>&emsp;adolesc<br>
                  #<br>#<br>
                <b>2</b>&emsp;depend<br>
                  #<br>#<br>
              </div>
              <div style='margin:0px 50px'>
                <b>3</b>&emsp;priv<br>
                  #<br>#<br>
                <b>4</b>&emsp;emot<br>
                  #<br>#<br>
              </div>
              <div>
                <b>5</b>&emsp;critic<br>
                  #<br>#<br>
                <b>6</b>&emsp;distrust<br>
                  #<br>#<br>
              </div>
          </div>
        </div>
        `,
				answer: [
					'adolescence / adolescent',
					'adolescence / adolescent',
					'dependence / dependent',
					'dependence / dependent',
					'privacy / private',
					'privacy / private',
					'emotion / emotional',
					'emotion / emotional',
					'critic / criticism / critical',
					'critic / criticism / critical',
					'distrust / distrustful',
					'distrust / distrustful',
				],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'FG11-U1-P19-E6',
		audio: '',
		video: '',
		// recorder: true,
		component: UI,
		titleQuestion: [
			{
				num: '6',
				title: `
          <span style='color:rgb(1 145 67)'>SPEAKING</span> <span style='color:rgb(154 62 154)'>KEY PHRASES</span>
        Work in pairs or small groups.
				Discuss points 1 and 2, using the phrases below to help
				you. Share your ideas and opinions with the class.`,
				color: '#92278f',
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page19/E6/1.jpg' }]],
	},
}

export default json
