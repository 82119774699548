import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P95-E1',
    exerciseKey: 'img/FriendsPlus/Page95/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 100,
    maxLength: 1,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        title: `
        <div style='font-size: 23px'>
				<div style='color: rgb(65,145,175);font-size: 25px '>Reading</div>
        Read the article about a type of art. Are the sentences true or false? Write T or F. 
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex'>
         <img style="width:23cm; height:21cm" src='img/FriendsPlus/Page95/E1/1.jpg' />
         <div style='font-size:22px; display:flex; flex-direction: column '>
              <div>#&ensp;<b>1. </b>Temporary art is a new kind of art.</div>
              <div>#&ensp;<b>2. </b>Artists use sand or chalk in their artwork so that it will exist for a long time.</div>
              <div>#&ensp;<b>3. </b>Jorge Rodríguez-Gerada is an important artist in the field of temporary art.</div>
              <div>#&ensp;<b>4. </b>His work is very quick to create.</div>
              <div>#&ensp;<b>5. </b>He uses maps to plan his artwork.</div>
							<div>#&ensp;<b>6. </b>A lot of people help him to create his art.</div>
							<div>#&ensp;<b>7. </b>Jorge’s artwork called Of the Many, One was part of a new garden design for the National Mall.</div>
							<div>#&ensp;<b>8. </b>The artwork does not exist anymore.</div>
          </div>
        </div>
          
        `,
        answer: ['F', 'F', 'T', 'F', 'T', 'T', 'F', 'T'],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P95-E2',
    // exerciseKey: 'img/FriendsPlus/Page95/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 100,
    maxLength: 1,
    textAlign: 'center',
    hideBtnFooter: true,
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      textTransform: 'uppercase',
    },
    isHiddenCheck: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        title: `
        <div style='font-size: 22px'>
				<div style='color: rgb(65,145,175);font-size: 25px '>Speaking</div>
        Work in pairs. You and a classmate are planning a 
				surprise for a friend to celebrate his / her birthday. You 
				would like to take him / her to a concert. Decide which 
				show is most suitable and discuss the arrangements 
				for the outing. Use the ideas below to help you. 
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style=''>
         <div>- When to go to the concert</div>
				 <div>- The type of concert</div>
				 <div>- Getting tickets</div>
				 <div>- Transport to and from</div>
				 <div>- Food and drink</div>
				 <div>- Keeping the surprise a secret</div>
        </div>
          
        `,
        answer: [''],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P95-E3',
    exerciseKey: 'img/FriendsPlus/Page95/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 100,
    maxLength: 30,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 22,
      height: 25,
      textTransform: 'uppercase',
    },
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 22,
      height: 170,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        title: `
        <div style='font-size: 22px'>
				<div style='color: rgb(65,145,175);font-size: 25px '>Writing</div>
       Match the phrases (A–G) with paragraphs 1–4.  
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex; font-size:22px'>
         <div style='width:50%; margin-right:20px'>
				 <div>Paragraph 1: Introduce a book</div>
				 <div>Paragraph 2: Describe the setting</div>
				 <div>Paragraph 3: Describe the plot and the characters</div>
				 <div>Paragraph 4: Express your opinion and give recommendation</div>
				 </div>
				 <div style='width:50%'>
				 <div><b>A </b>I’d definitely recommend it.</div>
				 <div><b>B </b>It’s set in …</div>
				 <div><b>C </b>What I love about the book is …</div>
				 <div><b>D </b>It’s called …. and is written by …</div>
				 <div><b>E </b>It tells the story of …</div>
				 <div><b>F </b>The story is very ….</div>
				 <div><b>G </b>I really identify with the main character, …</div>
				 </div>


        </div>
				<div style='display:flex; flex-direction: column; font-size:22px; margin-bottom:20px'>
              <b>ANSWER:</b>
							<div><i style='font-size:18px; color: rgb(152,198,204)'>*Ex: Answer: Paragraph 1 - A, B, C</i></div>
              &ensp;<b>Paragraph 1 - #</b><br>
              &ensp;<b>Paragraph 2 - #</b><br>
              &ensp;<b>Paragraph 3 - #</b><br>
							&ensp;<b>Paragraph 4 - #</b>
					</div>
				<div style='font-size:22px; width:90%'>
				 <b>Complete the sentences to write an entry (150–180 words) 
				 for a school writing competition ‘Your favourite book’. 
				 Describe the book and say what you liked and did not 
				 like about it</b>
				 <textarea id='4' row='3'>
				 </div>
          
        `,
        answer: ['D', 'B', 'E, F', 'C, G, A', ''],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'FGF11-U7-P95-E4',
    audio: '/Audios/3.11-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page95/E4/Key/E4answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        title: `
        <div style='font-size: 23px'>
				<div style='color: rgb(65,145,175);font-size: 25px '>Listening</div>
        <headphone1 name='3.11' src='Audios/3.11.mp3'></headphone1> You will hear a talk. Look at the questions below and choose the best answers (A–C).
        </div>
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`, //0
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //2
          `A <br> B <br> C`, //3
          `A <br><br> B <br><br> C`, //4
        ],
        answers: ['0-4', '1-0', '2-0', '3-8', '4-4'],
        initialValue: [],
      },
      Layout: `
       <div style='display:flex; flex-direction:column; background-color: rgb(242,237,218); height:420px; width:950px'>
	        
          <div style='position:relative; height:fit-content'>
            <div style=" position: absolute; top: 15px; left: 28px; font-size:18px; width:47%">
            <div><b>1 </b>The Gold Performance Academy</div>
            <div style="display: flex;">
              <div><input id='0' type='Circle'/></div>
              <div style='margin-left:20px'>
                is a school of contemporary dance.<br>
                teaches people dance, drama and music.<br>
                specialises in classical ballet and musical theatre.
              </div>
            </div>
          </div>
          <div style=" position: absolute; top: 145px; left: 28px; font-size:18px; width:47%">
            <div><b>2 </b>The Academy helps students to</div>
            <div style="display: flex;">
              <div><input id='1' type='Circle'/></div>
              <div style='margin-left:20px'>
                build confidence as a performer.<br>
                choose the right performing art for them. <br>
                decide on a future career.
              </div>
            </div>
          </div>
          <div style=" position: absolute; top: 285px; left: 28px; font-size:18px; width:47%">
            <div><b>3 </b>Many of the teachers at the Academy</div>
            <div style="display: flex;">
              <div><input id='2' type='Circle'/></div>
              <div style='margin-left:20px'>
                have experience in the industry.<br>
                perform in the lessons.<br>
                like pop music.
              </div>
            </div>
          </div>

          <div style=" position: absolute; top: 15px; left: 504px; font-size:18px; width:77%">
            <div><b>4 </b>Every year, students</div>
            <div style="display: flex;">
              <div><input id='3' type='Circle'/></div>
              <div style='margin-left:20px'>
                work in a theatre during the summer.<br>
                design a certificate for a ceremony.<br>
                take part in stage performances. 
              </div>
            </div>
          </div>
          <div style=" position: absolute; top: 145px; left: 504px; font-size:18px; width:47%">
            <div><b>5 </b>The speaker</div>
            <div style="display: flex;">
              <div><input id='4' type='Circle'/></div>
              <div style='margin-left:20px'>
                wants everyone in the audience to attend the Academy. <br>
                invites her audience to come to see a lesson at the Academy. <br>
                asks the audience to write an email to apply to the Academy.
              </div>
            </div>
          </div>

          </div>
      </div>
      `,
    },
  },
};

export default json;
