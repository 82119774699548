import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P47-E1',
    exerciseKey: 'img/FriendsPlus/Page47/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 100,
    maxLength: 5,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 25,
      height: 30,
      textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '1',
        title: `
        <div style="margin-bottom: 10px;font-size: 25px; color: rgb(0,111,149);">Reading</div>  
        Read extracts 1 and 2 below, which have missing 
        sentences. Try to predict what each missing sentence is 
        about. Then look at A−D and choose the sentences that 
        are closest to your prediction.
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
          <div style='font-size:23px'>
              <div style="display: flex; gap: 20px;">
                <b>1&ensp;</b>
                <div style='width:900px; text-align:left'>Be careful when you choose a leisure activity because some activities take up a lot of time or effort. 
                #&ensp;.Find out what an activity needs before you begin, and you won’t have wasted your time
                </div>
              </div>
              <div style="display: flex; gap: 20px;">
                <b>2&ensp;</b>
                <div style='width:900px; text-align:left'>If you think you have hurt yourself while exercising, stop imediately.
                #&ensp;. As a result, they end up with worse injuries, so it takes them much longer to get better.
                </div>
              </div>
      
              <div style="display: flex; gap: 20px;">
                <b>A&ensp;</b>
                <div style='width:900px; text-align:left'>
                This is important if a doctor has told you to rest.
                </div>
              </div>
              <div style="display: flex; gap: 20px;">
                <b>B&ensp;</b>
                <div style='width:900px; text-align:left'>
                This means that you may become discouraged and give up because it is too demanding.
                </div>
              </div>
              <div style="display: flex; gap: 20px;">
                <b>C&ensp;</b>
                <div style='width:900px; text-align:left'>
                 Too many people try to continue, despite the pain.
                </div>
              </div>
              <div style="display: flex; gap: 20px;">
                <b>D&ensp;</b>
                <div style='width:900px; text-align:left'>
                Then they find that it doesn’t interest them as much as they thought it would.
                </div>
              </div>
          </div>
          
        `,
        answer: ['B', 'C'],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P47-E2',
    exerciseKey: 'img/FriendsPlus/Page47/E2/Key/E2answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 50,
    maxLength: 5,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 20,
      height: 20,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `<span style='font-size: 22px'>Read the text. Five sentences have been removed. 
        Choose the correct sentences (A-G) to fill the gaps (1-5). 
        There are two extra sentences. </span>
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
          <div style='display:flex; font-size:19px; background-color:rgb(212,235,217); width:1250px; padding: 10px 20px'>
              <div style="display: flex; flex-direction:column; gap: 20px; width:65%; margin-right:20px">
                <div style=' text-align:left'>
                  Hobbies and leisure activities are an ideal way to 
                  destress, meet people and develop your creativity. But if 
                  you don’t have a lot of spare time, it can be a challenge 
                  to find one that suits your tastes and fits into your busy 
                  life. Of course, some readers ask why they should take 
                  up a hobby at all.&ensp; <b style='font-size:15px; padding-bottom:80px'>1</b>&ensp;# 
                  They don’t realise that being 
                  more active and doing something really enjoyable can 
                  improve their energy levels and their mood.
                </div>
                <div style=' text-align:left'>
                  One strategy for finding the right leisure activity is to 
                  think about what you enjoyed when you were a young 
                  child.&ensp; <b style='font-size:15px; padding-bottom:80px'>2</b>&ensp;# Now here’s your chance to try again. 
                  Perhaps you wish you could spend time exploring 
                  on your bicycle again, or wonder why you stopped 
                  drawing cartoons. Think about going back to similar 
                  activities.
                </div>
                <div style=' text-align:left'>
                  Another approach is to think about the hobbies your 
                  friends have taken up.&ensp; <b style='font-size:15px; padding-bottom:80px'>3</b>&ensp;# Training together will 
                  give both of you a boost in motivation. Or if you see a 
                  friend’s paintings and you fancy doing some painting 
                  as well, give it a try. Something to keep in mind is that 
                  an activity may not interest you after you’ve tried it. 
                </div>
                <div style=' text-align:left'>
                 &ensp; <b style='font-size:15px; padding-bottom:80px'>4</b>&ensp;#  So don’t beg your parents to pay for a lot of 
                  equipment or a whole year’s lessons right away.
                  Begin slowly, borrow what you need, and see if the 
                  activity is really right for you. If you still enjoy an 
                  activity after a few weeks of starting it, it might be 
                  the right hobby for you.&ensp; <b style='font-size:15px; padding-bottom:80px'>5</b>&ensp;#  
                  And if you complete it, perhaps you can ask for some equipment for your next 
                  birthday present.
                </div>
              </div>
              
              
               <div style="display: flex; flex-direction:column; gap: 20px; width:35%">
                <div style='text-align:left'>
                  <b>A&ensp;</b>
                  Most people try activities they think they will be good at.
                </div>
                <div style='text-align:left'>
                  <b>B&ensp;</b>
                  Actually, lots of people give hobbies up within a couple of months of starting them.
                </div>
                <div style='text-align:left'>
                  <b>C&ensp;</b>
                  If you are interested when a classmate talks about her martial arts class, ask if you can join her.
                </div>
                <div style='text-align:left'>
                  <b>D&ensp;</b>
                  Only then is the time to pay for the full course.
                </div>
                <div style='text-align:left'>
                  <b>E&ensp;</b>
                  Parents may also have good ideas for activities too.
                </div>
                <div style='text-align:left'>
                  <b>F&ensp;</b>
                  After an exhausting day at school, all they want to do is sit in front of the TV or go online.
                </div>
                <div style='text-align:left'>
                  <b>G&ensp;</b>
                  Do you regret giving up those music lessons when you were ten? 
                </div>
              </div>

              
          </div>
          
        `,
        answer: ['F', 'G', 'C', 'B', 'D'],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P47-E3',
    audio: 'Audios/1.40-audio.mp3',
    exerciseKey: 'img/FriendsPlus/Page47/E3/Key/E3answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 100,
    maxLength: 5,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 23,
      height: 30,
      textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
        <div style="margin-bottom: 10px;font-size: 25px; color: rgb(0,111,149);">Listening</div>  
        <div style='width:800px'>
         <span style=''><headphone1 name="1.40" src="Audios/1.40-tieude.mp3"></headphone1></span>
          You will hear four people talking about 
          body-related subjects. You will hear the audio twice. 
          Match sentences A-E with speakers 1-4. There is one 
          extra sentence.
        </div>
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
          <div style='font-size:23px'>

              <div style="display: flex; gap: 20px;">
                <b>A&ensp;</b>
                <div style='width:900px; text-align:left'>
                This speaker talks about the result of a past situation.
                </div>
              </div>
              <div style="display: flex; gap: 20px;">
                <b>B&ensp;</b>
                <div style='width:900px; text-align:left'>
                This speaker wants to advertise a solution to a problem.
                </div>
              </div>
              <div style="display: flex; gap: 20px;">
                <b>C&ensp;</b>
                <div style='width:900px; text-align:left'>
                 This speaker asks for advice about a problem.
                </div>
              </div>
              <div style="display: flex; gap: 20px;">
                <b>D&ensp;</b>
                <div style='width:900px; text-align:left'>
                This speaker gives instructions to a group.
                </div>
              </div>
              <div style="display: flex; gap: 20px;">
                <b>E&ensp;</b>
                <div style='width:900px; text-align:left'>
                This speaker predicts the future results of a problem.
                </div>
              </div>
              
              <div style="display: flex; gap: 20px; margin-top:60px">
                <b>1. </b>#&ensp;
                <b>2. </b>#&ensp;
                <b>3. </b>#&ensp;
                <b>4. </b>#&ensp;
              </div>
          </div>
          
        `,
        answer: ['D', 'B', 'A', 'E'],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P47-E4',
    // exerciseKey: 'img/FriendsPlus/Page47/E1/Key/E1answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        title: `
        <div style="margin-bottom: 10px; color: rgb(0,111,149);">Speaking</div>  
        <div style="font-size: 21px">
        Work in pairs. You and a friend want to go  cycling this
        weekend, but where? You have two options A and B 
        to choose from. Agree on the one which you consider 
        more appropriate, and explain why you reject the 
        other one.
        </div>
        
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
          <div>
             <img style="width:16cm;" src='img/FriendsPlus/Page47/E4/1.jpg' />
          </div>
          
        `,
        answer: [''],
      },
    ],
  },
  5: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P47-E5',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page59/E2/Key/E2answerKey.png',
    component: T6,
    inputSize: 1000,
    // maxLength: 15,
    hideBtnFooter: true,
    textareaStyle: {
      // background: 'none',
      width: 700,
      fontSize: 20,
      height: 340,
    },
    titleQuestion: [
      {
        title: `
				<div style=''>
				<div style='color: rgb(124,181,220); font-size: 27px'>Writing</div>
				<div>Read the task below and write an opinion essay 
        (150-180 words).
				</div>
				
				</div>
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style='font-size:23px; width: 80%'>Many people believe that teenagers spend too much 
        time on social media. Write an essay in which you
        give your own opinion about this issue and propose 
        solutions to it.
        </div>
				<div style='font-size:20px;  margin-top:20px'>
					<b>ANSWER:</b>
					<textarea id='0' row='20'></textarea>
					</div>


	      `,
        answer: [''],
      },
    ],
  },
};

export default json;
