import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P30-E1',
		audio: '',
		video: '',
		component: UI,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '1',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> Look at the blog and the photos. What kind of
        game doX you think the people are playing? What do you
        think happens?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page30/E1/1.jpg' }]],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P30-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page30/Key/E2answerKey.png',
		// checkUppercase:true,
		titleQuestion: [
			{
				num: '2',
				title: 'Read the blog post quickly and check your ideas from exercise 1.',
				color: '#8e258c',
				//prefix: { icon: [''], text: 'VOCABULARY' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 760,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
          <img style='margin:10px' src='img/FriendsPlus/Page30/E1/1.jpg'>
           <div>
            <u style='font-weight:bold;'>ANSWERS:</u>&emsp;
            #
           </div>
          `,
				answer: ['They are geocaching. They find geocaches with their smartphones.'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P30-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page30/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `Read the blog post quickly and check your ideas from xexercise 1.`,
				color: '#8e258c',
				//prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// maxLength: 27,
		isHiddenCheck: true,
		textareaStyle: { width: 700 },
		inputSize: 40,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
					<div style='display:flex'>
          		 <img style='margin:10px' src='img/FriendsPlus/Page30/E3/1.jpg'>
							 	<div style='margin:10px 15px'>
									<b>1</b>&ensp;Which questions in exercise 4 ask about?<br>
          		  	<div style='margin:0px 20px'>
										<b>a</b>&ensp;#<br>
										<b>b</b>&ensp;#,#<br>
										<b>c</b>&ensp;#,#,#<br>

									</div>
									 <div>
										<b>3</b>&ensp;Which question in exercise 4 is about the whole blog post?<br>
											<input width='200px' id='6'></input>
										</div>
								</div>
					</div>

            <div>
							<b>2</b>&ensp;How did you decide on the answers to question 1?<br>
								<textarea id='7' rows='2'></textarea>
						</div>
           
          `,
				answer: [
					'6',
					'4 / 5',
					'4 / 5',
					'1 / 2 / 3',
					'1 / 2 / 3',
					'1 / 2 / 3',
					'question 6',
					'By looking for words that give clues, e.g. The writer thinks suggests that the question is about the writer’s opinion.',
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P30-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page30/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `Read the blog post again. Choose the correct option (a–d).`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		component: Circle_Write,
		totalInput: 6,
		titleImage: '',
		isHiddenCheck: true,
		isAllowSubmit: false,
		question: {
			Write: {
				isHiddenCheck: true,
				maxLength: 11,
				inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
				answers: [''],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					borderRadius: '50%',
					fontWeight: 'bold',
					borderColor: 'transparent',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					// borderColor: '#00aeef',
				},
				limitSelect: 1,
				listWords: [
					`a <br> b <br> c <br> d`, //1
					`a <br> b <br> c <br> d`, //1
					`a <br> b <br> c <br> d`, //1
					`a <br> b <br> c <br> d`, //1
					`a <br> b <br> c <br> d`, //1
					`a <br> b <br> c <br> d`, //1

				],
				answers: ['0-0', '1-12', '2-8', '3-4', '4-4', '5-0'],
				initialValue: [],
			},
			Layout: `
			<div>
				<div>
					<b>1</b>&ensp;
           		 To take part in geocaching you need <br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
             	a mobile phone with GPS.<br>
              a mobile phone with GPS and a toy or gift.<br>
              a mobile phone, a toy or gift and a logbook.<br>
							nothing – just yourself!
            	</div>
            </div>

						<b>2</b>&ensp;
           		  The activity of geocaching <br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
            	began in the 19<sup>th</sup> century, but only became popular after 2000. <br>
              was originally only popular in one region of England, but now has fans all over the world.<br>
              was called ‘letterboxing’ when it was first invented.<br>
							has similarities with a 19th-century game.
            	</div>
            </div>

						<b>3</b>&ensp;
           		  When you find a gift in a geocache, you <br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
              make a note of the gift on the website. <br>
              can borrow the gift, but have to return it. <br>
              are allowed to take the gift if you replace it with something else.<br>
							take a photo of the gift as proof that you have found it.
            	</div>
            </div>
				</div>
			
				<div>
					<b>4</b>&ensp;
           		  The writer thinks that the most enjoyable kind of geocaching is when <br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
              you have to find a public webcam. <br>
              you have to find a series of geocaches. <br>
              you have to find a geocache and then hide it in a different place.<br>
							you simply have to find one geocache.
            	</div>
            </div>

						<b>5</b>&ensp;
           		  The writer believes the ‘Fumble after Dark’ event<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='4' type='Circle'/></div>
		          <div style='margin-left:20px'>
              is less fun than geocaching with a couple of friends.<br>
              would be a very enjoyable event to attend.<br>
              is only suitable for adventurous people.<br>
							needs to attract more people.
            	</div>
            </div>

						<b>6</b>&ensp;
           		  The writer has written the blog mainly to<br>
            <div style="display: flex;margin-left:40px">

		          <div><input id='5' type='Circle'/></div>
		          <div style='margin-left:20px'>
              suggest that people try geocaching.<br>
              explain the differences between letterboxing and geocaching.<br>
              publicise a geocaching event in Sweden.<br>
							warn readers that geocaching is addictive.
            	</div>
            </div>
				
				</div>
			
			</div> 
      `,
		},
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P30-E5',
		audio: '',
		video: '',
		component: UI,
		hideBtnFooter: true,
		// recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '5',
				title: `<b style="color:rgb(10 127 64)">SPEAKING</b> <span style='color:#8e258c'>KEY PHRASE</span> Work in pairs. Would you like to
      play this game? Why? / Why not? Use the adjectives, and phrases below to help you.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page30/E5/1.jpg' }]],
	},
}

export default json
