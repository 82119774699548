import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
	1: {
		unit: 'Unit 6',
		id: 'FGF11-M-P81-E1',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page81/key/E1answerKey.png',
		component: T6,
		inputSize: 160,
		// maxLength: 1,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 25,
		},
		isHiddenCheck: false, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(45 206 137)">SPEAKING</span> Work in pairs. Read the definition of vocational. 
Then think of three jobs for which you would need to do a 
vocational degree. 
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div>
						<img style="margin-left: 200px;" src="/img/FriendsPlus/Page81/E1/1.jpg" alt="">
						<p style="font-weight:bold; margin-top:50px">Answer</p>
						<div style="display: flex; margin-right:30px">
						<p>#, </p>
						<p>#, </p>
						<p>#</p>
						</div>
					</div>
        `,
				answer: ['nurse / doctor / teacher', 'nurse / doctor / teacher', 'nurse / doctor / teacher'],
			},
		],
	},
	2: {
		unit: 'Unit 6',
		id: 'FGF11-M-P81-E2',
		audio: '',
		video: '',
		// exerciseKey: '',
		hideBtnFooter: true,
		component: T6,
		recorder: true,
		inputSize: 160,
		// maxLength: 1,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 22,
		},
		isHiddenCheck: false, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
           Read the task and the essay. Do you agree with the writer’s 
					general opinion? Why? / Why not?
        `,
				color: '#860052',
			},
		], //hideBtnFooter: true,
		questions: [
			{
				title: `
          <div>
						<img style="margin-left: 50px; margin-top: 40px" src="/img/FriendsPlus/Page81/E2/1.jpg" alt="">
						
					</div>
        `,
				answer: [''],
			},
		],
	},
	3: {
		unit: 'Unit 6',
		id: 'FGF11-M-P81-E3',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page81/key/E3answerKey.png',
		component: T6,
		inputSize: 760,
		// maxLength: 1,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          Look at the paragraph plan for a for and against essay. 
Then decide where the paragraph breaks should come in 
the essay in exercise 2.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div>
						<p style="margin-top:-10px">Paragraph 1: Introduction</p>

						<p style="margin-top:-10px">Paragraph 2: Arguments for</p>

						<p style="margin-top:-10px">Paragraph 3: Arguments against</p>

						<p style="margin-top:-10px">Paragraph 4: Conclusion (the writer's opinion)</p>
						
					</div>
					<div>
						<h1>Answer:</h1>
						<p style="margin-left:50px" >Paragraph 1: #</p>
						<p style="margin-left:50px">Paragraph 2: #</p>
						<p style="margin-left:50px">Paragraph 3: #</p>
						<p style="margin-left:50px">Paragraph 4: #</p>
					</div>
        `,
				answer: ['Some people maintain ... we need to consider.', 'It is certainly true that the workplace … with a vocational qualification.', 'On the other hand ... at university?', 'On balance, … really interests you.'],
			},
		],
	},
	4: {
		unit: 'Unit 6',
		id: 'FGF11-M-P81-E4',
		exerciseKey: '/img/FriendsPlus/Page81/key/E4answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 500,
		// maxLength: 1,
		// textareaStyle: { width: 610 },
		hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
          Look at the essay in exercise 2 again. What phrase does the 
writer use to
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
         <div>
				 		<div style="margin-top:10px">
							<p style="margin-top:-15px; margin-right:10px"><span style="font-weight:bold">1 </span>   introduce the first argument for?</p>
							<p style="margin-top:-15px; margin-right:10px"><span style="font-weight:bold">2 </span>   introduce the second argument for?</p>
							<p style="margin-top:-15px; margin-right:10px"><span style="font-weight:bold">3 </span>   introduce the first argument against?</p>
							<p style="margin-top:-15px; margin-right:10px"><span style="font-weight:bold">4 </span>   introduce the second argument against?</p>
							<p style="margin-top:-15px; margin-right:10px"><span style="font-weight:bold">5 </span>   begin the conclusion?</p>
							
						</div>
						<div style="margin-top: 50px">
						<h1>Answer:</h1>
								<p style="font-weight:bold">1  #</p>
								<p style="font-weight:bold">2  #</p>
								<p style="font-weight:bold">3  #</p>
								<p style="font-weight:bold">4  #</p>
								<p style="font-weight:bold">5  #</p>
						</div>
				 </div>
        `,
				answer: ['It is certainly true that ...', 'It is also undeniable that ...', 'On the other hand, ...', 'Another problem is that ...', 'On balance, ...'],
			},
		],
	},
	5: {
		unit: "Unit 7",
		id: "FGF11-U7-P85-E5",
		audio: "",
		video: "",
		component: T6,
		inputSize: 1100,
		isHiddenCheck: true,
		exerciseKey: '/img/FriendsPlus/Page81/key/E5answerKey.png',
		// maxLength: 1,
		textareaStyle: { width: 610 },
		hideBtnFooter: false, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
       Read the <span style="color:#3785eb">Writting Strategy 1</span>  Underline two rhetorical 
questions in the essay in exercise 2. What are the expected 
answers?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
						<img src='img/FriendsPlus/Page81/E5/1.jpg'>
						<img style='height:15cm' src='img/FriendsPlus/Page81/E5/2.jpg'>

						<div style='margin-top:-40px'>
							<u style='font-weight:bold;font-style:italic'>Answer:</u><br>
							#?<br>#<br>#?<br>#
						</div>

        `,
				answer: ["How can a teenager know what job is right for him or her / The expected answer is that it is better to explore other interests while at university.",
					"The expected answer is that they can't know what job is right for them when they are still teenagers. / Is it not better for students to explore other interests while at university",
					"How can a teenager know what job is right for him or her / The expected answer is that it is better to explore other interests while at university.",
					"The expected answer is that they can't know what job is right for them when they are still teenagers. / Is it not better for students to explore other interests while at university",
				],
			},
		],
	},
	6: {
		unit: 'Unit 6',
		id: 'FGF11-M-P81-E6',
		// exerciseKey: '',
		audio: '',
		video: '',
		hideBtnFooter: true,
		recorder: true,
		component: T6,
		inputSize: 500,
		// maxLength: 1,
		// textareaStyle: { width: 610 },
		// Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
          Read the task. Then plan your essay following the 
paragraph plan below.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
         <div>
				 <div>
				 		<img style="margin-left: 10px;" src="/img/FriendsPlus/Page81/E6/1.jpg" alt="">
						</div>
						<div style="margin-top:20px">
						<p  style="font-weight:bold">Paragraph 1: Introduction <span style="font-weight:200">(rephrase the statement in 
 the task)</span></p>
 						<p  style="font-weight:bold;margin-top:-20px">Paragraph 2: Arguments for <span style="font-weight:200">(include two)</span></p>
 						<p  style="font-weight:bold;margin-top:-20px">Paragraph 3: Arguments against <span style="font-weight:200">(include two)</span></p>
 						<p  style="font-weight:bold;margin-top:-20px">Paragraph 4: Conclusion  <span style="font-weight:200">(state your opinion)</span></p>
				 </div>
				 </div>
				 
        `,
				answer: [''],
			},
		],
	},
	7: {
		unit: 'Unit 6',
		id: 'FGF11-M-P81-E7',
		// exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 500,
		// maxLength: 1,
		textareaStyle: { width: 800 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
          Write your essay (150-180 words) using your plan from 
exercise 6. Include one or two rhetorical questions.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
         <div>
				 <div>
				 		<img style="margin-left: 90px;" src="/img/FriendsPlus/Page81/E7/1.jpg" alt="">
						<div style="margin-left:150px; margin-top:30px">
						<textarea id='6' rows="9"></textarea>
						</div>
				 </div>
				 
        `,
				answer: [''],
			},
		],
	},
};

export default json;
