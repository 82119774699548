import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P72-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
        <b style='color:rgb(0,147,70);'>SPEAKING</b>  Describe the photos. In your opinion, which is <br/>a) the most interesting job
and b) the most difficult job? Give reasons.
        
        
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page72/E1/1.jpg' }]],
  },
  2: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P72-E2',
    audio: '',
    video: '',
    component: T6,
    inputSize: 200,
    isHiddenCheck: true,
    // checkDuplicated: true,
    // stylesTextInput: {
    //   textAlign: 'center',
    //   borderBottom: 'none',
    //   fontWeight: 'bold',
    //   fontSize: 23,
    // },
    exerciseKey: '/img/FriendsPlus/Page72/Key/E2answerKey.png',
    textAlign: 'center',
    maxLength: 17,
    textareaStyle: {},
    titleQuestion: [
      {
        num: '2',
        title: `
            Look at the list of nouns for describing character. What are the
corresponding adjectives? Use a dictionary and the list of adjective endings below
to help you. Sometimes, you just need to remove a noun ending to form the
adjective.
            
        `,
        color: '#8e258c',
        prefix: { icon: [''], text: 'VOCABULARY' },
      },
    ],
    hintBox: [
      {
        src: ['<b>Describing character</b>'],
        width: 300,
        fontColor: 'purple',
        borderColor: 'none',
      },
      {
        src: [
          '<b>Nouns</b>',
          'ambition',
          'cheerfulness',
          'creativity',
          'enthusiasm',
          'flexibility',
          'generosity',
          'honesty',
          'idealism',
          'intelligence',
          'loyalty',
          'maturity',
          'modesty',
          'optimism',
          'patience',
          'pessimism',
          'punctuality',
          'realism',
          'self - confidence',
          'seriousness',
          'shyness',
          'sociability',
          'stubbornness',
          'sympathy',
          'thoughtfulness',
        ],
        width: 850,
        fontColor: 'purple',
        borderColor: 'none',
      },
      {
        src: [
          '<b>Adjective endings</b>',
          '-able / -ible',
          '-al',
          '-ant / -ent',
          '-astic / -istic',
          '-ed',
          '-est',
          '- ful',
          '- ive',
          '- ous',
          '- ual',
        ],
        width: 700,
        fontColor: 'purple',
        borderColor: 'none',
      },
    ],

    questions: [
      {
        title: `
          <hintbox id='0'></hintbox>
          <hintbox id='1'></hintbox>
          <hintbox id='2'></hintbox>
          <div style='width:20cm'>
            <u style='font-style:italic;font-weight:bold'>Answer:</u>#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#,#
          </div>

        `,
        answer: [
          'ambitious',
          'cheerful',
          'creative',
          'enthusiastic',
          'flexible',
          'generous',
          'honest',
          'idealistic',
          'intelligent',
          'loyal',
          'mature',
          'modest',
          'optimistic',
          'patient',
          'pessimistic',
          'punctual',
          'realistic',
          'self - confident',
          'serious',
          'shy',
          'sociable',
          'stubborn',
          'sympathetic',
          'thoughtful',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P72-E3',
    exerciseKey: '/img/FriendsPlus/Page72/Key/E3answerKey.png',
    audio: 'Audios/2.28-audio.mp3',
    video: '',
    titleQuestion: [
      {
        num: '3',
        title: `<headphone1 name='2.28' src='Audios/2.28-tieude.mp3'></headphone1> <span style='color:#2486c5'>PRONUNCIATION</span> Listen to ten noun–adjective pairs, paying attention to the difference in main stress between the noun and the adjective.
            
        `,
        color: '#860052',
        prefix: { icon: [''], text: '' },
      },
    ],
    component: Circle_Write,

    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 149, fontSize: 23, borderBottom: 'none', textAlign: 'center' },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: 'transparent',
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: 'solid 2px',
          borderColor: '#00aeef',
        },
        limitSelect: 2,
        listWords: [
          `
          cr|e|a|ti|ve| – cr|ea|ti|vi|ty
         `,
          `ent|hu|s|ia|st|ic – en|thu|sia|sm`,
          `fle|x|ible – flexi|bil|ity`,
          `ge|ner|ous – ge|ner|os|ity`,
          `op|tim|is|tic – op|tim|ism`,
          `pessi|mi|s|tic – pe|ssim|ism`,
          `pu|n|ct|ual – pun|ctu|al|ity`,
          `rea|li|stic – re|al|ism`,
          `so|ci|able – so|cia|bi|lity`,
          `sym|pa|the|tic – sym|pa|thy`,
        ],
        answers: [
          '0-40',
          '0-26',
          '1-6',
          '1-16',
          '2-0',
          '2-10',
          '3-0',
          '3-12',
          '4-4',
          '4-10',
          '5-2',
          '5-10',
          '6-0',
          '6-14',
          '7-2',
          '7-8',
          '8-0',
          '8-12',
          '9-4',
          '9-10',
        ],
        initialValue: [],
      },
      Layout: `
       
          <div>
            <input id='0'  type='Circle' /><br>
            <input id='1'  type='Circle' /><br>
            <input id='2'  type='Circle' /><br>
            <input id='3'  type='Circle' /><br>
            <input id='4'  type='Circle' /><br>
            <input id='5'  type='Circle' /><br>
            <input id='6'  type='Circle' /><br>
            <input id='7'  type='Circle' /><br>
            <input id='8'  type='Circle' /><br>
            <input id='9'  type='Circle' />
          </div>
      `,
    },
  },
  4: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P72-E4',
    exerciseKey: '/img/FriendsPlus/Page72/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 160,
    maxLength: 11,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 20,
    },
    textareaStyle: {},
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
            Match 1–5 with a–e and make second conditional sentences using the correct form of the verbs in brackets.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style='display:flex;justify-content:space-between;padding:5px 20px;background:rgb(253 247 223) '>
            <div style='width:45%'>
              <b>1</b>&ensp;I#(can) buy clothes really cheaply <br>
              <b>2</b>&ensp;If tablet computers#(not cost) so much,<br>
              <b>3</b>&ensp;I#(be) upset <br>
              <b>4</b>&ensp;If I#(need) money,<br>
              <b>5</b>&ensp;If I#(not have) a mobile phone, 
            </div>
            <div style='width:50%'>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>a</b>&ensp;I#(ask) my dad to buy one for me.<br>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>b</b>&ensp;I#(use) my mum’s laptop to send messages.<br>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>c</b>&ensp;if I#(shop) at the charity shop.<br>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>d</b>&ensp;I#(get) a holiday job.<br>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>e</b>&ensp;if the coffee shop in my village#(close).
            </div>
          </div>
    
        `,
        answer: [
          'could',
          "didn't cost",
          'would be',
          'needed',
          "didn't have",
          '2',
          'would ask',
          '5',
          'would use',
          '1',
          'shopped',
          '4',
          'would get',
          '3',
          'closed',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P72-E5',
    exerciseKey: '/img/FriendsPlus/Page72/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 160,
    maxLength: 10,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      borderBottom: 'none',
    },
    isHiddenCheck: true,
    textareaStyle: {},
    titleQuestion: [
      {
        num: '5',
        title: `
            Complete the money tips with the second conditional. Use the correct form of the verbs below.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <span style="color: rgb(239,140,27);">can','get','make','save','sell','swap</span>
       
            <div style='position: relative;'>
              <div><img src="/img/FriendsPlus/Page72/E5/E5.jpg" ></div>
              <div style='position: absolute; top: 66px; left: 399px;'>#</div>
              <div style='position: absolute; top: 103px; left: 153px;'>#</div>
              <div style='position: absolute; top: 218px; left: 100px;'>#</div>
              <div style='position: absolute; top: 254px; left: 100px;'>#</div>
              <div style='position: absolute; top: 333px; left: 300px;'>#</div>
              <div style='position: absolute; top: 369px; left: 97px;'>#</div>
              
            </div>

        `,
        answer: ['would save', 'made', 'swapped', 'would get', 'sold', 'could'],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'FGF11-U6-P72-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: `
            <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Ask and answer the following questions.
            <div style="margin-top: 10px; font-weight:400">
              <span>What would you do if …</span> <br>
              <b>1</b>
              <span> there were no shops in your town?</span> <br>
              <b>2</b>
              <span> you won 1 billion VND?</span> <br>
              <b>3</b>
              <span> you could have a free holiday anywhere in the world?</span>
              
            </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
