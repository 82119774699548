import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 1',
    id: 'FGF11-U1-P20-E1',
    // exerciseKey: 'img/FriendsPlus/Page85/E6/Key/E6answerKey.png',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    // recorder: true,
    // audio: 'Audios/2.02.mp3',
    component: T6,
    inputSize: 130,
    maxLength: 20,
    hideBtnFooter: true,
    textAlign: 'center',
    stylesTextInput: {
      background: 'none',
      // borderBottom: 'none',
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
    },
    hideBtnFooter: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style='color:rgb(2, 146, 97);'>SPEAKING</span>
      Work in pairs. Look at the advert below. In your 
      opinion, what would be the best and worst things about 
      spending a year with a family abroad? Use the prompts 
      below and your own ideas.
			  `,
        color: 'rgb(146,39,143)',
      },
    ],

    questions: [
      {
        title: `
        <div style='width: 100%; position: relative'>
          <div style='font-size:24px; position: relative'>
              <div style="color:rgb(2, 146, 97); margin-bottom:10px  ">
                <b>Exchange programmes</b>
                &ensp;eating different food                
                &ensp;gaining confidence
                &ensp;going to a different school
                &ensp;making new friends
                &ensp;missing family / friends / home
                &ensp;speaking a foreign language
                &ensp;visiting different places
              </div>

            </div>
              
          </div>
          
        `,
        answer: [''],
      },
    ],
  },
  2: {
    unit: 'Unit 1',
    id: 'FG11-SB-U1-P20-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page20/E2/Key/answerKey.png',
    component: T6,
    inputSize: 635,
    isHiddenCheck: true,
    maxLength: 48,
    hintBox: [
      {
        src: [
          'bedtime',
          'clothes',
          'going out with friends',
          'homework',
          'housework',
          'staying up late',
          'using computers or tablets',
        ],
        borderColor: 'white',
        fontColor: 'rgb(67 63 151)',
        width: '700px',
      },
    ],

    titleQuestion: [
      {
        num: '2',
        title: `
        Read the <span style='color:rgb(1 145 67)'>Speaking Strategy</span> and the task above. Then
        match one or two of the ideas below with each topic.`,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 500 },
    questions: [
      {
        title: `
						<div style='display:flex'>
								<div style='color:rgb(1 145 67)'>
										carrying a dictionary&emsp;doing hobbies together&emsp;&emsp;<br>
										eating in the canteen&emsp;helping with housework&emsp;&emsp;<br>
										keeping your room tidy&emsp;wearing a uniform
								</div>
        				<div style='width:14cm; height: 4.3cm ;font-size: 21px; box-shadow: 1px 2px 2px 2px gray;background:rgb(229 239 230);border: 6px dotted rgb(1 145 67);border-radius:15px; padding:15px'>
									<b style='color:rgb(1 145 67)'>
											Speaking Strategy
									</b> <br>
											Use your preparation time well. Read the task carefully.
											Then think of one thing to say about each topic. If you
											have more time, think of more ideas.
								</div>
						</div>
						<div style='padding:10px 15px;border-radius:15px;width:20cm;margin:10px 150px; background:rgb(236,251,255)'>
							Imagine the following situation: you recently spent a term
							as an exchange student in England. You are having a video
							call with a student from Japan who is preparing to take
							part in the same scheme and you are giving him / her
							some advice. Discuss the following topics.
							<ul>
								<li>Getting to know your exchange student</li>#
								<li>Useful things to take with you</li>#
								<li>Going to school in England</li>#
								<li>Advice about staying with an English family</li>#
							</ul
						</div>

        `,
        answer: [
          'doing hobbies together',
          'carrying a dictionary',
          'eating in the canteen, wearing a uniform / wearing a uniform, eating in the canteen',
          'keeping your room tidy, helping with housework / helping with housework, keeping your room tidy',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 1',
    id: 'FG11-SB-U1-P20-E3',
    audio: 'Audios/1.16-audio.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page20/E3/Key/answerKey.png',
    component: T6,
    inputSize: 906,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <headphone1 name='1.16' src='Audios/1.16-tieude.mp3'></headphone1> Listen to a student doing the task from
        exercise 2. Does she discuss all the topics? Which ideas
        from the exercise does she mention?`,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 906 },
    questions: [
      {
        title: `
						<div>
							 Does she discuss all the topics?<br>#	
						</div>
							 Which ideas from the exercise does she mention?<br><input id='1' width='906px'></input>

        `,
        answer: [
          'She discusses all the topics.',
          'She mentions doing hobbies together, eating in the canteen and wearing a uniform.',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 1',
    id: 'FG11-SB-U1-P20-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page20/E4/Key/answerKey.png',
    component: T6,
    inputSize: 260,
    isHiddenCheck: true,
    textAlign: 'center',
    maxLength: 21,

    titleQuestion: [
      {
        num: '4',
        title: `
        Read the <span style='color:rgb(1 145 67)'>Learn this!</span> box. Then complete sentences 1–3
        using the words in brackets. Do not change the words,
        but add extra words if necessary.`,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 500 },
    questions: [
      {
        title: `

        <div style='display:flex'>
        		<img style='width:16cm' src='img/FriendsPlus/Page20/E4/1.jpg'/>

        		<div style='line-height:55px'>
        		  <b>1</b>&emsp;You#(ought / find out) about his hobbies.<br>
        		  <b>2</b>&emsp;I#(think / should / send) him an email.<br>
        		  <b>3</b>&emsp;You#(ought / take) a present for the parents.<br>
        		</div>
        </div>
        `,
        answer: ['ought to find out', 'think you should send', 'ought to take'],
      },
    ],
  },

  5: {
    unit: 'Unit 1',
    id: 'FG11-SB-U1-P20-E5',
    audio: 'Audios/1.16-audio.mp3',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '5',
        title: `
         <headphone1 name='1.16' scr='Audios/1.16-tieude.mp3'></headphone1> Listen again. Check your answers to exercise 4.`,
        color: '#92278f',
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page20/E5/1.png' }]],
  },
  6: {
    unit: 'Unit 1',
    id: 'FG11-SB-U1-P20-E6',
    audio: '',
    video: '',
    // recorder: true,
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: `
          <span style='color:rgb(1 145 67)'>SPEAKING</span> 
        Work in pairs. Student A is an English student
who is going to stay with Student B’s family next month.
Ask for and give advice about these topics.`,
        color: '#92278f',
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page20/E6/1.jpg' }]],
  },
  7: {
    unit: 'Unit 1',
    id: 'FG11-SB-U1-P20-E7',
    audio: '',
    video: '',
    // recorder: true,
    component: UI,
    titleQuestion: [
      {
        num: '7',
        title: `
          <span style='color:rgb(1 145 67)'>SPEAKING</span> 
        Work in groups. Read the task below. Think of
				two ideas for each topic and make notes. Then do the
				task.`,
        color: '#92278f',
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page20/E7/1.jpg' }]],
  },
};

export default json;
