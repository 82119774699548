import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P124-U6.2-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/key/E1answerKey.png',
    component: Circle_Write,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: "<span style='font-size:30px'>Choose the correct option to complete the sentences.</span>",
        color: 'rgb(144 38 143)',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '',
        },
        limitSelect: 1,
        listWords: [
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c ',
        ],
        answers: ['2-8', '3-8', '0-4', '1-4', '4-0', '5-8'],
        initialValue: [],
      },
      /*html */
      Layout: `
       <div style="display:flex; flex-direction:column">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong>1</strong> <div >___  along the beach is very relaxing.</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="0" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span>Having walked</span>
            <span style="margin-left: 100px">Walking</span>
            <span style="margin-left: 150px">Walked</span>
          
          </div>
        </div>
       </div>
       </div>



        <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong>2</strong> <div >___   to class late for many times, he made his teacher angry.</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="1" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span> To come</span>
            <span style="margin-left: 160px">Having come</span>
            <span style="margin-left: 70px">Came</span>
          
          </div>
        </div>
       </div>
       </div>
       

         <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong style="margin-right:10px">3</strong> <div>  It’s not worth ___  for a refund if you don’t like the food.</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="2" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span> Having asked</span>
            <span style="margin-left: 100px">ask</span>
            <span style="margin-left: 180px">asking</span>
          </div>
        </div>
       </div>
       </div>



          <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong style="margin-right:10px">4</strong> <div>  She scolded her son for ___  rock climbing on his own.
</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="3" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span> to go</span>
            <span style="margin-left: 200px">gone</span>
            <span style="margin-left: 180px">having gone</span>
          </div>
        </div>
       </div>
       </div>



       
          <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong style="margin-right:10px">5</strong> <div> ___ a long way to find the restaurant was closed, we
were very disappointed.
</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="4" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span> Having driven</span>
            <span style="margin-left: 100px">Driving</span>
            <span style="margin-left: 150px">To drive</span>
          </div>
        </div>
       </div>
       </div>



       <div style="display:flex; flex-direction:column;margin-top:70px">
       <div style="display:flex;flex-direction:row; font-size:30px">
       <strong style="margin-right:10px">6</strong> <div>The rescue team succeeded in ___ the car up the hill.
</div>
       </div>
       <div>
      <div style="position: relative; width: 1000px;font-size:30px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="5" type="Circle"/></strong>
          <div style="position: absolute; left:70px; top: 0; z-index: 1">
            <span> having pushed</span>
            <span style="margin-left: 80px">pushed</span>
            <span style="margin-left: 150px">pushing</span>
          </div>
        </div>
       </div>
       </div>
				`,
      answer: [],
    },
  },
  2: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P124-U6.1-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/key/E2answerKey.png',
    component: T6,
    // hideBtnFooter:true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 22,
    },
    inputSize: 200,
    maxLength: 30,
    titleQuestion: [
      {
        num: '2',
        title: `
         <span> Complete the sentences with the correct form of the verbs in brackets.</span>
        `,
        color: 'rgb(144 38 143)',
      },
    ],
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
        <div style="display:flex;flex-direction:column;">

       
       
        <div style="display:flex;margin-bottom:20px">
         <strong  style="margin-right:20px">1</strong> # (have) a good sense of humour,  the entertainer makes the children laugh a lot.
        </div>
        <div style="display:flex;margin-bottom:20px">
         <strong  style="margin-right:20px">2</strong> #  (be) all well received by readers,  The Hunger Games was made into a film in 2012.
        </div>
         <div style="display:flex;margin-bottom:20px">
         <strong  style="margin-right:20px">3</strong> #  (watch) a really good video clip on the internet the other day, Beth wrote a review about it.
        </div>
        <div style="display:flex;margin-bottom:20px">
         <strong  style="margin-right:20px">4</strong>The woman accused the taxi driver of # (drive) carelessly.
        </div>
       <div style="display:flex;margin-bottom:20px">
         <strong  style="margin-right:20px">5</strong> #  (finish) her novel for over two months, Rin wants it to be published soon.
        </div>
         <div style="display:flex;margin-bottom:20px">
         <strong  style="margin-right:20px">6</strong>Khoa always dreams of # (travel) around the world on his motorbike.
        </div>
          </div>
        `,
        answer: ['Having', 'Having been', 'Having watched', 'driving', 'Having finished', 'travelling'],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P124-U6.3-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/key/1.1answerKey.png',
    component: T6v2,
    stylesTextInput: {
      textAlign: 'left',
    },
    inputSize: 700,
    maxLength: 23,
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="font-size:30px">Rewrite the questions as indirect questions. Use the
phrases in brackets. </span>
        `,
        color: 'rgb(144 38 143)',
      },
    ],
    textareaStyle: {
      width: 600,
    },
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div>

          <div style="display:flex"><strong style="margin-right:20px">1</strong>Where is the post office? (Could you tell me)</div>
#           <div style="display:flex"><strong style="margin-right:20px">2</strong>Do you have any mayonnaise? (I was wondering)</div>
#            <div style="display:flex"><strong style="margin-right:20px">3</strong>What time does the film finish? (I’d like to know)</div>
#             <div style="display:flex"><strong style="margin-right:20px">4</strong>What is your name? (May I ask)</div>
#              <div style="display:flex"><strong style="margin-right:20px">5</strong>What is the salary for this job? (I’d be interested to know)</div>
#               <div style="display:flex"><strong style="margin-right:20px">6</strong>Should I apply for the job online? (I’d like to know)</div>
#                <div style="display:flex"><strong style="margin-right:20px">7</strong>Did you receive my application? (I’d be interested to know)</div>
#                 <div style="display:flex"><strong style="margin-right:20px">8</strong>When does the train leave? (Could you tell me)</div>
#                  <div style="display:flex"><strong style="margin-right:20px">9</strong>Do you have a table for four? (I was wondering)</div>
#                   <div style="display:flex"><strong style="margin-right:20px">10</strong>When will the interviews take place? (May I ask)</div>
#          
          </div>
        `,
        answer: [
          'Could you tell me where the post office is? ',
          'I was wondering if / whether you have any mayonnaise',
          `I'd like to know what time the film finishes.`,
          'May I ask what your name is?',
          `I'd be interested to know what the salary for this job is.`,
          `I'd like to know if / whether I should apply for the job online.`,
          `I'd be interested to know if / whether you received my invitation.`,
          'Could you tell me when the train leaves? ',
          'I was wondering if / whether you have a table for four.',
          'May I ask when the interviews will take place?',
        ],
      },
    ],
  },
};

export default json;
