import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P40-E1',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		maxLength: 16,
		exerciseKey: 'img/FriendsPlus/Page40/Key/E1answerKey.png',
		question: [],
		textAlign: 'left',
		titleQuestion: [
			{
				num: '1',
				title: `What differences can you see between the first and last
pair of photos? 
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'become dehydrated',
					'climber',
					'desert',
					'food and water',
					'frostbite',
					'high altitude',
					'oxygen',
					'tent',
				],
				width: 750,
				fontColor: '#be1e54',
				borderColor: 'none',
			},
		],
		// maxLength: 14,
		isHiddenCheck: true,
		textareaStyle: { width: 950 },
		inputSize: 450,
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: ` 
          <img src="img/FriendsPlus/Page40/E1/1.jpg" style="width:90%" />
           <div style='display:flex'>
            <u style='font-style:italic;font-weight:bold'>Answers:</u>
            <textarea id='0' rows='3'></textarea>

          

           

           </div>
          `,
				answer: [
					'In the last photo, the people have bigger eyes, larger noses and nostrils, larger brains and foreheads and darker skin.',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 3',
		audio: '',
		id: 'FGF11-U3-P40-E2',
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page40/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `Read the article. Did you mention any differences in
exercise 1 that are not mentioned in the text?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		maxLength: 9,
		hintBox: [
			{
				src: ['<b>Parts of the body</b>', 'ankle', 'wrist'],
				width: 900,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 150,
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex'>
          <img  style='height:13cm'  src="img/FriendsPlus/Page40/E2/1.jpg" />
        
          <div>
            <u style='font-style:italic;font-weight:bold'>Answers:</u> <br>
              <textarea id='0' rows='3'></textarea>
            </div>

           </div>
           
          `,
				answer: [''],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P40-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page40/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `Read the <span style="color:#ee9933">Learn this!</span> box. Complete the examples and
				rules. How many more examples of each tense can you find in the article?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		// maxLength: 6,
		hintBox: [
			{
				src: ['<b>Parts of the body</b>', 'ankle', 'blood', 'bottom', 'brain', 'calf', 'cheek', 'chin', 'LOL'],
				width: 900,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		maxLength: 15,
		component: T6v2,
		isHiddenCheck: true,
		textareaStyle: {
			// background: 'none',
			width: 550,
			fontSize: 22,
			height: 350,
		},

		inputSize: 240,
		textAlign: 'center',
		stylesTextInput: { fontSize: 23, height: 25, borderBottom: 'none', background: 'none' },
		questionImage: [],
		questions: [
			{
				title: `
        <div style='position: relative;margin-left: -90px'>
            <img  src="img/FriendsPlus/Page40/E3/1.jpg" style="margin-left:20px; width: 18cm"/>
            <span style='position: absolute; top: 117px; left: 246px'>#</span>
            <span style='position: absolute; top: 223px; left: 299px'>#</span>
            <span style='position: absolute; top: 295px; left: 253px'>#</span>
            <span style='position: absolute; top: 367px; left: 250px'>#</span>
            <span style='position: absolute; top: 07px; left: 730px'>
							<b>How many more examples of each tense can you find in the article?</b>
							<textarea id='4' row='4'>
						</span>
            
        </div>
        <div style='font-size: 22px; display: flex'>
				 <img  style='width:15cm; height:13cm'  src="img/FriendsPlus/Page40/E2/1.jpg" />
				
					
        </div>
          `,
				answer: [
					'will have',
					'will be',
					'perfect',
					'continuous',
					'future perfect 5: How will the human body have changed in 100,000 years? As a result, our eyelids will have become thicker …; Our nostrils will have grown larger …; The size of our skull will have increased …; our brain will have got larger. future continuous 2: Humans will be living in other parts of the solar system.; … we will be using a nano-chip inside our head …',
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P40-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page40/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: ` Complete the sentences using the correct future continuous
or future perfect form of the verbs in brackets.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		maxLength: 17,
		component: T6,
		isHiddenCheck: true,
		textareaStyle: { width: 340 },
		inputSize: 250,
		textAlign: 'center',
		// recorder: true,
		questionImage: [],
		questions: [
			{
				title: `
        <div style='line-height:2'>
            <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:20px'>Five hours from now, we#(finish) this English lesson.</div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>My brother is at university, but in two years’ time, he#(work).</div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:20px'>Hopefully, I#(not live) with my parents when I’m thirty.</div>
          </div>
          <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:20px'>I’m sure the party will be a big surprise for her. Nobody#(tell) her about it.</div>
          </div>
          <div style='display:flex'>
            <b>5</b>
            <div style='margin-left:20px'>According to the weather forecast, the sun#(shine) all day tomorrow.</div>
          </div>
        
        </div>
           
          `,
				answer: ['will have finished', 'will be working', "won't be living", 'will have told', 'will be shining'],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P40-E5',
		// audio: 'Audios/1.33.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page40/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `Read the <span style="color:#ee9933">Learn this!</span> box. Add two more time
expressions from the article in exercise 2.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: 'USE OF ENGLISH' },
			},
		],

		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 40,
		isHiddenCheck: true,
		textareaStyle: { width: 530, fontSize: 22 },
		inputSize: 620,
		// stylesTextInput: { fontSize: 22, height: 23, borderBottom: 'none' },
		// textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <img style='width:50%' src="img/FriendsPlus/Page40/E2/1.jpg" />
          <img style='width:50%' src="img/FriendsPlus/Page40/E5/1.jpg" />
             <div>
            <u style='font-style:italic;font-weight:bold'>Answers:</u> <br>
              #
            </div>      
           
          `,
				answer: ['in 100,000 years, In the distant future'],
			},
		],
	},

	6: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P40-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page40/Key/E6answerKey.png',

		titleQuestion: [
			{
				num: '6',
				title: ` <span style="color:rgb(10 127 64)">SPEAKING</span> Read the prompts. Then in pairs, make predictions
using the future continuous or future perfect and a suitable
time expression. Do you agree with your friend?.`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 12,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 145,
		// stylesTextInput: { fontSize: 22, height: 23, borderBottom: 'none', background: 'none' },
		textAlign: 'center',

		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex'>
            <div ><b>1</b>&ensp;Scientists / find a cure for most diseases<textarea id='0' rows='2'></textarea><br>
            <b>2</b>&ensp;Most people / live to 200<textarea id='1' rows='3'></textarea><br>
            <b>3</b>&ensp;New diseases / appear <textarea id='2' rows='3'></textarea><br></div>
            <div style='margin-left:20px'><b>4</b>&ensp;Computers / manage all major companies<textarea id='3' rows='4'></textarea><br><b>5</b>&ensp;The Earth / fight wars against other planets<textarea id='4' rows='4  '></textarea><br></div>
        </div>
                 
          `,
				answer: [
					'',
					`I think most people will be living to 200 years from now./ I don't think most people will be living to 200 years from now.`,
					`I think new diseases will have appeared in 1,000 years' time./ I don't think new diseases will have appeared in 1,000 years' time.`,
					`I think computers will be managing all major companies in the foreseeable future./ I don't think computers will be managing all major companies in the foreseeable future.`,
					`I think the Earth will be fighting wars against other planets 10,000 years from now./ I don't think the Earth will be fighting wars against other planets 10,000 years from now.`,
				],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P40-E7',
		audio: '',
		video: '',
		// exerciseKey: 'img/FriendsPlus/Page40/Key/E7answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `<b style="color:rg#(10 127 64)">SPEAKING</b>  Discuss the questions in pairs.
`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Compound nouns</b>',
					'flood lights',
					'football pitch',
					'main road',
					'mountain range',
					'safety net',
					'sea shore',
					'swimming pool',
					'tennis court',
					'tennis player',
					'tower block',
				],
				width: 800,
				fontColor: '#8e258c',
				borderColor: 'none',
			},
		],
		component: T6,
		maxLength: 1,
		// isHiddenCheck: true,
		textareaStyle: { width: 540 },
		inputSize: 40,
		textAlign: 'center',
		recorder: true,
		//hideBtnFooter: true,
		questionImage: [],
		questions: [
			{
				title: `
          <div style='display:flex;width:18cm'>
            <b>1</b>
            <div style='margin-left:20px'>
              Have you ever been awake all night or most of the night?
              If so, when / where / why?
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>
              Have you ever felt very cold? If so, when / where / why?
            </div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:20px'>
              Have you ever experienced high altitude? If so, when /
              where / why? How did you feel?
            </div>
          </div>

          `,
				answer: [''],
			},
		],
	},
	8: {
		// Exercise num
		unit: 'Unit 3',
		id: 'FGF11-U3-P40-E8',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/Key/E4answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '8',
				title: `<b style="color:rg#(10 127 64)">SPEAKING</b> Work in groups. Which facility from exercise 7
would you like most for your school? Why? Can the whole
class agree on one choice?`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page40/E8/1.pNg' }]],
	},
}

export default json
