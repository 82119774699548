import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P59-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page59/E1/Key/E1answerKey.png',
    component: DesignUnderLine,
    totalInput: 3,
    isHiddenCheck: true,
    titleQuestion: [
      {
        title: `
        <div style='color: rgb(124,181,220); font-size: 30px'>Reading</div>
        Read the three texts and choose the best answers (A–D).
			  `,
        color: '#92278f',
      },
    ],
    questionImage: [
      // EX 1

      [{ url: 'img/FriendsPlus/Page59/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page59/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/3.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page59/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page59/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/8.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page59/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/11.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page59/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page59/E1/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/16.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/18.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page59/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/21.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page59/E1/22.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page59/E1/23.jpg' }],
      [
        { url: 'img/FriendsPlus/Page59/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/25.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page59/E1/26.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page59/E1/27.jpg' }],
      [
        { url: 'img/FriendsPlus/Page59/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/29.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page59/E1/30.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page59/E1/31.jpg' }],
      [
        { url: 'img/FriendsPlus/Page59/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/33.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page59/E1/34.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page59/E1/35.jpg' }],
      [
        { url: 'img/FriendsPlus/Page59/E1/36.jpg' },
        { url: 'img/FriendsPlus/Page59/E1/37.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page59/E1/38.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page59/E1/39.jpg' }],
    ],
  },
  2: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P59-E2',
    audio: 'Audios/2.15-audio.mp3',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_14.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page59/E2/Key/E2answerKey.png',
    component: T6,
    inputSize: 300,
    maxLength: 15,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 24,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        title: `
				<div style='font-size: '>
				<div style='color: rgb(124,181,220); font-size: 27px'>Listening</div>
				<headphone1 name="2.15" src="-tieude.mp3"></headphone1>
				You will hear a conversation about house moves. 
				Complete each sentence with no more than three words.</div>
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				
				<div style='display: flex: flex-direction: row; line-height:2.5'>
					<div><b>1. </b> Steve now lives in a(n) # house in a village.</div>
					<div><b>2. </b> Liz’s old flat was # than her new flat.</div>
					<div><b>3. </b> Steve would rather # spend so much time waiting for buses.</div>
					<div><b>4. </b> Steve’s # enjoying doing DIY.</div>
					<div><b>5. </b> Liz # live in the countryside.</div>
					
				</div>

	      `,
        answer: ['old detached', 'smaller', 'not', 'dad is', 'wants to'],
      },
    ],
  },
  3: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P59-E3',
    // audio: 'Audios/2.15.mp3',
    // audio: 'Audios/Friends Global Grade 10 SB_CD2_14.mp3',
    video: '',
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page59/E2/Key/E2answerKey.png',
    component: T6,
    inputSize: 700,
    hideBtnFooter: true,
    // maxLength: 15,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'left',
      fontSize: 24,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        title: `
				<div style='font-size: 24px'>
				<div style='color: rgb(124,181,220); font-size: 27px'>Speaking</div>
				Work in groups. Compare and contrast the photos 
				(A and B) which show homeless people in Britain. 
				Include the following points.</div>
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<img style="width:15cm" src='img/FriendsPlus/Page59/E3/1.jpg' />
				<div style='display: flex: flex-direction: row; width:80%'>
					<div>Where homeless people usually live.</div>
					<div> # </div>
					<div>Why people may become homeless.</div>
					<div> # </div>
					<div>How you think we can help homeless people.</div>
					<div> # </div>
				</div>

	      `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Unit 4',
    id: 'FGF11-U4-P59-E4',
    video: '',
    // exerciseKey: 'img/FriendsPlus/Page59/E2/Key/E2answerKey.png',
    component: T6,
    inputSize: 1000,
    // maxLength: 15,
    hideBtnFooter: true,
    textareaStyle: {
      // background: 'none',
      width: 700,
      fontSize: 20,
      height: 400,
    },
    titleQuestion: [
      {
        title: `
				<div style='font-size: 20px'>
				<div style='color: rgb(124,181,220); font-size: 27px'>Writing</div>
				<div>You have recently moved to a different house, and you 
				want to give the news to an English-speaking friend in 
				another country. In your letter,<br>
				- tell your friend the news.<br>
				- explain why you have moved.<br>
				- describe your new house.<br>
				- invite your friend to come and visit<br>
				<b>Write at least 150 words.</b>
				</div>
				
				</div>
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
				<div style='font-size:20px; margin-left:10px'>
					<b>ANSWER:</b>
					<textarea id='0' row='30'></textarea>
					</div>


	      `,
        answer: [],
      },
    ],
  },
};

export default json;
