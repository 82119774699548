import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  4: {
    unit: 'unit 5',
    id: 'FGF11-A-P67-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page67/Key/E4answerKey.png',
    component: Circle_Write,
    inputSize: 40,
    maxLength: 1,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
          Read the rest of the article. Choose the correct answers (a–d).
        `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: 'center' },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: '50%',
          fontWeight: 'bold',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
          `a <br> b <br> c <br> d`, //1
        ],
        answers: ['0-0', '1-0', '2-4', '3-0'],
        initialValue: [],
      },
      Layout: `
      <div >
        <div style ='flex:1;width:100%'>


 						<b>1</b>&ensp;
		      Ann Sullutor
            <div style="display: flex;margin-left:40px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      is an invention of the 22nd century. <br>
              costs a huge sum of money. <br>
              takes a 20-hour energy charge. <br>
              can’t be controlled orally.
		          </div>
            </div>


          
            <b>2</b>&ensp;
		     The inventor of Ann Sullutor
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	     takes inspiration from Helen Keller’s tutor.<br>
             is a dedicated tutor to visually-impaired children. <br>
            has a fondness for wristwatches and jewellery. <br>
            works as a teacher in mainstream schools.
		          </div>
            </div>

              <b>3</b>&ensp;
		          Nick can
            <div style="display: flex;margin-left:40px">
		          <div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	       regularly send reports to Ann Sullutor. <br>
             listen to Ann Sullutor’s instructions. <br>
              switch Ann Sullutor off.<br>
              always get Ann Sullutor’s help with his schoolwork immediately.
		          </div>
            </div>


           <b>4</b>&ensp;
		          The writer
            <div style="display: flex;margin-left:40px">
		          <div><input id='3' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	      believes that Ann Sullutor can be of great help to a child. <br>
             finds Ann Sullutor needs a lot of improvement. <br>
             thinks the lessons Ann Sullutor suggests are too difficult.<br>
              suggests that Ann Sullutor should be registered in the school system for the best use.
		          </div>
            </div>

		          </div>
            </div>
           </div>
      </div>

                        </div>


        
      `,
    },
  },

  5: {
    unit: 'unit 5',
    id: 'FGF11-A-P67-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page67/Key/E5answerKey.png',
    component: T6,
    inputSize: 250,
    isHiddenCheck: true,
    maxLength: 15,
    isHiddenCheck: true,
    stylesTextInput: {
      fontSize: 23,
      height: 29,
      textAlign: 'center',
    },
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '5',
        title: `
					<span style="color: rgb(146, 39, 144)"> VOCABULARY </span> Complete the verb–noun collocations with the nouns below. They are all in the text.
				`,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style='font-size: 23px; line-height: 60px'>
          <div style='color: rgb(0,103,180)'>
          <b>Verb-noun collocations</b> &ensp;&ensp; track &ensp; the gap &ensp; a hand &ensp; reports &ensp; suggestion(s)
          </div>
          <div style="display:flex">
            <div>
              <div><b>1</b> make #</div>
              <div><b>2</b> send #</div>
              <div><b>3</b> bridge #</div>
            </div>
             <div style="margin-left:100px">
              <div><b>4</b> offer #</div>
              <div><b>5</b> keep #</div>

            </div>
          </div>
        </div>
				  `,
        answer: [`suggestion(s)`, `reports`, `the gap`, `a hand`, `track`],
      },
    ],
  },
  6: {
    unit: 'unit 5',
    id: 'FGF11-A-P67-E6',
    audio: '',
    video: '',
    // exerciseKey: '',
    component: T6,
    inputSize: 40,
    maxLength: 150,
    recorder: true,
    hideBtnFooter: true,
    //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 1060 },
    titleQuestion: [
      {
        num: '6',
        title: `
					 <span style="color:green">SPEAKING</span> Work in pairs. Discuss the advantages and 
          disadvantages if you have Ann Sullutor as a companion. 
          Then share your ideas with the class. Which idea is the best?
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: ` <img  src="img/FriendsPlus/Page67/E6/1.jpg" style="width:60%;margin-left:200px"/>`,
        answer: [],
      },
    ],
  },
};

export default json;
