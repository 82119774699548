import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P26-E1',
		audio: '',
		video: '',
		component: T6,
		inputSize: 500,
		titleImage: '',
		// recorder: true,
		hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page26/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: 'Look at the photo and the title of the article below. What is the man’s hobby, do you think?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
           <img style='width:12cm; margin-left:350px' src='img/FriendsPlus/Page26/E1/1.jpg' />
          `,
				answer: [],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P26-E2',
		audio: '',
		video: '',
		component: T6,
		inputSize: 500,
		// maxLength: 1,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page26/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: 'Read the article and check your ideas. Do you collect anything? If so, what?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
          <div style='display:flex'>
           <div>
					  <img style='width:15cm' src='img/FriendsPlus/Page26/E1/1.jpg'>
						</div>
						<div style="margin-left:30px">
						<div><b>ANSWER:</b></div>
							#
						</div>
          </div>      
          `,
				answer: ["He collects McDonald's memorabilia."],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P26-E3',
		// audio: 'Audios/1.21.mp3',
		video: '',
		component: T6,
		inputSize: 480,
		textareaStyle: {
			// background: 'none',
			width: 370,
			height: 150,
			fontSize: 21,
		},
		stylesTextInput: {
			width: 180,
			background: 'none',
			borderBottom: 'none',
			fontSize: 21,
			height: 22,
		},
		maxLength: 20,
		titleImage: '',
		textAlign: 'center',
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page26/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title:
					'<span style="font-size:23px">Find all the examples of the past simple and the present perfect in the article.<br>Complete the rules in the <span style="color:orange">Learn this!</span> box below with present perfect or past simple.<br>Then underline an example of each rule in the article.</span>',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
      <div style='position:relative'>
			<div style='display:flex; position: relative'>
					<div>
							<div style=' display: flex; flex-direction: column'>
            		<b>1 present perfect:</b>      
            		<div><textarea id='5' rows='4'></div>
            	</div> 
					      
          		<div style='display: flex; flex-direction: column'>
          		  <b>2 past simple:</b>        
          		  <div><textarea id='6' rows='4'></div>
          		</div> 
					</div>
					<div>                         
						<img style='width:193mm; height:12cm' src='img/FriendsPlus/Page26/E1/2.jpg' />
					</div>
    			<div style=" position: absolute; top: 45px; left: 551px; width:300px "><input id='0'></input></div>
    			<div style=" position: absolute; top:109px; left: 551px; width:300px "><input id='1'></input></div>
    			<div style=" position: absolute; top:172px; left: 551px; width:300px "><input id='2'></input></div>
     			<div style=" position: absolute; top:238px; left: 551px; width:300px "><input id='3'></input></div>
        	<div style=" position: absolute; top:330px; left: 551px; width:300px "><input id='4'></input></div>
      	</div>
			</div>

     				
     
</div>
				<img style=' height: 17cm' src='img/FriendsPlus/Page26/E1/1.jpg' />


          `,
				answer: [
					'past simple',
					'present perfect',
					'present perfect',
					'present perfect',
					'present perfect',
					"His first job was cooking Big Macs; … he began collecting badges …; … Mike opened his own McDonald's restaurant …",
					"… has spent almost fifty …; He has already filled …; Mike has been at McDonald's …; … and he hasn't stopped since; He has decorated it …; …they've ever seen …",
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P26-E4',
		// audio: 'Audios/1.22.mp3',
		video: '',
		component: T6,
		inputSize: 50,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 22,
			height: 30,
			width: 550,
			// textTransform: 'uppercase',
		},
		textareaStyle: {
			// background: 'none',
			width: 550,
			fontSize: 22,
			height: 200,
		},
		titleImage: '',
		textAlign: 'left',
		exerciseKey: 'img/FriendsPlus/Page26/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title:
					'<span style="font-size:23px; width:80%">Complete the lists with the orange time phrases from the article. Which tense is used with ‘finished’ time phrases and which with ‘unfinished’ time phrases? </sp>',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
      <div style=''>
        <div style='display: flex; margin-bottom: 50px'>
          <img style='height:13cm;' src='img/FriendsPlus/Page26/E1/1.jpg' />
          <div style='margin-left: 10px'>
            <div style=''>  
            <div>
             <b>1</b> Time phrases with the past simple: <span style='color: rgb(152,198,204)'>yesterday,... </span>
            </div>   
            <div style='margin-bottom: 30px'>#</div>   
          </div> 
					       
          <div style=''>  
            <div>
              <b>2</b> Time phrases with the present perfect: <span style='color: rgb(152,198,204)'>already,... </span>
            </div>
            <div style='margin-bottom: 30px'>#</div>      
          </div> 
          <div style='margin-top:10px;'>
						<div><b>Which tense is used with ‘finished’ time phrases and which with ‘unfinished’ time phrases?</b></div>
            <div><textarea id='2' row='2'></div>
				</div>
          </div>
					
        </div>
          
        
        </div>
          `,
				answer: [
					'A year later, A few years ago',
					'almost fifty years, already, since 1968, ever',
					"The past simple is used with 'finished time' phrases. The present perfect is used with 'unfinished time' phrases.",
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P26-E5',
		audio: '',
		video: '',
		component: T6,
		maxLength: 12,
		inputSize: 50,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 21,
			height: 22,
			width: 150,
		},
		isHiddenCheck: true,
		// maxLength: ,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page26/Key/E5answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title:
					'<span style="font-size: 23px">Complete the article above with the present perfect or past simple form of the verbs in brackets.</span>',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style=" position: relative;margin-left:20px ">                        
					<img style='width:22cm; height: 16cm' src='img/FriendsPlus/Page26/E5/1.jpg' />
				
        
    		<div style="  position: absolute; top:236px; left: 57px; width:202mm; font-size: 20px">
          Jian Yang is in his thirties and collects dolls. So far, he 
          <sup>1 </sup> <input id='0'></input> (spend) twenty years and over £250,000 
          on his collection, which includes 6,000 Barbie dolls. The young 
          man from Singapore <sup>2 </sup> <input id='1'></input> (start) collecting 
          Barbie dolls when he <sup>3 </sup> <input id='2'></input> (be) just thirteen. 
          The first doll he <sup>4 </sup> <input id='3'></input> (buy) was the ‘Great 
          Shape’ model in a gym outfit and leg warmers. Jian buys dolls 
          when he travels for work and <sup>5 </sup> <input id='4'></input> (purchase) 
          65 dolls on his last trip to New York. A while ago, a girlfriend 
          <sup>6 </sup> <input id='5'></input> (walk out) on him because of his hobby. 
          Apparently, she <sup>7 </sup> <input id='6'></input> (feel) threatened by his 
          collection. Jian <sup>8 </sup> <input id='7'></input> (find) that worrying, but 
          now he accepts it. 
        
        </div>
      </div>
          `,
				answer: ['has spent', 'started', 'was', 'bought', 'purchased', 'walked out', 'felt', 'found'],
			},
		],
	},
	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P26-E5',
		// audio: 'Audios/1.22.mp3',
		video: '',
		component: T6,
		isHiddenCheck: true,
		inputSize: 50,
		textareaStyle: { width: 500 },
		titleImage: '',
		maxLength: 4,
		textAlign: 'left',
		exerciseKey: 'img/FriendsPlus/Page26/Key/E6answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title:
					'Read the <span style="color:orange">Look out!</span> box. Complete the example with the correct tense of the verb go. (Remember that go has two past participles.).',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: '' }]],
		questions: [
			{
				title: `
      <div style=" position: relative; padding-top:10px; ">	
				<div>
				<img style='width:21cm' src='img/FriendsPlus/Page26/E1/4.jpg' />
				</div>
    		<div style="  position: absolute; top: 203px;left: 62px; width: 97px; "><input id='0' font-size='23px' width='97px' border-bottom='0px'></input></div>
    		<div style="  position: absolute; top: 203px;left:184px; width: 100px; "><input id='1' font-size='23px' width='100px' border-bottom='0px'></input></div>
    		<div style="  position: absolute; top: 203px;left: 474px; width: 97px; "><input id='2' font-size='23px' width='97px' border-bottom='0px'></input></div>
      </div>
          `,
				answer: ['Have', 'been', 'went'],
			},
		],
	},

	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'FGF11-U2-P26-E7',
		// audio: 'Audios/Friends Global Grade 10 SB_CD1_24.mp3',
		video: '',
		component: T6,
		hideBtnFooter: true,
		// recorder: true,
		titleQuestion: [
			{
				num: '7',
				title: `
				<span style="color: rgb(2, 146, 97);">SPEAKING</span> Work in pairs. Ask and answer about the experiences below.Give more details using the past simple.
					`,
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
				<div style='display: flex;'>
				<ul>
						<li>Go abroad</li>
						<li>Go ice skating</li>
						<li>Do martial arts</li>
						<li>Speak English in a dream</li>						
				</ul>
				<ul style='margin-left: 58px;'>
						<li>Break a bone</li>
						<li>Ride a horse</li>
						<li>Find any money</li>
				</ul>
				</div>
          `,
				answer: [],
			},
		],
	},
}

export default json
