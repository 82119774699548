import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P45-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    recorder: true,

    titleQuestion: [
      {
        num: '1',
        title: `
				<span style='color:rgb(2, 146, 97);'> Speaking </span>Discuss the questions in pairs. Give reasons for your opinions.

			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    hideBtnFooter: true,
    // recorder: true,
    textareaStyle: { width: 500 },
    questions: [
      {
        title: `

          <div style='display: flex; gap:30px; margin-left: 0px;'>
            <div style='padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div> Do you think most teenagers have a healthy lifestyle?   
</div>
              </div>
              <div style='' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div>  Do you have a healthy lifestyle? Why? / Why not?

</div>
              </div>
         </div>
       
			  `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P45-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page45/key/E2answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
      Read the task below. What two different elements does the task contain?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    // //hideBtnFooter: true,
    // recorder: true,
    textareaStyle: { width: 450 },
    questions: [
      {
        title: `

         <div style='background-color: rgb(210, 222, 244); margin-bottom: 20px ; border-radius: 15px; margin-top:20px '>
              <div style='padding: 15px 25px 25px 25px;' >
                <div>Many people agree that teenagers don’t get enough
                <br> exercise. Write an essay in which you give your own view 
                <br> of the problem and propose ways of solving it. </div>
                
              </div>
            </div>
  
          <div style='display: flex;'>
              <div>
                <div style='display: flex;'>
                  <div style='margin-top: 7px;'><b> First element:  </b></div>
                  <div><textarea id="0" rows="2"></textarea></input></div>
                </div>
                  <div style='display: flex;'>
                  <div style='margin-top: 7px;'><b> Second element:  </b></div>
                  <div><textarea id="1" rows="2"></textarea></input></div>
                </div>
                </div>
                                </div>

			  `,
        answer: [`Describe the problem (teenagers not getting enough exercise)`, `Ways of solving this problem`],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P45-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page45/key/E3answerKey.png',
    // recorder: true,

    titleQuestion: [
      {
        num: '3',
        title: `
      Read the essay. What is the writer’s opinion about the problem? How many solutions does she propose?

			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textareaStyle: { width: 500 },
    questions: [
      {
        title: `
         <div style='display:flex;'>
          <div style='flex:2.5'>
          <img src='img/FriendsPlus/Page45/E3/1.jpg' style='max-width:100%'>
          </div>
          <div style='flex:2.5; margin-left:100px'>
          What is the writer’s opinion about the problem?
          <textarea id='0' rows='2'></textarea>
          How many solutions does she propose?
           <textarea id='1' rows='4'></textarea>
          
         </div>
       
			  `,
        answer: [
          `She thinks that the problem is serious.`,
          `She proposes two solutions: making school canteens serve healthy food and giving teenagers free membership of their local sports facilities. `,
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'FG11-U3-P45-E4',
    audio: '',
    video: '',
    component: T6,
    inputSize: 875,
    exerciseKey: 'img/FriendsPlus/Page45/Key/E4answerKey.png',
    checkUppercase: true,
    // maxLength: 48,
    titleQuestion: [
      {
        num: '4',
        title: `
        Read the <span style='color:rgb(38 130 197)'>Writing Strategy</span>. Does the writer follow all of  the advice? Give examples.`,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 925 },
    questions: [
      {
        title: `
                <img src='img/FriendsPlus/Page45/E4/1.jpg' style='max-width:60%'  />
                <div>
                 Does the writer follow all of the advice? 
                 <br> # <br>
                 Give examples. <br>
                 <b>1.</b> #
                 <br> <b>2.</b> #
                 <br> <b>3.</b> #
                 <br> <b>4.</b> #


                </div>
        `,
        answer: [
          'Yes.',
          `1 is an introduction, 2 and 3 are the main body and 4 is the conclusion.`,
          `2 deals with the causes and 3 suggests solutions.`,
          `What is more, …, in order to …, Furthermore, …`,
          `I strongly believe …, What I propose is …, I would strongly recommend …`,
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P45-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page45/key/E5answerKey.png',
    inputSize: 135,
    titleQuestion: [
      {
        num: '5',
        title: `
				<span style='color:rgb(124, 8, 79);'> KEY PHRASES </span> Complete the useful phrases with the words below and find them in the text in exercise 3.

			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
    },
    // selectOptionRandom: true,
    selectOptionValues: ['T', 'F'],
    questions: [
      {
        title: `
         <div style="max-width: 574px; color: rgb(38 130 197); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span>common</span><span>conclusion</span><span>view</span>
          <span>opinion</span><span>propose</span><span>said</span><span>see</span><span>seems</span>
          <span>solution</span><span>sum</span><span>What</span><span>widely</span><span>would</span>
        </div>
       <div style=' border-left: 5px solid rgb(192, 232, 198);width:100%'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(38 130 197);'>1&ensp;Introducing your opinions</b></div>
          <div>I (strongly) believe that &ensp; In my <sup>1</sup># / <sup>2</sup>#</div> 
          <div>It <sup>3#</sup> to me that &ensp; As I <sup>4</sup> # it,</div>

           <div><b style='color:rgb(38 130 197);'>2&ensp;Introducing other people’s opinions</b></div>
          <div> It is a <sup>5</sup># held view that</div>
          <div> It is often <sup>6</sup># that</div>
          <div> It is a <sup>7</sup># belief that</div>

           <div><b style='color:rgb(38 130 197);'>3&ensp;Making an additional point</b></div>
          <div><sup>8</sup># is more, &ensp; Moreover, &ensp; Futhermore</div>
           <div><b style='color:rgb(38 130 197);'>4&ensp;Introducing proposals and solutions </b></div>
          <div>One <sup>9</sup># might be to </div>
          <div>What I <sup>10</sup># (instead) is that </div>
          <div>I <sup>11</sup># strongly recommend that </div>

           <div><b style='color:rgb(38 130 197);'>5&ensp;Concluding </b></div>
          <div> To <sup>12</sup># up, &ensp; In <sup>13</sup>#, &ensp; To conclude,</div>
          </div>
			  `,
        answer: [
          'view',
          `opinion`,
          `seems`,
          `see `,
          `widely`,
          `said`,
          `common`,
          `What`,
          `solution`,
          `propose`,
          `would`,
          `sum`,
          `conclusion`,
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P45-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    recorder: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <b style='color:rgb(2, 146, 97);'>SPEAKING</b> Work in pairs. Read the task below and discuss questions 1–3. Write notes.

			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page45/E6/1.jpg' style ='max-width:60%'>
          <div style='display: flex;'>
            <div style='padding: 10px 10px 25px 10px;' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div> Which sports do you do at school? How often?   
</div>
              </div>
              
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 </b></div>
                <div>  Are there other subjects that are more important than sport? <br>  Which ones? Why?

</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 </b></div>
                <div>  How could more sport be added to the school timetable <br> without affecting other subjects?
</div>
              </div>
         </div>

			  `,
        answer: [],
      },
    ],
  },
  7: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P45-E7',
    audio: '',
    video: '',
    component: T6,
    textareaStyle: { width: 550 },
    hideBtnFooter: true,
    maxLength: 1,
    textAlign: 'center',
    inputSize: 50,
    exerciseKey: 'img/FriendsPlus/Page23/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `
          Using your notes from exercise 6, write a paragraph plan for your essay. Use the essay in exercise 3 as a model and follow the advice in the <span style="margin-right: 10px; color: rgb(0, 111, 147); font-size: 30px;">Writing Strategy.</span>
          `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style="display: flex; flex-wrap: wrap; gap: 30px;">
          <div style="flex: 1;">
            <div style="margin-top: 10px;"><img src='img/FriendsPlus/Page45/E4/1.jpg' width='90%';/></div>
            <div style="margin-top: 10px;"><img src='img/FriendsPlus/Page45/E3/1.jpg' width='90%';/></div>
          </div>
          <div style="flex: 1;"><textarea id="0" rows="17"></textarea></div>

          
        </div>
        `,
        answer: [],
      },
    ],
  },
  8: {
    unit: 'Unit 3',
    id: 'FGF11-U3-P46-E8',
    audio: '',
    video: '',
    component: T6,
    textareaStyle: { width: 550 },
    hideBtnFooter: true,
    maxLength: 1,
    textAlign: 'center',
    inputSize: 50,
    exerciseKey: 'img/FriendsPlus/Page23/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title: `
          Write your essay (150–180 words). Follow your paragraph plan from exercise 7 and the advice in the  <span style="margin-right: 10px; color: rgb(0, 111, 147); font-size: 30px;">Writing Strategy.</span>Use phrases from exercise 5.
          `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style="display: flex; flex-wrap: wrap; gap: 30px;">
          <div style="flex: 1;">
          <div style="margin-top:30px;max-width: 574px; color: rgb(38 130 197); font-weight: bold; display: flex; gap: 5px 30px; flex-wrap: wrap;">
          <span>common</span><span>conclusion</span><span>view</span>
          <span>opinion</span><span>propose</span><span>said</span><span>see</span><span>seems</span>
          <span>solution</span><span>sum</span><span>What</span><span>widely</span><span>would</span>
        </div>
            <div style="margin-top: 50px;"><img src='img/FriendsPlus/Page45/E8/1.jpg' width='100%';/></div>
          </div>
          <div style="flex: 1;"><textarea id="0" rows="17"></textarea></div>

          
        </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
