import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		unit: 'Introduction',
		id: 'FGF11-I-P10-E1',
		recorder: true,
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		isHiddenCheck: true,
		// exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span> In pairs, describe the photo. Would you  like to appear on stage in a theatre production?  Why? / Why not? `,
				color: '#92278f',
			},
		],

		textareaStyle: { width: 550 },
		questions: [
			{
				title: `
        <img src='img/FriendsPlus/Page10/E1.jpg' style='width:12cm;margin-left:70px';/>
        `,
				answer: [''],
			},
		],
	},
	2: {
		unit: 'Introduce',
		id: 'FGF11-I-P10-E2',
		audio: 'Audios/1.04-audio.mp3',
		video: '',
		component: T6,
		isHiddenCheck: true,
		textareaStyle: { width: 400, fontSize: 22 },
		exerciseKey: 'img/FriendsPlus/Page10/Key/E2answerkey.png',
		titleQuestion: [
			{
				num: '2',
				title: `<headphone1 name="1.04" src='Audios/1.04-tieude.mp3'></headphone1>
        Read and listen to the dialogue. Why  does Mason change from feeling anxious to  feeling terrified? `,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
        <div style='display:flex;font-size:23px'>
          <div style='font-weight:bold;'>
             Ruby<br><br>
             Mason<br><br>
             Ruby<br>
             Mason<br>
             Ruby<br>
             Mason<br>
             Ruby<br><br>
             Miss Hart<br><br><br>
             Mason<br>
             Miss Hart<br><br>
             Ruby<br>
              Mason
          </div>
          <div style='width:15cm;margin:0px 30px;'>
              So this is your first school show, Mason. 
              Are you excited about it?<br>
               Yes, I am. But I’m anxious too. There’s 
              going to be a big audience!<br>
               Don’t worry. They’re friendly!<br>
               I hope so. Where’s Alex? He isn’t here yet.<br>
               That’s strange. He’s usually very punctual.<br>
               Maybe he’s too frightened to come.<br>
               Alex? No, it can’t be that. He’s a really 
              confident person.<br>
               Hello, Mason. I had a message from Alex. He’s ill 
              and can’t perform tonight. He’s very upset about 
              it. Can you sing his song in the second half?<br>
               I know the song well, so maybe …<br>
               Thanks, Mason. That’s brave of you. Don’t worry, 
              you’ll be great.<br>
               You’ve got your own song now. How exciting!<br>
               I know. But I’m terrified
          </div>
          
          <div><u style='font-weight:bold;font-style:italic'>Answer:</u>  <textarea id=0 rows='2'></textarea></div>
        </div>
        `,
				answer: ["He's terrified because he now has to sing a song on his own."],
			},
		],
	},
	3: {
		unit: 'Introduce',
		id: 'FGF11-I-P10-E3',
		audio: '',
		video: '',
		component: Circle_Write,
		inputSize: 10,
		isHiddenCheck: true,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page10/Key/E3answerkey.png',
		titleQuestion: [
			{
				num: '3',
				title: `<span style="color: purple ">VOCABULARY</span> Look at the adjectives below. Underline five of them in the dialogue in exercise 2. `,
				color: '#92278f',
				// prefix: { icons: [''], text: '' },
			},
		],
		hintBox: [
			{
				src: [
					'<b>Adjectives describing feelings</b>',
					'anxious',
					'ashamed',
					'bored',
					'confused',
					'cross',
					'delighted',
					'disappointed',
					'embarrassed',
					'envious',
					'excited',
					'frightened',
					'proud',
					'relieved',
					'shocked',
					'suspicious',
					'terrified',
					'upset',
				],
				fontColor: '#92278f',
				borderColor: 'none',
				width: 1100,
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					// borderRadius: "50%,
					borderBottom: '2px solid black',
					borderColor: 'transparent',
					// color: "#00aeef",
				},
				selectWordStyle: {
					borderBottom: '2px solid black',
					borderColor: 'black',
				},
				limitSelect: 5,
				listWords: [
					` So this is your first school show, Mason. 
              Are you excited about it? <br>
               Yes, I am. But I’m anxious too. There’s 
              going to be a big audience! <br>
               Don’t worry. They’re friendly! <br>
               I hope so. Where’s Alex? He isn’t here yet. <br>
               That’s strange. He’s usually very punctual. <br>
               Maybe he’s too frightened to come. <br>
               Alex? No, it can’t be that. He’s a really 
              confident person. <br>
               Hello, Mason. I had a message from Alex. He’s ill 
              and can’t perform tonight. He’s very upset about 
              it. Can you sing his song in the second half? <br>
               I know the song well, so maybe … <br>
               Thanks, Mason. That’s brave of you. Don’t worry, 
              you’ll be great. <br>
               You’ve got your own song now. How exciting! <br>
               I know. But I’m terrified`,
				],
				answers: ['0-778', '0-320', '0-504', '0-100', '0-52'],

				initialValue: [],
			},

			Layout: `
    <div style='width:1100px'><hintbox id=0></hintbox></div>
      <div style='display:flex;'>
          <div style='font-weight:bold;'>
             Ruby<br>
             Mason<br>
             Ruby<br>
             Mason<br>
             Ruby<br>
             Mason<br>
             Ruby<br>
             Miss Hart<br><br>
             Mason<br>
             Miss Hart<br>
             Ruby<br>
            Mason
          </div>
          <div style='width:24cm;margin-left:30px'>
            <input id='0'  type='Circle' />
          </div>
        </div>
      `,
		},
	},
	4: {
		unit: 'Introduction',
		id: 'FGF11-I-P10-E4',
		audio: 'Audios/1.05-audio.mp3',
		video: '',
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page10/Key/E4answerkey.png',
		titleQuestion: [
			{
				num: '4',
				title: `<headphone1 name="1.05" src='Audios/1.05-tieude.mp3'></headphone1>
        Listen to the speakers. How is each person feeling? Choose from the adjectives in exercise 3.  `,
				color: '#92278f',
			},
		],

		component: Circle_Write,
		hintBox: [
			{
				src: [
					'<b>Adjectives describing feelings</b>',
					'anxious',
					'ashamed',
					'bored',
					'confused',
					'cross',
					'delighted',
					'disappointed',
					'embarrassed',
					'envious',
					'excited',
					'frightened',
					'proud',
					'relieved',
					'shocked',
					'suspicious',
					'terrified',
					'upset',
				],
				fontColor: '#92278f',
				borderColor: 'none',
				width: 1100,
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					// borderRadius: "50%,
					borderBottom: '2px solid black',
					borderColor: 'transparent',
					color: '#92278f',
				},
				selectWordStyle: {
					// padding: 5,
					// border: "solid 2px",
					borderBottom: '3px solid',
					borderColor: '#92278f',
				},
				limitSelect: 6,
				listWords: [
					`anxious &emsp;&ensp; ashamed &emsp;&ensp; bored &emsp;&ensp; 
confused &emsp;&ensp; cross &emsp;&ensp; delighted &emsp;&ensp; disappointed &emsp;&ensp; embarrassed &emsp;&ensp; 
envious &emsp;&ensp; excited &emsp;&ensp; frightened &emsp;&ensp; proud &emsp;&ensp; relieved &emsp;&ensp; shocked &emsp;&ensp; 
suspicious &emsp;&ensp; terrified &emsp;&ensp; upset`,
				],
				answers: ['0-66', '0-36', '0-30', '0-52', '0-14', '0-62'],

				initialValue: [],
			},

			Layout: `
      <div style='width:25cm; margin-bottom: 100px'>
      
          <b style='color:rgb(146 39 143);margin-right:40px'>
              Adjectives describing feelings
          </b>
            <input id='0'  type='Circle' />
      </div>
        
        
      `,
		},
	},
	5: {
		unit: 'Introduction',
		id: 'FGF11-I-P10-E5',
		audio: '',
		video: '',
		component: Circle_Write,
		inputSize: 250,
		// //hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page10/Key/E5answerkey.png',
		titleQuestion: [
			{
				num: '5',
				title: `<span style="color: purple ">VOCABULARY</span>
 Work in pairs. Look at the list of personality adjectives below. Then underline four more in the dialogue in exercise 2. How many other personality adjectives do you know? `,
				color: '#92278f',
			},
		],

		hintBox: [
			{
				src: [
					'<b>Adjectives describing feelings</b>',
					'flexible',
					'hard-working',
					'honest',
					'kind',
					'loyal',
					'organised',
					'outgoing',
					'patient',
					'reliable',
					'sensitive',
					'shy',
				],
				fontColor: '#92278f',
				borderColor: 'none',
				width: 1100,
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					// borderRadius: "50%",
					// border: "2px solid black",
					borderColor: 'transparent',
					// fontWeight:"bold",
					color: 'black',
				},
				selectWordStyle: {
					// padding: 5,
					// border: "solid 2px",
					borderBottom: '3px solid',
					borderColor: '#92278f',
				},
				limitSelect: 4,
				listWords: [
					` So this is your first school show, Mason. 
              Are you excited about it? <br>
               Yes, I am. But I’m anxious too. There’s 
              going to be a big audience! <br>
               Don’t worry. They’re friendly! <br>
               I hope so. Where’s Alex? He isn’t here yet. <br>
               That’s strange. He’s usually very punctual. <br>
               Maybe he’s too frightened to come. <br>
               Alex? No, it can’t be that. He’s a really 
              confident person. <br>
               Hello, Mason. I had a message from Alex. He’s ill 
              and can’t perform tonight. He’s very upset about 
              it. Can you sing his song in the second half? <br>
               I know the song well, so maybe … <br>
               Thanks, Mason. That’s brave of you. Don’t worry, 
              you’ll be great. <br>
               You’ve got your own song now. How exciting! <br>
               I know. But I’m terrified`,
				],
				answers: ['0-186', '0-280', '0-406', '0-644'],

				initialValue: [],
			},

			Layout: `
               <hintbox id=0></hintbox>
      <div style='display:flex;'>
          <div style='font-weight:bold;'>
             Ruby<br>
             Mason<br>
             Ruby<br>
             Mason<br>
             Ruby<br>
             Mason<br>
             Ruby<br>
             Miss Hart<br><br>
             Mason<br>
             Miss Hart<br>
             Ruby<br>
            Mason
          </div>
          <div style='width:24cm;margin-left:30px'>
            <input id='0'  type='Circle' />
          </div>
        </div>
                  
        `,
		},
	},
	6: {
		unit: 'Introduction',
		id: 'FGF11-I-P10-E6',
		audio: '',
		video: '',
		component: T6,
		maxLength: 12,
		// stylesTextInput: {
		//   border: 'none',
		//   fontSize: '22px',
		// },
		textAlign: 'center',
		inputSize: 170,
		isHiddenCheck: true,
		// //hideBtnFooter: true,

		exerciseKey: 'img/FriendsPlus/Page10/Key/E6answerkey.png',
		titleQuestion: [
			{
				num: '6',
				title: `
				Read the <span style="margin-right: 10px; color: #92278f"> Look out!</span>box. Complete the sentences with an <i>-ed or -ing</i> adjective formed from the verbs in brackets.
				`,
				color: '#92278f',
			},
		],

		questions: [
			{
				title: `
        			<div ><img style='width:17cm' src='img/FriendsPlus/Page10/E6/1.jpg'></div>

       <div style='font-size:23px'>
            <div>
             <b>1</b>&ensp;The singing and dancing were # (amaze).  
             </div>
			      	<div>
               <b>2</b>&ensp; A few actors forgot their lines and looked quite # (embarrass). 
             </div>
			      	<div>
               <b>3</b>&ensp;  The final scenes were actually quite # (move).  
             </div>
             <div>
               <b>4</b>&ensp;   I was # (surprise) that it was over two hours long.  
             </div>
             <div>
               <b>5</b>&ensp;   I was a bit # (bore) by the end of it.
             </div>
        </div>
        
       
        `,
				answer: ['amazing', 'embarrassed', 'moving', 'surprised', 'bored'],
			},
		],
	},
	7: {
		unit: 'Introduction',
		id: 'FGF11-I-P10-E7',
		audio: '',
		video: '',
		component: T6,
		inputSize: 300,
		maxLength: 28,
		textAlign: 'center',
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page10/Key/E7answerkey.png',
		titleQuestion: [
			{
				num: '7',
				title: `
				Read the <span style = "margin-right: 10px; color: #92278f"> Lean this</span>box. Which adjectives from exercise 5 can have a negative prefix? Use a dictionary to help you.
				`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		hintBox: [
			{
				src: [
					'<b>Adjectives describing feelings</b>',
					'flexible',
					'hard-working',
					'honest',
					'kind',
					'loyal',
					'organised',
					'outgoing',
					'patient',
					'reliable',
					'sensitive',
					'shy',
				],
				fontColor: '#92278f',
				borderColor: 'none',
				width: 1100,
			},
		],
		questions: [
			{
				title: `
        <img style="margin-left :100px; width:18cm" src='img/FriendsPlus/Page10/E7/1.jpg'>
				
						<div>
							    <b>
                   Which adjectives from exercise 5 can have a negative prefix? <br>
                   #&emsp;&emsp;#<br>
                   #&emsp;&emsp;#<br>
                   #&emsp;&emsp;#<br>
                   #&emsp;&emsp;#
						</div>
        `,
				answer: [
					'flexible - inflexible',
					'honest - dishonest',
					'kind - unkind',
					'loyal - disloyal',
					'organised - disorganised',
					'patient - impatient',
					'reliable - unreliable',
					'sensitive - insensitive',
				],
			},
		],
	},
	8: {
		unit: 'Introduction',
		id: 'FGF11-I-P10-E8',
		audio: '',
		video: '',
		component: T6,
		inputSize: 780,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '8',
				title: `<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span> Work in pairs. Ask and answer the questions.`,
				color: '#92278f',
			},
		],
		recorder: true,
		textareaStyle: { width: 550 },
		questions: [
			{
				title: `
         <div style='line-height: 50px'>
          <b>1</b>&ensp; How do you think performers in a school show usually feel before and after a performance? <br>
        
          <b>2</b>&ensp; Do you mind speaking in public? Why? / Why not?<br>
      
        </div>
				
        
        `,
				answer: [''],
			},
		],
	},
}

export default json
