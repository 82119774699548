import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P15-E1',
		audio: '',
		video: '',
		component: T6,
		// recorder: true,
		// hideBtnFooter: true,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page15/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span> Work in pairs. Look at the photo. 
				What do you think this app does? Why might some people need it?
				`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		questions: [
			{
				title: `

				<div style="display: flex; gap: 20px;">
					<div><img src='img/FriendsPlus/Page15/E1/1.jpg' width='100%';/></div>
         <div>
          <u style='font-style:italic;font-weight:bold'>Answers:</u>
          <textarea id="0" rows="10"></textarea>
         </div>
				</div>
        `,
				answer: [
					`It allows parents to lock their children's phones at times when they should be doing other things such as studying. Some children might need this app because they spend too much time on their phones instead of doing other things.`,
					// '',
				],
			},
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P15-E2',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		// recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span> 
				Read the text and check your answer to exercise 1. 
				Do you think the app would increase or decrease the number of arguments 
				in your family? Why?
				`,
				color: '#92278f',
			},
		],
		textareaStyle: { width: 500 },
		questions: [
			{
				title: `
				<div style="display: flex; gap: 20px; align-items: flex-start;">
					<div style="border-radius: 10px; border: 1px solid rgb(238, 53, 95); padding: 10px;">
					<img src='img/FriendsPlus/Page15/E2/1.jpg' width='100%';/></div>
					<textarea id="0" rows="9"></textarea>
				</div>
        `,
				answer: [``],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P15-E3',
		audio: '',
		video: '',
		component: T6,
		textAlign: 'center',
		isHiddenCheck: true,
		inputSize: 190,
		exerciseKey: 'img/FriendsPlus/Page15/E3/Key/answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `
				<span style="margin-right: 10px; color: rgb(146, 39, 144)">VOCABULARY</span>
				Work in pairs. Check the meaning of the adjectives below. 
				Which describe a positive attitude? Which describe a negative attitude?
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="max-width: 900px; color: rgb(196, 12, 70); display: flex; gap: 5px 30px; flex-wrap: wrap;">
					<span style="font-weight: bold;">Attitude (adjectives)</span>
					<span>accusing</span><span>aggressive</span><span>arrogant</span><span>bitter</span>
					<span>calm</span><span>complimentary</span><span>enthusiastic</span><span>grateful</span>
					<span>miserable</span><span>nostalgic</span><span>optimistic</span><span>pessimistic</span>
					<span>sarcastic</span><span>sympathetic</span><span>urgent</span>
				</div>

				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 20px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">positive</span>
						<div>#, #, #, #, #, #, #</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">negative</span>
						<div>#, #, #, #, #, #, #, #, #</div>
					</div>
				</div>	
        `,
				answer: [
					'calm',
					'complimentary',
					'enthusiastic',
					'grateful',
					'nostalgic',
					'optimistic',
					'sympathetic',
					'accusing',
					'aggressive',
					'arrogant',
					'bitter',
					'miserable',
					'nostalgic',
					'pessimistic',
					'sarcastic',
					'urgent',
				],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P4-E4',
		audio: 'Audios/1.09-audio.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page15/E4/Key/answerKey.png',
		component: Circle_Write,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '4',
				title: `
				<span style='margin-right: 10px;'><headphone1 name="1.09" src="Audios/1.09-tieude.mp3"></headphone1></span>
				Read the <span style="color: rgb(196, 12, 70);">Listening Strategy</span>. 
				Then listen and underline the adjective which best matches the speaker’s attitude. 
				Use their tone of voice to help you.
				`,
				color: '#92278f',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { border: 'none', borderColor: 'transparent' },
				// initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 5, borderBottom: '2px solid', borderColor: 'gray' },
				limitSelect: 1,
				listWords: [
					`arrogant / pessimistic / confident`, // 0
					`aggressive / miserable / sarcastic`, // 1
					`calm / complimentary / optimistic`, // 2
					`accusing / enthusiastic / sympathetic`, // 3
				],
				answers: ['0-4', '1-0', '2-4', '3-8'],
				initialValue: [],
			},
			Layout: `
			<div><img src='img/FriendsPlus/Page15/E4/1.jpg' width='50%';/></div>

			<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
				<div style="display: flex; gap: 20px;">
					<strong>1</strong>
					<div>
						<input id='0' type='Circle' /> 
					</div>
				</div>
				<div style="display: flex; gap: 20px;">
					<strong>2</strong>
					<div>
						<input id='1' type='Circle' /> 
					</div>
				</div>
				<div style="display: flex; gap: 20px;">
					<strong>3</strong>
					<div>
						<input id='2' type='Circle' /> 
					</div>
				</div>
				<div style="display: flex; gap: 20px;">
					<strong>4</strong>
					<div>
						<input id='3' type='Circle' /> 
					</div>
				</div>
			</div>
      `,
		},
	},
	5: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P15-E5',
		audio: 'Audios/1.10-audio.mp3',
		video: '',
		component: T6,
		hideBtnFooter: true,
		// recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title: `
				<span style='margin-right: 10px;'><headphone1 name="1.10" src="Audios/1.10-tieude.mp3"></headphone1></span>
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span> 
				Listen and compare two different ways of saying the sentences. 
				Then, in pairs, say a sentence in one of the two ways. 
				Can your partner guess the adjective?
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">1</span>
						<div>‘Thanks, Andy. That’s really helpful.’ (first grateful, then sarcastic)</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">2</span>
						<div>‘Our train leaves in ten minutes.’ (first calm, then urgent)</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">3</span>
						<div>‘You and your sister always argued during dinner.’ (first nostalgic, then accusing)</div>
					</div>
				</div>	
        `,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P15-E6',
		audio: 'Audios/1.11-audio.mp3',
		video: '',
		component: T6,
		maxLength: 1,
		isHiddenCheck: true,
		textAlign: 'center',
		inputSize: 50,
		exerciseKey: 'img/FriendsPlus/Page15/E6/Key/answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title: `
				<span style='margin-right: 10px'><headphone1 name="1.11" src="Audios/1.11-tieude.mp3"></headphone1></span>
				Work in pairs. Listen to four speakers and decide which speaker sounds:
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="display: flex; gap: 100px; flex-wrap: wrap;">
					<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 5px;">
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">a</span>
							<div>urgent</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">b</span>
							<div>calm</div>
						</div>
					</div>	
					<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 5px;">
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">c</span>
							<div>enthusiastic</div>
						</div>
						<div style="display: flex; gap: 10px;">
							<span style="font-weight: bold; margin-right: 10px;">d</span>
							<div>arrogant</div>
						</div>
					</div>	
				</div>

				<div>
					<div style="margin-top: 20px; font-style: italic; text-decoration: underline;">Answer:</div>
					<div style="margin-top: 10px; display: flex; gap: 30px; flex-wrap: wrap;">
						<div style="font-weight: bold; margin-right: -15px;">Speaker 1: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Speaker 2: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Speaker 3: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Speaker 4: </div>#
					</div>
				</div>
        `,
				answer: ['b', 'c', 'a', 'd'],
			},
		],
	},
	7: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P15-E7',
		audio: 'Audios/1.11-audio.mp3',
		video: '',
		component: T6,
		maxLength: 1,
		isHiddenCheck: true,
		textAlign: 'center',
		inputSize: 50,
		exerciseKey: 'img/FriendsPlus/Page15/E7/Key/answerKey.png',
		titleQuestion: [
			{
				num: '7',
				title: `
				<span style='margin-right: 10px;'><headphone1 name="1.11" src="Audios/1.11-tieude.mp3"></headphone1></span>
				Listen again. Match sentences A–E with speakers 1–4. 
				Use the tone of voice to help you. There is one extra sentence.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">A</span>
						<div>The speaker is giving advice about winning family arguments.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">B</span>
						<div>We learn how a bad argument had a positive result for the speaker.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">C</span>
						<div>The speaker is advertising a course for families who want to argue less.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">D</span>
						<div>The speaker is persuading somebody to attend a family reunion.</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<span style="font-weight: bold; margin-right: 10px;">E</span>
						<div>The speaker describes how a relative lost his job because of a family argument.</div>
					</div>
				</div>	

				<div>
					<div style="margin-top: 20px; font-style: italic; text-decoration: underline;">Answer:</div>
					<div style="margin-top: 10px; display: flex; gap: 30px; flex-wrap: wrap;">
						<div style="font-weight: bold; margin-right: -15px;">Speaker 1: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Speaker 2: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Speaker 3: </div>#
						<div style="font-weight: bold; margin-right: -15px;">Speaker 4: </div>#
					</div>
				</div>
        `,
				answer: ['C', 'A', 'D', 'B'],
			},
		],
	},
	8: {
		unit: 'Unit 1',
		id: 'FGF11-U1-P15-E8',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		// recorder: true,
		exerciseKey: 'img/FriendsPlus/Page15/E8/Key/answerKey.png',
		titleQuestion: [
			{
				num: '8',
				title: `
				<span style="margin-right: 10px; color: rgb(0, 147, 69)">SPEAKING</span> 
				Work in pairs. Decide which of these topics is most likely to 
				cause arguments in your family and why. 
				Are there any others you can think of? 
				Compare your ideas with the class.
				`,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
				<div>
					<div>Doing chores</div>
					<div>Staying out late</div>
					<div>Sharing a family computer</div>
					<div>Too much time spent on social media and games</div>
					<div>What to eat</div>
				</div>
        `,
				answer: [],
			},
		],
	},
}

export default json
