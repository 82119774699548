import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P126-U7.1-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page126/key/E1answerKey.png',
    component: T6v2,
    textAlign: 'center',
    titleQuestion: [
      {
        num: '1',
        title: `
          Complete the passive sentences with the correct form of the
verb be. One sentence has more than one correct answer.
      
        `,
        color: 'rgb(158,16,86)',
      },
    ],
    stylesTextInput: {
      fontSize: '23px',
    },
    inputSize: 240,
    // maxLength: 15,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style="font-size:23px">

          <div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">1</strong> Your homework <span>#</span> returned to you
tomorrow afternoon</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">2</strong> As I was walking home last night, I thought <span>#</span> that I followed.
</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">3</strong>The road into town <span>#</span> closed for three
days last week.</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">4</strong> ‘Where <span>#</span> parmesan cheese made?’
‘In Italy.’</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">5</strong> Right now the match <span>#</span> watched by
millions of people round the world.</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">6</strong> My dress <span>#</span> cleaned, so I can wear it
tonight.</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">7</strong> I rang the station, and they told me that the train <span>#</span>  cancelled.</div>
           
          </div>
				`,
        answer: [
          'will be',
          'was being',
          'was',
          'is',
          'is being',
          'has been / is being | has been | is being',
          'had been',
        ],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P126-U7.1-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page126/key/E2answerKey.png',
    component: T6,
    isHiddenCheck: true,
    stylesTextInput: {
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '2',
        title: `
         <span>Complete the sentences with the passive form of the verbs
below. Use an appropriate tense. </span>
        `,
        color: 'rgb(158,16,86)',
      },
    ],
    inputSize: 240,

    maxLength: 21,
    // //hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div>
          <span style="color:rgb(247,160,70);  margin-bottm:30px;word-spacing:15px"> destroy    film   perform  sell    steal   upload    use</span>
					<div/>

					<div style='line-height:2'>
					 <div style="display:flex;">
					<b>1</b>
					<div style='margin-left:10px'> Six paintings # from the museum lastnight.</div>
					</div>

				<div style="display:flex;">
				<b>2</b>
				<div style='margin-left:10px'>We had to have our choir rehearsal outside yesterday because the school hall  # for a meeting.</div>
			</div>
			<div style="display:flex;">
			<b>3</b>
			<div style='margin-left:10px'>This play # only # two or
			three times in the last fifty years.</div>
			</div>
			<div style="display:flex;">
			<b>4</b>
			<div style='margin-left:10px'>By the time they put the fire out, most of the opera house #.</div>
			</div>
			<div style="display:flex;">
			<b>5</b> <div style='margin-left:10px'>Millions of photos # to social
			networking sites every day.</div>
			</div>
		<div style="display:flex;">
		<b>6</b>
 				<div style='margin-left:10px'>When the new art gallery opens next year,  	the old art gallery # to property developers.</div>

			</div>
			<div style="display:flex;">
			<b>7</b>
			<div style='margin-left:10px'>
			They’re halfway through making the film.
			It #  in the Middle East</div>
			</div>
					</div>

		
           
        `,
        answer: [
          'were stolen',
          'was being used',
          'has',
          'been performed',
          'had been destroyed',
          'are uploaded',
          'will be sold',
          'is being filmed',
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P126-U7.1-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page126/key/E3answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: `
          <div> Complete the sentences with a modal verb followed by a
passive infinitive. Use the words in brackets.</div>
         
        `,
        color: 'rgb(158,16,86)',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      height: 30,
    },
    inputSize: 250,
    // maxLength: 9,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    questions: [
      {
        title: `
          <div>
          
          <div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">1</strong> If it rains, the match # (might / cancel)</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">2</strong> During lessons, your mobile phone  # in your bag. (must / leave)
</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">3</strong>Meat # right through. (should / cook)</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">4</strong> Books # out of the library. (must not / take)</div>
<div style="display:flex;flex-direction:row;margin-bottom:20px"><strong style="margin-right:20px">5</strong> School uniforms # on the school trip. (needn’t / wear)</div>

          </div>
        `,
        answer: ['might be cancelled', 'must be left', 'should be cooked', 'must not be taken', `needn't be worn`],
      },
    ],
  },
  4: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P126-U7.2-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page126/key/E4answerKey.png',
    component: T6,
    textAlign: 'center',
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '1',
        title: `
          Complete the second sentence in each pair to mean the
same as the first. Use the structure have something done. 

        `,
        color: 'rgb(158,16,86)',
      },
    ],
    stylesTextInput: {
      fontSize: 25,
    },
    inputSize: 450,
    // maxLength: 21,
    questions: [
      {
        title: `
						<b>1</b>&ensp; Somebody vandalised our car at the weekend.<br>&emsp; We# at the weekend. 
						<div style='margin:20px 0px'> <b>2</b>&ensp; They dyed Grace’s hair at the hairdresser’s.<br>&emsp; Grace#  at the hairdresser’s. </div>
						<b>3</b>&ensp; They’re going to repair my laptop by Monday.<br>&emsp; I#  for the front cover of the magazine. 
						<div style='margin:20px 0px'> <b>4</b>&ensp; They chose Seth’s photo for the front cover of the magazine.<br>&emsp; Seth#  for the front cover of the magazine. </div>
						<b>5</b>&ensp; My uncle’s nose was broken in a boxing match.<br>&emsp; My uncle#  in a boxing match. 
						<div style='margin:20px 0px'> <b>6</b>&ensp; Somebody designed a new website for my dad’s business.<br>&emsp; My dad#  for his business. </div>
        `,
        answer: [
          'had our car vandalised',
          'had her hair dyed',
          "'m going to have my laptop repaired",
          'had his photo chosen',
          'had his nose broken',
          'had a new website designed',
        ],
      },
    ],
  },
  5: {
    unit: 'Grammar Builder and Reference',
    id: 'FGF11-GBAR-P126-U7.1-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page126/E5/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: `
          <div> Write true sentences about your own experiences using
have something done and the words below.</div>
        
        `,
        color: 'rgb(158,16,86)',
      },
    ],
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 22,
    },
    textareaStyle: {
      width: 1100,
      height: 120,
      fontSize: 35,
    },
    inputSize: 850,
    maxLength: 18,
    isHiddenCheck: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div>
          <div style="display:flex;flex-direction:row">  <strong style="margin-right:20px" >1 </strong>  hearing / test</div>
           <span style=" color: rgb(66,153,210); font-style:italic">I’ve never had my hearing tested. / I had my hearing tested
when I was very young.</span>

  <div style="display:flex;flex-direction:row">  <strong style="margin-right:20px" >2 </strong>  some money / steal</div>
<input/>
	<div style="display:flex;flex-direction:row">  <strong style="margin-right:20px" >3 </strong> eyes / test
</div>
<input/>
      <div style="display:flex;flex-direction:row">  <strong style="margin-right:20px" >4 </strong>  nails / paint</div>
<input/>

        <div style="display:flex;flex-direction:row">  <strong style="margin-right:20px" >5 </strong>  bike / repair
</div>
<input/>


          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
